import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AnalyticsService } from 'src/app/services/analytics.service';
import { UserService } from 'src/app/services/user.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService, TOKEN_KEY } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';

import * as LoginSelectors from 'src/app/store/selectors/login.selectors';
import { Storage } from '@capacitor/storage';

@Component({
  selector: 'app-external',
  templateUrl: './external.page.html',
  styleUrls: ['./external.page.scss'],
})
export class ExternalPage implements OnInit {
  @Input() company: any;
  user = {
    username: '',
    password: '',
  };

  user$: Observable<User>;

  loginForm: FormGroup;
  submitAttempt = false;
  errorMsg: any;

  loggedUser: any;
  isWeb: boolean = false;

  constructor(
    private viewCtrl: ModalController,
    private analytics: AnalyticsService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private fb: FormBuilder,
    private userService: UserService,
    private companiesService: CompaniesService,
    private auth: AuthService,
    private router: Router,
    private store: Store,
    private platform: Platform,
  ) {
    platform.ready().then(() => {
      if (!platform.is('mobile')) this.isWeb = true;
    });
  }

  ngOnInit() {
    this.analytics.trackScreen(
      'login_company_external',
      'LoginOperadoraExternalScreen',
    );
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    // Getting user data if logged in
    this.user$ = this.store.select(LoginSelectors.getUser);
    this.user$.subscribe((res) => (this.loggedUser = res?.name ? true : false));
  }

  async formSubmit() {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.companiesService
      .authenticateExternal(this.company.id, this.user)
      .subscribe(
        async (res) => {
          if (res.success) {
            const externalData: any = {
              access: res.access,
              empresa: this.company,
              empresa_id: this.company.id,
              user: this.user,
            };

            if (res.subscriberId) {
              externalData.subscriberId = res.subscriberId;
            } else {
              externalData.login_data = res.login_data;
            }

            this.validateAuthorization(externalData, loading, res.message);
          } else {
            this.presentAlert('Oops!', res.message);
            await loading.dismiss();
          }
        },
        async (err) => {
          this.presentAlert(
            'Oops!',
            'Algo deu errado na autenticação com o sistema da sua operadora.',
          );
          await loading.dismiss();
        },
      );
  }

  formattedReturnTypesLogin(type: 'title' | 'placeholder' | 'error' = 'title') {
    let hasPassword = this.company?.campsoft?.type_logins_password;

    if (this.company?.campsoft) {
      let auxLoginMethods = this.company?.campsoft?.type_logins_identifiers.map(
        (identifier, id) => ` ${identifier.name}`,
      );

      if (type === 'title')
        auxLoginMethods =
          auxLoginMethods + (hasPassword ? ' e senha da' : ' da');

      return auxLoginMethods;
    }

    switch (type) {
      case 'title':
        return 'usuário e senha do';
      case 'placeholder':
        return 'login de usuário';
      case 'error':
        return 'usuário';
    }
  }

  /**
   * Validate user authorization
   */
  validateAuthorization(externalData, loading, message) {
    this.companiesService
      .validateAuthorizationResourceExternal(this.company.id, externalData)
      .subscribe(
        async (res) => {
          // if has errors
          if (!res.success) {
            this.errorMsg = res.message;
            await loading.dismiss();
            return;
          }

          // if is logged user
          if (this.loggedUser) {
            this.saveSubscriberData(externalData, loading);
            return;
          }
          // if already has an user with this subscriberId
          if (res.user_found) {
            this.loginByExternalKey(externalData, loading);
            return;
          }

          this.startUserCreation(externalData, loading);

          this.presentToast(message);
        },
        async (err) => {
          this.presentAlert(
            'Oops!',
            'Algo deu errado na validação do seu usuário.',
          );
          await loading.dismiss();
        },
      );
  }

  loginByExternalKey(externalData, loading) {
    if (externalData.subscriberId) {
      this.loginWithSubscriber(externalData.subscriberId, loading);
      return;
    }
    if (externalData.login_data) {
      this.loginInterFocusData(externalData.login_data, loading);
      return;
    }
  }

  /**
   * Obtain User Token from subscriberId
   */
  loginWithSubscriber(subscriberId, loading) {
    this.auth.loginSubscriber(this.company.id, subscriberId).subscribe(
      async (res) => {
        this.auth.loadToken();
        this.userService.loadUser();

        await loading.dismiss();
        this.router.navigateByUrl('/home', { replaceUrl: true });
        this.closeModal();
      },
      async (err) => {
        this.presentAlert('Oops!', 'Algo deu errado no seu login.');
        await loading.dismiss();
      },
    );
  }

  /**
   * Obtain User Token from subscriberId
   */
  loginInterFocusData(loginData, loading) {
    this.auth.loginInterFocusData(this.company.id, loginData).subscribe(
      async (res) => {
        this.auth.loadToken();
        this.userService.loadUser();

        await loading.dismiss();
        this.router.navigateByUrl('/home', { replaceUrl: true });
        this.closeModal();
      },
      async (err) => {
        this.presentAlert('Oops!', 'Algo deu errado no seu login.');
        await loading.dismiss();
      },
    );
  }

  async saveSubscriberData(externalData, loading) {
    this.userService.saveSubscriberData(externalData).subscribe(
      async (res) => {
        if (!res.success) {
          await loading.dismiss();
          this.presentAlert(
            'Oops!',
            res?.message || 'Algo deu errado na sua vinculação.',
          );
          return;
        }

        this.auth.loadToken();
        this.userService.loadUser();

        await loading.dismiss();

        this.router.navigateByUrl('/home', { replaceUrl: true });
        this.closeModal();
      },
      async (err) => {
        await loading.dismiss();
      },
    );
  }

  /**
   * @author André Ramos <oi@andreramos.dev>
   * @param externalData subscriberId from user
   */
  async startUserCreation(externalData, loading) {
    if (this.company.neotv) {
      // se é da NEO, cria um cadastro sem os dados sensíveis (nome, email, cpf, telefone etc)
      // regra contratual com eles :x
      this.createUserBlank(externalData, loading);
      return;
    }

    await loading.dismiss();
    // se não é da NEO, abre a tela de cadastro
    this.openRegisterModal(externalData);
    this.closeModal();
  }

  /**
   * Create user with subscriberId and Company
   */
  createUserBlank(externalData, loading) {
    this.auth.register(externalData).subscribe(
      async (res) => {
        if (!res.erro) {
          this.presentToast('Registro criado com sucesso!');
          this.loginWithSubscriber(externalData.subscriberId, loading);
          return;
        }
        this.presentToast('Algo deu errado no seu cadastro no nosso sistema.');
        await loading.dismiss();
      },
      async (err) => {
        this.presentAlert(
          'Oops!',
          'Algo deu errado no seu cadastro no nosso sistema.',
        );
        await loading.dismiss();
      },
    );
  }

  closeModal() {
    this.viewCtrl.dismiss();
  }

  async openRegisterModal(externalData) {
    const registerModal = await this.viewCtrl.create({
      component: RegisterPage,
      cssClass: 'modal-full',
      componentProps: {
        company: this.company,
        externalData,
      },
    });
    return await registerModal.present();
  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
    });
    toast.present();
  }

  async presentAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
