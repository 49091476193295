import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { AuthFacebookService } from 'src/app/services/auth-facebook.service';

import { LoaderService } from 'src/app/services/loader.service';

import { Router } from '@angular/router';

import { AnalyticsService } from 'src/app/services/analytics.service';

import { Platform } from '@ionic/angular';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { isPlatform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { UserService } from 'src/app/services/user.service';

import * as LoginActions from 'src/app/store/actions/login.actions';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.page.html',
  styleUrls: ['./social-login.page.scss'],
})
export class SocialLoginPage implements OnInit {
  IS_IOS = false;
  IS_ANDROID = false;

  @Input() cpf: any;
  @Input() company: any;
  @Input() userID: any;

  constructor(
    private platform: Platform,
    private router: Router,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private toastController: ToastController,
    private loader: LoaderService,
    private facebook: AuthFacebookService,
    private analytics: AnalyticsService,
    private store: Store,
    private userService: UserService,
    private alertController: AlertController,
  ) {
    if (!isPlatform('capacitor')) {
      GoogleAuth.initialize();
    }
  }

  ngOnInit() {
    this.analytics.trackScreen('login_social', 'LoginSocialScreen');

    this.IS_IOS = this.platform.is('ios');
    this.IS_ANDROID = this.platform.is('android');
  }

  async loginWithFacebook(ev) {
    this.loader.show();
    const loginState = await this.facebook.getCurrentState();
    if (!loginState) {
      this.facebook
        .signIn()
        .then((accessToken) => {
          this.analytics.trackEvent('login', {
            method: 'facebook',
          });
          this.getFacebookUser();
          return;
        })
        .catch((err) => {
          console.error('ERRRORRRR: ', err);
          this.loader.hide();
          return;
        });
    }
    this.getFacebookUser();
  }

  loginWithAppleID(ev) {
    // track method: apple
    this.authService.signInApple((res) => {
      if (this.userID == res?.user_id) {
        this.vinculateCompany(this.company.nome);
        this.socialLoginCallback();
      } else {
        // GoogleAuth.signOut();
        this.presentAlert(
          'Email informado não corresponde a conta cadastrada. Verifique o email e tente novamente.',
          'fechar',
        );
      }
    });
  }

  async googleSignup(ev) {
    this.loader.show();

    try {
      const googleUser = await GoogleAuth.signIn();

      this.authService
        .loginGoogle({
          google_id: googleUser.id,
          name: googleUser.name,
          email: googleUser.email,
          picture: googleUser.imageUrl,
        })
        .subscribe(
          async (response) => {
            this.analytics.trackEvent('login', {
              method: 'google',
            });

            if (this.userID == response?.user_id) {
              this.vinculateCompany(this.company.nome, this.loader);
              this.socialLoginCallback();
            } else {
              this.loader.hide();
              GoogleAuth?.signOut().catch((err) => console.log(err));
              this.presentAlert(
                'Email informado não corresponde a conta cadastrada. Verifique o email e tente novamente.',
                'fechar',
              );
            }
          },
          async (err) => {
            this.loader.hide();
            const toast = await this.toastController.create({
              message: 'Algo deu errado no login do Google',
              duration: 2000,
              color: 'dark',
            });
            await toast.present();
          },
        );
    } catch (e) {
      this.loader.hide();
    }
  }

  socialLoginCallback() {
    // this.authService.loadToken();
    if (this.authService.isAuthenticatedStatus) {
      this.dismiss();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getFacebookUser() {
    this.facebook
      .getUserData()
      .then((res): any => {
        this.authService
          .loginFacebook({
            facebook_id: res.id,
            name: res.name,
            email: res.email,
          })
          .subscribe(
            (response) => {
              if (this.userID == response?.user_id) {
                this.vinculateCompany(this.company.nome, this.loader);
                this.socialLoginCallback();
              } else {
                this.loader.hide();
                this.facebook.logoutFacebook();
                this.presentAlert(
                  'Email informado não corresponde a conta cadastrada. Verifique o email e tente novamente.',
                  'fechar',
                );
              }
            },
            async (err) => {
              this.loader.hide();
              const toast = await this.toastController.create({
                message: 'Algo deu errado no login do facebook',
                duration: 2000,
                color: 'dark',
              });
              await toast.present();
            },
          );
      })
      .catch((err) => {
        this.loader.hide();
      });
  }

  async presentAlert(message, button = 'OK', callback = () => {}) {
    const alert = await this.alertController.create({
      message,
      buttons: [
        {
          text: button,
          handler: () => {
            callback();
          },
        },
      ],
    });
    await alert.present();
  }

  async vinculateCompany(company, loading?) {
    const data = {
      cpf: this.cpf,
      provedor_codigo: this.company.nome,
    };

    this.userService.vinculateCompany(data).subscribe(
      async (res) => {
        if (res.premium) {
          this.analytics.trackEvent('partner_vinculation', {
            partner_name: company.nome,
            is_logged: true,
          });
          this.userService.loadUser(async (success) => {
            loading && (await loading.hide());
          });

          this.presentAlert('Vinculado com sucesso!', 'Voltar', () => {
            // this.router.navigateByUrl("/home/profile", {
            // });
          });

          this.store.dispatch(
            LoginActions.getAutenticatedUserSuccess({
              payload: res.user_updated,
            }),
          );
        } else {
          this.presentAlert(
            `Oops...${'\n'}Algo deu errado na sua vinculação.`,
            'fechar',
          );
          loading && (await loading.hide());
        }
      },
      async (err) => {
        console.log('[ERR] ==>', err);

        loading && (await loading.hide());
        this.presentAlert(
          `Oops...\nAlgo deu errado na sua vinculação. ${'aqui'}`,
          'fechar',
        );
      },
    );
  }
}
