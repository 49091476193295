import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomPremium } from 'src/app/models/custompremium';

// actions
import * as CustomPremiumAction from 'src/app/store/actions/customPremium.actions';
// selectors
import * as CustomPremiumSelector from 'src/app/store/selectors/custompremium.selectors';

@Component({
  selector: 'app-custom-premium-modal',
  templateUrl: './custom-premium-modal.page.html',
  styleUrls: ['./custom-premium-modal.page.scss'],
})
export class CustomPremiumModalPage implements OnInit {
  mockCustomPremiumData: CustomPremium = {
    id: 1,
    title: 'Receba todos os benefícios de #serbitt',
    subtitle: '',
    text: 'Período gratuito de 30 dias. Após esse período será cobrado um valor de R$29,99 por mês',
    external_link: null,
    internal_link: '/premium',
    status: 1,
    slug: 'modal-premium',
    image: 'assets/img/devices_mock_modal_premium.png',
  };

  customPremium$: Observable<CustomPremium[]>;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private store: Store,
  ) {}

  ngOnInit() {
    this.setupInit();
  }

  setupInit() {
    this.customPremium$ = this.store.select(
      CustomPremiumSelector.getCustomPremium,
    );
  }
  async selectOpen(externalLink, internalLink) {
    if (externalLink) {
      const browser = await Browser.open({
        url: externalLink,
      });
    } else {
      this.router.navigateByUrl(internalLink, {
        state: {
          useNavControlBack: true,
        },
      });
      this.back();
    }
  }

  getPrice(value, part) {
    if (!value) return;
    let strValue = value.toString();
    let splitValue = strValue.split('.');
    return splitValue[part];
  }

  back() {
    this.modalController.dismiss();
  }
}
