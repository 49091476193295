import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getAppConfigs = createAction('[App Configs] Get App Configs');

export const getAppConfigsSuccess = createAction(
  '[App Configs] Get App Configs Success',
  props<{ payload: any }>(),
);

export const getAppConfigsError = createAction(
  '[App Configs] Get App Configs Error',
  props<{ payload: HttpErrorResponse }>(),
);
