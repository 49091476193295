import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

// get

export const getTrainingPlanCustom = createAction(
  '[TrainingPlanCustom] Get TrainingPlanCustom'
);

export const getTrainingPlanCustomSuccess = createAction(
  '[TrainingPlanCustom] Get TrainingPlanCustom Success',
  props<{ payload: any }>()
);

export const getTrainingPlanCustomError = createAction(
  '[TrainingPlanCustom] Get TrainingPlanCustom Error',
  props<{ payload: HttpErrorResponse }>()
);

// set

export const setTrainingPlanCustom = createAction(
  '[TrainingPlanCustom] Set TrainingPlanCustom'
);

export const setTrainingPlanCustomSuccess = createAction(
  '[TrainingPlanCustom] Set TrainingPlanCustom Success',
  props<{ payload: any }>()
);

export const setTrainingPlanCustomError = createAction(
  '[TrainingPlanCustom] Set TrainingPlanCustom Error',
  props<{ payload: HttpErrorResponse }>()
);
