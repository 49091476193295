import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';

import * as FavoriteContentActions from '../actions/favoriteContent.actions';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class FavoriteContentEffects {
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteContentActions.setFavoriteContent),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.FAVORITE_CONTENT,
        }),
      ),
      switchMap(({ payload }) => {
        return this.userService.setFavoriteContent(payload).pipe(
          map((res: any) => {
            return FavoriteContentActions.setFavoriteContentSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              FavoriteContentActions.setFavoriteContentError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setFavoriteContentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteContentActions.setFavoriteContentSuccess),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FAVORITE_CONTENT,
        });
      }),
    ),
  );

  setFavoriteContentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteContentActions.setFavoriteContentError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FAVORITE_CONTENT,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private loadingController: LoadingController,
  ) {}
}
