import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { API_URL_V5 } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ContinueTrainingService {
  constructor(private http: HttpClient) {}

  getContinueTraining(qnt_items: number): Observable<any> {
    const url: string =
      API_URL_V5 + `/desafios/continuar?page=1&per_page=${qnt_items}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setContinueTraining(id: number, data = {}): Observable<any> {
    const url: string = API_URL_V5 + `/desafios/continuar/${id}`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  deleteContinueTraining(id: number): Observable<any> {
    const url: string = API_URL_V5 + `/desafios/continuar/${id}`;

    return this.http.delete(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
