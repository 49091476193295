import { createReducer, on } from "@ngrx/store";
import * as StatesCitiesActions from "../actions/state-city.actions";
import { CidadeIbge, City, EstadoIbge } from "src/app/models";

export interface StatesCitiesState {
  states: EstadoIbge[];
  cities: CidadeIbge[];

  // partners related
  statesPartners: any[];
  citiesPartners: any[];

  loading: boolean;
}

const initialState: StatesCitiesState = {
  states: [],
  cities: [],

  // partners related
  statesPartners: [],
  citiesPartners: [],

  loading: false,
};

export const statesCitiesReducer = createReducer(
  initialState,
  on(StatesCitiesActions.getStates, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(StatesCitiesActions.getStatesSuccess, (state, { payload: states }) => {
    return {
      ...state,
      states,
      loading: false,
    };
  }),
  on(StatesCitiesActions.getCitiesSuccess, (state, { payload: cities }) => {
    return {
      ...state,
      cities,
      loading: false,
    };
  }),
  on(StatesCitiesActions.getStatesWhereHasPartners, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    StatesCitiesActions.getStatesWhereHasPartnersSuccess,
    (state, { payload: statesPartners }) => {
      return {
        ...state,
        statesPartners,
        loading: false,
      };
    }
  ),
  on(StatesCitiesActions.getCitiesWhereHasPartners, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    StatesCitiesActions.getCitiesWhereHasPartnersSuccess,
    (state, { payload: citiesPartners }) => {
      return {
        ...state,
        citiesPartners,
        loading: false,
      };
    }
  )
);
