import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// modal
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SearchResultModalPage } from 'src/app/pages/_modals/search-result-modal/search-result-modal.page';

// actions
import * as SearchResultActions from 'src/app/store/actions/searchResult.actions';

@Component({
  selector: 'btt-menu',
  templateUrl: './btt-menu.component.html',
  styleUrls: ['./btt-menu.component.scss'],
})
export class BttMenuComponent implements OnInit {
  @Input() items = [];
  @Output() logoutClick: EventEmitter<any> = new EventEmitter();
  @Output() closeMenu: EventEmitter<any> = new EventEmitter();

  selectedIndex = 0;
  searchValue: string;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private store: Store
  ) {}

  ngOnInit(): void {}

  handleLogoutClick() {
    this.logoutClick.emit();
  }
  handleCloseMenu() {
    this.closeMenu.emit();
  }
  navigateRouter(url) {
    this.router.navigateByUrl(url);
  }

  async openSearchResult(event: any) {
    // let valueChanged = event.target.value;

    this.store.dispatch(
      SearchResultActions.getSearchResult({
        payload: event.target.value,
      })
    );

    const searchResultModal = await this.modalController.create({
      component: SearchResultModalPage,
      componentProps: {
        searchValue: event.target.value,
      },
    });

    await searchResultModal.present();

    return this.handleCloseMenu();
  }
}
