import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setWorkoutSheets = createAction(
  '[Workout Sheets] Set Workout Sheets',
  props<{ payload: any }>(),
);

export const setWorkoutSheetsSuccess = createAction(
  '[Workout Sheets] Set Workout Sheets Success',
  props<{ payload: any }>(),
);

export const setWorkoutSheetsError = createAction(
  '[Workout Sheets] Set Workout Sheets Error',
  props<{ payload: HttpErrorResponse }>(),
);

// ###############################

export const getWorkoutSheets = createAction(
  '[Workout Sheets] Get Workout Sheets',
);

export const getWorkoutSheetsSuccess = createAction(
  '[Workout Sheets] Get Workout Sheets Success',
  props<{ payload: any }>(),
);

export const getWorkoutSheetsError = createAction(
  '[Workout Sheets] Get Workout Sheets Error',
  props<{ payload: HttpErrorResponse }>(),
);
