import { createReducer, on } from "@ngrx/store";
import * as PartnersActions from "../actions/partners.actions";

export interface PartnersState {
  partners: any[];

  partnerSelected: any;

  loading: boolean;
}

const initialState: PartnersState = {
  partners: [],
  loading: false,
  partnerSelected: null,
};

export const partnersReducer = createReducer(
  initialState,
  on(PartnersActions.getPartners, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(PartnersActions.getPartnersSuccess, (state, { payload: partners }) => {
    return {
      ...state,
      partners,
      loading: false,
    };
  }),
  on(PartnersActions.getPartnersError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(PartnersActions.getPartnerDetail, (state) => {
    return {
      ...state,
      partnerSelected: null,
      loading: true,
    };
  }),
  on(
    PartnersActions.getPartnerDetailSuccess,
    (state, { payload: partnerSelected }) => {
      return {
        ...state,
        partnerSelected,
        loading: false,
      };
    }
  ),
  on(PartnersActions.getPartnerDetailError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  })
);
