import { createReducer, on } from '@ngrx/store';
import * as PageRedirectActions from '../actions/pageRedirect.actions';

export interface PageRedirectState {
  pageRedirect: string;
}

const initialState: PageRedirectState = {
  pageRedirect: null,
};

export const pageRedirectReducer = createReducer(
  initialState,
  on(
    PageRedirectActions.setPageRedirect,
    (state, { payload: pageRedirect }) => {
      return {
        ...state,
        pageRedirect,
      };
    },
  ),
);
