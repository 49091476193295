import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as GetExerciseByIdActions from '../actions/getExerciseById.actions';

import { TrainingProgramsService } from 'src/app/services/training-programs.service';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class GetExerciseByIdEffects {
  getStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetExerciseByIdActions.getExerciseById),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.GET_EXERCISE_BY_ID,
        }),
      ),
      switchMap(({ payload }) => {
        return this.trainingProgramsService.getExerciseById(payload).pipe(
          map((res: any) => {
            return GetExerciseByIdActions.getExerciseByIdSuccess({
              payload: res[0],
            });
          }),
          catchError((error) =>
            of(
              GetExerciseByIdActions.getExerciseByIdError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    );
  });

  getExerciseByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExerciseByIdActions.getExerciseByIdSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.GET_EXERCISE_BY_ID,
        }),
      ),
    ),
  );

  getExerciseByIdError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExerciseByIdActions.getExerciseByIdError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.GET_EXERCISE_BY_ID,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingProgramsService: TrainingProgramsService,
  ) {}
}
