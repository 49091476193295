import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
} from 'src/environments/environment';
import { NewCustomWorkout } from '../models/newCustomWorkout';

@Injectable({
  providedIn: 'root',
})
export class TrainingCustomService {
  constructor(private http: HttpClient) {}

  /**
   * Create New Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  createCustomTraining(data): Observable<any> {
    const url = `${API_URL}/treino-customizado-create`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  getCustomTraining(): Observable<any> {
    const url: string = API_URL + '/treino-customizado';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Custom Training By Id
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  getDetail(id): Observable<any> {
    const url = `${API_URL}/treino-customizado/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Add Exercises to Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  addExercises(id, data): Observable<any> {
    const url = `${API_URL}/treino-customizado/${id}/add-exercicios`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Reorder Exercises in Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  updateTraining(id, data): Observable<any> {
    const url = `${API_URL}/treino-customizado/${id}/update`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  deleteTraining(id, data = null): Observable<any> {
    const url = `${API_URL}/treino-customizado/${id}/delete`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Reorder Exercises in Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  reorderExercises(id, data): Observable<any> {
    const url = `${API_URL}/treino-customizado/${id}/ordernar-exercicios`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Delete Exercise from Custom Training
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  deleteExercise(id, data = null): Observable<any> {
    const url = `${API_URL}/treino-customizado/exercicio/${id}/delete`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Update Exercise from Custom Training
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  updateCustomExercise(data: any): Observable<any> {
    const {
      exerciseId,
      workoutId,
      exerciseData,
    }: { exerciseId: number; workoutId: number; exerciseData: any } = data;
    const url = `${API_URL}/user/treino-customizado/${workoutId}/exercicio/${exerciseId}`;

    return this.http.put(url, exerciseData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Set New Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  setNewCustomWorkout(data: any): Observable<any> {
    const workoutData: NewCustomWorkout = data;

    const url = `${API_URL}/user/treino-customizado`;

    return this.http.post(url, workoutData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get New Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  getNewCustomWorkout(): Observable<any> {
    const url = `${API_URL}/user/treino-customizado`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * User Set Finished Custom workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  setFinishedCustomWorkout({
    id,
    percentage_completed,
    finish_all_exercises,
  }: any): Observable<any> {
    const url: string =
      API_URL + `/user/set-treino-customizado-concluido/${id}`;
    return this.http
      .post(url, { percentage_completed, finish_all_exercises })
      .pipe(
        tap((response) => {
          return response;
        }),
      );
  }

  /**
   * Get Exercices By Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  getExercisesByCustomWorkout(params: number): Observable<any> {
    const url: string =
      API_URL + `/user/exercicios-treino-customizado/${params}`;
    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Completed Custom Workouts of the Week
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  getCompletedCustomWorkoutsWeek(): Observable<any> {
    const url: string = API_URL + `/user/treinos-customizados-semana`;
    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
    Set New Exercise In Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  setNewExerciseInCustomWorkout(payload: {
    workoutId: number;
    dataExercise: any;
  }): Observable<any> {
    const { workoutId, dataExercise } = payload;

    const url: string =
      API_URL + `/user/treino-customizado/${workoutId}/adicionar-exercicio`;
    return this.http.post(url, dataExercise).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
    Edit Exercises In Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  setEditExercisesInCustomWorkout(payload: {
    workoutId: number;
    dataExercise: any;
  }): Observable<any> {
    const { workoutId, dataExercise } = payload;

    const url: string =
      API_URL + `/user/treino-customizado/${workoutId}/editar-exercicios`;
    return this.http.put(url, dataExercise).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
    Delete Custom Workout
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */
  setDeleteCustomWorkout(customWorkoutId: number): Observable<any> {
    const url: string = API_URL + `/user/treino-customizado/${customWorkoutId}`;
    return this.http.delete(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
