import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ContinueTrainingActions from '../actions/continueTraining.actions';

import { ContinueTrainingService } from 'src/app/services/continue-training.service';

@Injectable()
export class ContinueTrainingEffects {
  getContinueTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContinueTrainingActions.getContinueTraining),
      switchMap(({ payload: { qnt_items } }) => {
        return this.continueTrainingService.getContinueTraining(qnt_items).pipe(
          map((res: any) => {
            return ContinueTrainingActions.getContinueTrainingSuccess({
              payload: res.data,
            });
          }),
          catchError((error) =>
            of(
              ContinueTrainingActions.getContinueTrainingError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setContinueTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContinueTrainingActions.setContinueTraining),
      switchMap(({ payload: { id } }) => {
        return this.continueTrainingService.setContinueTraining(id).pipe(
          map((res: any) => {
            return ContinueTrainingActions.setContinueTrainingSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ContinueTrainingActions.setContinueTrainingError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  deleteContinueTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContinueTrainingActions.deleteContinueTraining),
      switchMap(({ payload: { id } }) => {
        return this.continueTrainingService.deleteContinueTraining(id).pipe(
          map((res: any) => {
            return ContinueTrainingActions.deleteContinueTrainingSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ContinueTrainingActions.deleteContinueTrainingError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private continueTrainingService: ContinueTrainingService,
    private store: Store,
  ) {}
}
