import { createReducer, on } from '@ngrx/store';
import * as CategoriesActions from '../actions/categories.actions';

export interface CategoriesState {
  categoryItems: any[];
  popularItems: any[];
  currentCategoryItem: any[];

  loading: boolean;
}

const initialState: CategoriesState = {
  categoryItems: [],
  popularItems: [],
  currentCategoryItem: [],
  loading: false,
};

export const categoriesReducer = createReducer(
  initialState,
  on(CategoriesActions.getCategory, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    CategoriesActions.getCategorySuccess,
    (state, { payload: categoryItems }) => {
      return {
        ...state,
        categoryItems,
        loading: false,
      };
    },
  ),
  on(CategoriesActions.getCategoryError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(CategoriesActions.getCategoryItem, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    CategoriesActions.getCategoryItemSuccess,
    (state, { payload: { listItems, popular } }) => {
      return {
        ...state,
        currentCategoryItem: listItems,
        popularItems: popular,
        loading: false,
      };
    },
  ),
  on(CategoriesActions.getCategoryItemError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);
