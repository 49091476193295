import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as PremiumActions from '../actions/premium.actions';

import { PremiumService } from 'src/app/services/premium.service';
import { Premium } from 'src/app/models/premium';
import { Router } from '@angular/router';

import * as PremiumSelectors from '../selectors/premium.selectors';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class PremiumEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PremiumActions.getPremiumStatus),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({ payload: loadingTypesEnum.PREMIUM }),
        ),
      ),
      switchMap(() => {
        return this.premiumService.getPremiumStatus().pipe(
          map((res: any) => {
            const premium: Premium = {
              empresa: {
                nome: res.empresa?.nome,
                img: res.empresa?.img,
                imgGrande: res.empresa?.img_grande,
                imgHome: res?.empresa?.img_home,
                imgLista: res.empresa?.img_lista,
                campoVinculacao: res.empresa?.campo_vinculacao,
                tamanhoLogoHome: res.empresa?.tamanho_logo_home,
              },
              premium: res.premium,
            };
            return PremiumActions.getPremiumStatusSuccess({
              payload: premium,
            });
          }),
          catchError((error) =>
            of(PremiumActions.getPremiumStatusError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getPremiumStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PremiumActions.getPremiumStatusSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.PREMIUM }),
      ),
    ),
  );

  getPremiumStatusError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PremiumActions.getPremiumStatusError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.PREMIUM }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private premiumService: PremiumService,
    private store: Store,
    private router: Router,
  ) {}
}
