import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// actions
import * as CustomPremiumActions from 'src/app/store/actions/customPremium.actions';

@Component({
  selector: 'btt-header-video',
  templateUrl: './btt-header-video.component.html',
  styleUrls: ['./btt-header-video.component.scss'],
})
export class BttHeaderVideoComponent implements OnInit {
  @Input() img = '';
  @Input() video;
  @Input() noBlur: boolean = false;
  @Input() newPlayerLayout: boolean = false;
  @Input() isActiveLandscape: boolean = false;

  @Input() showPrevButton = false;
  @Input() showNextButton = false;
  @Input() blockAccess: boolean = false;

  @Output() clickPrevButton: EventEmitter<any> = new EventEmitter();
  @Output() clickNextButton: EventEmitter<any> = new EventEmitter();
  @Output() clickPremiumButton?: EventEmitter<any> = new EventEmitter();

  @Output() clickPlayButton?: EventEmitter<any> = new EventEmitter();

  public playVideo = false;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  handlePrevButtonClick() {
    if (this.showPrevButton) this.clickPrevButton.emit();
  }
  handleNextButtonClick() {
    if (this.showNextButton) this.clickNextButton.emit();
  }

  startVideo() {
    this.playVideo = true;
    this.clickPlayButton.emit();
  }

  openPremiumPage() {
    this.store.dispatch(CustomPremiumActions.getCustomPremium());
    this.clickPremiumButton.emit();
  }
}
