import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getHome = createAction('[Home] Get Home');

export const getHomeSuccess = createAction(
  '[Home] Get Home Success',
  props<{ payload: any }>()
);

export const getHomeError = createAction(
  '[Home] Get Home Error',
  props<{ payload: HttpErrorResponse }>()
);

export const getModalities = createAction('[Home] Get Modalities');
export const getModalitiesSuccess = createAction(
  '[Home] Get Modalities Success',
  props<{ payload: any }>()
);
export const getModalitiesError = createAction(
  '[Home] Get Modalities Error',
  props<{ payload: HttpErrorResponse }>()
);

// first access to the app
// export const getFirstAcess = createAction("[Home] Get FirstAcess");
// export const getFirstAcessSuccess = createAction(
//   "[Home] Get FirstAcess Success",
//   props<{ payload: any }>()
// );
// export const getFirstAcessError = createAction(
//   "[Home] Get FirstAcess Error",
//   props<{ payload: HttpErrorResponse }>()
// );
