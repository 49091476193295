import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getPersonality = createAction('[Personality] Get Personality');

// submit personality
export const setPersonality = createAction(
  '[Personality] Set Personality',
  props<{ payload: any }>()
);
export const setPersonalitySuccess = createAction(
  '[Personality] Set Personality Success',
  props<{ payload: any }>()
);
export const setPersonalityError = createAction(
  '[Personality] Set Personality Error',
  props<{ payload: HttpErrorResponse }>()
);
