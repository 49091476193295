import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ExerciseRatingActions from '../actions/exerciseRating.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ExerciseRatingEffects {
  // setOPersonality
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setExerciseRating),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.setExerciseRating(payload).pipe(
          map((res: any) => {
            return ExerciseRatingActions.setExerciseRatingSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ExerciseRatingActions.setExerciseRatingError({ payload: error }),
            ),
          ),
        );
      }),
    ),
  );

  setExerciseRatingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setExerciseRatingSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
    ),
  );

  setExerciseRatingError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setExerciseRatingError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
    ),
  );

  // set user liked exercise

  setLikedExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setLikedExercise),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.setLikedExercise(payload).pipe(
          map((res: any) => {
            return ExerciseRatingActions.setLikedExerciseSuccess(res);
          }),
          catchError((error) =>
            of(ExerciseRatingActions.setLikedExerciseError({ payload: error })),
          ),
        );
      }),
    ),
  );
  setLikedExerciseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setLikedExerciseSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
    ),
  );
  setLikedExerciseError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseRatingActions.setLikedExerciseError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_RATING,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
  ) {}
}
