import { createReducer, on } from '@ngrx/store';
import * as DailyWorkoutsViewedActions from '../actions/dailyWorkoutsViewed.actions';

import { Exercicio } from 'src/app/models/trainingPlanCustom';

export interface DailyWorkoutsViewedState {
  dailyWorkoutsViewed: {
    events: Exercicio[];
    calendar: Exercicio[];
  };
}

const initialState: DailyWorkoutsViewedState = {
  dailyWorkoutsViewed: {
    events: [],
    calendar: [],
  },
};

export const dailyWorkoutsViewedReducer = createReducer(
  initialState,
  on(
    DailyWorkoutsViewedActions.getDailyWorkoutsViewedSuccess,
    (state, { payload: dailyWorkoutsViewed }) => {
      return {
        ...state,
        dailyWorkoutsViewed,
      };
    },
  ),
);
