import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as HomeActions from '../actions/home.actions';

import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { ModalityService } from 'src/app/services/modality.service';
import { Modality } from 'src/app/shared/models/modality.model';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class HomeEffects {
  getModalities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getModalities),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.HOME }),
      ),
      switchMap(() => {
        return this.modalityService.getItems().pipe(
          map((res: any) => {
            const modalities: Modality[] = res.map((modality) => {
              return {
                id: modality.id,
                imagem: modality.img_v2,
                mobile_page: modality.mobile_page,
                peso: modality.peso,
                tipo_exibicao: modality.tipo_exibicao,
                titulo: modality.titulo,
                url: modality.url,
                video_url: modality.video_url,
                video_youtube_id: modality.video_youtube_id,
                vimeo_id: modality.vimeo_id,
              };

              // TO DO: FORMATAR DIREITO
            });

            return HomeActions.getModalitiesSuccess({
              payload: modalities,
            });
          }),
          catchError((error) =>
            of(HomeActions.getModalitiesError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getModalitiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getModalitiesSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.HOME }),
      ),
    ),
  );

  getModalitiesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getModalitiesError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.HOME }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private modalityService: ModalityService,
    private store: Store,
    private router: Router,
  ) {}
}
