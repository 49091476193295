import { createReducer, on } from '@ngrx/store';
import * as BannerActions from '../actions/banners.actions';

import { Banner } from 'src/app/models/banner';

export interface BannerState {
  banners: Banner[];
}

const initialState: BannerState = {
  banners: [],
};

export const bannersReducer = createReducer(
  initialState,
  on(BannerActions.getBannersSuccess, (state, { payload: banners }) => {
    return {
      ...state,
      banners,
    };
  })
);
