import { ModalController } from '@ionic/angular';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Exercise } from 'src/app/shared/models/exercise.model';
import { BttNewExerciseDescriptionComponent } from '../btt-new-exercise-description/btt-new-exercise-description.component';
import { BttNewExerciseTimerComponent } from '../btt-new-exercise-timer/btt-new-exercise-timer.component';
import { SwiperComponent } from 'swiper/angular';
import { Exercicio } from 'src/app/models/trainingPlanCustom';
import { Router } from '@angular/router';

@Component({
  selector: 'app-btt-new-exercise-card',
  templateUrl: './btt-new-exercise-card.component.html',
  styleUrls: ['./btt-new-exercise-card.component.scss'],
})
export class BttNewExerciseCardComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  sliderConfig = {
    slidesPerView: 1.1,
    centeredSlides: false,
    spaceBetween: 16,
    setWrapperSize: true,
  };

  @Input() listExercises: any;
  @Input() editExerciseEnable: boolean = false;
  @Input() selectedWorkout: any;
  @Output() visualizedExercise: EventEmitter<any> = new EventEmitter();
  @Output() referenceSwiper?: EventEmitter<any> = new EventEmitter();

  listExercisesSecond: any;

  userHasPlayVideo = false;

  // custom icons
  bttPencil = 'assets/custom-icon/btt-pencil.svg';

  constructor(
    private modalController: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {
    this.listExercisesSecond = this.listExercises;
  }

  ngAfterViewInit() {
    this.referenceSwiper.emit(this.swiper);
  }

  hasPlayedVideo(auxExercise: Exercise) {
    let exercise = this.listExercises.find(
      (exercise) => exercise === auxExercise,
    );

    return !exercise.visualized;
  }

  setUserHasPlayVideo(auxExercise: Exercise) {
    this.userHasPlayVideo = true;

    let exercise = this.listExercises.find(
      (exercise) => exercise === auxExercise,
    );
    exercise.visualized = true;

    this.listExercisesSecond = { ...this.listExercisesSecond, exercise };
  }

  hasFinishedExercise(auxExercise: Exercise) {
    let exercise = this.listExercises.find(
      (exercise) => exercise === auxExercise,
    );
    this.visualizedExercise.emit(exercise);

    exercise.is_finished = !exercise.is_finished;

    this.listExercisesSecond = { ...this.listExercisesSecond, exercise };
  }

  getCompletedExercise(auxExercise: Exercise) {
    let auxExerciseIn = this.listExercises.find(
      (exercise) => exercise === auxExercise,
    );
    return Boolean(auxExerciseIn?.is_finished);
  }

  async openExerciseTimer(auxExercice: Exercise) {
    const modal = await this.modalController.create({
      component: BttNewExerciseTimerComponent,
      cssClass: 'modal-rate',
      componentProps: {
        defaultAccountantValue: this.editExerciseEnable
          ? auxExercice.intervalo_descanso
          : 45,
      },
    });
    await modal.present();
  }

  async openExerciseDescription(auxExercice: Exercise) {
    const modal = await this.modalController.create({
      component: BttNewExerciseDescriptionComponent,
      cssClass: 'modal-rate',
      componentProps: {
        exercise: auxExercice,
      },
    });
    await modal.present();
  }

  setEditExercise(dataExercise: Exercicio) {
    this.router.navigateByUrl(
      `home/training-plan/custom/btt-my-custom-exercise-edit`,
      {
        state: {
          selectedExercise: dataExercise,
          currentOrder: this.selectedWorkout.order,
          workoutId: this.selectedWorkout.id,
          currentExercises: null,
          hasBeenListed: false,
        },
        replaceUrl: false,
      },
    );
  }
}
