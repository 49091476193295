import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V2,
  API_URL_V3,
  environment,
} from 'src/environments/environment';

import { PremiumService } from './premium.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Init with null to filter out the first value in a guard!
  public user: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  hasUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private premiumService: PremiumService,
  ) {
    // this.loadUser();
  }

  loadUser(callback = (success) => {}) {
    this.getUserByToken().subscribe(
      (res) => {
        this.user.next(res);
        this.hasUser.next(true);

        this.premiumService.loadPremiumStatus();
        callback(true);
      },
      (err) => {
        this.user.next(null);
        this.hasUser.next(false);
        console.log('[error]=> ', err);
        console.error(err);
        callback(false);
      },
    );
  }

  /**
   * Return Logged User Data
   * @author André Ramos <oi@andreramos.dev>
   */
  getUserByToken(): Observable<any> {
    const url: string = API_URL + '/user/authenticated';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Anamnese Options list
   * @return json
   */
  getAnamneseOptions(): Observable<any> {
    const url: string = API_URL + '/anamnese_opcoes';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Save anamnese config for user
   * @param data Anamnese items
   * @author André Ramos <oi@andreramos.dev>
   */
  saveUserAnamnese(data): Observable<any> {
    const url: string = API_URL + '/anamnese/salvar';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Banners for User (if vinculated, return with company banners)
   * @author André Ramos <oi@andreramos.dev>
   */
  getBanners(): Observable<any> {
    const url: string = API_URL_V2 + '/user/get-banners';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Set Company Vinculation by user token
   * @author André Ramos <oi@andreramos.dev>
   */
  vinculateCompany(data): Observable<any> {
    const url: string = API_URL_V3 + '/users/set-provedor';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * UnSet Company Vinculation by user token
   * @author André Ramos <oi@andreramos.dev>
   */
  unsetCompany(): Observable<any> {
    const url: string = API_URL + '/user/unset-provedor';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Update User Data
   * @author André Ramos<oi@andreramos.dev>
   */
  updateUserData(data): Observable<any> {
    const url: string = API_URL + '/user/editar';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Update User SubscriberData
   * @author André Ramos<oi@andreramos.dev>
   */
  saveSubscriberData(data): Observable<any> {
    const url: string = API_URL + '/user/save-subscriberId';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Update User Photo
   * @author André Ramos<oi@andreramos.dev>
   */
  saveUserPhoto(data): Observable<any> {
    const url: string = API_URL + '/user/upload-foto';

    console.log('upload-foto');

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  //// set user personality
  setUserPersonality(personalityData): Observable<any> {
    const url: string = API_URL + `/user/update-personality`;

    return this.http.put(url, personalityData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // set favorite contents
  setFavoriteContent(data): Observable<any> {
    const url: string = API_URL + '/user/set-favorito';

    return this.http.patch(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // my favorite content
  getMyFavoriteContent(): Observable<any> {
    const url: string = API_URL + '/user/favoritos';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  removeUser() {
    const url: string = API_URL + '/user';

    return this.http.delete(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // set workout sheeets
  setWorkoutSheets(data): Observable<any> {
    const url: string = API_URL + '/user/set-ficha';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // get workout sheeets
  getWorkoutSheets(): Observable<any> {
    const url: string = API_URL + '/user/get-ficha';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // get completed workouts of the week
  getCompletedWorkoutsWeek(): Observable<any> {
    const url: string = API_URL + '/user/treinos-concluidos-semana';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // send annotations
  setAnnotation(payload): Observable<any> {
    const url: string = API_URL + '/user/annotation';
    return this.http.post(url, payload).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  changePassword(payload): Observable<any> {
    const url: string = API_URL + '/user/change-password';

    return this.http.patch(url, payload).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
