import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Premium } from 'src/app/models/premium';

// selectors
import * as PremiumSelectors from 'src/app/store/selectors/premium.selectors';

interface IListTabs {
  id: number;
  name: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'btt-filter-page-category',
  templateUrl: './btt-filter-page-category.component.html',
  styleUrls: ['./btt-filter-page-category.component.scss'],
})
export class BttFilterPageCategoryComponent implements OnInit {
  premiumStatus$: Observable<Premium>;
  @Input() showAuxiliarBar: boolean;
  @Input() listTabs: IListTabs[];
  tabSelected: string = 'all';

  @Input() sliderConfig = {
    slidesPerView: 3.3,
    spaceBetween: 6,
    centeredSlides: false,
    setWrapperSize: true,
  };

  constructor(private store: Store) {}

  ngOnInit() {
    this.getPremium();
  }

  handleSetTabSelected(tab: IListTabs) {
    this.listTabs.forEach((item) => {
      item.selected = item?.value === tab?.value;
    });
  }

  getPremium() {
    this.premiumStatus$ = this.store.select(PremiumSelectors.getPremiumStatus);
  }

  getListButtons(listButtons, isPremium) {
    if (isPremium)
      return listButtons.filter((btn: any) => btn.value !== 'free');

    return listButtons;
  }
}
