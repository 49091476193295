import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-btt-image-opening-slider',
  templateUrl: './btt-image-opening-slider.component.html',
  styleUrls: ['./btt-image-opening-slider.component.scss'],
})
export class BttImageOpeningSliderComponent implements OnInit {
  @Input() images: any[];
  @Input() showSlider: boolean = false;
  @Input() selectedImage: number = 0;

  @Output() closeSlider: EventEmitter<any> = new EventEmitter();

  hideSlide: boolean = false;

  sliderConfig = {
    slidesPerView: 1.3333333,
    centeredSlides: true,
    spaceBetween: 16,
    setWrapperSize: true,

    breakpoints: {
      964: {
        slidesPerView: 1.66,
        spaceBetween: 20,
      },
      1108: {
        slidesPerView: 2.333,
        spaceBetween: 24,
      },
      1354: {
        slidesPerView: 3.33,
        spaceBetween: 32,
      },
    },
  };

  constructor() {}

  ngOnInit() {}

  handleCloseSlider() {
    this.hideSlide = true;

    setTimeout(() => {
      this.closeSlider.emit();
      this.hideSlide = false;
    }, 290);
  }
}
