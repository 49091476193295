import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as NotificationsActions from '../actions/notifications.actions';
import * as LoadingActions from '../actions/loading.actions';

import { NotificationsService } from 'src/app/services/notifications.service';

@Injectable()
export class NotificationsEffects {
  getUserNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.getUserNotifications),
      switchMap(() => {
        return this.notificationsService.getUserNotifications().pipe(
          map((res: any) => {
            return NotificationsActions.getUserNotificationsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              NotificationsActions.getUserNotificationsError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  readAllUserNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.readAllUserNotifications),

      switchMap((payload) => {
        return this.notificationsService.readAllUserNotifications(payload).pipe(
          map((res: any) => {
            return NotificationsActions.readAllUserNotificationsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              NotificationsActions.readAllUserNotificationsError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  //   setStates$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(InterestsActions.setInterests),
  //     tap(() => LoadingActions.exibirLoading()),
  //     switchMap((payload) => {
  //       return this.trainingService.setUserInterests(payload).pipe(
  //         map((res: any) => {
  //           return InterestsActions.setInterestsSuccess({
  //             payload: res,
  //           });
  //         }),
  //         catchError((error) =>
  //           of(InterestsActions.getInterestsError({ payload: error }))
  //         )
  //       );
  //     })
  //   )
  // );

  // getNutritionItem$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(NotificationsActions.getNutritionItem),
  //     switchMap(({ payload: { id } }) => {
  //       return this.notificationsService.getItem(id).pipe(
  //         map((res: any) => {
  //           return NotificationsActions.getNutritionItemSuccess({
  //             payload: res,
  //           });
  //         }),
  //         catchError((error) =>
  //           of(NotificationsActions.getNutritionItemsError({ payload: error }))
  //         )
  //       );
  //     })
  //   )
  // );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store: Store,
  ) {}
}
