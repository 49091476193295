import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getSearchResultCategories = createAction(
  '[Search Result Categories] Get Search Result Categories',
  props<{ payload: any }>(),
);

export const getSearchResultCategoriesSuccess = createAction(
  '[Search Result Categories] Get Search Result Categories Success',
  props<{ payload: any }>(),
);

export const getSearchResultCategoriesError = createAction(
  '[Search Result Categories] Get Search Result Categories Error',
  props<{ payload: HttpErrorResponse }>(),
);
