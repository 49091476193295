import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-btt-content-viewer',
  templateUrl: './btt-content-viewer.component.html',
  styleUrls: ['./btt-content-viewer.component.scss'],
})
export class BttContentViewerComponent implements OnInit {
  @Input() previowsPage = '';
  @Input() useNavControlBack: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit() {}

  back() {
    if (this.useNavControlBack) {
      this.onClick.emit();
    } else {
      // this.router.navigate([this.previowsPage]);
      this.router.navigateByUrl(this.previowsPage, {
        replaceUrl: true,
      });
    }
  }
}
