export const WorkoutsOrder = [
  {
    order: 1,
    value: 'A',
  },
  {
    order: 2,
    value: 'B',
  },
  {
    order: 3,
    value: 'C',
  },
  {
    order: 4,
    value: 'D',
  },
  {
    order: 5,
    value: 'E',
  },
  {
    order: 6,
    value: 'F',
  },
];
