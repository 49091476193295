import { createReducer, on } from '@ngrx/store';
import * as GetExerciseByIdActions from '../actions/getExerciseById.actions';

// import { Banner } from 'src/app/models/banner';

export interface GetExerciseByIdState {
  getExerciseById: any;
}

const initialState: GetExerciseByIdState = {
  getExerciseById: '',
};

export const getExerciseByIdReducer = createReducer(
  initialState,
  on(
    GetExerciseByIdActions.getExerciseByIdSuccess,
    (state, { payload: getExerciseById }) => {
      return {
        ...state,
        getExerciseById,
      };
    },
  ),
);
