import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V4,
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModalityService {
  constructor(private http: HttpClient) {}

  getItems(): Observable<any> {
    // return of(modalityListMock);
    const url: string = API_URL_V3 + '/get-modalidades-list';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getDetails(id, subcategory = false): Observable<any> {
    let url = API_URL_V3;

    url += subcategory
      ? `/get-subcategoria-treinos/`
      : `/get-modalidade-details/`;
    url += id;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get list of modalities
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  getListModalities(): Observable<any> {
    const url: string = API_URL_V4 + '/modalidades';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get show modality
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  getShowModality(id: number): Observable<any> {
    const url: string = API_URL_V4 + `/modalidades/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get show submodality
   * @return json
   * @author Werlley Gonçalves <werlleyponte.eng@gmail.com>
   */

  getShowSubmodality(id: number): Observable<any> {
    const url: string = API_URL_V4 + `/submodalidades/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
