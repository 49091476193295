import { createReducer, on } from '@ngrx/store';
import * as NotificationsActions from '../actions/notifications.actions';

export interface NotificationsState {
  notifications: any[];
  hasUnreaded: boolean;
  loading: boolean;
}

const initialState: NotificationsState = {
  notifications: [],
  hasUnreaded: false,
  loading: false,
};

export const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.getUserNotifications, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    NotificationsActions.getUserNotificationsSuccess,
    (state, { payload: notifications }) => {
      return {
        ...state,
        notifications,
        loading: false,
      };
    },
  ),
  on(
    NotificationsActions.getUserNotificationsError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
  on(NotificationsActions.readAllUserNotifications, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    NotificationsActions.readAllUserNotificationsSuccess,
    (state, { payload: { notifications, hasUnreaded } }) => {
      return {
        ...state,
        notifications,
        hasUnreaded,
        loading: false,
      };
    },
  ),
  on(
    NotificationsActions.readAllUserNotificationsError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
);
