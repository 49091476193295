import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Recommended } from "src/app/models";

export const getRecommended = createAction("[Recommended] Get Recommended");

export const getRecommendedSuccess = createAction(
  "[Recommended] Get Recommended Success",
  props<{ payload: Recommended[] }>()
);

export const getRecommendedError = createAction(
  "[Recommended] Get Recommended Error",
  props<{ payload: HttpErrorResponse }>()
);
