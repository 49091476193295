import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "btt-partner-item",
  templateUrl: "./btt-partner-item.component.html",
  styleUrls: ["./btt-partner-item.component.scss"],
})
export class BttPartnerItemComponent implements OnInit {
  @Input() image;
  @Input() title;
  @Input() subtitle;

  constructor() {}

  ngOnInit() {}
}
