import { Injectable } from '@angular/core';
import { AppRate } from '@ionic-native/app-rate/ngx';

import { Storage } from '@capacitor/storage';

import { Market } from '@ionic-native/market/ngx';
import { Platform } from '@ionic/angular';

import { ModalController } from '@ionic/angular';

import { PreRatingModalComponent } from '../components/pre-rating-modal/pre-rating-modal.component';
import { RatingModalComponent } from '../components/rating-modal/rating-modal.component';

import { DialogService } from './dialog.service';

export const STORAGE_ALREADY_HAS_ASKED_AND_FINISHED =
  'user_has_asked_to_rate_app';
export const STORAGE_ASK_FOR_RATE_COUNT = 'user_has_asked_to_rate_app_count';

// modal
import { BttRatingModalNewComponent } from 'src/app/components/btt-rating-modal-new/btt-rating-modal-new.component';
export const TIMES_BEFORE_ASK_AGAIN = 30; //30

@Injectable({
  providedIn: 'root',
})
export class AppRateService {
  constructor(
    private market: Market,
    private platform: Platform,
    private modalController: ModalController,
    private dialogService: DialogService,
  ) {}

  async startAppRating() {
    const hasAskedAndFinished = await this.alreadyHasAskedAndFinished();

    console.log('hasAskedAndFinished:: ', hasAskedAndFinished);

    if (!hasAskedAndFinished) {
      const countForRate = await this.getAskForRatingCount();
      console.log('countForRate:: ', countForRate);
      // first try or ask again
      if (countForRate >= TIMES_BEFORE_ASK_AGAIN) {
        // old code
        // this.openPreRatingModal(countForRate);

        // new code
        await Storage.set({
          key: STORAGE_ASK_FOR_RATE_COUNT,
          value: JSON.stringify(1),
        });

        // open rate modal
        const modal = await this.modalController.create({
          component: BttRatingModalNewComponent,
          cssClass: 'modal-rate',
          componentProps: {},
        });
        await modal.present();

        return;
      }

      if (countForRate < TIMES_BEFORE_ASK_AGAIN) {
        await Storage.set({
          key: STORAGE_ASK_FOR_RATE_COUNT,
          value: JSON.stringify(countForRate + 1),
        });
      }
    }
  }

  async alreadyHasAskedAndFinished() {
    const alreadyHasAskedAndFinished = await Storage.get({
      key: STORAGE_ALREADY_HAS_ASKED_AND_FINISHED,
    });

    if (alreadyHasAskedAndFinished) {
      return JSON.parse(alreadyHasAskedAndFinished.value);
    }

    return false;
  }

  async getAskForRatingCount() {
    const { value } = await Storage.get({ key: STORAGE_ASK_FOR_RATE_COUNT });

    if (!value) {
      await Storage.set({
        key: STORAGE_ASK_FOR_RATE_COUNT,
        value: JSON.stringify(0),
      });

      return 0;
    }

    return JSON.parse(value);
  }

  async openPreRatingModal(countForRate: number) {
    const action = await this.dialogService.show({
      bodyComponent: PreRatingModalComponent,
      button1Text: 'Não estou gostando',
      button2Text: 'Estou gostando',
      cssClass: 'dialog-wrapper-btt',
      buttonClose: false,
    });

    if (action === 'button1Action') {
      // reset counter to 1 (not is prompted like 'first time' - value === 0)
      await Storage.set({
        key: STORAGE_ASK_FOR_RATE_COUNT,
        value: JSON.stringify(1),
      });
    }
    if (action === 'button2Action') {
      this.openAppRatingModal(countForRate);
    }
  }

  async openAppRatingModal(countForRate: number) {
    const action = await this.dialogService.show({
      bodyComponent: RatingModalComponent,
      button1Text: countForRate === 0 ? 'Mais tarde' : 'Não, obrigado',
      button2Text: 'Avaliar agora',
      cssClass: 'dialog-wrapper-btt',
      buttonClose: false,
    });
    if (action === 'button1Action') {
      // reset counter to 1 (not is prompted like 'first time' - value === 0)
      await Storage.set({
        key: STORAGE_ASK_FOR_RATE_COUNT,
        value: JSON.stringify(1),
      });
    }
    if (action === 'button2Action') {
      this.redirectToShop();
    }
  }

  async redirectToShop() {
    let appId = null;
    if (this.platform.is('ios')) {
      appId = 'bittrainers/id1389395944';
    }
    if (this.platform.is('android')) {
      appId = 'com.bittrainers';
    }
    if (appId) {
      this.market.open(appId);
      await Storage.set({
        key: STORAGE_ALREADY_HAS_ASKED_AND_FINISHED,
        value: JSON.stringify(true),
      });
    }
  }
}
