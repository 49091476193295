import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-btt-alert-action',
  templateUrl: './btt-alert-action.component.html',
  styleUrls: ['./btt-alert-action.component.scss'],
})
export class BttAlertActionComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() primaryButtonText: string = 'Cancelar';
  @Input() buttonText: string;
  @Input() localIcon: string;

  @Input() colorIcon?: string = 'white';
  @Input() changeFlow?: boolean = false;

  @Input() newColors: boolean = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}
  handleClickClose() {
    this.modalController.dismiss(null, 'cancel');
  }

  handleClickConfirm() {
    return this.modalController.dismiss(null, 'confirm');
  }
}
