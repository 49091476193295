import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as CategoriesActions from '../actions/categories.actions';

import { CategoryService } from 'src/app/services/category.service';

@Injectable()
export class CategoriesEffects {
  getCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getCategory),
      switchMap(({ payload: { type } }) => {
        return this.categoryService.getCategory(type).pipe(
          map((res: any) => {
            return CategoriesActions.getCategorySuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(CategoriesActions.getCategoryError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getCategoryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesActions.getCategoryItem),
      switchMap(({ payload: { id, limit_popular } }) => {
        const params = limit_popular
          ? `${id}?limit_popular=${limit_popular}`
          : id;

        return this.categoryService.getCategoryItem(params).pipe(
          map((res: any) => {
            const resCategory = {
              listItems: res.receitas,
              popular: res.populares,
            };
            return CategoriesActions.getCategoryItemSuccess({
              payload: resCategory,
            });
          }),
          catchError((error) =>
            of(CategoriesActions.getCategoryItemError({ payload: error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private categoryService: CategoryService,
    private store: Store,
  ) {}
}
