import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  Router,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { Storage } from '@capacitor/storage';
import { Platform } from '@ionic/angular';

export const INTRO_KEY = 'intro-seen';

@Injectable({
  providedIn: 'root',
})
export class IntroGuard implements CanLoad {
  isWeb: any;

  constructor(private router: Router, private platform: Platform) {}

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    this.isWeb = !this.platform.is('android') && !this.platform.is('ios');

    if (!this.isWeb) {
      const hasSeenIntro = await Storage.get({ key: INTRO_KEY });

      if (hasSeenIntro && hasSeenIntro.value === 'true') {
        return true;
      } else {
        this.router.navigateByUrl('/intro', { replaceUrl: true });
        return false;
      }
    }
  }
}
