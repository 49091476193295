import { createReducer, on } from '@ngrx/store';
import * as InterestsActions from '../actions/interests.actions';

import { Interest } from 'src/app/models/interest';

export interface InterestState {
  interests: Interest[];
}

const initialState: InterestState = {
  interests: [],
};

export const interestsReducer = createReducer(
  initialState,
  on(InterestsActions.getInterestsSuccess, (state, { payload: interests }) => {
    return {
      ...state,
      interests,
    };
  })
);
