import { createReducer, on } from '@ngrx/store';
import * as CompletedWorkoutsWeekActions from '../actions/completedWorkoutsWeek.actions';
import * as NewCustomWorkoutActions from '../actions/newCustomWorkout.actions';

import { ICompletedWorkoutsDay } from 'src/app/models/completedWorkoutsDay';

export interface CompletedWorkoutsWeekState {
  completedWorkoutsWeek: ICompletedWorkoutsDay[];
}

const initialState: CompletedWorkoutsWeekState = {
  completedWorkoutsWeek: [],
};

export const completedWorkoutsWeekReducer = createReducer(
  initialState,
  on(
    CompletedWorkoutsWeekActions.getCompletedWorkoutsWeekSuccess,
    (state, { payload: completedWorkoutsWeek }) => {
      return {
        ...state,
        completedWorkoutsWeek,
      };
    },
  ),
  on(
    NewCustomWorkoutActions.getCompletedCustomWorkoutsWeekSuccess,
    (state, { payload: completedWorkoutsWeek }) => {
      return {
        ...state,
        completedWorkoutsWeek,
      };
    },
  ),
);
