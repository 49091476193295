import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFeedData, IReactions } from "src/app/models/feed";
import * as FeedsActions from "src/app/store/actions/feed.actions";

import { Store } from "@ngrx/store";

@Component({
  selector: "app-interactions-feed",
  templateUrl: "./interactions-feed.component.html",
  styleUrls: ["./interactions-feed.component.scss"],
})
export class InteractionsFeedComponent implements OnInit {
  @Input() interactions: IReactions[];
  @Input() feed_id: number;
  @Input() feed_interaction_id: number;
  @Input() feed: IFeedData;

  constructor(private store: Store) {}

  ngOnInit() {}

  setReactions(feed_id: number, feed_reaction_id: number) {
    this.store.dispatch(
      FeedsActions.setReactions({
        payload: {
          feed_id,
          feed_reaction_id,
        },
      }),
    );
  }

  updateReactions(feed_interaction_id: number, feed_reaction_id: number) {
    this.store.dispatch(
      FeedsActions.updateReactions({
        payload: {
          feed_interaction_id,
          feed_reaction_id,
        },
      }),
    );
  }

  setInteraction(reaction: IReactions) {
    if (this?.feed_interaction_id) {
      if (this.feed?.reaction && this.feed?.reaction?.id === reaction.id) {
        this.deleteReactions(this.feed_id, this.feed_interaction_id);
      } else {
        this.updateReactions(this.feed_interaction_id, reaction.id);
      }
    } else {
      this.setReactions(this.feed_id, reaction.id);
    }
    console.log("reaction:", reaction);
  }

  displayGrid() {
    let auxGrid = "grid-template-columns:";
    if (this.interactions.length <= 4) {
      for (let index = 0; index < this.interactions.length; index++) {
        auxGrid += " 1fr";
      }
      return auxGrid;
    } else if (this.interactions.length > 4) {
      return "grid-template-columns: 1fr 1fr 1fr 1fr";
    }
  }

  deleteReactions(feed_id: number, feed_interaction_id: number) {
    this.store.dispatch(
      FeedsActions.deleteReactions({
        payload: {
          feed_id,
          feed_interaction_id,
        },
      }),
    );
  }
}
