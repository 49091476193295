import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  IFeedData,
  IInteractionFeed,
  IPostFeed,
  IUpdateFeed,
} from "src/app/models/feed";

export const getFeeds = createAction(
  "[Feeds] Get Feeds",
  props<{
    payload: {
      page: number;
      per_page: number;
      empresa_id?: number; // Filtro
      verificado?: boolean; // Filtro
      auxData?: IFeedData[]; // Valor de scroll anterior
    };
  }>(),
);

export const getFeedsSuccess = createAction(
  "[Feeds] Get Feeds Success",
  props<{ payload: any }>(),
);

export const getFeedsError = createAction(
  "[Feeds] Get Feeds Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const getMyFeed = createAction(
  "[Feeds] Get My Feed",
  props<{
    payload: {
      page: number;
      per_page: number;
      empresa_id?: number; // Filtro
      verificado?: boolean; // Filtro
      auxData?: IFeedData[]; // Valor de scroll anterior
    };
  }>(),
);

export const getMyFeedSuccess = createAction(
  "[Feeds] Get My Feed Success",
  props<{ payload: any }>(),
);

export const getMyFeedError = createAction(
  "[Feeds] Get My Feed Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const getFeedByID = createAction(
  "[Feeds] Get FeedByID",
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const getFeedByIDSuccess = createAction(
  "[Feeds] Get Feed By ID Success",
  props<{ payload: any }>(),
);

export const getFeedByIDError = createAction(
  "[Feeds] Get FeedByID Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const getReactions = createAction("[Feeds] Get Reactions Item");

export const getReactionsSuccess = createAction(
  "[Feeds] Get Reactions Success",
  props<{ payload: any }>(),
);

export const getReactionsError = createAction(
  "[Feeds] Get Reactions Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const getInteractions = createAction(
  "[Feeds] Get Interactions",
  props<{
    payload: {
      page: number;
      per_page: number;
      auxData?: IInteractionFeed[]; // Valor de scroll anterior
    };
  }>(),
);

export const getInteractionsSuccess = createAction(
  "[Feeds] Get Interactions Success",
  props<{ payload: any }>(),
);

export const getInteractionsError = createAction(
  "[Feeds] Get Interactions Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const getMessages = createAction("[Feeds] Get Messages");

export const getMessagesSuccess = createAction(
  "[Feeds] Get Messages Success",
  props<{ payload: any }>(),
);

export const getMessagesError = createAction(
  "[Feeds] Get Messages Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const setReactions = createAction(
  "[Feeds] Set Reactions Item",
  props<{
    payload: {
      feed_id: number;
      feed_reaction_id: number;
    };
  }>(),
);

export const setReactionsSuccess = createAction(
  "[Feeds] Set Reactions Success",
  props<{ payload: any }>(),
);

export const setReactionsError = createAction(
  "[Feeds] Set Reactions Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const setFeed = createAction(
  "[Feeds] Set Feed Item",
  props<{
    payload: {
      data: IPostFeed;
    };
  }>(),
);

export const setFeedSuccess = createAction(
  "[Feeds] Set Feed Success",
  props<{ payload: any }>(),
);

export const setFeedError = createAction(
  "[Feeds] Set Feed Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const updateFeed = createAction(
  "[Feeds] Update Feed Item",
  props<{
    payload: {
      id: number;
      data: IUpdateFeed;
    };
  }>(),
);

export const updateFeedSuccess = createAction(
  "[Feeds] Update Feed Success",
  props<{ payload: any }>(),
);

export const updateFeedError = createAction(
  "[Feeds] Update Feed Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const updateReactions = createAction(
  "[Feeds] Update Reactions Item",
  props<{
    payload: {
      feed_interaction_id: number;
      feed_reaction_id: number;
    };
  }>(),
);

export const updateReactionsSuccess = createAction(
  "[Feeds] Update Reactions Success",
  props<{ payload: any }>(),
);

export const updateReactionsError = createAction(
  "[Feeds] Update Reactions Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteReactions = createAction(
  "[Feeds] Delete Reactions Item",
  props<{
    payload: {
      feed_id: number;
      feed_interaction_id: number;
    };
  }>(),
);

export const deleteReactionsSuccess = createAction(
  "[Feeds] Delete Reactions Success",
  props<{ payload: any }>(),
);

export const deleteReactionsError = createAction(
  "[Feeds] Delete Reactions Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteFeed = createAction(
  "[Feeds] Delete Feed Item",
  props<{
    payload: {
      feed_id: number;
    };
  }>(),
);

export const deleteFeedSuccess = createAction(
  "[Feeds] Delete Feed Success",
  props<{ payload: any }>(),
);

export const deleteFeedError = createAction(
  "[Feeds] Delete Feed Error",
  props<{ payload: HttpErrorResponse }>(),
);

export const setViewInteractionFeed = createAction(
  "[Feeds] Set View Interaction Feed Item",
  props<{
    payload: {
      feed_interaction_id: number;
    };
  }>(),
);

export const setViewInteractionFeedSuccess = createAction(
  "[Feeds] Set View Interaction Feed Success",
  props<{ payload: any }>(),
);

export const setViewInteractionFeedError = createAction(
  "[Feeds] Set View Interaction Feed Error",
  props<{ payload: HttpErrorResponse }>(),
);

// -----------------------------------------------
// get report messages
export const getReportMessages = createAction("[Feeds] get report messages");

export const getReportMessagesSuccess = createAction(
  "[Feeds] get report messages success",
  props<{ payload: any }>(),
);

export const getReportMessagesError = createAction(
  "[Feeds] get report messages error",
  props<{ payload: HttpErrorResponse }>(),
);

// -----------------------------------------------
// set feed report

export const setFeedReport = createAction(
  "[Feeds] set feed report",
  props<{ payload: any }>(),
);

export const setFeedReportSuccess = createAction(
  "[Feeds] set feed report success",
);

export const setFeedReportError = createAction(
  "[Feeds] set feed report error",
  props<{ payload: HttpErrorResponse }>(),
);

// -----------------------------------------------
// set block user

export const setBlockUser = createAction(
  "[Blocked Users] set block user",
  props<{ payload: any }>(),
);

export const setBlockUserSuccess = createAction(
  "[Blocked Users] set block user success",
);

export const setBlockUserError = createAction(
  "[Blocked Users] set block user error",
  props<{ payload: HttpErrorResponse }>(),
);

// -----------------------------------------------
// set hide and show post

export const setHidePost = createAction(
  "[Feeds] set hide post",
  props<{ payload: any }>(),
);

export const setShowPost = createAction(
  "[Feeds] set show post",
  props<{ payload: any }>(),
);

// -----------------------------------------------
// get blocked users

export const getBlockedUsers = createAction("[Feeds] get blocked users");

export const getBlockedUsersSuccess = createAction(
  "[Blocked Users] get blocked users success",
  props<{ payload: any }>(),
);

export const getBlockedUsersError = createAction(
  "[Blocked Users] get blocked users error",
  props<{ payload: HttpErrorResponse }>(),
);

// -----------------------------------------------
// remove blocked user from store
export const setRemoveBlockedUserFromStore = createAction(
  "[Blocked Users] set remove blocked user from store",
  props<{ payload: any }>(),
);

// -----------------------------------------------
// add blocked user in store
export const setAddBlockedUserInStore = createAction(
  "[Blocked Users] set add blocked user in store",
  props<{ payload: any }>(),
);

// -----------------------------------------------
// set unblock user
export const setUnblockUser = createAction(
  "[Blocked Users] set unblocked user",
  props<{ payload: any }>(),
);

export const setUnblockUserSuccess = createAction(
  "[Blocked Users] set unblocked user success",
);

export const setUnblockUserError = createAction(
  "[Blocked Users] set unblocked user error",
  props<{ payload: any }>(),
);
