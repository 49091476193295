import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setUpdateCustomExercise = createAction(
  '[Update Custom Exercise] Set Update Custom Exercise',
  props<{ payload: any }>(),
);

export const setUpdateCustomExerciseSuccess = createAction(
  '[Update Custom Exercise] Set Update Custom Exercise Success',
);

export const setUpdateCustomExerciseError = createAction(
  '[Update Custom Exercise] Set Update Custom Exercise Error',
  props<{ payload: HttpErrorResponse }>(),
);
