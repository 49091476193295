import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setFavoriteContent = createAction(
  '[FavoriteContent] Set FavoriteContent',
  props<{ payload: any }>(),
);

export const setFavoriteContentSuccess = createAction(
  '[FavoriteContent] Set FavoriteContent Success',
  props<{ payload: any }>(),
);

export const setFavoriteContentError = createAction(
  '[FavoriteContent] Set FavoriteContent Error',
  props<{ payload: HttpErrorResponse }>(),
);
