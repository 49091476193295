import { Injectable } from "@angular/core";

import { Storage } from "@capacitor/storage";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    constructor() {}

    // JSON "set" example
    async setObject() {
        await Storage.set({
            key: "user",
            value: JSON.stringify({
                id: 1,
                name: "Max",
            }),
        });
    }

    // JSON "get" example
    async getObject() {
        const ret = await Storage.get({ key: "user" });
        const user = JSON.parse(ret.value);
    }

    async setItem(key: string, value: any): Promise<void> {
        await Storage.set({
            key,
            value: JSON.stringify(value),
        });
    }

    async getItem(key: string): Promise<any> {
        const { value } = await Storage.get({ key });
        return JSON.parse(value);
    }

    async removeItem(key: string): Promise<void> {
        await Storage.remove({ key });
    }

    async keys() {
        const { keys } = await Storage.keys();
        console.log("Got keys: ", keys);
    }

    async clear() {
        await Storage.clear();
    }
}
