import {
  Component,
  ElementRef,
  Input,
  Output,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Slide } from 'src/app/shared/models/slide.model';

import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider-full',
  templateUrl: './slider-full.component.html',
  styleUrls: ['./slider-full.component.scss'],
})
export class SliderFullComponent implements OnInit {
  @ViewChild('slides', { static: true }) slides: ElementRef;

  @Input() buttonAction: any;

  @Input() items: Slide[];

  @Output() finishedSlider = new EventEmitter();

  @Input() options = {
    pagination: {
      el: '.btt-slider-full-slide--bullets',
    },
  };

  currentIdSlide: number = 0;

  constructor() {}

  ngOnInit() {}

  async nextSlide(nexSlide: boolean = false) {
    if (nexSlide) {
      this.slides.nativeElement.slideNext();
    } else {
      this.currentIdSlide = this.currentIdSlide + 1;
    }
  }

  async prevSlide() {
    this.currentIdSlide = this.currentIdSlide - 1;
  }

  finishSlider() {
    this.finishedSlider.emit();
  }
}
