import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-text-read-more',
  templateUrl: './text-read-more.component.html',
  styleUrls: ['./text-read-more.component.scss'],
})
export class TextReadMoreComponent implements OnInit, AfterViewInit {
  @ViewChild('textWrapper') textWrapper: ElementRef;
  @ViewChild('button') button;

  @Input() text;
  @Input() minLines: number = 3;

  showAllText: boolean = true;
  rowsNumber: number;

  constructor() {}

  ngOnInit() {
    if (this.text) {
      this.text = this.text.replace(new RegExp('\n', 'g'), '<br />');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.hideAllText(), 500);
  }

  // Função para manipular o tamanho da div de texto
  loadMore() {
    this.showAllText = !this.showAllText;
  }

  hideAllText() {
    if (this.rowsNumber) return;
    let element = this.textWrapper.nativeElement;
    let lineHeight = parseInt(
      window.getComputedStyle(element).lineHeight,
    ) as number;

    let height = parseInt(window.getComputedStyle(element).height) as number;
    this.rowsNumber = Math.round(height / lineHeight);
    this.showAllText = false;
  }
}
