import { Component, Input, OnInit } from '@angular/core';
import { NativeAudio } from '@capacitor-community/native-audio';

import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';

import { App } from '@capacitor/app';
import { Router } from '@angular/router';

interface ISetupListeners {
  onDismiss: boolean;
}
@Component({
  selector: 'app-btt-new-exercise-timer',
  templateUrl: './btt-new-exercise-timer.component.html',
  styleUrls: ['./btt-new-exercise-timer.component.scss'],
})
export class BttNewExerciseTimerComponent implements OnInit {
  nativeBackButtonListener$: any;
  soundOn: boolean = true;
  soundBeep: any;
  soundBeepInterval: number = 2; //in seconds

  @Input() defaultAccountantValue = 45;
  accountantValue: number = 0;
  eventAccountant: any;

  startCounterCtrl: boolean = false;

  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    private alertController: AlertController,
  ) {
    this.preloadSound();
  }

  ngOnInit() {
    this.backBtnAuxiliarFunction();
  }

  async preloadSound() {
    await NativeAudio.preload({
      assetId: 'beepAudio',
      assetPath: 'public/assets/beep-exercicio.mp3',
      audioChannelNum: 1,
      isUrl: false,
    });
  }

  startCounter() {
    if (this.startCounterCtrl) return;
    this.startCounterCtrl = true;
    // play audio within soundBeepInterval seconds
    this.soundBeep = setInterval(
      () =>
        this.soundOn &&
        NativeAudio.play({
          assetId: 'beepAudio',
          time: null,
        }),
      this.soundBeepInterval * 1000,
    );
    // increment the counter up to the defaultAccountantValue
    this.eventAccountant = setInterval(() => {
      this.accountantValue = this.accountantValue + 1;
      if (this.accountantValue > this.defaultAccountantValue)
        this.stopCounter();
    }, 1000);
  }

  stopCounter() {
    // Stop counter, clearing the count event and playing the sound, resetting the counter, stopping the running audio
    clearInterval(this.eventAccountant);
    clearInterval(this.soundBeep);
    this.accountantValue = 0;

    NativeAudio.stop({
      assetId: 'beepAudio',
    });
    this.startCounterCtrl = false;
  }

  handleChangeSound() {
    // turn on and off sound
    this.soundOn = !this.soundOn;
  }

  handleClickClose() {
    // stop counter and close modal
    // this.stopCounter();
    this.modalController.dismiss().then(() => this.stopCounter());
  }

  backBtnAuxiliarFunction() {
    const setupListeners = { onDismiss: true };
    this.handleChangeBackButton(setupListeners);
  }

  // legacy app component
  async backButtonAlert() {
    const alert = await this.alertController.create({
      message: 'Deseja sair do aplicativo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'Cancel',
        },
        {
          text: 'Sim',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  handleChangeBackButton({ onDismiss }: ISetupListeners) {
    // Remove previous event from backButton
    this.nativeBackButtonListener$ = App.removeAllListeners();
    // set on dismiss
    if (onDismiss) {
      this.stopCounter();
      this.nativeBackButtonListener$ = App.addListener(
        'backButton',
        async (res) => {
          const setupListeners = { onDismiss: false };
          // set on dismiss
          this.handleClickClose();
          this.handleChangeBackButton(setupListeners);
        },
      );
    } else {
      //Re-add event to backButton
      this.nativeBackButtonListener$ = App.addListener(
        'backButton',
        async (res) => {
          if (!res.canGoBack) {
            this.backButtonAlert();
          } else {
            this.navCtrl.back();
          }
        },
      );
    }
  }
}
