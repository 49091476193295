import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { CalendarPageModal } from 'src/app/pages/_modals/calendar/calendar.page';
import { ExerciseModalPage } from 'src/app/pages/_modals/exercise-modal/exercise-modal.page';
import { Exercise } from 'src/app/shared/models/exercise.model';

// actions
import * as ExerciseViewActions from 'src/app/store/actions/exerciseView.actions';

// enum
import { ViewTypesEnum } from 'src/app/enums/viewTypes.enum';

@Component({
  selector: 'app-btt-weekly-schedule',
  templateUrl: './btt-weekly-schedule.component.html',
  styleUrls: ['./btt-weekly-schedule.component.scss'],
})
export class BttWeeklyScheduleComponent implements OnInit {
  @Input() dataCalendar = [];
  @Input() dataEvents = [];
  @Input() user_premium: boolean = false;
  @Input() user: User;

  listDays = [
    {
      id: 1,
      title: 'D',
      value: 'domingo',
      percentage_completed: null,
    },
    {
      id: 2,
      title: 'S',
      value: 'segunda-feira',
      percentage_completed: null,
    },
    {
      id: 3,
      title: 'T',
      value: 'terça-feira',
      percentage_completed: null,
    },
    {
      id: 4,
      title: 'Q',
      value: 'quarta-feira',
      percentage_completed: null,
    },
    {
      id: 5,
      title: 'Q',
      value: 'quinta-feira',
      percentage_completed: null,
    },
    {
      id: 6,
      title: 'S',
      value: 'sexta-feira',
      percentage_completed: null,
    },
    {
      id: 7,
      title: 'S',
      value: 'sábado',
      percentage_completed: null,
    },
  ];
  weeksEvents = [];

  now: Date = new Date();

  constructor(private modalController: ModalController, private store: Store) {}
  ngOnInit() {
    this.getDataDay();
  }

  getDataDay() {
    let now = new Date(new Date().setHours(0, 0, 1));
    let auxNowNumber = 0;
    this.listDays.map((valueData) => {
      if (now.toLocaleString('pt-BR', { weekday: 'long' }) === valueData.value)
        auxNowNumber = valueData.id;
    });

    let lastWeekDay = new Date(
      new Date(new Date().setHours(0, 0, 1)).getTime() -
        auxNowNumber * 1440 * 60000,
    );
    let dataWeek = this.dataCalendar.filter((value) => {
      let auxValue = new Date(new Date(value.data_liberacao).setHours(0, 0, 1));
      return auxValue >= lastWeekDay && auxValue <= now;
    });

    this.weeksEvents = dataWeek.reverse();
  }

  today(eventDay) {
    let auxEventDay = new Date().toLocaleString('pt-BR', { weekday: 'long' });
    return auxEventDay.toString() === eventDay.toString();
  }

  styleDays(dataDay) {
    let eventDay = dataDay.value;
    let auxDay = dataDay.title;

    let dataOneDay = this.weeksEvents.find((value) => {
      let auxValue = new Date(
        new Date(value.data_liberacao).setHours(0, 0, 1),
      ).toLocaleString('pt-BR', { weekday: 'long' });
      return auxValue === eventDay;
    });

    if (!dataOneDay) return 'div__content_day';

    let atualDay = new Date(dataOneDay.data_liberacao).setHours(0, 0, 1);
    let count: number = 0;
    let totalPercentageCompleted = this.dataCalendar.reduce((total, value) => {
      let dataLiberacao = new Date(
        new Date(value.data_liberacao).setHours(0, 0, 1),
      );
      let auxAtual = new Date(new Date(atualDay).setHours(0, 0, 1));
      if (dataLiberacao.toLocaleString() === auxAtual.toLocaleString()) {
        count = count + 1;
        return total + Number(value?.percentage_completed);
      }
      return total;
    }, 0);

    let numberValue = (totalPercentageCompleted / count) * 100;

    let myClass = `div__day div__content_day c-circular-progress c-circular-progress--${numberValue.toFixed(
      0,
    )} c-circular-progress--${numberValue.toFixed(0)}--${auxDay}`;

    return myClass;
  }

  validateDate(auxDataDay, typeDay) {
    let eventDay = auxDataDay.value;

    let dataOneDay = this.weeksEvents.find((value) => {
      let auxValue = new Date(
        new Date(value.data_liberacao).setHours(0, 0, 1),
      ).toLocaleString('pt-BR', { weekday: 'long' });
      return auxValue === eventDay;
    });

    if (!dataOneDay) return false;

    let dataDay = this.dataEvents?.find((value) => {
      let auxDay = new Date(dataOneDay.data_liberacao).setHours(0, 0, 0);

      let auxStartDay = new Date(value?.startTime).setHours(0, 0, 0);
      let auxEndDay = new Date(value?.endTime).setHours(0, 0, 0);

      return auxDay >= auxStartDay && auxDay <= auxEndDay;
    });

    if (!dataDay) return false;

    let auxDay = new Date(dataOneDay.data_liberacao).setHours(0, 0, 0);
    let auxStartDay = dataDay?.startTime.setHours(0, 0, 0);
    let auxEndDay = dataDay?.endTime.setHours(0, 0, 0);
    let returnValue = false;

    if (auxDay === auxStartDay && auxDay == auxEndDay) return false;

    switch (typeDay) {
      case 'between':
        if (auxDay > auxStartDay && auxDay < auxEndDay) returnValue = true;
        break;
      case 'start':
        if (auxDay === auxStartDay) returnValue = true;
        break;
      case 'end':
        if (auxDay === auxEndDay) returnValue = true;
        break;
      default:
        returnValue = false;
    }
    return returnValue;
  }

  async handleChangeDay(auxDataDay: any) {
    let eventDay = auxDataDay.value;

    let dataOneDay = this.weeksEvents.find((value) => {
      let auxValue = new Date(
        new Date(value.data_liberacao).setHours(0, 0, 1),
      ).toLocaleString('pt-BR', { weekday: 'long' });
      return auxValue === eventDay;
    });

    if (!dataOneDay) return;

    let auxDay = new Date(dataOneDay.data_liberacao).setHours(0, 0, 0);
    let dataResul = this.dataCalendar.filter((training) => {
      let auxExerciseDay = new Date(training.data_liberacao).setHours(0, 0, 0);
      if (auxDay === auxExerciseDay) return true;
    });

    dataResul = dataResul.map((values) => {
      return { ...values, ...values.exercicio };
    });

    if (dataResul) {
      const modal = await this.modalController.create({
        component: CalendarPageModal,
        componentProps: {
          training: dataResul,
          selectedDate: dataOneDay.data_liberacao,
        },
        cssClass: 'modal-white',
        breakpoints: [0.4, 0.7],
        initialBreakpoint: 0.4,
      });
      await modal.present();

      modal.onWillDismiss().then(async (res) => {
        if (res.data) this.openExercise(res.data);
      });
    }
  }
  openExercise(dataResul) {
    this.openExerciseModal(this.formatExercise(dataResul), dataResul);
  }
  formatExercise(exercise) {
    const formated: Exercise = {
      id: exercise.id,
      video: exercise?.vimeo?.video,
      image: exercise?.vimeo?.picture,
      title: exercise?.titulo,
      text: exercise?.descricao,
      instrutor: exercise?.instrutor,
      contentRating: Math.round(exercise?.average_rating) || 0,
      hasNextExercise: false,
      hasPrevExercise: false,
      percentageCompleted: exercise.percentage_completed,
      is_active_landscape: exercise?.is_active_landscape,
      is_liked: exercise?.is_liked,
    };
    return formated;
  }
  async openExerciseModal(exercise, dataResul) {
    let auxData = {
      payload: {
        exerciseId: exercise.id,
        origin: ViewTypesEnum.SCHEDULED_TRAINING,
        origin_id: dataResul.treino_agendado_id,
      },
    };
    this.store.dispatch(ExerciseViewActions.setExerciseView(auxData));

    const modal = await this.modalController.create({
      component: ExerciseModalPage,
      cssClass: 'modal-full',
      componentProps: {
        exercise,
        onCompleteSubtitle: dataResul.nome,
        blockAccess: !this.user_premium,
        origin: ViewTypesEnum.SCHEDULED_TRAINING,
      },
    });

    return await modal.present();

    // TO DO: handle do modalDismiss para validar se concluiu (acho q n tem nessa tela, mas bom confirmar)
  }
}
