import { Component, OnInit } from "@angular/core";
import { Platform, ModalController, ToastController } from "@ionic/angular";

@Component({
  selector: "app-pre-rating-modal",
  templateUrl: "./pre-rating-modal.component.html",
  styleUrls: ["./pre-rating-modal.component.scss"],
})
export class PreRatingModalComponent implements OnInit {
  evaluateActionButton: any;
  noThanksActionButton: any;
  subscribe$: any;
  toast: HTMLIonToastElement;

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    this.initButtons();
  }

  evaluate = async () => {
    this.modalCtrl.dismiss("evaluate");
  };

  noThanks = async () => {
    this.showSnackBar();
    this.modalCtrl.dismiss("noThanks");
  };

  initButtons(): void {
    this.evaluateActionButton = {
      actionName: "evaluate",
      action: this.evaluate,
    };
    this.noThanksActionButton = {
      actionName: "noThanks",
      action: this.noThanks,
    };
  }

  executeFunctionBtn(action: any): void {
    action.action();
  }

  ionViewWillEnter() {
    this.subscribe$ = this.platform.backButton.subscribeWithPriority(
      1,
      () => { }
    );
  }

  ionViewDidLeave() {
    this.subscribe$.unsubscribe();
  }

  async showSnackBar(): Promise<void> {
    this.toast = await this.toastController.create({
      message:
        "Você pode nos contar sua experiência no app quando quiser, em “<b>Avalie nosso App</b>”, na opção Ajustes da barra de navegação.",
      duration: 10000,
      position: "bottom",
      mode: "md",
      animated: true,
      cssClass: "custom-toast",
    });
    this.toast.present();
  }
}
