import {
  OnInit,
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ElementRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit {
  @Input()
  title: string;

  @Input()
  buttonClose: boolean = true;

  @Input()
  bodyComponent: any;

  @Input()
  dialogHeight: number;

  @ViewChild('dialogContent', { read: ViewContainerRef, static: false })
  viewContainerRef: ViewContainerRef;

  @Input()
  button1Text: string;

  @Input()
  button2Text: string;

  constructor(
    private modalCtrl: ModalController,
    private _elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngAfterViewInit() {
    let dialogHeight = this.dialogHeight ? this.dialogHeight : 300;
    if (!this.buttonClose && !this.dialogHeight) {
      dialogHeight -= 38;
    }
    this._elementRef.nativeElement.parentNode.style.height = dialogHeight
      ? `${dialogHeight}px`
      : `${dialogHeight}px`;
    this.addBodyComponent();
  }

  ngOnInit() {}

  /**
   * Ação botão 1.
   */
  public button1Action() {
    this.modalCtrl.dismiss('button1Action');
  }

  /**
   * Ação botão 2.
   */
  public button2Action() {
    this.modalCtrl.dismiss('button2Action');
  }

  /**
   * Fecha o dialog.
   */
  public closeModal() {
    this.modalCtrl.dismiss();
  }

  /**
   * Adiciona o componente passado por parâmetro no body do dialog.
   */
  addBodyComponent() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.bodyComponent,
    );
    const ref = this.viewContainerRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
