import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ForgetPasswordActions from 'src/app/store/actions/forgotPassword.actions';
import { AuthService, FORGOT_PASS_KEY } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';

import * as LoadingActions from '../actions/loading.actions';
import * as LoginActions from '../actions/login.actions';

import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { TOKEN_KEY } from 'src/app/services/auth.service';
import { SuccessfullyResetPasswordModal } from 'src/app/pages/_modals/successfully-reset-password/successfully-reset-password.page';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
import { setPageRedirect } from '../actions/pageRedirect.actions';

@Injectable()
export class ForgotPasswordEffects {
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setForgotPassword),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.FORGOT_PASSWORD,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.authService.setForgotPassword(payload).pipe(
          map((res: any) => {
            this.navigateToVerificationPage(res);
            return ForgetPasswordActions.setForgotPasswordSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ForgetPasswordActions.setForgotPasswordError({ payload: error }),
            ),
          ),
        );
      }),
    ),
  );

  setForgotPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setForgotPasswordSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FORGOT_PASSWORD,
        }),
      ),
    ),
  );

  setForgotPasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setForgotPasswordError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FORGOT_PASSWORD,
        }),
      ),
    ),
  );

  navigateToVerificationPage(responseData: any) {
    if (!responseData.error) {
      this.router.navigateByUrl('/verification');
      Storage.set({
        key: FORGOT_PASS_KEY,
        value: JSON.stringify({ email: responseData.email }),
      });
    }
  }

  setCodeToRedefinePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setCodeToRedefinePassword),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.CODE_TO_REDEFINE_PASSWORD,
        }),
      ),
      switchMap(({ payload }) => {
        return this.authService.setCodeToRedefinePassword(payload).pipe(
          map((res: any) => {
            if (res.token) {
              this.setAccessToken(res.token);
            }
            return ForgetPasswordActions.setCodeToRedefinePasswordSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ForgetPasswordActions.setCodeToRedefinePasswordError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  async setAccessToken(token: string) {
    from(
      Storage.set({
        key: TOKEN_KEY,
        value: JSON.stringify({ access_token: token, token_type: 'Bearer' }),
      }),
    );

    await this.authService.loadToken();

    this.store.dispatch(LoginActions.getAutenticatedUser());
    this.router.navigateByUrl('/reset-password', {
      replaceUrl: true,
    });
  }

  setCodeToRedefinePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setCodeToRedefinePasswordSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CODE_TO_REDEFINE_PASSWORD,
        }),
      ),
    ),
  );

  setCodeToRedefinePasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setCodeToRedefinePasswordError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CODE_TO_REDEFINE_PASSWORD,
        }),
      ),
    ),
  );

  // ----------------------------

  setChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setChangePassword),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.CHANGE_PASSWORD,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.userService.changePassword(payload).pipe(
          map((res: any) => {
            return ForgetPasswordActions.setChangePasswordSuccess();
          }),
          catchError((error) =>
            of(
              ForgetPasswordActions.setChangePasswordError({ payload: error }),
            ),
          ),
        );
      }),
    ),
  );

  setChangePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setChangePasswordSuccess),
      tap(() => {
        return this.showSuccessfullyResetPasswordModal();
      }),
      map(() => {
        this.store.dispatch(setPageRedirect({ payload: null }));
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CHANGE_PASSWORD,
        });
      }),
    ),
  );

  setChangePasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgetPasswordActions.setChangePasswordError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CHANGE_PASSWORD,
        }),
      ),
    ),
  );

  async showSuccessfullyResetPasswordModal() {
    const modal = await this.modalController.create({
      component: SuccessfullyResetPasswordModal,
      cssClass: 'modal-success-pass',
      breakpoints: [0.6],
      initialBreakpoint: 0.6,
      backdropDismiss: false,
    });
    return await modal.present();
  }

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private loadingController: LoadingController,
    private store: Store,
    private router: Router,
    private modalController: ModalController,
  ) {}
}
