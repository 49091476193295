import { createReducer, on } from '@ngrx/store';
import * as PremiumActions from '../actions/premium.actions';

import { Premium } from 'src/app/models/premium';

export interface PremiumState {
  status: Premium;
}

const initialState: PremiumState = {
  status: null,
};

export const premiumReducer = createReducer(
  initialState,
  on(PremiumActions.getPremiumStatusSuccess, (state, { payload: status }) => {
    return {
      ...state,
      status,
    };
  }),
);
