import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V2,
  API_URL_V3,
  environment,
} from 'src/environments/environment';

import { PremiumService } from './premium.service';

@Injectable({
  providedIn: 'root',
})
export class SearchCategoriesService {
  constructor(private http: HttpClient) {}

  getSearchResultCategories(
    category: string,
    searchValue: string,
  ): Observable<any> {
    const url: string =
      API_URL_V2 +
      '/nutricionista-parceiras-dicas-exercicios/' +
      category +
      '?titulo=' +
      searchValue;
    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
