import { createReducer, on } from '@ngrx/store';
import * as ContinueTrainingActions from '../actions/continueTraining.actions';

export interface IContinueTrainingState {
  continueTraining: any[];
  loading: boolean;
}

const initialState: IContinueTrainingState = {
  continueTraining: [],
  loading: false,
};

export const continueTrainingReducer = createReducer(
  initialState,
  on(ContinueTrainingActions.getContinueTraining, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ContinueTrainingActions.getContinueTrainingSuccess,
    (state, { payload: continueTraining }) => {
      return {
        ...state,
        continueTraining,
        loading: false,
      };
    },
  ),
  on(
    ContinueTrainingActions.getContinueTrainingError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
);
