import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.page.html',
  styleUrls: ['./video-modal.page.scss'],
})
export class VideoModalPage implements OnInit {
  @Input() video: any;
  @Input() isActiveLandscape: boolean = false;

  constructor(
    private viewCtrl: ModalController,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.trackScreen('video_execution', 'VideoScreen');
  }

  closeModal() {
    this.viewCtrl.dismiss();
  }
}
