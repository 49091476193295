import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";
import { environment } from 'src/environments/environment';
import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    constructor(
        private platform: Platform,
        private mixpanel: Mixpanel
    ) {
        if (environment.production) {
            this.initMixpanel();
            this.initFirebase();
        }
    }

    async initFirebase() {
        const deviceInfo = Device.getInfo();

        try {
            if ((await deviceInfo).platform === "web") {
                FirebaseAnalytics.initializeFirebase({
                    apiKey: "AIzaSyDQx1HzwcWv5Ozs2LhKtImW42kUp9-A6_g",
                    authDomain: "bittrainers-210217.firebaseapp.com",
                    databaseURL: "https://bittrainers-210217.firebaseio.com",
                    projectId: "bittrainers-210217",
                    storageBucket: "bittrainers-210217.appspot.com",
                    messagingSenderId: "256061605972",
                    appId: "1:256061605972:web:ec3534bca088891ab505c1",
                    measurementId: "G-QWRNX9MP43"
                });
            }
        } catch (e) {
            console.error("[ERROR INIT FIREBASE ANALYTICS SERVICE] =====> ", e);
        }
    }

    async initMixpanel() {
        try {
            await this.mixpanel.init(environment.mixpanelToken);
        } catch (e) {
            console.error("[ERROR INIT MIXPANEL ANALYTICS SERVICE] =====> ", e);
        }
    }

    async identifyMixpanelUser(userId: string) {
        try {
            await this.mixpanel.identify(userId);
        } catch (e) {
            console.error("[ERROR IDENTIFY MIXPANEL] =====> ", e);
        }
    }

    async trackScreen(screenName, nameOverride) {
        console.log("Analytics Service TrackScrenn: ", screenName);
        try {
            if (environment.production) {
                await this.mixpanel.track('Screen Viewed', {
                    screenName,
                    nameOverride
                });
                await FirebaseAnalytics.setScreenName({
                    screenName,
                    nameOverride,
                });
            }
        } catch(e) {
            console.error('[ERROR TRACK SCREEN] ====> ', e)
        }
    }

    async trackEvent(name, params = {}) {
        console.log('Analytics Service TrackEvent', name, params);
        if (environment.production) {
            await this.mixpanel.track(name, params);
            await FirebaseAnalytics.logEvent({
                name,
                params,
            });
        }

    }
}
