import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';

import * as ProfilePictureActions from '../actions/profilePicture.actions';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

import * as LoginActions from '../actions/login.actions';
import * as LoadingActions from '../actions/loading.actions';

import * as LoginSelectors from 'src/app/store/selectors/login.selectors';

import { User } from 'src/app/models/user';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ProfilePictureEffects {
  // setOPersonality
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePictureActions.setProfilePicture),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.PROFILE_PICTURE,
        }),
      ),
      switchMap(({ payload }) => {
        return this.userService.saveUserPhoto(payload).pipe(
          map((res: any) => {
            let auxUser: User;
            let user$ = this.store.select(LoginSelectors.getUser);
            user$.subscribe((resUser) => (auxUser = resUser));

            let user: User = {
              ...auxUser,
              foto: res.foto,
            };

            LoginActions.getAutenticatedUserSuccess({
              payload: user,
            });

            return ProfilePictureActions.setProfilePictureSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ProfilePictureActions.setProfilePictureError({ payload: error }),
            ),
          ),
        );
      }),
    ),
  );

  setProfilePictureSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePictureActions.setProfilePictureSuccess),
      map(() => {
        // this.store.dispatch(LoginActions.getAutenticatedUser());
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PROFILE_PICTURE,
        });
      }),
    ),
  );

  setProfilePictureError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePictureActions.setProfilePictureError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PROFILE_PICTURE,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private loadingController: LoadingController,
  ) {}
}
