import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "partnerType",
})
export class PartnerTypePipe implements PipeTransform {
  transform(value: any[], ...args: unknown[]): unknown {
    if (value && value.length > 0) {
      if (value.includes("personal")) {
        return "Personal";
      }
      if (value.includes("nutricionistas parceiras")) {
        return "Nutricionista";
      }

      // se precisar de outro, só adicionar o retorno tratando
    }

    return null;
  }
}
