import { createReducer, on } from '@ngrx/store';
import * as TrainingProgramsActions from '../actions/trainingprograms.actions';

import { Training } from 'src/app/models/training';

export interface TrainingProgramsState {
  trainingPrograms: Training[];
}

const initialState: TrainingProgramsState = {
  trainingPrograms: [],
};

export const trainingProgramsReducer = createReducer(
  initialState,
  on(
    TrainingProgramsActions.getTrainingProgramsSuccess,
    (state, { payload: trainingPrograms }) => {
      return {
        ...state,
        trainingPrograms,
      };
    }
  )
);
