import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'btt-content-creator',
  templateUrl: './btt-content-creator.component.html',
  styleUrls: ['./btt-content-creator.component.scss'],
})
export class BttContentCreatorComponent implements OnInit {
  @Input() img = '';
  @Input() name = '';
  constructor() {}

  ngOnInit(): void {}
}
