import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import { NotificationsService } from "src/app/services/notifications.service";

import { Router } from "@angular/router";

import { NavController } from "@ionic/angular";

@Component({
  selector: "app-header-full",
  templateUrl: "./header-full.component.html",
  styleUrls: ["./header-full.component.scss"],
})
export class HeaderFullComponent implements OnInit, OnDestroy {
  hasNotifications = false;

  @Input() extraImage?: string;
  @Input() sizeImage?: number;

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    this.notificationsService.hasUnreaded.subscribe(
      (hasNotifications) => {
        this.hasNotifications = hasNotifications;
      },
      (err) => {}
    );
  }

  ngOnDestroy() {
    // this.notificationsService.hasUnreaded.unsubscribe();
  }

  openStickyNotesPage() {
    this.router.navigateByUrl("/home/sticky-notes");
  }

  showNotifications: boolean = false;

  openNotifications() {
    this.router.navigateByUrl("/notifications");
  }
}
