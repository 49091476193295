import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-btt-header-default',
  templateUrl: './btt-header-default.component.html',
  styleUrls: ['./btt-header-default.component.scss'],
})
export class BttHeaderDefaultComponent implements OnInit {
  @Input() showCloseOption: boolean = true;

  constructor(private navCtrl: NavController, private router: Router) {}

  ngOnInit() {}

  back() {
    this.navCtrl.back();
  }
  closePage() {
    this.router.navigate(['/home'], { replaceUrl: true });
  }
}
