import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instructorGender',
})
export class InstructorGenderPipe implements PipeTransform {
  transform(valor: string): string {
    if (valor === 'M') {
      return 'Prof. ';
    } else if (valor === 'F') {
      return 'Profª. ';
    } else {
      return '';
    }
  }
}
