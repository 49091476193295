import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SearchResult } from 'src/app/models/searchResult';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';

// selects
import * as SearchResultSelectors from 'src/app/store/selectors/searchResult.selectors';
import * as LoadingSelectors from 'src/app/store/selectors/loading.selectors';

// actions
import * as SearchResultActions from 'src/app/store/actions/searchResult.actions';
import * as ModalitiesActions from 'src/app/store/actions/modalities.actions';
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
@Component({
  selector: 'app-search-result-modal',
  templateUrl: './search-result-modal.page.html',
  styleUrls: ['./search-result-modal.page.scss'],
})
export class SearchResultModalPage implements OnInit {
  isWeb: boolean = false;

  loading$: Observable<string[]>;
  loadingTypes = loadingTypesEnum;

  constructor(
    private modalController: ModalController,
    private store: Store,
    private router: Router,
    private analytics: AnalyticsService,
    private loadingController: LoadingController,
    private platform: Platform,
  ) {
    platform.ready().then(() => {
      if (!platform.is('mobile')) {
        this.isWeb = true;
      } else {
        this.isWeb = false;
      }
    });
  }

  @Input() searchValue;

  inputSearchValue: string;
  listSearchResult$: Observable<SearchResult[]>;

  ngOnInit() {
    this.setConfigInit();
  }

  setConfigInit() {
    this.inputSearchValue = this.searchValue;
    this.loading$ = this.store.select(LoadingSelectors.getLoadingStatus);
    this.setListResult();
  }

  async setListResult() {
    this.listSearchResult$ = this.store.select(
      SearchResultSelectors.getSearchResult,
    );
  }

  getSelectedListResponseWithFilter() {
    return this.listSearchResult$;
  }

  async handleNewSearch(event: any) {
    this.store.dispatch(
      SearchResultActions.getSearchResult({
        payload: event.target.value,
      }),
    );

    this.setListResult();
  }

  handleChangeContent(selectedContent: any) {
    if (selectedContent.type === 'desafio') {
      this.router.navigateByUrl(
        `/home/selected-category-open/${selectedContent?.id}`,
      );
    } else if (selectedContent.type === 'modalidade') {
      this.openSubmodality(selectedContent);
    } else if (selectedContent.type === 'dica_receita') {
      // console.log('[selected content dica_receita]=> ', selectedContent);
      this.openDetail(selectedContent);
    } else if (selectedContent.type === 'treino_agendado') {
      // console.log('[selected content dica_receita]=> ', selectedContent);
      this.openScheduledTraining(selectedContent);
    } else {
      console.log('[selected content]=> ', selectedContent);
    }

    this.dismiss();
  }

  // open selectec modality
  openSubmodality(modality) {
    // console.log(modality);
    // return;
    const id = modality.id;
    const name = modality.nome;

    this.analytics.trackEvent('open_modality', {
      modality_id: id,
      modality_name: name,
    });

    this.store.dispatch(
      ModalitiesActions.setModalityImage({
        payload: modality.img,
      }),
    );
    if (modality.subcategorias && modality.subcategorias.length > 0) {
      this.store.dispatch(
        ModalitiesActions.setSubmodality({
          payload: modality,
        }),
      );
      this.router.navigate(['/home/modality/subcategory'], {
        state: {
          title: modality.nome,
          subcategories: modality.subcategorias,
        },
      });
    } else {
      this.router.navigate(['/home/modality/detail'], {
        state: {
          id,
          title: modality.nome,
          description: modality.descricao,
          isSubcategory: true,
        },
      });
    }
  }
  // open select detail
  openDetail(item) {
    this.analytics.trackEvent('nutrition_open_detail', {
      id: item.id,
      title: item.titulo,
    });

    this.router.navigate(['/home/nutrition/', item.id]);
  }
  // open scheduled training
  openScheduledTraining(item) {
    this.router.navigateByUrl('/home/training-scheduled', {
      state: {
        training: item,
      },
    });
  }

  dismiss() {
    const finished = true; // TO DO: fazer validação se concluiu
    this.modalController.dismiss({
      finished,
    });
  }
}
