import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Recommended } from 'src/app/models';

export const getContinueTraining = createAction(
  '[ContinueTraining] Get Continue Training',
  props<{
    payload: {
      qnt_items: number;
    };
  }>(),
);

export const getContinueTrainingSuccess = createAction(
  '[ContinueTraining] Get Continue Training Success',
  props<{ payload: any }>(),
);

export const getContinueTrainingError = createAction(
  '[ContinueTraining] Get Continue Training Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const setContinueTraining = createAction(
  '[ContinueTraining] Set Continue Training',
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const setContinueTrainingSuccess = createAction(
  '[ContinueTraining] Set Continue Training Success',
  props<{ payload: any }>(),
);

export const setContinueTrainingError = createAction(
  '[ContinueTraining] Set Continue Training Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteContinueTraining = createAction(
  '[ContinueTraining] Delete Continue Training',
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const deleteContinueTrainingSuccess = createAction(
  '[ContinueTraining] Delete Continue Training Success',
  props<{ payload: any }>(),
);

export const deleteContinueTrainingError = createAction(
  '[ContinueTraining] Delete Continue Training Error',
  props<{ payload: HttpErrorResponse }>(),
);
