import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DialogOptions } from '../components/dialog/utils';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  hasOpenModal = false;

  constructor(private modalCtrl: ModalController) {}

  /**
   * Apresenta um modal na tela com base nos parâmetros.
   * @param dialogOptions Opções para apresentação do modal.
   */
  public async show(dialogOptions: DialogOptions) {
    if (!this.hasOpenModal) {
      let cssClass: string[] = [];

      if (dialogOptions.cssClass) {
        cssClass.push(dialogOptions.cssClass);
      }

      if (dialogOptions.buttonClose === false) {
        cssClass.push('no-close-button');
      }

      this.hasOpenModal = true;
      const modal = await this.modalCtrl.create({
        component: DialogComponent,
        componentProps: dialogOptions,
        cssClass: cssClass,
        backdropDismiss: false,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      this.hasOpenModal = false;
      return data;
    }
    return null;
  }
}
