import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ModalController, Platform } from '@ionic/angular';
import { ExternalPage } from 'src/app/pages/login-company/external/external.page';
import { FormPage } from 'src/app/pages/login-company/form/form.page';
@Component({
  selector: 'app-web-login-company',
  templateUrl: './web-login-company.component.html',
  styleUrls: ['./web-login-company.component.scss'],
})
export class WebLoginCompanyComponent implements OnInit {
  isLoading = true;
  companies: any = [];
  companiesServer: any = [];
  companiesForView: any = [];
  hasViewedAllCompanies = false;
  search: string;
  isWeb: boolean = false;

  segment = 'client';
  companiesForWoly: any = [];
  companiesNotWoly: any = [];

  constructor(
    private companiesService: CompaniesService,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private platform: Platform,
  ) {
    platform.ready().then(() => {
      if (!platform.is('mobile')) this.isWeb = true;
    });
  }

  ngOnInit() {
    this.getCompanies();
    this.analytics.trackScreen('login_company', 'LoginOperadoraScreen');
  }

  getCompanies() {
    this.isLoading = true;

    this.companiesService.getCompanies().subscribe(
      (res) => {
        this.companies = res.empresas;

        this.companiesNotWoly = this.companies.filter((company) => !company.integracao_colaborador);
        this.companiesForWoly = this.companies.filter((company) => company.integracao_colaborador);

        this.updateCompaniesView();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      },
    );
  }

  updateCompaniesView() {
    this.companiesForView = this.segment === "client" ? this.companiesNotWoly : this.companiesForWoly;
  }
  changeSegment(param: string) {
    this.segment = param;
    this.updateCompaniesView();
  }

  openCompanyVinculation(company) {
    this.analytics.trackEvent('click_company_vinculation', {
      company_name: company.nome,
    });
    console.log('[res] => ', company);
    // se utiliza autenticação externa
    if (company.tipos_vinculacao.includes('auth_external')) {
      this.openPageModal(ExternalPage, { company });
      return;
    }

    this.openPageModal(FormPage, { company });
    return;
  }

  filterCompanies(event: any) {
    const searchTerm = event.target.value.toLowerCase();

    const baseList = this.segment === "client" ? this.companiesNotWoly : this.companiesForWoly;

    if (!searchTerm) {
      this.companiesForView = baseList;
    } else {
      this.companiesForView = baseList.filter((company) => company.nome.toLowerCase().includes(searchTerm));
    }
  }

  async openPageModal(page, props = {}) {
    const pageModal = await this.modalCtrl.create({
      component: page,
      cssClass: this.isWeb ? 'modal-half-web' : 'modal-half',
      componentProps: props,
    });
    return await pageModal.present();
  }
}
