import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as RecommendedActions from '../actions/recommended.actions';

import { UserService } from 'src/app/services/user.service';
import { Banner } from 'src/app/models/banner';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { RecommendedService } from 'src/app/services/recommended.service';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class RecommendedEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedActions.getRecommended),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.RECOMMENDED }),
      ),
      switchMap(() => {
        return this.recommendedService.getRecommended().pipe(
          map((res: any) => {
            return RecommendedActions.getRecommendedSuccess({
              payload: res.registros,
            });
          }),
          catchError((error) =>
            of(RecommendedActions.getRecommendedError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getRecommendedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedActions.getRecommendedSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.RECOMMENDED,
        }),
      ),
    ),
  );

  getRecommendedError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedActions.getRecommendedError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.RECOMMENDED,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private recommendedService: RecommendedService,
    private store: Store,
    private router: Router,
  ) {}
}
