import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { getUser } from '../store/selectors/login.selectors';
import { PremiumService } from '../services/premium.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanLoad {
  constructor(
    private router: Router,
    private store: Store,
    private premiumService: PremiumService,
  ) {}

  canLoad(): Observable<boolean> {
    return this.store.select(getUser).pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((user) => {
        console.log('[user auth login]=>', user);
        if (user?.id) {
          // check if user is premium
          this.premiumService.startService();
          // Directly open inside area
          this.router.navigate(['/home'], { replaceUrl: true });
          return false;
        } else {
          // Simply allow access to the login
          return true;
        }
      }),
    );
  }
}
