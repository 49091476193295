import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'btt-card-icon',
  templateUrl: './card-icon.component.html',
  styleUrls: ['./card-icon.component.scss'],
})
export class CardIconComponent implements OnInit {
  @Input() items: any[];
  @Input() isWeb: boolean;

  expanded: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  handleChangeExpanded() {
    this.expanded = !this.expanded;
  }

  handleChangeItem(item: any) {
    this.openSelectedCategory(item);
  }
  async openSelectedCategory(selectedContent: any) {
    this.router.navigateByUrl('/home/selected-category', {
      state: {
        ...selectedContent,
        showSearchBar: false,
        showAuxiliarBar: false,
      },
    });
  }
}
