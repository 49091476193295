import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppRateService } from 'src/app/services/app-rate.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-btt-rating-modal-new',
  templateUrl: './btt-rating-modal-new.component.html',
  styleUrls: ['./btt-rating-modal-new.component.scss'],
})
export class BttRatingModalNewComponent implements OnInit {
  @Output() closeButton: EventEmitter<any> = new EventEmitter();

  constructor(
    private appRateService: AppRateService,
    private modalController: ModalController,
  ) {}

  rateValue: number = 0;

  ngOnInit() {}

  collectUserRating(rate) {
    this.rateValue = rate;
    console.log(rate);
  }

  handleClickClose() {
    this.closeButton.emit();
    this.modalController.dismiss();
  }

  async handleChangeRate() {
    console.log('[event value] => ', this.rateValue);
    await this.appRateService.redirectToShop();
    this.modalController.dismiss();
  }
}
