import { createReducer, on } from '@ngrx/store';
import { IWorkoutSheet } from 'src/app/models/workoutSheet';
import * as WorkoutSheets from '../actions/workoutSheets.actions';
export interface WorkoutSheetsState {
  workoutSheets: IWorkoutSheet;
}

const initialState: WorkoutSheetsState = {
  workoutSheets: null,
};

export const workoutSheetsReducer = createReducer(
  initialState,
  on(
    WorkoutSheets.getWorkoutSheetsSuccess,
    (state, { payload: workoutSheets }) => {
      return {
        ...state,
        workoutSheets,
      };
    },
  ),
  on(
    WorkoutSheets.setWorkoutSheetsSuccess,
    (state, { payload: workoutSheets }) => {
      return {
        ...state,
        workoutSheets,
      };
    },
  ),
);
