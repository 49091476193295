import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { IBlockedUser } from "src/app/models/feed";

// actions
import * as FeedActions from "src/app/store/actions/feed.actions";
@Component({
  selector: "app-restricted-account-card",
  templateUrl: "./restricted-account-card.component.html",
  styleUrls: ["./restricted-account-card.component.scss"],
})
export class RestrictedAccountCardComponent implements OnInit {
  @Input() blockedUserData: IBlockedUser;
  @Input() blockedUserIndex: number;
  blockedUserTime: number = 5; // in seconds;
  blockedUserTimeout: any;

  defaultImage: string = "assets/img/user-image.jpg";

  constructor(private store: Store, private toastController: ToastController) {}

  ngOnInit() {}

  // handle sey unblock user
  handleSetUnblockUser() {
    const payload = {
      user_id: this.blockedUserData.id,
    };
    this.store.dispatch(FeedActions.setUnblockUser({ payload }));
  }

  // handle set unblock user so remove user from list
  handleSetRemoveUser(userId: number) {
    this.store.dispatch(
      FeedActions.setRemoveBlockedUserFromStore({ payload: userId }),
    );

    this.presentToast("bottom");

    this.blockedUserTimeout = setTimeout(() => {
      this.handleSetUnblockUser();
    }, this.blockedUserTime * 1000);
  }

  // handle set to return user
  handleSetToReturnUser() {
    const payload = {
      userIdx: this.blockedUserIndex,
      blockedUser: this.blockedUserData,
    };

    this.store.dispatch(FeedActions.setAddBlockedUserInStore({ payload }));
  }

  // set show toast
  async presentToast(position: "top" | "middle" | "bottom") {
    // toast config
    const toastConfig = {
      message: "A restrição foi removida deste perfil",
      duration: this.blockedUserTime * 1000,
      position: position,
      cssClass: "toast__alert_content_user",
      // button
      buttonText: "DESFAZER",
      buttonSide: "end",
      buttonRole: "cancel",
      buttonCssClass: "toast__alert_content_user_button",
      buttonHandler: () => this.clearBlockedUserTimeout(),
    };

    const toast = await this.toastController.create({
      message: toastConfig.message,
      duration: toastConfig.duration,
      position: toastConfig.position,
      cssClass: toastConfig.cssClass,
      buttons: [
        {
          text: toastConfig.buttonText,
          side: toastConfig.buttonSide as "end" | "start",
          role: toastConfig.buttonRole,
          cssClass: toastConfig.buttonCssClass,
          handler: toastConfig.buttonHandler,
        },
      ],
    });

    await toast.present();
  }

  // clear blocked user timeout
  clearBlockedUserTimeout() {
    this.handleSetToReturnUser();
    clearInterval(this.blockedUserTimeout);
  }
}
