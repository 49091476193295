import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// models
import { Exercicio } from 'src/app/models/trainingPlanCustom';
// services
import { TrainingService } from 'src/app/services/training.service';
// actions
import * as ExercisesByWorkoutActions from '../actions/exercisesByWorkout.actions';
import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ExercisesByWorkoutEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExercisesByWorkoutActions.getExercisesByWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.EXERCISES_BY_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.getExercicesByWorkout(payload).pipe(
          map((res: Exercicio[]) => {
            return ExercisesByWorkoutActions.getExercisesByWorkoutSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ExercisesByWorkoutActions.getExercisesByWorkoutError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getExercisesByWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExercisesByWorkoutActions.getExercisesByWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISES_BY_WORKOUT,
        }),
      ),
    ),
  );

  getExercisesByWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExercisesByWorkoutActions.getExercisesByWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISES_BY_WORKOUT,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
  ) {}
}
