import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-btt-preview-list-images',
  templateUrl: './btt-preview-list-images.component.html',
  styleUrls: ['./btt-preview-list-images.component.scss'],
})
export class BttPreviewListImagesComponent implements OnInit, AfterViewInit {
  @Input() images;

  @ViewChild('listImages') suaDiv: ElementRef;

  imagesPerPage: number;

  showSlider: boolean = false;
  selectedImage: number = 0;

  @Output() handleShowSlide: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      const divElement = this.suaDiv.nativeElement;
      const sizeDiv = divElement.offsetWidth;

      this.imagesPerPage = (sizeDiv / 56) | 0;
    }, 100);
  }

  handleClickImage(idxImage: number) {
    this.selectedImage = idxImage;
    this.showSlider = true;
    this.handleShowSlide.emit();
  }

  handleCloseSlider() {
    this.showSlider = false;
    this.handleShowSlide.emit();
  }
}
