import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  UrlSegment,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { getUser } from '../store/selectors/login.selectors';
import { PremiumService } from '../services/premium.service';

// page redirect action
import { setPageRedirect } from '../store/actions/pageRedirect.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: Store,
    private premiumService: PremiumService,
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(getUser).pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        const auxRouter = segments.join('/');
        // store page redirect
        this.store.dispatch(setPageRedirect({ payload: auxRouter }));
        if (isAuthenticated) {
          // check if user is premium
          this.premiumService.startService();
          return true;
        } else {
          this.router.navigateByUrl('/landing', {
            replaceUrl: true,
          });
          return false;
        }
      }),
    );
  }
}
