import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VimeoThumbPipe } from './vimeo-thumb/vimeo-thumb.pipe';
import { PhonePipe } from './phone.pipe';

import { ReactiveComponentModule } from '@ngrx/component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { PartnerTypePipe } from './partner-type.pipe';
import { InstructorGenderPipe } from './instructor-gender.pipe';

@NgModule({
  declarations: [
    VimeoThumbPipe,
    PhonePipe,
    SafeHtmlPipe,
    PartnerTypePipe,
    InstructorGenderPipe,
  ],
  imports: [CommonModule, ReactiveComponentModule],
  exports: [
    VimeoThumbPipe,
    PhonePipe,
    ReactiveComponentModule,
    SafeHtmlPipe,
    PartnerTypePipe,
    InstructorGenderPipe,
  ],
})
export class PipesModule {}
