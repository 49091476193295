import { createReducer, on } from '@ngrx/store';
import * as TrainingPlanCustomActions from '../actions/trainingPlanCustom.actions';

import { Banner } from 'src/app/models/banner';

export interface TrainingPlanCustomState {
  trainingPlanCustom: any;
}

const initialState: TrainingPlanCustomState = {
  trainingPlanCustom: [],
};

export const trainingPlanCustomReducer = createReducer(
  initialState,
  on(
    TrainingPlanCustomActions.getTrainingPlanCustomSuccess,
    (state, { payload: trainingPlanCustom }) => {
      return {
        ...state,
        trainingPlanCustom,
      };
    }
  )
);
