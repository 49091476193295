import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const getPartners = createAction(
  "[Partners] Get Partners",
  props<{
    payload: {
      filter: any;
      partnerType: "parceiros" | "estabelecimentos";
    };
  }>()
);

export const getPartnersSuccess = createAction(
  "[Partners] Get Partners Success",
  props<{ payload: any }>()
);

export const getPartnersError = createAction(
  "[Partners] Get Partners Error",
  props<{ payload: HttpErrorResponse }>()
);

export const getPartnerDetail = createAction(
  "[Partners] Get Partner Detail",
  props<{
    payload: {
      id: number | string;
      partnerType: "parceiros" | "estabelecimentos";
      companyType?: string;
    };
  }>()
);

export const getPartnerDetailSuccess = createAction(
  "[Partners] Get Partner Detail Success",
  props<{ payload: any }>()
);

export const getPartnerDetailError = createAction(
  "[Partners] Get Partner Detail Error",
  props<{ payload: HttpErrorResponse }>()
);
