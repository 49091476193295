import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

// is dev mode
import { isDevMode } from '@angular/core';

// actions
import * as PremiumActions from 'src/app/store/actions/premium.actions';
// selectors
import * as LoginSelectors from 'src/app/store/selectors/login.selectors';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V2,
  API_URL_V3,
  API_URL_V5,
  environment,
} from 'src/environments/environment';

import {
  InAppPurchase2,
  IAPProduct,
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

import { LoadingController, ToastController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Premium } from '../models/premium';

@Injectable({
  providedIn: 'root',
})
export class PremiumService {
  // VALIDATOR URL
  VALIDATOR_URL =
    'https://validator.fovea.cc/v1/validate?appName=com.bittrainers&apiKey=63cddb03-02b8-44cb-b667-55d9170f8ac5';

  // products IDs
  IOS_PRODUCTS_IDS = [
    'assinatura.mensal.bittrainers.com',
    'assinatura.anual.bittrainers.com',
  ];
  ANDROID_PRODUCTS_IDS = [
    'assinatura.mensal.bittrainers',
    'assinatura.anual.bittrainers',
  ];

  // handle current platform products
  PLT_PRODUCTS = [];

  // User is Premium
  isPremium: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPremiumStatus = false;

  vinculatedCompany: BehaviorSubject<object> = new BehaviorSubject<object>(
    null,
  );

  private hasPurchasedSource$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public hasPurchased$: Observable<boolean> =
    this.hasPurchasedSource$.asObservable();
  hasPurchasedStatus = false;

  constructor(
    private http: HttpClient,
    private inAppPurchase: InAppPurchase2,
    private loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private platform: Platform,
    private store: Store,
  ) {
    this.inAppPurchase.verbosity = this.inAppPurchase.DEBUG;
    this.inAppPurchase.validator = this.VALIDATOR_URL;
  }

  startService() {
    if (this.platform.is('hybrid')) {
      // this.loadPremiumStatus();
      this.initializeStore();
      this.setupListeners();
    }
  }

  // proccess payment in api
  proccessPayment(data) {
    const url: string = API_URL_V5 + '/app-purchase/transaction';

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  async initializeStore() {
    const deviceInfo = Device.getInfo();

    if ((await deviceInfo).platform === 'ios') {
      this.PLT_PRODUCTS = this.IOS_PRODUCTS_IDS;
    } else {
      this.PLT_PRODUCTS = this.ANDROID_PRODUCTS_IDS;
    }
    this.registerProducts();
  }

  registerProducts() {
    this.PLT_PRODUCTS.forEach((p) => {
      const product = this.inAppPurchase.get(p);
      if (!product) {
        this.inAppPurchase.register({
          id: p,
          type: this.inAppPurchase.PAID_SUBSCRIPTION,
        });
        // check if you are in development mode
        this.inAppPurchase.applicationUsername = () =>
          this.getFormattedApplicationUsername();
      }
    });
    this.inAppPurchase.refresh();
  }

  setupListeners() {
    // General query to all products
    this.inAppPurchase
      .when('product')
      .approved((p: IAPProduct) => {
        this.presentToast('Validando premium, por favor aguarde...');
        return p.verify();
      })
      .verified((p: IAPProduct) => {
        this.presentToast('Validando premium, por favor aguarde...');
        p.finish();
        this.isPremium.next(true);
        this.isPremiumStatus = true;
        this.hasPurchasedSource$.next(true);
        this.hasPurchasedStatus = true;
      });

    this.inAppPurchase.when('subscription').updated((p) => {
      // updated subscription
      if (p.owned) {
        // updated subscription owned
        this.isPremium.next(true);
        this.isPremiumStatus = true;
        this.hasPurchasedSource$.next(true);
        this.hasPurchasedStatus = true;
        this.getApiPremiumStatus();
      }
    });
  }

  async proccessPaymentServer(data) {
    const loader = await this.loadingCtrl.create();
    await loader.present();
    this.proccessPayment(data).subscribe(
      async (res: any) => {
        if (res.success) {
          this.presentToast(
            'Pronto, agora você é Premium! Aproveite os nossos conteúdos e bom treino!',
          );
        }
        await loader.dismiss();
      },
      async (err) => {
        await loader.dismiss();
        console.error('proccess payment server error', err);
      },
    );
  }

  set IsPremiumSource(val) {
    this.isPremium.next(val);
    this.isPremiumStatus = val;
  }

  get IsPremium(): Observable<boolean> {
    return this.isPremium.asObservable();
  }

  set hasPurchasedSource(val) {
    this.hasPurchasedSource$.next(val);
  }

  get hasPurchased() {
    return this.hasPurchased$;
  }

  loadPremiumStatus() {
    // load premium status with this.hasPurchased
    this.hasPurchased.subscribe((res) => {
      // has purchased: True or False
      if (res) this.getApiPremiumStatus();
    });

    if (!this.hasPurchasedStatus) {
      // load premium status hasPurchased
      this.getPremiumStatus().subscribe(
        (response) => {
          // user premium validation
          if (response.premium !== undefined && !this.hasPurchasedStatus) {
            this.isPremium.next(response.premium);
            this.isPremiumStatus = response.premium;
            // object or null
            this.vinculatedCompany.next(response.empresa);
          }
        },
        (err) => {
          console.error('user premium validation: ', err);
          this.isPremium.next(false);
          this.isPremiumStatus = false;
        },
      );
    }
  }

  // get api premium status 
  getApiPremiumStatus() {
    this.store.dispatch(
      PremiumActions.getPremiumStatus(),
    );
  }

  /**
   * Get Banners for User (if vinculated, return with company banners)
   * @author André Ramos <oi@andreramos.dev>
   */
  getPremiumStatus(): Observable<any> {
    const url: string = API_URL_V3 + '/users/get-premium-status';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      color: 'dark',
    });
    toast.present();
  }

  getCustomPremium(slugValue: string = 'modal-premium'): Observable<any> {
    const url: string = API_URL + `/modais?slug=${slugValue}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getFormattedApplicationUsername() {
    let userId: number;
    this.store
      .select(LoginSelectors.getUser)
      .subscribe((userData) => (userId = userData.id));

    return `${isDevMode() ? 'dev' : 'prod'}:${userId}`;
  }
}
