import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as FirstAccessActions from 'src/app/store/actions/firstaccess.actions';
@Component({
  selector: 'app-btt-header-web',
  templateUrl: './btt-header-web.component.html',
  styleUrls: ['./btt-header-web.component.scss'],
})
export class BttHeaderWebComponent implements OnInit {
  @Input() closeButton: boolean = false;
  @Input() redirectPage: string = '/home';
  @Input() viewCtrl: any;

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {}

  closePage() {
    this.store.dispatch(FirstAccessActions.setFirstAccess({ payload: false }));
    this.router.navigateByUrl(this.redirectPage, { replaceUrl: true });
  }
}
