import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getCategoryRecipes = createAction(
  '[Orientations] Get CategoryRecipes',
  props<{
    payload: {
      type: 'receitas';
    };
  }>(),
);

export const getCategoryRecipesSuccess = createAction(
  '[Orientations] Get CategoryRecipes Success',
  props<{ payload: any }>(),
);

export const getCategoryRecipesError = createAction(
  '[Orientations] Get CategoryRecipes Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getCategoryRecipesItem = createAction(
  '[Orientations] Get CategoryRecipes Item',
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const getCategoryRecipesItemSuccess = createAction(
  '[Orientations] Get CategoryRecipes Item Success',
  props<{ payload: any }>(),
);

export const getCategoryRecipesItemError = createAction(
  '[Orientations] Get CategoryRecipes Item Error',
  props<{ payload: HttpErrorResponse }>(),
);
