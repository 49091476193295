import { createReducer, on } from '@ngrx/store';
import * as CategoriesRecipesActions from '../actions/categoriesrecipes.actions';

export interface CategoriesRecipesState {
  categoryRecipesItems: any[];
  currentCategoryRecipesItem: any[];

  loading: boolean;
}

const initialState: CategoriesRecipesState = {
  categoryRecipesItems: [],
  currentCategoryRecipesItem: [],
  loading: false,
};

export const categoriesRecipesReducer = createReducer(
  initialState,
  on(CategoriesRecipesActions.getCategoryRecipes, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    CategoriesRecipesActions.getCategoryRecipesSuccess,
    (state, { payload: categoryRecipesItems }) => {
      return {
        ...state,
        categoryRecipesItems,
        loading: false,
      };
    },
  ),
  on(
    CategoriesRecipesActions.getCategoryRecipesError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),

  on(CategoriesRecipesActions.getCategoryRecipesItem, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    CategoriesRecipesActions.getCategoryRecipesItemSuccess,
    (state, { payload: currentCategoryRecipesItem }) => {
      return {
        ...state,
        currentCategoryRecipesItem,
        loading: false,
      };
    },
  ),
  on(
    CategoriesRecipesActions.getCategoryRecipesItemError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
);
