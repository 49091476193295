import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'btt-star-rating',
  templateUrl: './btt-star-rating.component.html',
  styleUrls: ['./btt-star-rating.component.scss'],
})
export class BttStarRatingComponent implements OnInit {
  @Input() rating = 5;
  @Input() size: 'small' | 'large' = 'small';

  constructor() {}

  ngOnInit(): void {}
}
