import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, filter, take } from "rxjs/operators";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import {
    SERVER_URL,
    API_URL,
    API_URL_V3,
    environment,
    API_URL_V2,
} from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class StatesService {
    statesList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(private http: HttpClient) { }

    getEstadosIbge(): Observable<any> {
        const url: string = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
        return this.http.get(url, {
            params: {
                orderBy: 'nome'
            }
        });
    }
    getCidadesIbge(uf: number): Observable<any> {
        const url: string = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
        return this.http.get(url, {
            params: {
                orderBy: 'nome'
            }
        });
    }

    getItems(): Observable<any> {
        const url: string = API_URL + "/estados";

        return this.http.get(url).pipe(
            tap((response) => {
                if (response.registros) {
                    this.statesList.next(response.registros);
                }
                return response;
            })
        );
    }

    getCitiesWhereHasPartners(stateId, type = "parceiros"): Observable<any> {
        const url: string = API_URL + `/estados/${stateId}/${type}`;

        return this.http.get(url).pipe(
            tap((response) => {
                return response;
            })
        );
    }
}
