import { ValidatorFn, FormControl, FormGroup } from "@angular/forms";

export const ddds: string[] = [
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "22",
    "21",
    "24",
    "27",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "37",
    "38",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "51",
    "53",
    "54",
    "55",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "71",
    "73",
    "74",
    "75",
    "77",
    "79",
    "81",
    "82",
    "83",
    "84",
    "85",
    "88",
    "86",
    "89",
    "87",
    "91",
    "93",
    "94",
    "92",
    "97",
    "95",
    "96",
    "98",
    "99",
];

export const getDDD = (phone: string) => {
    return phone.substring(0, 2);
};
export const getPhone = (phone: string) => {
    return phone.substring(2);
};

export const cellPhoneValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value as string;
    let ret = null;
    if (!value) {
        return ret;
    }
    if (value && value.length === 11) {
        if (!ddds.includes(getDDD(value))) {
            ret = { invalid: true };
        }
        if (value.substring(2, 3) !== "9") {
            ret = { invalid: true };
        }
    } else if (value) {
        ret = { invalid: true };
    }
    return ret;
};

export const phoneValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value as string;
    let ret = null;
    if (!value) {
        return ret;
    }
    if (value && value.length === 10) {
        if (!ddds.includes(getDDD(value))) {
            ret = { invalid: true };
        }
        if (value.substring(2, 3) === "9") {
            ret = { invalid: true };
        }
    } else if (value) {
        ret = { invalid: true };
    }
    return ret;
};
