import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
    providedIn: "root",
})
export class LoaderService {
    constructor(private loadingController: LoadingController) {}

    show() {
        this.loadingController
            .create({
                spinner: "crescent",
            })
            .then((res) => {
                res.present();
            });
    }

    hide() {
        this.loadingController.dismiss();
    }
}
