import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as FinishedWorkoutActions from '../actions/finishedWorkout.actions';
import * as LoadingActions from '../actions/loading.actions';

import { TrainingService } from 'src/app/services/training.service';
import { ToastController } from '@ionic/angular';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
@Injectable()
export class FinishedWorkoutEffects {
  setFinishedWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FinishedWorkoutActions.setFinishedWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.FINISHED_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.setFinishedWorkout(payload).pipe(
          map((res: any) => {
            if (payload.percentage_completed === 1)
              this.presentToast('bottom', res.message);
            return FinishedWorkoutActions.setFinishedWorkoutSuccess();
          }),
          catchError((error) =>
            of(
              FinishedWorkoutActions.setFinishedWorkoutError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setFinishedExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FinishedWorkoutActions.setFinishedExercise),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.FINISHED_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.setFinishedExercise(payload).pipe(
          map((res: any) => {
            if (res?.completed) this.presentToast('bottom', res.message);
            return FinishedWorkoutActions.setFinishedExerciseSuccess();
          }),
          catchError((error) =>
            of(
              FinishedWorkoutActions.setFinishedExerciseError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setFinishedWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FinishedWorkoutActions.setFinishedWorkoutSuccess,
        FinishedWorkoutActions.setFinishedExerciseSuccess,
      ),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FINISHED_WORKOUT,
        });
      }),
    ),
  );

  setFinishedWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FinishedWorkoutActions.setFinishedWorkoutError,
        FinishedWorkoutActions.setFinishedExerciseError,
      ),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.FINISHED_WORKOUT,
        }),
      ),
    ),
  );

  // toast
  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: position,
      cssClass: 'toast__alert_content',
    });

    await toast.present();
  }

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private toastController: ToastController,
  ) {}
}
