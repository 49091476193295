import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as RecipeActions from '../actions/recipe.actions';

import { NutritionService } from 'src/app/services/nutrition.service';

@Injectable()
export class RecipeEffects {
  getRecipeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeActions.getRecipeItems),
      switchMap(({ payload: { type } }) => {
        return this.nutritionService.getNutritionItems(type).pipe(
          map((res: any) => {
            return RecipeActions.getRecipeItemsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(RecipeActions.getRecipeItemsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getRecipeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeActions.getRecipeItem),
      switchMap(({ payload: { id } }) => {
        return this.nutritionService.getItem(id).pipe(
          map((res: any) => {
            return RecipeActions.getRecipeItemSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(RecipeActions.getRecipeItemsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private nutritionService: NutritionService,
    private store: Store,
  ) {}
}
