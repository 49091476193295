import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getExercisesByWorkout = createAction(
  '[Exercises By Workout] Set Exercises By Workout',
  props<{ payload: any }>(),
);

export const getExercisesByWorkoutSuccess = createAction(
  '[Exercises By Workout] Set Exercises By Workout Success',
  props<{ payload: any }>(),
);

export const getExercisesByWorkoutError = createAction(
  '[Exercises By Workout] Set Exercises By Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);
