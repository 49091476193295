import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getNutritionItems = createAction(
  '[Orientations] Get Nutrition Items',
  props<{
    payload: {
      type: 'dicas' | 'receitas' | 'orientations';
      search?: string;
      per_page?: number;
      page?: number;
    };
  }>(),
);

export const getNutritionItemsSuccess = createAction(
  '[Orientations] Get Nutrition Items Success',
  props<{ payload: any }>(),
);

export const getNutritionItemsError = createAction(
  '[Orientations] Get Nutrition Items Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getNutritionItemsCategory = createAction(
  '[Orientations] Get Nutrition Items Category',
  props<{
    payload: {
      type: 'dicas' | 'receitas' | 'orientations';
      search?: string;
      per_page?: number;
      page?: number;
    };
  }>(),
);

export const getNutritionItemsCategorySuccess = createAction(
  '[Orientations] Get Nutrition Items Category Success',
  props<{ payload: any }>(),
);

export const getNutritionItemsCategoryError = createAction(
  '[Orientations] Get Nutrition Items Category Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getNutritionItem = createAction(
  '[Orientations] Get Nutrition Item',
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const getNutritionItemSuccess = createAction(
  '[Orientations] Get Nutrition Item Success',
  props<{ payload: any }>(),
);

export const getNutritionItemError = createAction(
  '[Orientations] Get Nutrition Item Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getPopularItems = createAction(
  '[Orientations] Get Popular Items',
  props<{
    payload: {
      type: 'dicas' | 'receitas' | 'orientations';
      popular?: boolean;
      per_page?: number;
      page?: number;
    };
  }>(),
);

export const getPopularItemsSuccess = createAction(
  '[Orientations] Get Popular Items Success',
  props<{ payload: any }>(),
);

export const getPopularItemsError = createAction(
  '[Orientations] Get Popular Items Error',
  props<{ payload: HttpErrorResponse }>(),
);
