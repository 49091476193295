import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { AnalyticsService } from 'src/app/services/analytics.service';

import Player from '@vimeo/player';
import {
  Platform,
} from '@ionic/angular';
@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss'],
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() video;
  @ViewChild('playerFrame') playerFrame;

  @Input() autoStart = false;
  @Input() removeTopMargin: boolean = false;
  @Input() isActiveLandscape: boolean = false;

  private player: Player;

  videoId: any;
  videoUrl: any;

  videoStarted = false;

  // backbutton
  nativeBackButton$: any;
  nativeBackButtonListener$: any;

  //back button
  voltarNativoListener$: any;

  constructor(
    private sanitizer: DomSanitizer,
    private analytics: AnalyticsService,
    private platform: Platform,
  ) {
  }

  async ngOnInit() {
    this.videoId = this.video.replace('/videos/', '');

    this.videoUrl = await this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://player.vimeo.com/video/${this.videoId}?transparent=false`,
    );
  }

  ngOnDestroy(): void {
    this.nativeBackButton$?.unsubscribe();
  }

  ngAfterViewInit() {
    this.initPlayer();
  }

  initializeBackButtonListener() {
    this.nativeBackButton$ = this.platform.backButton.subscribeWithPriority(
      120,
      () => {
        console.log("[VIDEO BACK BUTTON CLICKED]");
        this.player
          .exitFullscreen()
          .then(() => this.removeBackButtonListener());
      },
    );
  }

  removeBackButtonListener() {
    this.nativeBackButton$?.unsubscribe();
  }

  initPlayer() {
    this.player = new Player(this.playerFrame.nativeElement);

    if (this.autoStart) {
      this.player.play();
      this.player.requestFullscreen();
    }

    this.player.on('play', () => {
      if (!this.videoStarted) {
        this.videoStarted = true;
        this.analytics.trackEvent('video_start', {
          video_id: this.videoId,
        });
      }
    });

    this.player.on('ended', () => {
      this.setDeviceOrientation('portrait');
      this.analytics.trackEvent('video_ended', {
        video_id: this.videoId,
      });
    });

    this.player.on('fullscreenchange', (isFullScreen) => {
      if (isFullScreen.fullscreen) {
        this.setDeviceOrientation('landscape');
        console.log('[fullscreenchange IN]');
        this.initializeBackButtonListener();
      } else {
        this.setDeviceOrientation('portrait');
        console.log('[fullscreenchange OUT]');
        this.removeBackButtonListener();
      }
    });
  }

  // set device orientation
  setDeviceOrientation(orientation: 'portrait' | 'landscape') {
    if (!this.isActiveLandscape) return;

    window.screen.orientation.lock(orientation);
  }
}
