import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, Platform, ToastController } from '@ionic/angular';

import { AuthService } from 'src/app/services/auth.service';
import { AuthFacebookService } from 'src/app/services/auth-facebook.service';

import { AnalyticsService } from 'src/app/services/analytics.service';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { isPlatform } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { getUser } from 'src/app/store/selectors/login.selectors';
import { PremiumService } from 'src/app/services/premium.service';

import * as LoginActions from 'src/app/store/actions/login.actions';
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
  IS_IOS = false;
  IS_ANDROID = false;

  constructor(
    private platform: Platform,
    private facebook: AuthFacebookService,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private router: Router,
    private authService: AuthService,
    private analytics: AnalyticsService,
    private store: Store,
    private premiumService: PremiumService,
  ) {
    platform.ready().then(() => {
      GoogleAuth.initialize();
    });
  }

  ngOnInit() {
    this.IS_IOS = this.platform.is('ios');
    this.IS_ANDROID = this.platform.is('android');
  }

  async socialLogin(source) {
    console.log(source);
    switch (source) {
      case 'facebook':
        console.log('is facebook');
        const loginState = await this.facebook.getCurrentState();
        console.log('loginState: ', loginState);
        if (!loginState) {
          console.log('nao tá logado');
          await this.facebook
            .signIn()
            .then((accessToken) => {
              this.analytics.trackEvent('login', {
                method: 'facebook',
              });
              this.getFacebookUser();
              return;
            })
            .catch((err) => {
              console.error('ERRRORRRR: ', err);
              return;
            });
        }
        this.getFacebookUser();
        break;
      case 'apple':
        this.authService.signInApple(() => {
          this.socialLoginCallback();
        });
        break;
      case 'google':
        console.log('Login Google');
        this.googleSignup();
        break;
    }
  }

  async googleSignup() {
    // const googleUser = (await GoogleAuth.signIn()) as any;

    const loading = await this.loadingController.create();
    await loading.present();

    try {
      const googleUser = await GoogleAuth.signIn();
      console.log('my user: ', googleUser);

      this.authService
        .loginGoogle({
          google_id: googleUser.id,
          name: googleUser.name,
          email: googleUser.email,
          picture: googleUser.imageUrl,
        })
        .subscribe(
          async (response) => {
            this.analytics.trackEvent('login', {
              method: 'google',
            });
            await loading.dismiss();
            this.socialLoginCallback();
          },
          async (err) => {
            await loading.dismiss();
            const toast = await this.toastController.create({
              message: 'Algo deu errado no login do Google',
              duration: 2000,
              color: 'dark',
            });
            await toast.present();
          },
        );
    } catch (e) {
      await loading.dismiss();
    }
    // await loading.dismiss();
  }

  async getFacebookUser() {
    const loading = await this.loadingController.create();
    await loading.present();

    try {
      const facebookRes = await this.facebook.getUserData();

      this.authService
        .loginFacebook({
          facebook_id: facebookRes.id,
          name: facebookRes.name,
          email: facebookRes.email,
        })
        .subscribe(
          async (response) => {
            await loading.dismiss();
            this.socialLoginCallback();
          },
          async (err) => {
            await loading.dismiss();
            const toast = await this.toastController.create({
              message: 'Algo deu errado no login do facebook',
              duration: 2000,
              color: 'dark',
            });
            await toast.present();
          },
        );
    } catch (e) {
      await loading.dismiss();
    }
  }

  socialLoginCallback() {
    if (this.authService.isAuthenticatedStatus) {
      this.store.dispatch(LoginActions.getAutenticatedUser());
      this.store.select(getUser).subscribe((user) => {
        if (user?.id) {
          // check if user is premium
          this.premiumService.startService();
          // Directly open inside area
          this.router.navigateByUrl('/home', { replaceUrl: true });
        }
      });
    }
  }
}
