import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getDailyWorkoutsViewed = createAction(
  '[Daily Workouts Viewed] Get Daily Workouts Viewed',
  props<{ payload: any }>(),
);

export const getDailyWorkoutsViewedSuccess = createAction(
  '[Daily Workouts Viewed] Get Daily Workouts Viewed Success',
  props<{ payload: any }>(),
);

export const getDailyWorkoutsViewedError = createAction(
  '[Daily Workouts Viewed] Get Daily Workouts Viewed Error',
  props<{ payload: HttpErrorResponse }>(),
);
