import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, filter, take } from "rxjs/operators";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PartnersService {
  constructor(private http: HttpClient) {}

  getPartners(
    params,
    partnerType: "parceiros" | "estabelecimentos"
  ): Observable<any> {
    const endpoint =
      partnerType === "parceiros" ? "parceiros" : "parceiros-estabelecimentos";
    const url: string = `${API_URL}/${endpoint}`;

    return this.http.get(url, { params }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getPartnerDetail(
    id,
    partnerType: "parceiros" | "estabelecimentos",
    type = null
  ): Observable<any> {
    let endpoint;
    if (partnerType === "parceiros") {
      endpoint = `parceiros/${id}`;
    }
    if (partnerType === "estabelecimentos") {
      endpoint = `parceiros-estabelecimentos/${type}/${id}`;
    }
    const url: string = `${API_URL}/${endpoint}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getPersons(params): Observable<any> {
    const url: string = API_URL + "/parceiros";

    return this.http.get(url, { params }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getItem(id): Observable<any> {
    const url: string = API_URL + `/parceiros/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getCompanies(params = {}): Observable<any> {
    const url: string = API_URL + "/parceiros-estabelecimentos";

    return this.http.get(url, { params }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getCompany(id, type): Observable<any> {
    const url: string = API_URL + `/parceiros-estabelecimentos/${type}/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      })
    );
  }
}
