import { createReducer, on } from '@ngrx/store';
import * as ObjectivesActions from '../actions/objectives.actions';

import { Objective } from 'src/app/models/objective';

export interface ObjectiveState {
  objectives: Objective[];
}

const initialState: ObjectiveState = {
  objectives: [],
};

export const objectivesReducer = createReducer(
  initialState,
  on(
    ObjectivesActions.getObjectivesSuccess,
    (state, { payload: objectives }) => {
      return {
        ...state,
        objectives,
      };
    }
  )
);
