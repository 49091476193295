import { BttSkeletonItemComponent } from "./btt-skeleton-item/btt-skeleton-item.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ItemTrainingComponent } from "./item-training/item-training.component";
import { ItemTrainingModalityComponent } from "./item-training-modality/item-training-modality.component";
import { ItemTrainingScheduledComponent } from "./item-training-scheduled/item-training-scheduled.component";
import { TextReadMoreComponent } from "./text-read-more/text-read-more.component";
import { VimeoPlayerComponent } from "./vimeo-player/vimeo-player.component";
import { HeaderFullComponent } from "./header-full/header-full.component";
import { BonusScrollComponent } from "./bonus-scroll/bonus-scroll.component";
import { RatingModalComponent } from "./rating-modal/rating-modal.component";
import { PreRatingModalComponent } from "./pre-rating-modal/pre-rating-modal.component";
import { SliderFullComponent } from "./slider-full/slider-full.component";
import { SocialLoginComponent } from "./social-login/social-login.component";
import { ShowHidePasswordComponent } from "./show-hide-password/show-hide-password.component";

import { DialogComponent } from "./dialog/dialog.component";

import { PipesModule } from "src/app/pipes/pipes.module";
import { BttCheckboxComponent } from "./btt-checkbox/btt-checkbox.component";
import { SliderComponent } from "./slider/slider.component";
import { SwiperModule } from "swiper/angular";
import { CardIconComponent } from "./card-icon/card-icon.component";
import { CardIconNutritionComponent } from "./card-icon-nutrition/card-icon-nutrition.component";
import { CardPreviewComponent } from "./card-preview/card-preview.component";
import { SliderCardsComponent } from "./slider-cards/slider-cards.component";
import { SubtitleComponent } from "./subtitle/subtitle.component";
import { CardPreviewResponsiveComponent } from "./card-preview-responsive/card-preview-responsive.component";
import { BttMenuComponent } from "./btt-menu/btt-menu.component";
import { BttMenuNotificationsComponent } from "./btt-menu-notifications/btt-menu-notifications.component";
import { BttHeaderTransparentComponent } from "./btt-header-transparent/btt-header-transparent.component";
import { BttHeaderVideoComponent } from "./btt-header-video/btt-header-video.component";
import { BttContentCreatorComponent } from "./btt-content-creator/btt-content-creator.component";
import { BttStarRatingComponent } from "./btt-star-rating/btt-star-rating.component";
import { BttHeaderImageComponent } from "./btt-header-image/btt-header-image.component";
import { BttTrainingInfoCardComponent } from "./btt-training-info-card/btt-training-info-card.component";
import { BttStarRatingInputComponent } from "./btt-star-rating-input/btt-star-rating-input.component";
import { BttPartnerItemComponent } from "./btt-partner-item/btt-partner-item.component";
import { BttContentViewerComponent } from "./btt-content-viewer/btt-content-viewer.component";
import { BttImageBackgroundComponent } from "./btt-image-background/btt-image-background.component";
import { BttContentCreatorNewComponent } from "./btt-content-creator-new/btt-content-creator-new.component";

import { SkeletonCardCategoriesComponent } from "./skeleton-card-categories/skeleton-card-categories.component";
import { BttRatingModalNewComponent } from "./btt-rating-modal-new/btt-rating-modal-new.component";
import { HeaderFullWebComponent } from "./header-full-web/header-full-web.component";
import { BttNotificationsWebComponent } from "./btt-notifications-web/btt-notifications-web.component";
import { WebLoginCompanyComponent } from "./web-login-company/web-login-company.component";
import { BttHeaderWebComponent } from "./btt-header-web/btt-header-web.component";
import { BttFooterWebComponent } from "./btt-footer-web/btt-footer-web.component";

import { BttHeaderUserOptionsComponent } from "./btt-header-user-options/btt-header-user-options.component";
import { BttSaveContentComponent } from "./btt-save-content/btt-save-content.component";
import { BttGridPopularComponent } from "./btt-grid-popular/btt-grid-popular.component";
import { ItemSeeTooComponent } from "./item-see-too/item-see-too.component";
import { BttWeeklyScheduleComponent } from "./btt-weekly-schedule/btt-weekly-schedule.component";
import { BttGenerateNewTrainingModalComponent } from "./btt-generate-new-training-modal/btt-generate-new-training-modal.component";
import { BttNewExerciseCardComponent } from "./btt-new-exercise-card/btt-new-exercise-card.component";
import { BttNewExerciseDescriptionComponent } from "./btt-new-exercise-description/btt-new-exercise-description.component";
import { BttNewExerciseTimerComponent } from "./btt-new-exercise-timer/btt-new-exercise-timer.component";
import { CardStickyNotesComponent } from "./card-sticky-notes/card-sticky-notes.component";
import { BttAlertActionComponent } from "./btt-alert-action/btt-alert-action.component";

import { BttAlertPermissionComponent } from "./btt-alert-permission/btt-alert-permission.component";
import { BttHeaderDefaultComponent } from "./btt-header-default/btt-header-default.component";
import { BttWorkoutDayItemComponent } from "./btt-workout-day-item/btt-workout-day-item.component";
import { BttTrainingSheetContentCardComponent } from "./btt-training-sheet-content-card/btt-training-sheet-content-card.component";
import { BttShowFullscreenImageComponent } from "./btt-show-fullscreen-image/btt-show-fullscreen-image.component";

import { BttFilterPageCategoryComponent } from "./btt-filter-page-category/btt-filter-page-category.component";
import { BttLikeContentComponent } from "./btt-like-content/btt-like-content.component";

import { BttFilterPageModalitiesComponent } from "./btt-filter-page-modalities/btt-filter-page-modalities.component";
import { BttILikedComponent } from "./btt-i-liked/btt-i-liked.component";
import { BttNewSaveContentComponent } from "./btt-new-save-content/btt-new-save-content.component";
import { BttInfoContentComponent } from "./btt-info-content/btt-info-content.component";
import { BttPreviewListImagesComponent } from "./btt-preview-list-images/btt-preview-list-images.component";
import { BttImageOpeningSliderComponent } from "./btt-image-opening-slider/btt-image-opening-slider.component";


import { InteractionsFeedComponent } from "./interactions-feed/interactions-feed.component";
import { BttHidePostContentComponent } from "./btt-hide-post-content/btt-hide-post-content.component";
import { RestrictedAccountCardComponent } from "./restricted-account-card/restricted-account-card.component";

import { BttPageTitleComponent } from "src/app/components/btt-page-title/btt-page-title.component";
import { ExercisePreviewComponent } from './exercise-preview/exercise-preview.component';


@NgModule({
  declarations: [
    ItemTrainingComponent,
    TextReadMoreComponent,
    VimeoPlayerComponent,
    ItemTrainingModalityComponent,
    ItemTrainingScheduledComponent,
    HeaderFullComponent,
    BonusScrollComponent,
    RatingModalComponent,
    PreRatingModalComponent,
    DialogComponent,
    SliderFullComponent,
    SocialLoginComponent,
    BttCheckboxComponent,
    SliderComponent,
    CardIconComponent,
    CardIconNutritionComponent,
    CardPreviewComponent,
    SliderCardsComponent,
    SubtitleComponent,
    CardPreviewResponsiveComponent,
    BttMenuComponent,
    BttMenuNotificationsComponent,
    BttHeaderTransparentComponent,
    BttHeaderVideoComponent,
    BttContentCreatorComponent,
    BttStarRatingComponent,
    BttHeaderImageComponent,
    BttTrainingInfoCardComponent,
    BttStarRatingInputComponent,
    BttPartnerItemComponent,
    BttSkeletonItemComponent,
    BttContentViewerComponent,
    BttImageBackgroundComponent,
    SkeletonCardCategoriesComponent,
    BttContentCreatorNewComponent,
    ShowHidePasswordComponent,
    BttRatingModalNewComponent,
    HeaderFullWebComponent,
    BttNotificationsWebComponent,
    WebLoginCompanyComponent,
    BttHeaderWebComponent,
    BttFooterWebComponent,
    BttHeaderUserOptionsComponent,
    BttSaveContentComponent,
    BttGridPopularComponent,
    ItemSeeTooComponent,
    BttWeeklyScheduleComponent,
    BttGenerateNewTrainingModalComponent,
    BttNewExerciseCardComponent,
    BttNewExerciseDescriptionComponent,
    BttNewExerciseTimerComponent,
    CardStickyNotesComponent,
    BttAlertActionComponent,
    BttHeaderDefaultComponent,
    BttWorkoutDayItemComponent,
    BttTrainingSheetContentCardComponent,
    BttShowFullscreenImageComponent,
    BttFilterPageCategoryComponent,
    InteractionsFeedComponent,
    BttAlertPermissionComponent,

    BttLikeContentComponent,

    BttFilterPageModalitiesComponent,
    BttILikedComponent,
    BttNewSaveContentComponent,
    BttInfoContentComponent,
    BttPreviewListImagesComponent,
    BttImageOpeningSliderComponent,
    BttHidePostContentComponent,
    RestrictedAccountCardComponent,
    BttPageTitleComponent,
    ExercisePreviewComponent
  ],
  imports: [CommonModule, PipesModule, SwiperModule],
  exports: [
    ItemTrainingComponent,
    TextReadMoreComponent,
    VimeoPlayerComponent,
    ItemTrainingModalityComponent,
    ItemTrainingScheduledComponent,
    HeaderFullComponent,
    BonusScrollComponent,
    RatingModalComponent,
    PreRatingModalComponent,
    DialogComponent,
    SliderFullComponent,
    SocialLoginComponent,
    BttCheckboxComponent,
    SliderComponent,
    CardIconComponent,
    CardIconNutritionComponent,
    CardPreviewComponent,
    SliderCardsComponent,
    SubtitleComponent,
    CardPreviewResponsiveComponent,
    BttMenuComponent,
    BttMenuNotificationsComponent,
    BttHeaderTransparentComponent,
    BttHeaderVideoComponent,
    BttContentCreatorComponent,
    BttStarRatingComponent,
    BttHeaderImageComponent,
    BttTrainingInfoCardComponent,
    BttStarRatingInputComponent,
    BttPartnerItemComponent,
    BttSkeletonItemComponent,
    BttContentViewerComponent,
    BttImageBackgroundComponent,
    SkeletonCardCategoriesComponent,
    BttContentCreatorNewComponent,
    ShowHidePasswordComponent,
    BttRatingModalNewComponent,
    HeaderFullWebComponent,
    BttNotificationsWebComponent,
    WebLoginCompanyComponent,
    BttHeaderWebComponent,
    BttFooterWebComponent,
    BttHeaderUserOptionsComponent,
    BttSaveContentComponent,
    BttGridPopularComponent,
    ItemSeeTooComponent,
    BttWeeklyScheduleComponent,
    BttGenerateNewTrainingModalComponent,
    BttNewExerciseCardComponent,
    BttNewExerciseDescriptionComponent,
    BttNewExerciseTimerComponent,
    CardStickyNotesComponent,
    BttAlertActionComponent,
    BttHeaderDefaultComponent,
    BttWorkoutDayItemComponent,
    BttTrainingSheetContentCardComponent,
    BttShowFullscreenImageComponent,
    BttFilterPageCategoryComponent,
    InteractionsFeedComponent,
    BttAlertPermissionComponent,
    BttLikeContentComponent,
    BttFilterPageModalitiesComponent,
    BttILikedComponent,
    BttNewSaveContentComponent,
    BttInfoContentComponent,
    BttPreviewListImagesComponent,
    BttImageOpeningSliderComponent,
    BttHidePostContentComponent,
    RestrictedAccountCardComponent,
    BttPageTitleComponent,
    ExercisePreviewComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {
  static forRoot(): ComponentsModule {
    return {
      ngModule: ComponentsModule,
    };
  }
}
