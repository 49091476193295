import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setAnnotation = createAction(
  '[Annotation] Set Annotation',
  props<{ payload: any }>(),
);

export const setAnnotationSuccess = createAction(
  '[Annotation] Set Annotation Success',
);

export const setAnnotationError = createAction(
  '[Annotation] Set Annotation Error',
  props<{ payload: HttpErrorResponse }>(),
);
