import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  Platform,
} from '@ionic/angular';

import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { SocialLoginPage } from 'src/app/pages/_modals/social-login/social-login.page';
import { LoginCompanyAlertPage } from 'src/app/pages/_alert/login-company-alert/login-company-alert.page';

import { AnalyticsService } from 'src/app/services/analytics.service';

import { SERVER_URL } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';

// service
import { UserService } from 'src/app/services/user.service';

// selectors
import * as LoginSelectors from 'src/app/store/selectors/login.selectors';

// actions
import * as LoginActions from 'src/app/store/actions/login.actions';
@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
})
export class FormPage implements OnInit {
  company: any;

  loginForm: FormGroup;
  form: FormGroup;

  userId = null;
  userLoginWays = [];

  message: any;

  user$: Observable<User>;
  user: User;

  isWeb: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private companiesService: CompaniesService,
    private analytics: AnalyticsService,
    private platform: Platform,
    private store: Store,
    private userService: UserService,
  ) {
    this.platform.keyboardDidShow.subscribe(() => {
      this.handleModalOnInput(true);
    });
    this.platform.keyboardDidHide.subscribe(() => {
      this.handleModalOnInput(false);
    });
    platform.ready().then(() => {
      if (!platform.is('mobile')) this.isWeb = true;
    });
  }

  async handleModalOnInput(keyboardIsOpened = false) {
    const modal = await this.modalCtrl.getTop();
    if (keyboardIsOpened) {
      modal.classList.add('keyboard-opened');
    } else {
      modal.classList.remove('keyboard-opened');
    }
  }

  ngOnInit() {
    this.analytics.trackScreen(
      'login_company_form',
      'LoginOperadoraFormScreen',
    );

    this.form = this.fb.group({
      chave: '',
      password: null,
    });

    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.company = this.router.getCurrentNavigation().extras.state.company;
      } else {
        this.router.navigateByUrl('/landing', {
          replaceUrl: true,
        });
      }
    });

    this.user$ = this.store.select(LoginSelectors.getUser);
    this.user$.subscribe((res) => (this.user = res));
  }

  async formSubmit() {
    const loading = await this.loadingController.create();
    await loading.present();

    if (!this.userId || this.userLoginWays.length > 0) {
      this.verifyClient(loading);
    } else {
      this.login(loading);
    }
  }

  async verifyClient(loading) {
    this.analytics.trackEvent('validate_partner_start', {
      partner_id: this.company.id,
      partner_name: this.company.nome,
    });
    this.companiesService
      .verifyClientV5(this.company.id, this.form.value)
      .subscribe(
        async (res) => {
          if (!res.user_found) {
            this.handleUserNotFound(loading);
            return;
          }

          this.analytics.trackEvent('validate_partner_success', {
            partner_id: this.company.id,
            partner_name: this.company.nome,
          });

          // if response has user (id)
          if (res.user) {
            // verifica se usuário está logado, senão mostra login
            if (this.user && res?.user_login_ways.length == 0) {
              this.presentAlert(
                'Você está cadastrado neste parceiro, insira a senha para efetuar o login e se vincular como premium.',
                'Continuar',
                // () => {
                //   this.closeModal();
                // },
              );
              this.userId = res.user;
              loading.dismiss();
            } else {
              this.handleUserAlreadyCreated(res, loading);
            }
            return;
          }

          this.handleNewUserCanVinculate(loading);
        },
        (err) => {
          console.error(err);
          loading.dismiss();
        },
      );
  }

  async login(loading) {
    const form = {
      username: this.form.value.chave,
      password: this.form.value.password,
    };
    this.auth.login(form).subscribe(
      async (res) => {
        this.vinculateCompany(this.company, loading);
        this.auth.loadToken();
        await this.closeModal();
        this.router.navigateByUrl('/home', { replaceUrl: true });
      },
      async (res) => {
        let message = 'O servidor não está respondendo';
        switch (res.status) {
          case 401:
            message =
              'Credenciais Inválidas. Verifique os dados e tente novamente';
            break;
        }
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Erro ao entrar',
          message,

          buttons: ['OK'],
        });

        await alert.present();
      },
    );
  }

  arrayContains(arr, val) {
    return arr.indexOf(val) !== -1;
  }

  async openModal(page, props = {}, cssClass = null) {
    const pageModal = await this.modalCtrl.create({
      component: page,
      cssClass,
      componentProps: props,
    });
    await pageModal.present();

    return await pageModal.onDidDismiss();
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  /**
   * Função para exibir um alerta com texto
   * @param message Texto a ser exibido
   */
  async presentAlert(message, button = 'OK', callback = () => {}) {
    const alert = await this.alertController.create({
      message,
      buttons: [
        {
          text: button,
          handler: () => {
            callback();
          },
        },
      ],
    });
    await alert.present();
  }

  async resetPassword() {
    const browser = await Browser.open({
      url: `${SERVER_URL}/password/reset`,
    });
  }

  openRegisterPage() {
    const form = {
      _campoVinculacao: this.company.campo_vinculacao,
      chave: this.form.value.chave,
    };

    this.openModal(
      RegisterPage,
      { formCompany: form, company: this.company },
      'modal-full',
    );
  }

  /**
   * Usuário Nào Encontra/Nào liberado para vincular
   * @author André Ramos <oi@andreramos.dev>
   */
  async handleUserNotFound(loading) {
    this.analytics.trackEvent('validate_partner_error', {
      partner_id: this.company.id,
      partner_name: this.company.nome,
    });
    loading.dismiss();
    await this.openModal(
      LoginCompanyAlertPage,
      {
        company: this.company,
        type: 'error',
      },
      'modal-full',
    );
  }

  /**
   * Usuário Pode Vincular e já possui conta Criada
   * @author André Ramos <oi@andreramos.dev>
   * @param res httpresponsedata
   */
  handleUserAlreadyCreated(res, loading) {
    this.userId = res.user;
    // verify user login ways
    if (res.user_login_ways) {
      this.userLoginWays = res.user_login_ways;
      if (
        this.arrayContains(this.userLoginWays, 'facebook') ||
        this.arrayContains(this.userLoginWays, 'apple') ||
        this.arrayContains(this.userLoginWays, 'google')
      ) {
        this.analytics.trackEvent('validate_partner_has_social_acc', {
          partner_id: this.company.id,
          partner_name: this.company.nome,
          user_login_ways: JSON.stringify(this.userLoginWays),
        });
        loading.dismiss();
        this.presentAlert(
          'Parece que você já tem uma conta via Facebook, Apple ID ou Google.',
          'Entrar',
          () => {
            this.openModal(
              SocialLoginPage,
              {
                userID: this.userId,
                cpf: this.form.value.chave,
                company: this.company,
              },
              'modal-transparent',
            );
          },
        );
        this.closeModal();
      }
    }
  }

  /**
   * Usuário Pode Vincular e AINDA Não  possui conta Criada
   * @author André Ramos <oi@andreramos.dev>
   */
  async handleNewUserCanVinculate(loading) {
    // if user login exists
    if (this.user.name) {
      this.updatedCpfProfile(loading);
    } else {
      // se ainda não tem usuário (não retornou ID)
      await loading.dismiss();
      await this.openModal(
        LoginCompanyAlertPage,
        {
          company: this.company,
          type: 'success',
        },
        'modal-full',
      );
      this.openRegisterPage();
    }

    await this.closeModal();
  }

  async updatedCpfProfile(loading) {
    let auxUserDataValue = {
      ...this.user,
      cpf: this.form.value.chave,
    };

    this.userService.updateUserData(auxUserDataValue).subscribe(
      async (res) => {
        if (res.erro) {
          await loading.dismiss();
          let errorMessage = '';
          Object.keys(res).map((key, index) => {
            if (key !== 'erro') {
              errorMessage += '- ' + res[key][0] + '<br>';
            }
          });
          this.presentAlert('Oops...', errorMessage);
          return;
        }

        this.analytics.trackEvent('user_data_edited');

        this.userService.user.next(res);
        this.validateUserDocumentForVinculation(this.company, loading);
      },
      async (err) => {
        await loading.dismiss();
      },
    );
  }

  async validateUserDocumentForVinculation(company, loading) {
    this.companiesService
      .verifyClientV5(company.id, { chave: this.form.value.chave })
      .subscribe(
        async (res) => {
          // can vinculate
          if (res.user_found) {
            this.vinculateCompany(company, loading);
          } else {
            await loading.dismiss();
          }
        },
        async (err) => {
          await loading.dismiss();
        },
      );
  }

  async vinculateCompany(company, loading) {
    const data = {
      cpf: this.form.value.chave,
      provedor_codigo: company.nome,
    };

    this.userService.vinculateCompany(data).subscribe(
      async (res) => {
        if (res.premium) {
          this.analytics.trackEvent('partner_vinculation', {
            partner_name: company.nome,
            is_logged: true,
          });
          this.userService.loadUser(async (success) => {
            loading && (await loading.dismiss());
          });

          this.presentAlert('Vinculado com sucesso!', 'Voltar', () => {
            // this.router.navigateByUrl("/home/profile", {
            // });
          });

          this.store.dispatch(
            LoginActions.getAutenticatedUserSuccess({
              payload: res.user_updated,
            }),
          );
        } else {
          this.presentAlert('Oops...', 'Algo deu errado na sua vinculação.');
          loading && (await loading.dismiss());
        }
      },
      async (err) => {
        loading && (await loading.dismiss());
        this.presentAlert('Oops...', 'Algo deu errado na sua vinculação.');
      },
    );
  }
}
