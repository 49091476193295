import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
} from 'src/environments/environment';

interface IDatavalueRating {
  idNutrition: number;
  user_id: number;
  ratingValue: number;
}

@Injectable({
  providedIn: 'root',
})
export class NutritionService {
  constructor(private http: HttpClient) {}

  getNutritionItems(type): Observable<any> {
    const url: string =
      API_URL_V3 + `/nutricionista-parceiras-dicas-exercicios/${type}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getItem(id): Observable<any> {
    const url: string =
      API_URL_V2 + `/nutricionista-parceiras-dicas-exercicios/detail/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // Evaluate the exercise
  setNutritionRating({ idNutrition, user_id, ratingValue }: IDatavalueRating) {
    const url: string =
      API_URL +
      `/visualizeds/nutricionista-parceiras-dicas-exercicios/${idNutrition}/set-nota`;
    const postData = {
      rating: ratingValue,
      user_id: user_id,
    };

    return this.http.patch(url, postData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setNutritionLike({ idNutrition }: any) {
    const url: string =
      API_URL +
      `/visualizeds/nutricionista-parceiras-dicas-exercicios/${idNutrition}/set-like`;

    return this.http.patch(url, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setNutritionDislike({ idNutrition }: any) {
    const url: string =
      API_URL +
      `/visualizeds/nutricionista-parceiras-dicas-exercicios/${idNutrition}/set-dislike`;

    return this.http.patch(url, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
