import { HomeEffects } from './home.effects';
import { LoginEffects } from './login.effects';
import { StatesCitiesEffects } from './state-city.effects';
import { PremiumEffects } from './premium.effects';
import { BannersEffects } from './banners.effects';
import { RecommendedEffects } from './recommended.effects';
import { PersonalityEffects } from './personality.effects';
import { ObjectivesEffects } from './objectives.effects';
import { InterestsEffects } from './interests.effects';
import { PartnersEffects } from './partners.effects';
import { OrientationsEffects } from './orientations.effects';
import { ModalitiesEffects } from './modalities.effects';
import { TrainingProgramsEffects } from './trainingprograms.effects';
import { RecommendedAccordionEffects } from './recommendedAccordion.effects';
import { CategoriesEffects } from './categories.effects';
import { SearchResultCategoriesEffects } from './searchCategories.effects';
import { TrainingPlanCustomEffects } from './trainingPlanCustom.effects';
import { ProfilePictureEffects } from './profilePicture.effects';
import { NotificationsEffects } from './notifications.effects';
import { SearchResultEffects } from './searchResult.effects';
import { ExerciseViewEffects } from './exerciseView.effects';
import { ExerciseRatingEffects } from './exerciseRating.effects';
import { OrientationsRatingEffects } from './orientationsRating.effects';
import { ScheduledTrainingEffects } from './scheduledTraining.effects';
import { TrainingTypesEffects } from './trainingTypes.effects';
import { RecipeEffects } from './recipe.effects';
import { CategoriesRecipesEffects } from './categoriesRecipes.effects';
import { FavoriteContentEffects } from './favoriteContent.effects';
import { MyFavoriteContentEffects } from './myFacoriteContent.effects';
import { GetExerciseByIdEffects } from './getExerciseById.effects';
import { CustomPremiumEffects } from './customPremium.effects';
import { ForgotPasswordEffects } from './forgotPassword.effects';
import { PersonalsListEffects } from './personalsList.effects';
import { DailyWorkoutsViewedEffects } from './dailyWorkoutsViewed.effects';
import { WorkoutSheetsEffects } from './workoutSheets.effects';
import { CompletedWorkoutsWeekEffects } from './completedWorkoutsWeek.effects';
import { FinishedWorkoutEffects } from './finishedWorkout.effects';
import { AnnotationEffects } from './annotation.effects';
import { ExercisesByWorkoutEffects } from './exercisesByWorkout.effects';
import { UpdateCustomExerciseEffects } from './updateCustomExercise.effects';
import { NewCustomWorkoutEffects } from './newCustomWorkout.effects';
import { ContinueTrainingEffects } from './continueTraining.effects';
import { AppConfigsEffects } from './appConfigs.effects';
import { FeedsEffects } from './feed.effects';

export const coreEffects = [
  LoginEffects,
  StatesCitiesEffects,
  PremiumEffects,
  BannersEffects,
  RecommendedEffects,
  HomeEffects,
  PersonalityEffects,
  ObjectivesEffects,
  InterestsEffects,
  PartnersEffects,
  OrientationsEffects,
  ModalitiesEffects,
  TrainingProgramsEffects,
  RecommendedAccordionEffects,
  CategoriesEffects,
  SearchResultCategoriesEffects,
  TrainingPlanCustomEffects,
  ProfilePictureEffects,
  NotificationsEffects,
  SearchResultEffects,
  ExerciseViewEffects,
  ExerciseRatingEffects,
  OrientationsRatingEffects,
  ScheduledTrainingEffects,
  TrainingTypesEffects,
  RecipeEffects,
  CategoriesRecipesEffects,
  FavoriteContentEffects,
  MyFavoriteContentEffects,
  GetExerciseByIdEffects,
  CustomPremiumEffects,
  ForgotPasswordEffects,
  PersonalsListEffects,
  DailyWorkoutsViewedEffects,
  WorkoutSheetsEffects,
  CompletedWorkoutsWeekEffects,
  FinishedWorkoutEffects,
  AnnotationEffects,
  ExercisesByWorkoutEffects,
  UpdateCustomExerciseEffects,
  NewCustomWorkoutEffects,
  ContinueTrainingEffects,
  AppConfigsEffects,
  FeedsEffects,
];
