import { createReducer, on } from '@ngrx/store';
import * as NewCustomWorkoutActions from '../actions/newCustomWorkout.actions';

export interface NewCustomWorkoutState {
  newCustomWorkout: any;
}

const initialState: NewCustomWorkoutState = {
  newCustomWorkout: null,
};

export const newCustomWorkoutReducer = createReducer(
  initialState,
  on(
    NewCustomWorkoutActions.getNewCustomWorkoutSuccess,
    (state, { payload: newCustomWorkout }) => {
      return {
        ...state,
        newCustomWorkout,
      };
    },
  ),
);
