import { ModalityService } from './../../services/modality.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ModalitiesActions from '../actions/modalities.actions';
import * as LoadingActions from '../actions/loading.actions';

import { NutritionService } from 'src/app/services/nutrition.service';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ModalitiesEffects {
  getModalityItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getModalityItem),
      switchMap(({ payload: { id, isSubcategory } }) => {
        return this.modalityService.getDetails(id, isSubcategory).pipe(
          map((res: any) => {
            let auxRes = res;
            auxRes.img = auxRes.img_v2;

            return ModalitiesActions.getModalityItemSuccess({
              payload: auxRes,
            });
          }),
          catchError((error) =>
            of(ModalitiesActions.getModalityItemError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getListModalities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getListModalities),
      switchMap(() => {
        return this.modalityService.getListModalities().pipe(
          map((res) => {
            return ModalitiesActions.getListModalitiesSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(ModalitiesActions.getListModalitiesError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getShowModality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowModality),
      tap(() => {
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.MODALITIES,
          }),
        );
      }),
      switchMap(({ payload }) => {
        return this.modalityService.getShowModality(payload).pipe(
          map((res) => {
            return ModalitiesActions.getShowModalitySuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(ModalitiesActions.getShowModalityError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getShowModalitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowModalitySuccess),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MODALITIES,
        });
      }),
    ),
  );

  getShowModalityError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowModalityError),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MODALITIES,
        });
      }),
    ),
  );

  getShowSubmodality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowSubmodality),
      tap(() => {
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.MODALITIES,
          }),
        );
      }),
      switchMap(({ payload }) => {
        return this.modalityService.getShowSubmodality(payload).pipe(
          map((res) => {
            return ModalitiesActions.getShowSubmodalitySuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(ModalitiesActions.getShowSubmodalityError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getShowSubmodalitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowSubmodalitySuccess),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MODALITIES,
        });
      }),
    ),
  );

  getShowSubmodalityError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalitiesActions.getShowSubmodalityError),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MODALITIES,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private modalityService: ModalityService,
    private store: Store,
  ) {}
}
