import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, filter, take } from "rxjs/operators";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import { Storage } from "@capacitor/storage";

import {
    SERVER_URL,
    API_URL,
    API_URL_V3,
    environment,
} from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    partnersScreen: BehaviorSubject<object> = new BehaviorSubject<object>({});

    constructor(private http: HttpClient) {}

    getAppConfig(): Observable<any> {
        const url: string = API_URL_V3 + "/get-app-configs";

        return this.http.get(url).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    getScreenConfig(screen): Observable<any> {
        const url = `${API_URL}/telas/${screen}`;

        return this.http.get(url).pipe(
            tap((response) => {
                if (response.registro) {
                    this.partnersScreen.next(response.registro);
                }
                return response;
            })
        );
    }
}
