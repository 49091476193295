import { ContentCreator } from 'src/app/shared/models/exercise.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btt-item-training-scheduled',
  templateUrl: './item-training-scheduled.component.html',
  styleUrls: ['./item-training-scheduled.component.scss'],
})
export class ItemTrainingScheduledComponent implements OnInit {
  @Input() t;
  @Input() contentCreator: ContentCreator;
  @Input() typeDate?: 'date' | 'hours' = 'hours';

  @Input() lines = false;
  @Input() blockAccess: boolean = false;
  @Input() exercise: boolean = false;
  @Input() hideBottomBorder: boolean = false;

  showAlertToast: boolean = false;
  timeToToastShow: number = 3; //in seconds

  constructor() {}

  ngOnInit() {}

  formattedDate(dataString: string) {
    let aux: string;

    const data = new Date(dataString);

    var day = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ][data.getDay()];
    var date = data.getDate();
    var month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ][data.getMonth()];

    aux = `${day}, ${date} de ${month}`;

    return aux;
  }

  formattedHours(dataString: string) {
    let aux: string;

    const data = new Date(dataString);

    const hours = data.getHours();

    const minutes = data.getMinutes();

    aux = `Disponível às ${hours}${minutes > 0 ? `:${minutes}` : ''}h`;

    return aux;
  }

  getFormattedText(description: string, maxCharacters: number) {
    return description?.length > maxCharacters
      ? `${description?.slice(0, maxCharacters)}...`
      : description;
  }

  handleClickSetToast() {
    this.showAlertToast = true;

    setTimeout(() => {
      this.showAlertToast = false;
    }, this.timeToToastShow * 1000);
  }
}
