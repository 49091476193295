import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  LOCALE_ID,
  Inject,
  ViewChild,
  Input,
} from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { CalendarComponent } from "ionic2-calendar";
import { Observable } from "rxjs";
import { Premium } from "src/app/models/premium";
import * as PremiumSelectors from "src/app/store/selectors/premium.selectors";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.page.html",
  styleUrls: ["./calendar.page.scss"],
})
export class CalendarPageModal implements OnInit {
  @Output() closeButton: EventEmitter<any> = new EventEmitter();
  @Output() openExercise: EventEmitter<any> = new EventEmitter();

  @Input() training;
  @Input() selectedDate: Date;
  formatedTitleDate: string;
  formatedTitleDialog: string;

  isWeb: boolean = false;

  date: string;
  type: "string"; // 'string' | 'js-date' | 'moment' | 'time' | 'object'

  @ViewChild(CalendarComponent) myCal: CalendarComponent;
  premiumStatus$: Observable<Premium>;

  constructor(
    private modalController: ModalController,
    public platform: Platform,
    private store: Store,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    platform.ready().then(() => {
      this.isWeb = !platform.is("android") && !platform.is("ios");
    });
  }

  ngOnInit() {
    this.getPremiumStatus();
    this.formatedTitleDate = `${new Date(this.selectedDate).toLocaleString(
      "pt-BR",
      { weekday: "long" },
    )}, ${new Date(this.selectedDate).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "long",
    })}`;
    this.setFormatedTitleDate();

    console.log("[training]=> ", this.training);
  }

  getPremiumStatus() {
    this.premiumStatus$ = this.store.select(PremiumSelectors.getPremiumStatus);
  }

  setFormatedTitleDate() {
    this.formatedTitleDialog =
      new Date(this.selectedDate).toLocaleString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }) ===
      new Date().toLocaleString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
        ? "Aulas de hoje:"
        : "Aulas do dia:";
  }

  handleSelectedContent(auxTraining) {
    this.openExercise.emit(auxTraining);
    this.modalController.dismiss(auxTraining);
  }
}
