import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, filter, take } from "rxjs/operators";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import { Storage } from "@capacitor/storage";

import {
    SERVER_URL,
    API_URL,
    API_URL_V2,
    API_URL_V5,
    environment,
} from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class CompaniesService {
    constructor(private http: HttpClient) {}

    /**
     * Get Main Companies
     * @return json
     */
    getMainCompanies(): Observable<any> {
        const url: string = API_URL_V5 + "/empresas-principais";

        return this.http.get(url).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Get Companies
     * @return json
     */
    getCompanies(): Observable<any> {
        const url: string = API_URL_V5 + "/empresas-integracoes";

        return this.http.get(url).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Verify Client
     * @author André Ramos <oi@andreramos.dev>
     */
    public verifyClientV5(companyId, data): Observable<any> {
        const url: string =
            API_URL_V5 + `/empresas/${companyId}/verifica-cliente`;

        return this.http.post(url, data).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Authenticate with external passive API
     * @author André Ramos <oi@andreramos.dev>
     */
    public authenticateExternal(companyId, credentials): Observable<any> {
        const url: string = API_URL + `/passive/${companyId}/authentication`;

        return this.http.post(url, credentials).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Validate if user has authorization to use reosurce, with external passive API
     * @author André Ramos <oi@andreramos.dev>
     */
    public validateAuthorizationResourceExternal(
        companyId,
        subscriberData
    ): Observable<any> {
        const url: string = API_URL + `/passive/${companyId}/authorization`;

        return this.http.post(url, subscriberData).pipe(
            tap((response) => {
                return response;
            })
        );
    }
}
