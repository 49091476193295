import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BttAlertActionComponent } from '../btt-alert-action/btt-alert-action.component';

interface IWeekDays {
  day: string;
  value: string;
  checked: boolean;
  indexValue: any;
}
interface IAuxDays {
  day: string;
  checked: boolean;
}

interface IValueStorage {
  weekDays: IWeekDays[];
  hours: string;
  formatHours: string;
  status: boolean;
  title?: string;
}

@Component({
  selector: 'app-card-sticky-notes',
  templateUrl: './card-sticky-notes.component.html',
  styleUrls: ['./card-sticky-notes.component.scss'],
})
export class CardStickyNotesComponent implements OnInit {
  auxSelectDays: IAuxDays[] = [];

  @Input() sticky: IValueStorage;
  @Input() indexSticky;
  @Input() accordionGroupOpen;
  @Output() openAccordion: EventEmitter<any> = new EventEmitter();
  @Output() handleSelectedDay: EventEmitter<any> = new EventEmitter();
  @Output() onChangeToggle: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  valueHours: any;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.valueHours = this.sticky.hours;
    this.setValuesAuxSelectDays();
  }

  ionViewDidEnter() {}

  setValuesAuxSelectDays() {
    this.sticky.weekDays.forEach((item) => {
      this.auxSelectDays.push({ day: item.day, checked: item.checked });
    });
  }

  public handleEnable(event: Event): void {
    this.onChangeToggle.emit({ event: event, index: this.indexSticky });
  }

  public openAccordionGroup(item: string): void {
    this.valueHours = this.sticky.hours;
    this.openAccordion.emit(item);
  }

  public deleteStick(): void {
    this.delete.emit(this.indexSticky);
  }

  public saveStick(): void {
    this.save.emit({
      index: this.indexSticky,
      valueDateTime: this.valueHours,
      valueSelectedDays: this.auxSelectDays,
    });
  }

  filterDaysSelected(item: IValueStorage) {
    return item.weekDays.filter((item) => item.checked === true);
  }

  selectedDaysClass(checked: boolean) {
    if (checked) {
      return 'selected-days--day selected-days--selected';
    }
    return 'selected-days--day';
    //selected-days--disabled
  }

  hourSelected(event: Event) {
    const formatDate = new Date(
      new Date((event.target as HTMLInputElement)?.value)
        .toString()
        .split('GMT')[0] + ' UTC',
    ).toISOString();

    this.valueHours = formatDate;
  }

  handleDays(item: IWeekDays) {
    item.checked = !item.checked;
  }

  async openModalAlertAction() {
    // open rate modal
    const modal = await this.modalController.create({
      component: BttAlertActionComponent,
      cssClass: 'modal-rate',
      componentProps: {
        title: 'Tem certeza que deseja continuar?',
        subTitle: 'O lembrete atual será excluído.',
        buttonText: 'Desejo excluir',
        localIcon: 'assets/custom-icon/btt-icon-warning.svg',
      },
    });
    await modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.deleteStick();
    }
  }
}
