import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getCustomPremium = createAction(
  '[Custom Premium] Get Custom Premium',
);

export const getCustomPremiumSuccess = createAction(
  '[Custom Premium] Get Custom Premium Success',
  props<{ payload: any }>(),
);

export const getCustomPremiumError = createAction(
  '[Custom Premium] Get Custom Premium Error',
  props<{ payload: HttpErrorResponse }>(),
);
