import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as RecommendedAccordionActions from '../actions/recommendedAccordion.actions';

import { RecommendedService } from 'src/app/services/recommended.service';
import { RecommendedAccordion } from 'src/app/models/recommendedAccordion';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class RecommendedAccordionEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedAccordionActions.getRecommendedAccordion),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.RECOMMENDED_ACCORDION,
        }),
      ),
      switchMap(() => {
        return this.recommendedService.getRecommendedAccordion().pipe(
          map((res: any) => {
            const recommendedAccordion: RecommendedAccordion[] = res.registros;

            return RecommendedAccordionActions.getRecommendedAccordionSuccess({
              payload: recommendedAccordion,
            });
          }),
          catchError((error) =>
            of(
              RecommendedAccordionActions.getRecommendedAccordionError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getBannersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedAccordionActions.getRecommendedAccordionSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.RECOMMENDED_ACCORDION,
        }),
      ),
    ),
  );

  getRecommendedAccordionError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendedAccordionActions.getRecommendedAccordionError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.RECOMMENDED_ACCORDION,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private recommendedService: RecommendedService,
    private store: Store,
    private router: Router,
  ) {}
}
