import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as LoadingActions from '../actions/loading.actions';
import * as WorkoutSheetsActions from '../actions/workoutSheets.actions';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { IWorkoutSheet } from 'src/app/models/workoutSheet';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class WorkoutSheetsEffects {
  setWorkoutSheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.setWorkoutSheets),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.WORKOUT_SHEETS,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.userService.setWorkoutSheets(payload).pipe(
          map((res: any) => {
            const response: IWorkoutSheet = res;
            if (response?.ficha) {
              this.router.navigate(['/home/training-plan/my-workout-bitt'], {
                replaceUrl: true,
              });
            } else {
              this.presentToast('bottom');
            }
            return WorkoutSheetsActions.setWorkoutSheetsSuccess({
              payload: response,
            });
          }),
          catchError((error) =>
            of(WorkoutSheetsActions.setWorkoutSheetsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  setWorkoutSheetsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.setWorkoutSheetsSuccess),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.WORKOUT_SHEETS,
        });
      }),
    ),
  );
  setWorkoutSheetsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.setWorkoutSheetsError),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.WORKOUT_SHEETS,
        });
      }),
    ),
  );

  // --------------------------------

  getWorkoutSheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.getWorkoutSheets),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.WORKOUT_SHEETS,
          }),
        ),
      ),
      switchMap(() => {
        return this.userService.getWorkoutSheets().pipe(
          map((res: any) => {
            const response: IWorkoutSheet = res;

            return WorkoutSheetsActions.getWorkoutSheetsSuccess({
              payload: response,
            });
          }),
          catchError((error) =>
            of(WorkoutSheetsActions.getWorkoutSheetsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getWorkoutSheetsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.getWorkoutSheetsSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.WORKOUT_SHEETS,
        }),
      ),
    ),
  );
  getWorkoutSheetsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkoutSheetsActions.getWorkoutSheetsError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.WORKOUT_SHEETS,
        }),
      ),
    ),
  );

  // toast
  async presentToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Não existe ficha com as opções selecionadas',
      duration: 2000,
      position: position,
      cssClass: 'toast__alert_content',
    });

    await toast.present();
  }

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private toastController: ToastController,
  ) {}
}
