import {
    Component,
    OnInit,
    Input,
    ViewChild,
    AfterViewChecked,
} from "@angular/core";

@Component({
    selector: "app-item-training",
    templateUrl: "./item-training.component.html",
    styleUrls: ["./item-training.component.scss"],
})
export class ItemTrainingComponent implements OnInit, AfterViewChecked {
    @Input() item;
    @Input() dayTitle;

    @ViewChild("description") description;

    constructor() {}

    ngOnInit() {}

    ngAfterViewChecked() {
        if (
            this.description &&
            this.description.nativeElement.offsetHeight > 30
        ) {
            this.description.nativeElement.style.maxHeight = "3rem";
            this.description.nativeElement.classList.add("reduzido");
        }
    }
}
