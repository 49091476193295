import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btt-notifications-web',
  templateUrl: './btt-notifications-web.component.html',
  styleUrls: ['./btt-notifications-web.component.scss'],
})
export class BttNotificationsWebComponent implements OnInit {
  // @Input() trigger: string;

  constructor() {}

  ngOnInit() {}
}
