import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-header-transparent',
  templateUrl: './btt-header-transparent.component.html',
  styleUrls: ['./btt-header-transparent.component.scss'],
})
export class BttHeaderTransparentComponent implements OnInit {
  @Input() showBackButton = true;
  @Input() showCloseButton = true;

  @Output() backButtonClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleBackButtonClick() {
    this.backButtonClick.emit();
  }
}
