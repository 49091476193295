import { createReducer, on } from '@ngrx/store';
import * as SearchResultCategoriesActions from '../actions/searchCategories.actions';

import { SearchCategoriesResult } from 'src/app/models/searchCategories';

export interface SearchCategoriesResultState {
  searchCategoriesResult: SearchCategoriesResult[];
}

const initialState: SearchCategoriesResultState = {
  searchCategoriesResult: [],
};

export const searchResultReducer = createReducer(
  initialState,
  on(
    SearchResultCategoriesActions.getSearchResultCategoriesSuccess,
    (state, { payload: searchCategoriesResult }) => {
      return {
        ...state,
        searchCategoriesResult,
      };
    },
  ),
);
