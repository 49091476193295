import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

// submit FirstAccess
export const setFirstAccess = createAction(
  '[FirstAccess] Set FirstAccess',
  props<{ payload: any }>()
);
export const setFirstAccessSuccess = createAction(
  '[FirstAccess] Set FirstAccess Success',
  props<{ payload: any }>()
);
