import { Exercicio } from 'src/app/models/trainingPlanCustom';
import { createReducer, on } from '@ngrx/store';
import * as ExercisesByWorkoutActions from '../actions/exercisesByWorkout.actions';
import * as NewCustomWorkoutActions from '../actions/newCustomWorkout.actions';

export interface ExercisesByWorkoutState {
  exercisesByWorkout: Exercicio[];
}

const initialState: ExercisesByWorkoutState = {
  exercisesByWorkout: [],
};

export const exercisesByWorkoutReducer = createReducer(
  initialState,
  on(
    ExercisesByWorkoutActions.getExercisesByWorkoutSuccess,
    (state, { payload: exercisesByWorkout }) => {
      return {
        ...state,
        exercisesByWorkout,
      };
    },
  ),
  on(
    NewCustomWorkoutActions.getExercisesByCustomWorkoutSuccess,
    (state, { payload: newCustomWorkout }) => {
      return {
        ...state,
        exercisesByWorkout: newCustomWorkout,
      };
    },
  ),
);
