import { Component, OnInit, Input } from "@angular/core";
import { ActionSheetController, ModalController } from "@ionic/angular";
import { Browser } from "@capacitor/browser";

import { VideoModalPage } from "src/app/pages/_modals/video-modal/video-modal.page";

@Component({
    selector: "app-bonus-scroll",
    templateUrl: "./bonus-scroll.component.html",
    styleUrls: ["./bonus-scroll.component.scss"],
})
export class BonusScrollComponent implements OnInit {
    @Input() bonusList;

    constructor(
        public actionSheetController: ActionSheetController,
        private modal: ModalController
    ) {}

    ngOnInit() {}

    async presentActionSheet(bonus) {
        const buttons = [];
        if (bonus.vimeo) {
            buttons.push({
                text: "Ver vídeo",
                // role: 'destructive',
                icon: "play-circle-outline",
                handler: () => {
                    this.openModalVideo(bonus.vimeo.video);
                },
            });
        }
        if (bonus.arquivo) {
            buttons.push({
                text: "Ver PDF",
                // role: 'destructive',
                icon: "document",
                handler: async () => {
                    await Browser.open({ url: bonus.arquivo });
                },
            });
        }
        buttons.push({
            text: "Cancelar",
            role: "cancel",
            handler: () => {
                console.log("Cancel clicked");
            },
        });
        const actionSheet = await this.actionSheetController.create({
            header: "Confira este bônus",
            buttons,
        });
        await actionSheet.present();
    }

    // abre a página de vídeo-modal, passando o video para ela
    async openModalVideo(video) {
        const modal = await this.modal.create({
            component: VideoModalPage,
            componentProps: { video },
        });
        return await modal.present();
    }
}
