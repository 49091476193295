enum ViewTypesEnum {
  TRAINING_PROGRAM = 'programa_de_treino',
  SUBMODALITY = 'submodalidade',
  SCHEDULED_TRAINING = 'treino_agendado',
  CUSTOM_TRAINING = 'treino_customizado',
  RECORD_TRAINING = 'ficha_de_treino',
  EXERCISE_SEARCH = 'exercise_search',
}

export { ViewTypesEnum };
