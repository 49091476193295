import { Component, OnInit } from "@angular/core";

@Component({
  selector: "btt-skeleton-item",
  templateUrl: "./btt-skeleton-item.component.html",
  styleUrls: ["./btt-skeleton-item.component.scss"],
})
export class BttSkeletonItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
