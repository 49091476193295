import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setOrientationsRating = createAction(
  '[OrientationsRating] Set OrientationsRating',
  props<{ payload: any }>(),
);

export const setOrientationsRatingSuccess = createAction(
  '[OrientationsRating] Set OrientationsRating Success',
  props<{ payload: any }>(),
);

export const setOrientationsRatingError = createAction(
  '[OrientationsRating] Set OrientationsRating Error',
  props<{ payload: HttpErrorResponse }>(),
);

/// set orientation like

export const setOrientationLike = createAction(
  '[Orientation like] Set Orientation like',
  props<{ payload: any }>(),
);

export const setOrientationLikeSuccess = createAction(
  '[Orientation like] Set Orientation like Success',
);

export const setOrientationLikeError = createAction(
  '[Orientation like] Set Orientation like Error',
  props<{ payload: HttpErrorResponse }>(),
);

/// set orientation dislike

export const setOrientationDislike = createAction(
  '[Orientation dislike] Set Orientation Dislike',
  props<{ payload: any }>(),
);

export const setOrientationDislikeSuccess = createAction(
  '[Orientation dislike] Set Orientation Dislike Success',
);

export const setOrientationDislikeError = createAction(
  '[Orientation dislike] Set Orientation Dislike Error',
  props<{ payload: HttpErrorResponse }>(),
);
