import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setForgotPassword = createAction(
  '[ForgotPassword] Set ForgotPassword',
  props<{ payload: any }>(),
);

export const setForgotPasswordSuccess = createAction(
  '[ForgotPassword] Set ForgotPassword Success',
  props<{ payload: any }>(),
);

export const setForgotPasswordError = createAction(
  '[ForgotPassword] Set ForgotPassword Error',
  props<{ payload: HttpErrorResponse }>(),
);

// ----------------------------------

export const setCodeToRedefinePassword = createAction(
  '[Code To Redefine Password] Set Code To Redefine Password',
  props<{ payload: any }>(),
);

export const setCodeToRedefinePasswordSuccess = createAction(
  '[Code To Redefine Password] Set Code To Redefine Password Success',
  props<{ payload: any }>(),
);

export const setCodeToRedefinePasswordError = createAction(
  '[Code To Redefine Password] Set Code To Redefine Password Error',
  props<{ payload: HttpErrorResponse }>(),
);

// ----------------------------------

export const setChangePassword = createAction(
  '[Change Password] Set Change Password',
  props<{ payload: any }>(),
);

export const setChangePasswordSuccess = createAction(
  '[Change Password] Set Change Password Success',
);

export const setChangePasswordError = createAction(
  '[Change Password] Set Change Password Error',
  props<{ payload: HttpErrorResponse }>(),
);
