import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as DailyWorkoutsViewedActions from '../actions/dailyWorkoutsViewed.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Exercicio } from 'src/app/models/trainingPlanCustom';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class DailyWorkoutsViewedEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyWorkoutsViewedActions.getDailyWorkoutsViewed),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.DAILY_WORKOUT_VIEWED,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.getDailyWorkoutsViewed(payload).pipe(
          map((res: any) => {
            const dailyWorkoutsViewed: Exercicio[] =
              this.formattedEventDate(res);
            const calendar: Exercicio[] = res.sort(
              (a, b) =>
                new Date(a.data_liberacao).getTime() -
                new Date(b.data_liberacao).getTime(),
            );
            return DailyWorkoutsViewedActions.getDailyWorkoutsViewedSuccess({
              payload: { events: dailyWorkoutsViewed, calendar: calendar },
            });
          }),
          catchError((error) =>
            of(
              DailyWorkoutsViewedActions.getDailyWorkoutsViewedError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  formattedEventDate(listExercises) {
    // Ordering assignments by release date
    let workoutsWithViews = listExercises
      .filter((value) =>
        Number(value.percentage_completed) !== 0 ? true : false,
      )

      .sort(
        (a, b) =>
          new Date(a.data_liberacao).getTime() -
          new Date(b.data_liberacao).getTime(),
      );

    // Standardizing and isolating the release time from the exercise list
    let auxWorkoutsWithViews = [];
    workoutsWithViews.map((value) => {
      let auxValue = new Date(new Date(value.data_liberacao).setHours(0, 0, 1));
      auxWorkoutsWithViews.push(auxValue);
      return auxValue;
    });

    // Removing repeated elements

    auxWorkoutsWithViews = auxWorkoutsWithViews.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    // Control variables
    let previousDay;
    let startDay;
    let arrayEvents = [];
    let listEvents = [];

    // Function to group events
    auxWorkoutsWithViews.map((event, idx) => {
      if (!previousDay && !startDay) {
        previousDay = event;
        startDay = event;
      } else {
        if (
          event.toLocaleString() !==
          new Date(previousDay.getTime() + 1440 * 60000).toLocaleString()
        ) {
          arrayEvents.push({
            title: 'My workout event',
            startTime: startDay,
            endTime: startDay === previousDay ? previousDay : previousDay,
            alldays: true,
            listEvents: listEvents,
          });
          startDay = event;
          listEvents = [];
        }

        if (idx === auxWorkoutsWithViews.length - 1) {
          listEvents.push(event);
          arrayEvents.push({
            title: 'My workout event',
            startTime: startDay,
            endTime: event,
            alldays: true,
            listEvents: listEvents,
          });
          startDay = event;
          listEvents = [];
        }

        previousDay = event;
      }
      listEvents.push(event);
    });

    return arrayEvents;
  }

  getDailyWorkoutsViewedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyWorkoutsViewedActions.getDailyWorkoutsViewedSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.DAILY_WORKOUT_VIEWED,
        }),
      ),
    ),
  );

  getDailyWorkoutsViewedError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyWorkoutsViewedActions.getDailyWorkoutsViewedError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.DAILY_WORKOUT_VIEWED,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
  ) {}
}
