import { createReducer, on } from '@ngrx/store';
import * as PersonalsListActions from '../actions/personalsList.actions';

export interface PersonalsListState {
  personalsList;
}

const initialState: PersonalsListState = {
  personalsList: [],
};

export const personalsListReducer = createReducer(
  initialState,
  on(
    PersonalsListActions.getPersonalsListSuccess,
    (state, { payload: personalsList }) => {
      return {
        ...state,
        personalsList,
      };
    },
  ),
);
