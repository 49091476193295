import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-icon-nutrition',
  templateUrl: './card-icon-nutrition.component.html',
  styleUrls: ['./card-icon-nutrition.component.scss'],
})
export class CardIconNutritionComponent implements OnInit {
  @Input() items: any[];
  @Input() openAcordeon: boolean;

  @Output() setTypeItemsCategories: EventEmitter<any> = new EventEmitter();

  expanded: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.expanded = this.openAcordeon;
  }

  handleChangeExpanded() {
    this.expanded = !this.expanded;
  }


  onItemClicked(item) {
    this.setTypeItemsCategories.emit(item);
  }

}
