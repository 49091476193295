import { ContentCreator } from 'src/app/shared/models/exercise.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-training-info-card',
  templateUrl: './btt-training-info-card.component.html',
  styleUrls: ['./btt-training-info-card.component.scss'],
})
export class BttTrainingInfoCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() text: string;

  @Input() contentCreator: ContentCreator;

  @Input() showButton = true;
  @Input() buttonText = 'Assistir';

  @Input() categorias_conteudos = [];

  @Output() buttonClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onButtonClick(ev) {
    this.buttonClick.emit(ev);
  }
}
