import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getSearchResult = createAction(
  '[Search Result] Get Search Result',
  props<{ payload: any }>()
);

export const getSearchResultSuccess = createAction(
  '[Search Result] Get Search Result Success',
  props<{ payload: any }>()
);

export const getSearchResultError = createAction(
  '[Search Result] Get Search Result Error',
  props<{ payload: HttpErrorResponse }>()
);
