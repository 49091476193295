import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-grid-popular',
  templateUrl: './btt-grid-popular.component.html',
  styleUrls: ['./btt-grid-popular.component.scss'],
})
export class BttGridPopularComponent implements OnInit {
  @Input() items: any = null;
  @Input() loading: any = null;

  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onItemClicked(item) {
    this.itemClick.emit(item);
  }
}
