import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { AnalyticsService } from 'src/app/services/analytics.service';

import { Browser } from '@capacitor/browser';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// actions
import * as AppConfigsActions from 'src/app/store/actions/appConfigs.actions';
// selectos
import * as AppConfigSelectors from 'src/app/store/selectors/appConfigs.selectors';
import { openSupport } from 'src/app/_helpers/open-support';

@Component({
  selector: 'app-login-company-alert',
  templateUrl: './login-company-alert.page.html',
  styleUrls: ['./login-company-alert.page.scss'],
})
export class LoginCompanyAlertPage implements OnInit {
  successTitle = 'Olá! Tudo certinho!';
  successText =
    'Você está para começar sua jornada em nosso app, mas antes é preciso fazer um cadastro para seu primeiro acesso. <strong>Vamos lá?</strong>';
  successButtonText = 'OK';

  errorTitle = 'Olá! Seus dados estão inválidos!';
  errorText =
    'Infelizmente não conseguimos verificar seus dados. Mas, fique tranquilo estamos a disposição para te ajudar. <strong>Clique no botão abaixo!</strong>';
  errorButtonText = 'Solucionar';

  @Input() company: any;
  @Input() type: any = 'success';

  appConfigs$: Observable<any>;
  openSupport = openSupport;

  constructor(
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.analytics.trackScreen(
      'login_company_alert',
      'LoginCompanyAlertScreen',
    );
    this.getAppConfigs();
  }

  // get app configs
  getAppConfigs() {
    this.store.dispatch(AppConfigsActions.getAppConfigs());
    this.appConfigs$ = this.store.select(AppConfigSelectors.getAppConfigs);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  // set open support
  async setOpenSupport(wppNumber: string) {
    await this.openSupport(wppNumber);
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
