import { ActionReducerMap } from "@ngrx/store";
import * as fromLogin from "./login.reducers";
import * as fromStatesCities from "./state-city.reducers";
import * as fromPremium from "./premium.reducers";
import * as fromLoading from "./loading.reducers";
import * as fromBanners from "./banners.reducers";
import * as fromRecommended from "./recommended.reducers";
import * as fromHome from "./home.reducers";
import * as fromPartners from "./partners.reducers";
import * as fromOrientations from "./orientations.reducers";
import * as fromModalities from "./modalities.reducers";

import * as fromObjectives from "./objectives.reducers";
import * as fromInterests from "./interests.reducers";
import * as fromFirstAccess from "./firstaccess.reducers";
import * as fromTrainingPrograms from "./trainingprograms.reducers";
import * as fromRecommendedAccordion from "./recommendedAccordion.reducers";
import * as fromTrainingPlanCustom from "./trainingPlanCustom.reducers";
import * as fromProfilePicture from "./profilePicture.reducers";
import * as fromNotifications from "./notifications.reducers";
import * as fromSearchResult from "./searchResult.reducers";

import * as fromCategories from "./categories.reducers";

import * as fromSearchResultCategories from "./searchCategories.reducers";
import * as fromScheduledTraining from "./scheduledTraining.reducers";
import * as fromTrainingTypes from "./trainingTypes.reducers";
import * as fromRecipe from "./recipe.reducers";
import * as fromCategoriesRecipes from "./categoriesRecipes.reducers";
import * as fromMyFavoriteContent from "./myFavoriteContent.reducers";
import * as fromGetExerciseById from "./getExerciseById.reducers";
import * as fromCustomPremium from "./custompremium.reducers";
import * as fromPersonalsList from "./personalsList.reducers";
import * as fromDailyWorkoutsViewed from "./dailyWorkoutsViewed.reducers";
import * as fromWorkoutSheets from "./workoutSheets.reducers";
import * as fromCompletedWorkoutsWeek from "./completedWorkoutsWeek.reducers";
import * as fromExercisesByWorkout from "./exercisesByWorkout.reducers";
import * as fromNewCustomWorkout from "./newCustomWorkout.reducers";

import * as fromContinueTraining from "./continueTraining.reducers";
import * as fromAppConfigs from "./appConfigs.reducers";
import * as fromPageRedirect from "./pageRedirect.reducers";
import * as fromCodeToRedefinePassword from "./forgotPassword.reducers";

import * as fromFeeds from "./feed.reducers";

export interface CoreState {
  login: fromLogin.LoginState;
  statesCities: fromStatesCities.StatesCitiesState;
  premium: fromPremium.PremiumState;
  loading: fromLoading.LoadingState;
  banners: fromBanners.BannerState;
  recommended: fromRecommended.RecommendedState;
  home: fromHome.HomeState;
  partners: fromPartners.PartnersState;
  orientations: fromOrientations.OrientationsState;
  modalities: fromModalities.ModalitiesState;
  objectives: fromObjectives.ObjectiveState;
  interests: fromInterests.InterestState;
  firstAccess: fromFirstAccess.FirstAccessState;
  trainingPrograms: fromTrainingPrograms.TrainingProgramsState;
  recommendedAccordion: fromRecommendedAccordion.RecommendedAccordionState;
  categories: fromCategories.CategoriesState;
  searchCategoriesResult: fromSearchResultCategories.SearchCategoriesResultState;
  trainingPlanCustom: fromTrainingPlanCustom.TrainingPlanCustomState;
  profilePicture: fromProfilePicture.ProfilePictureState;
  notifications: fromNotifications.NotificationsState;
  searchResult: fromSearchResult.SearchResultState;
  scheduledTraining: fromScheduledTraining.ScheduledTrainingState;
  trainingTypes: fromTrainingTypes.TrainingTypesState;
  recipe: fromRecipe.RecipeState;
  categoriesRecipes: fromCategoriesRecipes.CategoriesRecipesState;
  myFavoriteContent: fromMyFavoriteContent.MyFavoriteContentState;
  getExerciseById: fromGetExerciseById.GetExerciseByIdState;
  customPremium: fromCustomPremium.CustomPremiumState;
  personalsList: fromPersonalsList.PersonalsListState;
  dailyWorkoutsViewed: fromDailyWorkoutsViewed.DailyWorkoutsViewedState;
  workoutSheets: fromWorkoutSheets.WorkoutSheetsState;
  completedWorkoutsWeek: fromCompletedWorkoutsWeek.CompletedWorkoutsWeekState;
  fromExercisesByWorkout: fromExercisesByWorkout.ExercisesByWorkoutState;
  fromNewCustomWorkout: fromNewCustomWorkout.NewCustomWorkoutState;
  continueTraining: fromContinueTraining.IContinueTrainingState;
  appConfigs: fromAppConfigs.AppConfigsState;
  pageRedirect: fromPageRedirect.PageRedirectState;
  feeds: fromFeeds.IFeedState;
  interactions: fromFeeds.IInteractionsState;
  reactions: fromFeeds.IReactionsState;
  messagesFeed: fromFeeds.IMessagesState;
  setFeed: fromFeeds.ISetFeedState;
  getReportMessages: fromFeeds.IinitialReportMessages;
  codeToRedefinePassword: fromCodeToRedefinePassword.CodeToRedefinePasswordState;
  blockedUsers: fromFeeds.IBlockedUsersState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
  login: fromLogin.loginReducer,
  statesCities: fromStatesCities.statesCitiesReducer,
  premium: fromPremium.premiumReducer,
  loading: fromLoading.loadingReducer,
  banners: fromBanners.bannersReducer,
  recommended: fromRecommended.recommendedReducer,
  home: fromHome.homeReducer,
  partners: fromPartners.partnersReducer,
  orientations: fromOrientations.orientationsReducer,
  modalities: fromModalities.modalitiesReducer,
  objectives: fromObjectives.objectivesReducer,
  interests: fromInterests.interestsReducer,
  firstAccess: fromFirstAccess.firstAccessReducer,
  trainingPrograms: fromTrainingPrograms.trainingProgramsReducer,
  recommendedAccordion: fromRecommendedAccordion.recommendedAccordionReducer,
  categories: fromCategories.categoriesReducer,
  searchCategoriesResult: fromSearchResultCategories.searchResultReducer,
  trainingPlanCustom: fromTrainingPlanCustom.trainingPlanCustomReducer,
  profilePicture: fromProfilePicture.profilePictureReducer,
  notifications: fromNotifications.notificationsReducer,
  searchResult: fromSearchResult.searchResultReducer,
  scheduledTraining: fromScheduledTraining.scheduledTrainingReducer,
  trainingTypes: fromTrainingTypes.trainingTypesReducer,
  recipe: fromRecipe.recipeReducer,
  categoriesRecipes: fromCategoriesRecipes.categoriesRecipesReducer,
  myFavoriteContent: fromMyFavoriteContent.myFavoriteContentReducer,
  getExerciseById: fromGetExerciseById.getExerciseByIdReducer,
  customPremium: fromCustomPremium.customPremiumReducer,
  personalsList: fromPersonalsList.personalsListReducer,
  dailyWorkoutsViewed: fromDailyWorkoutsViewed.dailyWorkoutsViewedReducer,
  workoutSheets: fromWorkoutSheets.workoutSheetsReducer,
  completedWorkoutsWeek: fromCompletedWorkoutsWeek.completedWorkoutsWeekReducer,
  fromExercisesByWorkout: fromExercisesByWorkout.exercisesByWorkoutReducer,
  fromNewCustomWorkout: fromNewCustomWorkout.newCustomWorkoutReducer,
  continueTraining: fromContinueTraining.continueTrainingReducer,
  appConfigs: fromAppConfigs.appConfigsReducer,
  pageRedirect: fromPageRedirect.pageRedirectReducer,
  feeds: fromFeeds.feedsReducer,
  interactions: fromFeeds.interactionsReducer,
  reactions: fromFeeds.reactionsReducer,
  messagesFeed: fromFeeds.messagesReducer,
  setFeed: fromFeeds.setFeedReducer,
  codeToRedefinePassword:
    fromCodeToRedefinePassword.codeToRedefinePasswordReducer,
  getReportMessages: fromFeeds.getReportMessagesReducer,
  blockedUsers: fromFeeds.getBlockedUsersReducer,
};

export const getLoginState = (state: CoreState) => state.login;
export const getStatesCitiesState = (state: CoreState) => state.statesCities;
export const getPremiumState = (state: CoreState) => state.premium;
export const getLoadingState = (state: CoreState) => state.loading;
export const getBannersState = (state: CoreState) => state.banners;
export const getRecommendedState = (state: CoreState) => state.recommended;
export const getHomeState = (state: CoreState) => state.home;
export const getPartnersState = (state: CoreState) => state.partners;
export const getOrientationState = (state: CoreState) => state.orientations;
export const getModalitiesState = (state: CoreState) => state.modalities;

export const getObjectivesState = (state: CoreState) => state.objectives;
export const getInterestsState = (state: CoreState) => state.interests;
export const getFirstAccessState = (state: CoreState) => state.firstAccess;
export const getTrainingProgramsState = (state: CoreState) =>
  state.trainingPrograms;
export const getRecommendedAccordionState = (state: CoreState) =>
  state.recommendedAccordion;

export const getCategoryState = (state: CoreState) => state.categories;
export const getSearchCategoriesResultState = (state: CoreState) =>
  state.searchCategoriesResult;
export const getTrainingPlanCustomState = (state: CoreState) =>
  state.trainingPlanCustom;
export const getProfilePictureState = (state: CoreState) =>
  state.profilePicture;
export const getNotificationsState = (state: CoreState) => state.notifications;
export const getSearchResultState = (state: CoreState) => state.searchResult;
export const getScheduledTrainingState = (state: CoreState) =>
  state.scheduledTraining;
export const getTrainingTypesState = (state: CoreState) => state.trainingTypes;
export const getRecipeState = (state: CoreState) => state.recipe;
export const getCategoriesRecipesState = (state: CoreState) =>
  state.categoriesRecipes;
export const getMyFavoriteContentState = (state: CoreState) =>
  state.myFavoriteContent;
export const getExerciseByIdState = (state: CoreState) => state.getExerciseById;
export const getCustomPremiumState = (state: CoreState) => state.customPremium;
export const getPersonalsListState = (state: CoreState) => state.personalsList;
export const getDailyWorkoutsViewed = (state: CoreState) =>
  state.dailyWorkoutsViewed;
export const getWorkoutSheetsState = (state: CoreState) => state.workoutSheets;
export const getCompletedWorkoutsWeekState = (state: CoreState) =>
  state.completedWorkoutsWeek;
export const getExercisesByWorkoutState = (state: CoreState) =>
  state.fromExercisesByWorkout;

export const getNewCustomWorkoutState = (state: CoreState) =>
  state.fromNewCustomWorkout;
export const getContinueTrainingState = (state: CoreState) =>
  state.continueTraining;
export const getAppConfigsState = (state: CoreState) => state.appConfigs;
export const getPageRedirectState = (state: CoreState) => state.pageRedirect;

export const getFeedState = (state: CoreState) => state.feeds;
export const getInteractionState = (state: CoreState) => state.interactions;
export const getReactionState = (state: CoreState) => state.reactions;
export const getMessagesState = (state: CoreState) => state.messagesFeed;
export const setFeedState = (state: CoreState) => state.setFeed;
export const getCodeToRedefinePasswordState = (state: CoreState) =>
  state.codeToRedefinePassword;
export const getReportMessages = (state: CoreState) => state.getReportMessages;
export const getBlockedUsers = (state: CoreState) => state.blockedUsers;
