import { Component, Input, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';

// actions
import * as AnnotationActions from 'src/app/store/actions/annotation.actions';

interface ISetupListeners {
  onDismiss: boolean;
}
@Component({
  selector: 'app-btt-new-exercise-description',
  templateUrl: './btt-new-exercise-description.component.html',
  styleUrls: ['./btt-new-exercise-description.component.scss'],
})
export class BttNewExerciseDescriptionComponent implements OnInit {
  @Input() exercise: any;
  nativeBackButtonListener$: any;

  currentAnnotation: string;

  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private store: Store,
  ) {}

  selectedTab: 'description' | 'notes' = 'description';

  ngOnInit() {
    this.backBtnAuxiliarFunction();
    this.currentAnnotation = this.exercise?.annotation;
  }

  handleSelectTab(auxTab: 'description' | 'notes') {
    this.selectedTab = auxTab;
  }

  backBtnAuxiliarFunction() {
    const setupListeners = { onDismiss: true };
    this.handleChangeBackButton(setupListeners);
  }

  handleChangeBackButton({ onDismiss }: ISetupListeners) {
    // Remove previous event from backButton
    this.nativeBackButtonListener$ = App.removeAllListeners();
    // set on dismiss
    if (onDismiss) {
      this.nativeBackButtonListener$ = App.addListener(
        'backButton',
        async (res) => {
          const setupListeners = { onDismiss: false };
          // set on dismiss
          this.handleClickClose();
          this.handleChangeBackButton(setupListeners);
        },
      );
    } else {
      //Re-add event to backButton
      this.nativeBackButtonListener$ = App.addListener(
        'backButton',
        async (res) => {
          if (!res.canGoBack) {
            this.backButtonAlert();
          } else {
            this.navCtrl.back();
          }
        },
      );
    }
  }

  // legacy app component
  async backButtonAlert() {
    const alert = await this.alertController.create({
      message: 'Deseja sair do aplicativo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'Cancel',
        },
        {
          text: 'Sim',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  async setAnnotation() {
    const payload = {
      tipo_id: this.exercise.id,
      tipo: 'exercicio',
      anotacao: this.currentAnnotation,
    };
    this.store.dispatch(AnnotationActions.setAnnotation({ payload }));
    this.exercise.annotation = this.currentAnnotation;
  }

  handleChangeAnnotation(event) {
    this.currentAnnotation = event.target.value;
  }

  handleClickClose() {
    this.modalController.dismiss();
  }
}
