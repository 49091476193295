import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as SearchResultCategoriesActions from '../actions/searchCategories.actions';

import { SearchCategoriesService } from 'src/app/services/search-categories.service';
import { Banner } from 'src/app/models/banner';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { SearchCategoriesResult } from 'src/app/models/searchCategories';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class SearchResultCategoriesEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultCategoriesActions.getSearchResultCategories),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.SEARCH_CATEGORIES,
        }),
      ),
      switchMap(({ payload: { categoryValue, searchValue } }) => {
        return this.searchCategoriesService
          .getSearchResultCategories(categoryValue, searchValue)
          .pipe(
            map((res: any) => {
              let auxResponse: SearchCategoriesResult = res;

              return SearchResultCategoriesActions.getSearchResultCategoriesSuccess(
                {
                  payload: auxResponse,
                },
              );
            }),
            catchError((error) =>
              of(
                SearchResultCategoriesActions.getSearchResultCategoriesError({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  getSearchResultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultCategoriesActions.getSearchResultCategoriesSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SEARCH_CATEGORIES,
        }),
      ),
    ),
  );

  getSearchResultError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultCategoriesActions.getSearchResultCategoriesError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SEARCH_CATEGORIES,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchCategoriesService: SearchCategoriesService,
    private store: Store,
    private router: Router,
  ) {}
}
