import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ScheduledTrainingActions from '../actions/scheduledTraining.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ScheduledTrainingEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTraining),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.SCHEDULED_TRAINING,
          }),
        ),
      ),
      switchMap(() => {
        return this.trainingService.getTrainingScheduled().pipe(
          map((res: any) => {
            return ScheduledTrainingActions.getScheduledTrainingSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              ScheduledTrainingActions.getScheduledTrainingError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getScheduledTrainingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTrainingSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SCHEDULED_TRAINING,
        }),
      ),
    ),
  );

  getScheduledTrainingError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTrainingError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SCHEDULED_TRAINING,
        }),
      ),
    ),
  );

  getScheduledTrainingExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTrainingExercise),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.SCHEDULED_TRAINING,
          }),
        ),
      ),
      switchMap(({ payload: { date } }) => {
        return this.trainingService.getTrainingScheduledByDate(date).pipe(
          map((res: any) => {
            return ScheduledTrainingActions.getScheduledTrainingExerciseSuccess(
              {
                payload: res,
              },
            );
          }),
          catchError((error) =>
            of(
              ScheduledTrainingActions.getScheduledTrainingExerciseError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getScheduledTrainingExerciseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTrainingExerciseSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SCHEDULED_TRAINING,
        }),
      ),
    ),
  );

  getScheduledTrainingExerciseError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledTrainingActions.getScheduledTrainingExerciseError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SCHEDULED_TRAINING,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
  ) {}
}
