import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-btt-generate-new-training-modal',
  templateUrl: './btt-generate-new-training-modal.component.html',
  styleUrls: ['./btt-generate-new-training-modal.component.scss'],
})
export class BttGenerateNewTrainingModalComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {}
  handleClickClose() {
    this.modalController.dismiss();
  }

  setGenerateToNewWorkout() {
    this.router.navigateByUrl('/home/training-plan/preset');
    this.modalController.dismiss();
  }
}
