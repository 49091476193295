import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setFinishedWorkout = createAction(
  '[Finished Workout] Set Finished Workout',
  props<{ payload: any }>(),
);

export const setFinishedWorkoutSuccess = createAction(
  '[Finished Workout] Set Finished Workout Success',
);

export const setFinishedWorkoutError = createAction(
  '[Finished Workout] Set Finished Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// ####################################

export const setFinishedExercise = createAction(
  '[Finished Exercise] Set Finished Exercise',
  props<{ payload: any }>(),
);

export const setFinishedExerciseSuccess = createAction(
  '[Finished Exercise] Set Finished Exercise Success',
);

export const setFinishedExerciseError = createAction(
  '[Finished Exercise] Set Finished Exercise Error',
  props<{ payload: HttpErrorResponse }>(),
);
