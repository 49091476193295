import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getTrainingTypes = createAction(
  '[TrainingTypes] Get TrainingTypes'
);

export const getTrainingTypesSuccess = createAction(
  '[TrainingTypes] Get TrainingTypes Success',
  props<{ payload: any }>()
);

export const getTrainingTypesError = createAction(
  '[TrainingTypes] Get TrainingTypes Error',
  props<{ payload: HttpErrorResponse }>()
);
