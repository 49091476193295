import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISetFavoriteContentType } from 'src/app/models/setFavoriteContent';

// video modal
import { VideoModalPage } from 'src/app/pages/_modals/video-modal/video-modal.page';

// actions
import * as FavoriteContentActions from 'src/app/store/actions/favoriteContent.actions';
import * as PremiumActions from 'src/app/store/actions/premium.actions';

// selectors
import * as PremiumSelectors from 'src/app/store/selectors/premium.selectors';

@Component({
  selector: 'app-btt-info-content',
  templateUrl: './btt-info-content.component.html',
  styleUrls: ['./btt-info-content.component.scss'],
})
export class BttInfoContentComponent implements OnInit {
  @Input() content: any;
  @Input() contentType: ISetFavoriteContentType;

  premiumStatus$: Observable<any>;

  constructor(private modalCtrl: ModalController, private store: Store) {}

  ngOnInit() {
    this.setupListeners();
  }

  // data premium user
  setupListeners() {
    this.store.dispatch(PremiumActions.getPremiumStatus());
    this.premiumStatus$ = this.store.select(PremiumSelectors.getPremiumStatus);
  }

  // open video modal
  async openVideoModal(video) {
    if (!video?.video) return;

    const modal = await this.modalCtrl.create({
      component: VideoModalPage,
      cssClass: 'modal-full',
      componentProps: {
        video: video?.video,
        isActiveLandscape: true,
      },
    });

    await modal.present();
  }

  // set favorite content
  setFavorite() {
    let dataValues = {
      tipo: this.contentType,
      id: this.content?.id,
    };
    this.store.dispatch(
      FavoriteContentActions.setFavoriteContent({ payload: dataValues }),
    );

    this.content.is_favorite = !this.content?.is_favorite;
  }
}
