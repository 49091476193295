import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BttShowFullscreenImageComponent } from '../btt-show-fullscreen-image/btt-show-fullscreen-image.component';

@Component({
  selector: 'app-btt-image-background',
  templateUrl: './btt-image-background.component.html',
  styleUrls: ['./btt-image-background.component.scss'],
})
export class BttImageBackgroundComponent implements OnInit {
  @Input() img: string;
  @Input() height: 'small' | 'large' | 'extra_small' = 'small';
  @Input() noBlur: boolean = false;
  @Input() newPlayerLayoutImageBackground: boolean = false;
  @Input() onlyImage: boolean;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  showFullscreenImage() {
    if (!this.onlyImage) return;

    this.openExerciseTimer(this.img);
  }

  async openExerciseTimer(imageUrl: string) {
    const modal = await this.modalController.create({
      component: BttShowFullscreenImageComponent,
      cssClass: 'modal-rate',
      componentProps: {
        imageUrl: imageUrl,
      },
    });
    await modal.present();
  }
}
