import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as TrainingTypesActions from '../actions/trainingTypes.actions';

import { TrainingService } from 'src/app/services/training.service';
import { TrainingTypes } from 'src/app/models/trainingTypes';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class TrainingTypesEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingTypesActions.getTrainingTypes),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.TRAINING_TYPES,
        }),
      ),
      switchMap(() => {
        return this.trainingService.getTrainingTypes().pipe(
          map((res: any) => {
            const trainingTypes: TrainingTypes[] = res?.registros;

            return TrainingTypesActions.getTrainingTypesSuccess({
              payload: trainingTypes,
            });
          }),
          catchError((error) =>
            of(TrainingTypesActions.getTrainingTypesError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getTrainingTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingTypesActions.getTrainingTypesSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_TYPES,
        }),
      ),
    ),
  );

  getTrainingTypesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingTypesActions.getTrainingTypesError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_TYPES,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
  ) {}
}
