import { createReducer, on } from '@ngrx/store';
import * as ScheduledTrainingActions from '../actions/scheduledTraining.actions';

// import { Banner } from 'src/app/models/banner';

export interface ScheduledTrainingState {
  scheduledTraining: any;
  scheduledTrainingExercise: any;
  loading: boolean;
}

const initialState: ScheduledTrainingState = {
  scheduledTraining: [],
  scheduledTrainingExercise: [],
  loading: false,
};

export const scheduledTrainingReducer = createReducer(
  initialState,
  on(
    ScheduledTrainingActions.getScheduledTrainingSuccess,
    (state, { payload: scheduledTraining }) => {
      return {
        ...state,
        scheduledTraining,
      };
    },
  ),
  on(ScheduledTrainingActions.getScheduledTrainingExercise, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ScheduledTrainingActions.getScheduledTrainingExerciseSuccess,
    (state, { payload: scheduledTrainingExercise }) => {
      return {
        ...state,
        scheduledTrainingExercise,
        loading: false,
      };
    },
  ),
  on(
    ScheduledTrainingActions.getScheduledTrainingExerciseError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
);
