import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as ObjectivesActions from '../actions/objectives.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Objective } from 'src/app/models/objective';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ObjectivesEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.getObjectives),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.OBJECTIVES }),
      ),
      switchMap(() => {
        return this.trainingService.getObjetives().pipe(
          map((res: any) => {
            const objectives: Objective[] = res.registros.map((objective) => {
              return {
                id: objective.id,
                nome: objective.nome,
                status: objective.status,
                img: objective?.img,
              };
            });

            return ObjectivesActions.getObjectivesSuccess({
              payload: objectives,
            });
          }),
          catchError((error) =>
            of(ObjectivesActions.getObjectivesError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getObjectivesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.getObjectivesSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.OBJECTIVES }),
      ),
    ),
  );

  getObjectivesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.getObjectivesError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.OBJECTIVES }),
      ),
    ),
  );

  // setObjectives

  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.setObjectives),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.OBJECTIVES }),
      ),
      switchMap((payload) => {
        return this.trainingService.setUserObjective(payload).pipe(
          map((res: any) => {
            return ObjectivesActions.setObjectivesSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(ObjectivesActions.setObjectivesError({ payload: error })),
          ),
        );
      }),
    ),
  );
  setObjectivesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.setObjectivesSuccess),
      map(() => {
        this.router.navigate(['/personality/form-interests'], {
          replaceUrl: true,
        });

        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.OBJECTIVES,
        });
      }),
    ),
  );

  setObjectivesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectivesActions.setObjectivesError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.OBJECTIVES }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
  ) {}
}
