import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as TrainingPlanCustomActions from '../actions/trainingPlanCustom.actions';

import { TrainingCustomService } from 'src/app/services/training-custom.service';
import { TrainingPlanCustom } from 'src/app/models/trainingPlanCustom';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class TrainingPlanCustomEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingPlanCustomActions.getTrainingPlanCustom),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.TRAINING_PLAN_CUSTOM,
        }),
      ),
      switchMap(() => {
        return this.trainingCustomService.getCustomTraining().pipe(
          map((res: any) => {
            const trainingPlanCustom: TrainingPlanCustom[] = res.treinos;

            return TrainingPlanCustomActions.getTrainingPlanCustomSuccess({
              payload: trainingPlanCustom,
            });
          }),
          catchError((error) =>
            of(
              TrainingPlanCustomActions.getTrainingPlanCustomError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getTrainingPlanCustomSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingPlanCustomActions.getTrainingPlanCustomSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_PLAN_CUSTOM,
        }),
      ),
    ),
  );

  getTrainingPlanCustomError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingPlanCustomActions.getTrainingPlanCustomError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_PLAN_CUSTOM,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingCustomService: TrainingCustomService,
    private store: Store,
    private router: Router,
  ) {}
}
