import { createReducer, on } from '@ngrx/store';
import * as MyFavoriteContentActions from '../actions/myFavoriteContent.actions';

import { MyFavoriteContent } from 'src/app/models/myFavoriteContent';

export interface MyFavoriteContentState {
  myFavoriteContent: MyFavoriteContent[];
}

const initialState: MyFavoriteContentState = {
  myFavoriteContent: [],
};

export const myFavoriteContentReducer = createReducer(
  initialState,
  on(
    MyFavoriteContentActions.getMyFavoriteContentSuccess,
    (state, { payload: myFavoriteContent }) => {
      return {
        ...state,
        myFavoriteContent,
      };
    },
  ),
);
