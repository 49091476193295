import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getModalityItem = createAction(
  '[Modalities] Get Modality Item',
  props<{
    payload: {
      id: number;
      isSubcategory?: boolean;
    };
  }>(),
);

export const getModalityItemSuccess = createAction(
  '[Modalities] Get Modality Item Success',
  props<{ payload: any }>(),
);

export const getModalityItemError = createAction(
  '[Modalities] Get Modality Item Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const setModalityImage = createAction(
  '[Modalities] Set Modality Image',
  props<{ payload: any }>(),
);
export const setSubmodality = createAction(
  '[Modalities] Set Submodality',
  props<{ payload: any }>(),
);

// ====================================

export const getListModalities = createAction(
  '[Modalities] Get List Modalities',
);

export const getListModalitiesSuccess = createAction(
  '[Modalities] Get List Modalities Success',
  props<{ payload: any }>(),
);

export const getListModalitiesError = createAction(
  '[Modalities] Get List Modalities Error',
  props<{ payload: any }>(),
);

// ====================================

export const getShowModality = createAction(
  '[Modalities] Get Show Modality',
  props<{ payload: any }>(),
);

export const getShowModalitySuccess = createAction(
  '[Modalities] Get Show Modality Success',
  props<{ payload: any }>(),
);

export const getShowModalityError = createAction(
  '[Modalities] Get Show Modality Error',
  props<{ payload: any }>(),
);

// ====================================

export const getShowSubmodality = createAction(
  '[Modalities] Get Show Submodality',
  props<{ payload: any }>(),
);

export const getShowSubmodalitySuccess = createAction(
  '[Modalities] Get Show Submodality Success',
  props<{ payload: any }>(),
);

export const getShowSubmodalityError = createAction(
  '[Modalities] Get Show Submodality Error',
  props<{ payload: any }>(),
);
