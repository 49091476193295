import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V2,
  API_URL_V3,
  environment,
} from 'src/environments/environment';

import { PremiumService } from './premium.service';
import { recommendedMock } from '../mocks/home.mock';

@Injectable({
  providedIn: 'root',
})
export class RecommendedService {
  constructor(
    private http: HttpClient,
    private premiumService: PremiumService
  ) {
    // this.loadUser();
  }

  /**
   * Return Logged User Data
   * @author André Ramos <oi@andreramos.dev>
   */
  getRecommended(): Observable<any> {
    // return of(recommendedMock);
    const url: string = API_URL + '/user/recomendados';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getRecommendedAccordion(): Observable<any> {
    const url: string = API_URL + '/categorias-conteudos';
    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      })
    );
  }
}
