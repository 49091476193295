import { Observable } from "rxjs";
import { Component, OnInit, Input } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";

import { AnalyticsService } from "src/app/services/analytics.service";

import { Router } from "@angular/router";

import { TrainingService } from "src/app/services/training.service";

import { AppRateService } from "src/app/services/app-rate.service";
import {
  ExerciseFormattedMock,
  ExerciseFormattedOutroMock,
  ExerciseMock,
} from "src/app/services/mock/exercises.mock";
import { ContentCreator, Exercise } from "src/app/shared/models/exercise.model";
import { ExerciseModalCloseHandle } from "src/app/enums/exercise.enum";
import { NativeAudio } from "@capacitor-community/native-audio";

import { Store } from "@ngrx/store";
// actions
import * as GetExerciseByIdActions from "src/app/store/actions/getExerciseById.actions";
import * as ExerciseViewActions from "src/app/store/actions/exerciseView.actions";
import * as DailyWorkoutsViewedActions from "src/app/store/actions/dailyWorkoutsViewed.actions";
import * as ContinueTrainingActions from "src/app/store/actions/continueTraining.actions";
// selectors
import * as GetExerciseByIdSelectors from "src/app/store/selectors/getExerciseById.selectors";
import * as LoadingSelectors from "src/app/store/selectors/loading.selectors";
import * as LoginSelectors from "src/app/store/selectors/login.selectors";

import { User } from "src/app/models/user";

//back button
import { App } from "@capacitor/app";
import { loadingTypesEnum } from "src/app/enums/loadingTypes.enum";
import { ViewTypesEnum } from "src/app/enums/viewTypes.enum";
import { IDataFlow } from "src/app/models/feed";
import { SharingFlows } from "src/app/enums/sharingFlows.enum";
@Component({
  selector: "app-exercise-modal",
  templateUrl: "./exercise-modal.page.html",
  styleUrls: ["./exercise-modal.page.scss"],
})
export class ExerciseModalPage implements OnInit {
  @Input() showCompleteButton? = true;

  @Input() onCompleteSubtitle: any;

  // REWORK:
  @Input() exercise?: Exercise;
  @Input() prevExercise: Exercise;
  @Input() nextExercise: Exercise;

  @Input() fromTrainingGym: boolean = false;
  @Input() training: any;
  @Input() blockAccess: boolean = false;
  @Input() enableExerciseShow: boolean = false;
  @Input() contentSelected?: any;
  @Input() is_active_landscape?: boolean = false;
  @Input() origin?: string;
  @Input() origin_id?: number;

  @Input() flowOrigin?: SharingFlows;
  @Input() canShare: boolean = true;

  exercise$: Observable<any>;
  loading$: Observable<string[]>;
  carga: number;

  loading = true;
  // variables legado
  progressbar: any;
  seriesVar;
  repetiu = 1;
  fimDescanso = false;
  descanso = false;
  intervaloBeep: any;
  beepSonoro = true;
  exerciseTime: any;
  user: User;
  user$: Observable<User>;

  isWeb: boolean = false;
  userHasPlayVideo = false;

  //back button
  voltarNativoListener$: any;

  loadingTypes = loadingTypesEnum;

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private analytics: AnalyticsService,
    private trainingService: TrainingService,
    private router: Router,
    private appRateService: AppRateService,
    private platform: Platform,
    private store: Store,
    private toastController: ToastController,
    private navCtrl: NavController,
  ) {
    this.preloadSound();
    platform.ready().then(() => {
      // Check if it is a WEB or Mobile page
      if (!platform.is("mobile")) this.isWeb = true;
      // Remove previous event from backButton
      // this.voltarNativoListener$ = App.removeAllListeners();
      // //Add new event to backButton
      // this.voltarNativoListener$ = App.addListener(
      //   "backButton",
      //   async (res) => {
      //     // set on dismiss
      //     this.dismiss();
      //     // Remove previous event from backButton
      //     this.voltarNativoListener$ = App.removeAllListeners();
      //     //Re-add event to backButton
      //     this.voltarNativoListener$ = App.addListener(
      //       "backButton",
      //       async (res) => {
      //         if (!res.canGoBack) {
      //           this.backButtonAlert();
      //         } else {
      //           this.navCtrl.back();
      //         }
      //       },
      //     );
      //   },
      // );
    });
  }

  ngOnDestroy() {
    this.voltarNativoListener$?.remove();
  }

  ngOnInit() {
    console.log("canShare", this.canShare)
    this.getExerciceByIdFunctions();
    this.getUser();

    if (!this.enableExerciseShow && !this.exercise) {
      this.exercise = ExerciseFormattedMock;
      this.prevExercise = ExerciseFormattedOutroMock;
      this.nextExercise = ExerciseFormattedOutroMock;
    }

    // config legado
    this.progressbar = {};
    this.progressbar.color = "#ffcd07";
    this.progressbar.clockwise = false; // Definido como false o progess fica sentido horario
    this.progressbar.finish = false;
    this.progressbar.started = false;
    this.progressbar.duration = 800;
    this.progressbar.active = false;
    this.getItem();
  }

  async getExerciceByIdFunctions() {
    if (this.enableExerciseShow) {
      this.loading$ = this.store.select(LoadingSelectors.getLoadingStatus);
      const loading = await this.loadingController.create();
      await loading.present();
      this.store.dispatch(
        GetExerciseByIdActions.getExerciseById({
          payload: this.contentSelected.id,
        }),
      );
      this.exercise$ = this.store.select(
        GetExerciseByIdSelectors.getExerciseById,
      );
      this.exercise$.subscribe(async (response) => {
        this.exercise = await this.getFormatedExercise(response);
      });
      await loading.dismiss();
    }
  }

  async openFinishAlert() {
    if (this.nextExercise) {
      this.goToNextExercise();
    } else {
      let auxData = {
        payload: {
          exerciseId: this.exercise.id,
          origin: this.origin ? this.origin : ViewTypesEnum.TRAINING_PROGRAM, // Training Program type is Default
          origin_id: this.origin_id,
          percentage_completed: 1,
        },
      };
      this.store.dispatch(ExerciseViewActions.setExerciseView(auxData));
      const payload = {
        payload: this.user.id,
      };

      this.store.dispatch(
        DailyWorkoutsViewedActions.getDailyWorkoutsViewed(payload),
      );

      // this.openSharingScreen();
      if (this.canShare) {
        this.openSharingFeedScreen();
      } else {
        this.dismiss();
      }
    }
  }

  openSharingFeedScreen() {
    this.router.navigate(["/feed-sharing"], {
      state: {
        title: this.exercise.title,
        exerciseId: this.exercise?.id,
        contentId: this.origin_id,
        contentType: this.origin ?? ViewTypesEnum.TRAINING_PROGRAM,
        isEditFeed: false,
        feedData: null,
        flowOrigin: this.flowOrigin,
      },
      replaceUrl: false,
    });
    this.dismiss();
  }

  // openSharingScreen() {
  //   this.router.navigate(['/home/social-sharing'], {
  //     queryParams: {
  //       title: this.exercise.title,
  //       subtitle: this.onCompleteSubtitle ? this.onCompleteSubtitle : null,
  //       exerciseId: this.exercise?.id,
  //     },
  //   });
  //   this.dismiss();
  // }

  goToPrevExercise() {
    this.modalController.dismiss({
      handler: ExerciseModalCloseHandle.OPEN_PREV,
    });
  }
  goToNextExercise() {
    this.modalController.dismiss({
      handler: ExerciseModalCloseHandle.OPEN_NEXT,
    });
  }

  dismiss() {
    // this.exercise = undefined;
    const finished = true; // TO DO: fazer validação se concluiu
    this.modalController.dismiss({
      finished,
    });
  }

  async preloadSound() {
    await NativeAudio.preload({
      assetId: "beepAudio",
      assetPath: "beep-exercicio.mp3",
      audioChannelNum: 1,
      isUrl: false,
    });
  }

  // Aqui da o start no exercicio
  startTimer() {
    if (!this.exercise.tempo_exercicio) {
      this.startTimerDescanso();
      NativeAudio.play({
        assetId: "beepAudio",
        time: null,
      }),
        (error) => console.log("[error stop] => ", error);
    } else {
      this.progressbar.started = true;
      this.fimDescanso = false;
      setTimeout((x) => {
        // Caso o timer finalizar iniciamos o tempo de descanso
        if (this.progressbar.current_time <= 0) {
          if (this.seriesVar > 1) {
            this.progressbar.current_time = this.exercise.intervalo_descanso;
            this.progressbar.max_time = this.exercise.intervalo_descanso;
            this.progressbar.color = "#5cba47";
            this.progressbar.clockwise = true; // progress fica no sentido anti horario
            this.descanso = true;
            this.startTimerDescanso();
          } else {
            this.progressbar.finish = true;
          }
        } else if (this.progressbar.active) {
          // A cada dois segundos toca o beep
          if (
            this.progressbar.current_time % this.intervaloBeep === 0 &&
            this.beepSonoro
          ) {
            NativeAudio.play({
              assetId: "beepAudio",
              time: null,
            });
          }

          this.progressbar.current_time--;
          this.startTimer();
        }
      }, 1000);
    }
  }

  startTimerDescanso() {
    this.fimDescanso = false;
    this.progressbar.started = true;
    setTimeout((x) => {
      if (this.progressbar.current_time >= 0) {
        this.progressbar.current_time--;

        this.startTimerDescanso();
      } else {
        if (this.seriesVar > 1) {
          this.progressbar.current_time = this.exercise.tempo_exercicio
            ? this.exercise.tempo_exercicio
            : this.exercise.intervalo_descanso;
          this.progressbar.max_time = this.exercise.tempo_exercicio
            ? this.exercise.tempo_exercicio
            : this.exercise.intervalo_descanso;
          this.progressbar.color = "#ffcd07";
          this.progressbar.clockwise = false;
          this.descanso = false;
          this.fimDescanso = true;
          this.repetiu++;
          this.seriesVar--;

          // this.startTimer();
        } else {
          this.progressbar.finish = true;
        }
      }
    }, 1000);
  }

  async getItem() {
    this.analytics.trackEvent("exercise_open", {
      id: this.exercise?.id,
      title: this.exercise?.title,
    });

    const loading = await this.loadingController.create();
    await loading.present();

    this.progressbar.finish = false;
    this.progressbar.started = false;

    this.seriesVar = this.exercise.series;

    this.intervaloBeep = this.exercise.intervalo_beep
      ? this.exercise.intervalo_beep
      : 2; // defininfo intervalo do beep

    // Dados do progressbar
    this.progressbar.max_time = this.exercise.tempo_exercicio
      ? this.exercise.tempo_exercicio
      : this.exercise.intervalo_descanso;
    this.progressbar.current_time = this.exercise.tempo_exercicio
      ? this.exercise.tempo_exercicio
      : this.exercise.intervalo_descanso;

    if (this.exercise.tempo_exercicio) {
      this.progressbar.active = true;
    }

    this.exerciseTime = this.exercise.tempo_exercicio
      ? this.exercise.tempo_exercicio
      : "0";

    this.loading = false;
    await loading.dismiss();
  }

  handleBeep() {
    this.beepSonoro = !this.beepSonoro;
    if (!this.beepSonoro) {
      NativeAudio.stop({
        assetId: "beepAudio",
      });
    }
  }

  openFinishAlertExercise() {
    if (this.canShare) {
      this.openSharingFeedScreen();
    }
  }

  getFormatedExercise(exercise): Exercise {
    return {
      id: exercise.id,
      video: exercise?.vimeo?.video,
      image: exercise?.vimeo?.picture,
      title: exercise?.titulo,
      text: exercise?.descricao,
      instrutor: exercise?.instrutor,
      contentRating: Math.round(exercise?.average_rating) || 0,
      noWorkoutListed: true,
      is_favorite: exercise?.is_favorite,
      is_active_landscape: true,
    };
  }

  setUserHasPlayVideo() {
    this.userHasPlayVideo = true;
    console.log("Play setUserHasPlayVideo");
    this.exercise?.desafio_id &&
      this.store.dispatch(
        ContinueTrainingActions.setContinueTraining({
          payload: { id: this.exercise.desafio_id },
        }),
      );
  }

  // toast
  async presentToast(position: "top" | "middle" | "bottom") {
    if (this.exercise?.percentageCompleted === 1 || this.userHasPlayVideo)
      return;
    const toast = await this.toastController.create({
      message: "Você deve assistir a aula para finalizá-la.",
      duration: 2000,
      position: position,
      cssClass: "toast__alert_content",
    });

    await toast.present();
  }

  getUser() {
    this.user$ = this.store.select(LoginSelectors.getUser);
    this.user$.subscribe(
      (user) => {
        this.user = user;
      },
      (err) => {},
    );
  }

  // legacy app component
  async backButtonAlert() {
    const alert = await this.alertController.create({
      message: "Deseja sair do aplicativo?",
      buttons: [
        {
          text: "Cancelar",
          role: "Cancel",
        },
        {
          text: "Sim",
          handler: () => {
            navigator["app"].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }
}
