import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Premium } from 'src/app/models/premium';

// selectors
import * as PremiumSelectors from 'src/app/store/selectors/premium.selectors';

@Component({
  selector: 'btt-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
})
export class CardPreviewComponent implements OnInit {
  premiumStatus$: Observable<Premium>;

  @Input() image: string;
  @Input() title: string;

  @Input() showPlayButton = true;
  @Input() showOverlay = true;
  @Input() showTitle = true;

  @Input() centered = false;
  @Input() blockAccess: boolean = false;

  @Input() free: number = 1;
  @Input() gradient: boolean = false;
  @Input() footer: boolean = false;

  @Input() valueFooter?: string = '';

  @Input() size?: 'large' | 'medium' | 'small' | 'landscape' = 'large';
  @Input() scheduledTraining?: boolean = false;
  @Input() hideFreeContentTag?: boolean = false;

  @Input() showCheckMark?: boolean = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getPremium();
  }

  getPremium() {
    this.premiumStatus$ = this.store.select(PremiumSelectors.getPremiumStatus);
  }

  isFree() {
    return !Boolean(this.free);
  }
}
