import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/user';
import * as LoginActions from '../actions/login.actions';

export interface LoginState {
  credenciais: any;
  user: User;
}

const initialState: LoginState = {
  credenciais: {
    user: null,
    password: null,
  },
  user: {
    altura: null,
    apple_authorization_token: null,
    apple_identity_token: null,
    apple_user: null,
    bairro: null,
    cep: null,
    cidade: null,
    cidade_id: null,
    cpf: null,
    data_nascimento: null,
    data_nascimento_formatada: null,
    ddd: null,
    descricao: null,
    email: null,
    empresa: null,
    empresa_id: null,
    empresa_img: null,
    facebook: null,
    facebook_id: null,
    google_id: null,
    foto: null,
    social_photo: null,
    id: null,
    idade: null,
    isAnon: false,
    logradouro: null,
    name: null,
    numero: null,
    peso: null,
    player_id: null,
    sexo: null,
    subscriberId: null,
    telefone: null,
    termos_uso: null,
    uf: null,
    whatsapp: null,
  },
};

export const loginReducer = createReducer(
  initialState,
  on(LoginActions.userLogin, (state, { payload: credenciais }) => {
    return {
      ...state,
      credenciais,
    };
  }),
  on(LoginActions.getAutenticatedUserSuccess, (state, { payload: user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(LoginActions.logout, (state) => {
    return {
      ...initialState,
    };
  }),
);
