import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// actions
import * as ExerciseRatingActions from 'src/app/store/actions/exerciseRating.actions';

@Component({
  selector: 'app-btt-i-liked',
  templateUrl: './btt-i-liked.component.html',
  styleUrls: ['./btt-i-liked.component.scss'],
})
export class BttILikedComponent implements OnInit {
  @Input() isLiked: boolean = false;
  @Input() originId: number;
  @Input() origin: string;
  @Input() exerciseId: number;

  constructor(private store: Store) {}

  ngOnInit() {}

  handleLiked() {
    const payload = {
      origin: this.origin,
      originId: this.originId,
      exerciseId: this.exerciseId,
    };

    this.store.dispatch(ExerciseRatingActions.setLikedExercise({ payload }));
    this.isLiked = !this.isLiked;
  }
}
