import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setExerciseView = createAction(
  '[ExerciseView] Set ExerciseView',
  props<{ payload: any }>(),
);

export const setExerciseViewSuccess = createAction(
  '[ExerciseView] Set ExerciseView Success',
  props<{ payload: any }>(),
);

export const setExerciseViewError = createAction(
  '[ExerciseView] Set ExerciseView Error',
  props<{ payload: HttpErrorResponse }>(),
);
