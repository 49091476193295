import { Component, Input, OnInit } from '@angular/core';
import { ICompletedWorkoutsDay } from 'src/app/models/completedWorkoutsDay';

@Component({
  selector: 'app-btt-workout-day-item',
  templateUrl: './btt-workout-day-item.component.html',
  styleUrls: ['./btt-workout-day-item.component.scss'],
})
export class BttWorkoutDayItemComponent implements OnInit {
  @Input() dayWeekData: ICompletedWorkoutsDay;

  constructor() {}

  ngOnInit() {}

  formattedDay(auxDate: string) {
    let formattedDay = new Date(auxDate)
      .toLocaleString('pt-BR', { weekday: 'short' })
      .slice(0, 1)
      .toUpperCase();
    return formattedDay;
  }
}
