import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IScheduled } from 'src/app/models/scheduled';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'btt-filter-page-modalities',
  templateUrl: './btt-filter-page-modalities.component.html',
  styleUrls: ['./btt-filter-page-modalities.component.scss'],
})
export class BttFilterPageModalitiesComponent implements OnInit {
  @Input() showAuxiliarBar: boolean;
  @Input() listTabs: IScheduled[];
  tabSelected: string = 'all';

  @Input() sliderConfig = {
    slidesPerView: 4.5,
    spaceBetween: 8,
    centeredSlides: false,
    setWrapperSize: true,
  };
  constructor(private analytics: AnalyticsService, private router: Router) {}

  ngOnInit() {}

  openScheduled(training?: IScheduled) {
    this.analytics.trackEvent('open_scheduled_training', {
      name: training.nome,
    });

    this.router.navigateByUrl(`/home/training-scheduled/${training?.id}`, {
      state: {
        training,
      },
    });
  }
}
