import { createReducer, on } from "@ngrx/store";
import * as HomeActions from "../actions/home.actions";

export interface HomeState {
  banners: any[];
  recommended: any[];
  scheduledTraining: any[];
  modalities: any[];
  trainingPrograms: any[];

  // TO DO: criar um endpoint único que retorne todos os itens de uma vez só
}

const initialState: HomeState = {
  banners: [],
  recommended: [],
  scheduledTraining: [],
  modalities: [],
  trainingPrograms: [],
};

export const homeReducer = createReducer(
  initialState,
  on(HomeActions.getHomeSuccess, (state, { payload: home }) => {
    return {
      ...state,
    };
  }),
  on(HomeActions.getModalitiesSuccess, (state, { payload: modalities }) => {
    return {
      ...state,
      modalities,
    };
  })
);
