import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, flatMap, map, switchMap, take, tap } from "rxjs/operators";
import * as PartnersActions from "../actions/partners.actions";

import { PartnersService } from "src/app/services/partners.service";
import { Contact, Partner } from "src/app/shared/models/partner.model";
import { ContactType } from "src/app/shared/enum/contact";

@Injectable()
export class PartnersEffects {
  getPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnersActions.getPartners),
      switchMap(({ payload: { filter, partnerType } }) => {
        return this.partnersService.getPartners(filter, partnerType).pipe(
          map((res: any) => {
            return PartnersActions.getPartnersSuccess({
              payload: res.registros,
            });
          }),
          catchError((error) =>
            of(PartnersActions.getPartnersError({ payload: error }))
          )
        );
      })
    )
  );
  getPartnerDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnersActions.getPartnerDetail),
      switchMap(({ payload: { id, partnerType, companyType } }) => {
        return this.partnersService
          .getPartnerDetail(id, partnerType, companyType)
          .pipe(
            map((res: any) => {
              return PartnersActions.getPartnerDetailSuccess({
                payload: this.formatPartnerDetails(res.registro, partnerType),
              });
            }),
            catchError((error) =>
              of(PartnersActions.getPartnerDetailError({ payload: error }))
            )
          );
      })
    )
  );

  formatPartnerDetails(
    record,
    type: "parceiros" | "estabelecimentos"
  ): Partner {
    const contacts: Contact[] = this.getPartnerContacts(record);
    const subtitle = this.getPartnerSubtitle(record, type);
    return {
      id: record.id,
      title: record.nome,
      subtitle,
      description: record.descricao,
      image: record.img,
      voucher: record.voucher_online,
      contacts: contacts,
      address: record.endereco,
    };
  }

  getPartnerContacts(record) {
    const contacts: Contact[] = [];
    if (record.whatsapp) {
      contacts.push({
        icon: "logo-whatsapp",
        contact: record.whatsapp,
        type: ContactType.WHATSAPP,
      });
    }
    if (record.instagram) {
      contacts.push({
        icon: "logo-instagram",
        contact: record.instagram,
        type: ContactType.INSTAGRAM,
      });
    }
    if (record.facebook) {
      contacts.push({
        icon: "logo-facebook",
        contact: record.facebook,
        type: ContactType.FACEBOOK,
      });
    }
    if (record.linkedin) {
      contacts.push({
        icon: "logo-linkedin",
        contact: record.linkedin,
        type: ContactType.LINKEDIN,
      });
    }

    return contacts;
  }

  getPartnerSubtitle(record, type) {
    if (type === "parceiros") {
      if (record.papeis?.includes("personal")) {
        return `Personal CREF ${record.doc_pro ? record.doc_pro : ""}`;
      }
      if (record.papeis?.includes("nutricionistas parceiras")) {
        return `Nutricionista CRN ${record.doc_pro ? record.doc_pro : ""}`;
      }
    } else {
      return record.categoria;
    }
  }

  constructor(
    private actions$: Actions,
    private partnersService: PartnersService,
    private store: Store
  ) {}
}
