import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';

import * as LoginActions from 'src/app/store/actions/login.actions';
import * as LoginSelectors from 'src/app/store/selectors/login.selectors';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalController, PopoverController } from '@ionic/angular';
import { BttNotificationsWebComponent } from 'src/app/components/btt-notifications-web/btt-notifications-web.component';
import { BttHeaderUserOptionsComponent } from 'src/app/components/btt-header-user-options/btt-header-user-options.component';
import { SearchResultModalPage } from 'src/app/pages/_modals/search-result-modal/search-result-modal.page';

// actions
import * as SearchResultActions from 'src/app/store/actions/searchResult.actions';

@Component({
  selector: 'app-header-full-web',
  templateUrl: './header-full-web.component.html',
  styleUrls: ['./header-full-web.component.scss'],
})
export class HeaderFullWebComponent implements OnInit {
  hasNotifications = false;
  user$: Observable<User>;
  user: User;
  searchValue: string;

  @Input() extraImage?;
  @Input() sizeImage?: number;

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private store: Store,
    public popoverController: PopoverController,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.notificationsService.hasUnreaded.subscribe(
      (hasNotifications) => {
        this.hasNotifications = hasNotifications;
      },
      (err) => {},
    );
    this.getUser();
  }

  getUser() {
    this.store.dispatch(LoginActions.getAutenticatedUser());
    this.user$ = this.store.select(LoginSelectors.getUser);
    this.user$.subscribe((res) => {
      this.user = res;
    });
  }

  ngOnDestroy() {
    // this.notificationsService.hasUnreaded.unsubscribe();
  }

  async presentPopover(e: Event) {
    const popover = await this.popoverController.create({
      component: BttNotificationsWebComponent,
      event: e,
      cssClass: 'my_custom_popover',
      arrow: true,
      alignment: 'center',
      side: 'bottom',
    });

    await popover.present();
  }

  async presentPopoverMenu(e: Event) {
    const popover = await this.popoverController.create({
      component: BttHeaderUserOptionsComponent,
      event: e,
      cssClass: 'my_custom_popover_user_option',
      arrow: true,
      alignment: 'end',
      side: 'bottom',
    });

    await popover.present();
  }

  openNotifications() {
    this.router.navigateByUrl('/notifications');
  }

  setOpenProfile() {
    this.router.navigateByUrl('/home/profile-web', { replaceUrl: true });
  }

  async openSearchResult(event: any) {
    if (!event.target.value) return;
    this.searchValue = event.target.value;

    this.store.dispatch(
      SearchResultActions.getSearchResult({
        payload: event.target.value,
      }),
    );

    const searchResultModal = await this.modalController.create({
      component: SearchResultModalPage,
      componentProps: {
        searchValue: event.target.value,
      },
      cssClass: 'btt__modal_web_style_custom',
    });

    await searchResultModal.present();

    return setTimeout(() => (this.searchValue = ''), 100);
  }
}
