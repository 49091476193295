import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getCategory = createAction(
  '[Orientations] Get Category',
  props<{
    payload: {
      type: 'dicas' | 'receitas';
    };
  }>(),
);

export const getCategorySuccess = createAction(
  '[Orientations] Get Category Success',
  props<{ payload: any }>(),
);

export const getCategoryError = createAction(
  '[Orientations] Get Category Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getCategoryItem = createAction(
  '[Orientations] Get Category Item',
  props<{
    payload: {
      id: number;
      limit_popular?: number;
    };
  }>(),
);

export const getCategoryItemSuccess = createAction(
  '[Orientations] Get Category Item Success',
  props<{
    payload: {
      listItems: any[];
      popular: any[];
    };
  }>(),
);

export const getCategoryItemError = createAction(
  '[Orientations] Get Category Item Error',
  props<{ payload: HttpErrorResponse }>(),
);
