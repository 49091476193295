import { ContentCreator } from 'src/app/shared/models/exercise.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-see-too',
  templateUrl: './item-see-too.component.html',
  styleUrls: ['./item-see-too.component.scss'],
})
export class ItemSeeTooComponent implements OnInit {
  @Input() item: any;
  @Input() contentCreator: ContentCreator;

  @Input() lines = false;
  @Input() blockAccess: boolean = false;
  @Input() exercise: boolean = false;

  constructor() {}

  ngOnInit() {}
}
