import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-star-rating-input',
  templateUrl: './btt-star-rating-input.component.html',
  styleUrls: ['./btt-star-rating-input.component.scss'],
})
export class BttStarRatingInputComponent implements OnInit {
  @Output() ratingClick: EventEmitter<any> = new EventEmitter();

  @Input() size: 'small' | 'large' = 'small';

  @Input() rating: number = 0;

  constructor() {}

  ngOnInit() {}

  handleRatingClick(rate) {
    this.rating = rate + 1;
    this.ratingClick.emit(this.rating);
  }
}
