import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as NewCustomWorkoutActions from '../actions/newCustomWorkout.actions';
import * as LoadingActions from '../actions/loading.actions';

import { TrainingCustomService } from 'src/app/services/training-custom.service';
import { ToastController } from '@ionic/angular';
import { Exercicio } from 'src/app/models/trainingPlanCustom';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
import { Store } from '@ngrx/store';

@Injectable()
export class NewCustomWorkoutEffects {
  setNewCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService.setNewCustomWorkout(payload).pipe(
          map((res: any) => {
            if (res?.message) this.presentToast('bottom', res.message);
            return NewCustomWorkoutActions.setNewCustomWorkoutSuccess();
          }),
          catchError((error) =>
            of(
              NewCustomWorkoutActions.setNewCustomWorkoutError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setNewCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  setNewCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  getNewCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getNewCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(() => {
        return this.trainingCustomService.getNewCustomWorkout().pipe(
          map((res: any) => {
            let auxRes = res.treinos.length > 0 ? { ficha: res } : {};
            return NewCustomWorkoutActions.getNewCustomWorkoutSuccess({
              payload: auxRes,
            });
          }),
          catchError((error) =>
            of(
              NewCustomWorkoutActions.getNewCustomWorkoutError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getNewCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getNewCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  getNewCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getNewCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  setFinishedCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setFinishedCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService
          .setFinishedCustomWorkout(payload)
          .pipe(
            map((res: any) => {
              if (payload.percentage_completed === 1)
                this.presentToast('bottom', res.message);
              return NewCustomWorkoutActions.setFinishedCustomWorkoutSuccess();
            }),
            catchError((error) =>
              of(
                NewCustomWorkoutActions.setFinishedCustomWorkoutError({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  setFinishedCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setFinishedCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  setFinishedCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setFinishedCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  getExercisesByCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getExercisesByCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService
          .getExercisesByCustomWorkout(payload)
          .pipe(
            map((res: Exercicio[]) => {
              return NewCustomWorkoutActions.getExercisesByCustomWorkoutSuccess(
                {
                  payload: res,
                },
              );
            }),
            catchError((error) =>
              of(
                NewCustomWorkoutActions.getExercisesByCustomWorkoutError({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  getExercisesByCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getExercisesByCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  getExercisesByCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getExercisesByCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  getCompletedCustomWorkoutsWeek$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getCompletedCustomWorkoutsWeek),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT_COMPLETED,
          }),
        ),
      ),
      switchMap(() => {
        return this.trainingCustomService.getCompletedCustomWorkoutsWeek().pipe(
          map((res: any) => {
            return NewCustomWorkoutActions.getCompletedCustomWorkoutsWeekSuccess(
              {
                payload: res,
              },
            );
          }),
          catchError((error) =>
            of(
              NewCustomWorkoutActions.getCompletedCustomWorkoutsWeekError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getCompletedCustomWorkoutsWeekSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getCompletedCustomWorkoutsWeekSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT_COMPLETED,
        }),
      ),
    ),
  );

  getCompletedCustomWorkoutsWeekError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.getCompletedCustomWorkoutsWeekError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT_COMPLETED,
        }),
      ),
    ),
  );

  // -------------------------------------

  setNewExerciseInCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewExerciseInCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService
          .setNewExerciseInCustomWorkout(payload)
          .pipe(
            map((res: any) => {
              return NewCustomWorkoutActions.setNewExerciseInCustomWorkoutSuccess();
            }),
            catchError((error) =>
              of(
                NewCustomWorkoutActions.setNewExerciseInCustomWorkoutError({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  setNewExerciseInCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewExerciseInCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  setNewExerciseInCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setNewExerciseInCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  setEditExercisesInCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setEditExercisesInCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService
          .setEditExercisesInCustomWorkout(payload)
          .pipe(
            map((res: any) => {
              return NewCustomWorkoutActions.setEditExercisesInCustomWorkoutSuccess();
            }),
            catchError((error) =>
              of(
                NewCustomWorkoutActions.setEditExercisesInCustomWorkoutError({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  setEditExercisesInCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setEditExercisesInCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  setEditExercisesInCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setEditExercisesInCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // -------------------------------------

  setDeleteCustomWorkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setDeleteCustomWorkout),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
          }),
        ),
      ),
      switchMap(({ payload }) => {
        return this.trainingCustomService.setDeleteCustomWorkout(payload).pipe(
          map((res: any) => {
            if (res.message) this.presentToast('bottom', res.message);
            return NewCustomWorkoutActions.setDeleteCustomWorkoutSuccess();
          }),
          catchError((error) =>
            of(
              NewCustomWorkoutActions.setDeleteCustomWorkoutError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setDeleteCustomWorkoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setDeleteCustomWorkoutSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  setDeleteCustomWorkoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewCustomWorkoutActions.setDeleteCustomWorkoutError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.NEW_CUSTOM_WORKOUT,
        }),
      ),
    ),
  );

  // toast
  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: position,
      cssClass: 'toast__alert_content',
    });

    await toast.present();
  }

  constructor(
    private actions$: Actions,
    private trainingCustomService: TrainingCustomService,
    private toastController: ToastController,
    private store: Store,
  ) {}
}
