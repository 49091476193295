import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const getStates = createAction("[State City] Get States");

export const getStatesSuccess = createAction(
  "[State City] Get States Success",
  props<{ payload: any }>()
);

export const getStatesError = createAction(
  "[State City] Get States Error",
  props<{ payload: HttpErrorResponse }>()
);

export const getCities = createAction(
  "[State City] Get Cities",
  props<{ payload: number }>()
);

export const getCitiesSuccess = createAction(
  "[State City] Get Cities Success",
  props<{ payload: any }>()
);

export const getCitiesError = createAction(
  "[State City] Get Cities Error",
  props<{ payload: HttpErrorResponse }>()
);

// Partners Related
export const getStatesWhereHasPartners = createAction(
  "[State City] Get States Where has Partners"
);

export const getStatesWhereHasPartnersSuccess = createAction(
  "[State City] Get States Where has Partners Success",
  props<{ payload: any }>()
);

export const getStatesWhereHasPartnersError = createAction(
  "[State City] Get States Where has Partners Error",
  props<{ payload: HttpErrorResponse }>()
);

export const getCitiesWhereHasPartners = createAction(
  "[State City] Get Cities Where has Partners",
  props<{ payload: { stateId: number; partnerType: string } }>()
);

export const getCitiesWhereHasPartnersSuccess = createAction(
  "[State City] Get Cities Where has Partners Success",
  props<{ payload: any }>()
);

export const getCitiesWhereHasPartnersError = createAction(
  "[State City] Get Cities Where has Partners Error",
  props<{ payload: HttpErrorResponse }>()
);
