import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setNewCustomWorkout = createAction(
  '[New Custom Workout] Set New Custom Workout',
  props<{ payload: any }>(),
);

export const setNewCustomWorkoutSuccess = createAction(
  '[New Custom Workout] Set New Custom Workout Success',
);

export const setNewCustomWorkoutError = createAction(
  '[New Custom Workout] Set New Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const getNewCustomWorkout = createAction(
  '[New Custom Workout] Get New Custom Workout',
);

export const getNewCustomWorkoutSuccess = createAction(
  '[New Custom Workout] Get New Custom Workout Success',
  props<{ payload: any }>(),
);

export const getNewCustomWorkoutError = createAction(
  '[New Custom Workout] Get New Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const setFinishedCustomWorkout = createAction(
  '[Finished Custom Workout] Set Finished Custom Workout',
  props<{ payload: any }>(),
);

export const setFinishedCustomWorkoutSuccess = createAction(
  '[Finished Custom Workout] Set Finished Custom Workout Success',
);

export const setFinishedCustomWorkoutError = createAction(
  '[Finished Custom Workout] Set Finished Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const getExercisesByCustomWorkout = createAction(
  '[Exercises By Custom Workout] Get Exercises By Custom Workout',
  props<{ payload: any }>(),
);

export const getExercisesByCustomWorkoutSuccess = createAction(
  '[Exercises By Custom Workout] Get Exercises By Custom Workout Success',
  props<{ payload: any }>(),
);

export const getExercisesByCustomWorkoutError = createAction(
  '[Exercises By Custom Workout] Get Exercises By Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const getCompletedCustomWorkoutsWeek = createAction(
  '[Completed Custom Workout Week] Get Completed Custom Workout Week',
);

export const getCompletedCustomWorkoutsWeekSuccess = createAction(
  '[Completed Custom Workout Week] Get Completed Custom Workout Week Success',
  props<{ payload: any }>(),
);

export const getCompletedCustomWorkoutsWeekError = createAction(
  '[Completed Custom Workout Week] Get Completed Custom Workout Week Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const setNewExerciseInCustomWorkout = createAction(
  '[New Exercise In Custom Workout] Set New Exercise In Custom Workout',
  props<{ payload: any }>(),
);

export const setNewExerciseInCustomWorkoutSuccess = createAction(
  '[New Exercise In Custom Workout] Set New Exercise In Custom Workout Success',
);

export const setNewExerciseInCustomWorkoutError = createAction(
  '[New Exercise In Custom Workout] Set New Exercise In Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const setDeleteCustomWorkout = createAction(
  '[Delete Custom Workout] Set Delete Custom Workout',
  props<{ payload: any }>(),
);

export const setDeleteCustomWorkoutSuccess = createAction(
  '[Delete Custom Workout] Set Delete Custom Workout Success',
);

export const setDeleteCustomWorkoutError = createAction(
  '[Delete Custom Workout] Set Delete Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);

// =================================================

export const setEditExercisesInCustomWorkout = createAction(
  '[Edit Exercises In Custom Workout] Set Edit Exercises In Custom Workout',
  props<{ payload: any }>(),
);

export const setEditExercisesInCustomWorkoutSuccess = createAction(
  '[Edit Exercises In Custom Workout] Set Edit Exercises In Custom Workout Success',
);

export const setEditExercisesInCustomWorkoutError = createAction(
  '[Edit Exercises In Custom Workout] Set Edit Exercises In Custom Workout Error',
  props<{ payload: HttpErrorResponse }>(),
);
