import { Injectable } from '@angular/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';

@Injectable({
  providedIn: 'root',
})
export class AuthFacebookService {
  constructor() {}

  async getCurrentState(): Promise<boolean> {
    const result = await FacebookLogin.getCurrentAccessToken().catch(
      () => undefined,
    );
    return !(result === undefined || !result.hasOwnProperty('accessToken'));
  }

  async signIn(): Promise<any> {
    const FACEBOOK_PERMISSIONS = [
      'email',
      'public_profile',
      // "user_photos",
      // "user_gender",
    ];

    const result = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });
    console.log(result);
    if (result && result.accessToken) {
      console.log(`Facebook access token is ${result.accessToken.token}`);
      //   this.navCtrl.navigateRoot(['/']);
    }

    return result.accessToken;
  }

  async getUserData(): Promise<any> {
    const result = await FacebookLogin.getProfile({
      fields: ['name', 'email', 'id'],
    });
    console.log('getUserData: ', result);
    return result;
  }

  async logoutFacebook(): Promise<any> {
    console.log('[LOGOUT FACEBOOK]');
    await FacebookLogin.logout();
  }
}
