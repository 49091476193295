import { createAction, props } from '@ngrx/store';

export const exibirLoading = createAction(
  '[Loading] Exibir Loading',
  props<{ payload: any }>(),
);

export const ocultarLoading = createAction(
  '[Loading] Ocultar Loading',
  props<{ payload: any }>(),
);
