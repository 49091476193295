import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "btt-page-title",
  templateUrl: "./btt-page-title.component.html",
  styleUrls: ["./btt-page-title.component.scss"],
})
export class BttPageTitleComponent implements OnInit {
  @Input() pageTitle: string = "Menu";
  @Input() showTitle: boolean = true;
  @Input() showButtonClose: boolean = false;
  @Input() previowsPage: string = "";
  @Input() useNavControlBack: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit() {}

  back() {
    if (this.useNavControlBack) {
      this.onClick.emit();
    } else {
      this.router.navigate([this.previowsPage]);
      this.router.navigateByUrl(this.previowsPage, {
        replaceUrl: true,
      });
    }
  }
}
