import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getMyFavoriteContent = createAction(
  '[My Favorite Content] Get My Favorite Content',
);

export const getMyFavoriteContentSuccess = createAction(
  '[My Favorite Content] Get My Favorite Content Success',
  props<{ payload: any }>(),
);

export const getMyFavoriteContentError = createAction(
  '[My Favorite Content] Get My Favorite Content Error',
  props<{ payload: HttpErrorResponse }>(),
);
