import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Slide } from 'src/app/shared/models/slide.model';

@Component({
  selector: "btt-slider-cards",
  templateUrl: "./slider-cards.component.html",
  styleUrls: ["./slider-cards.component.scss"]
})
export class SliderCardsComponent implements OnInit {
  @Input() items: Slide[];

  @Input() sliderConfig = {
    slidesPerView: 1.3333333,
    centeredSlides: false,
    spaceBetween: 10,
    setWrapperSize: true
  };

  @Input() cardShowPlayButton = true;
  @Input() cardShowOverlay = true;
  @Input() cardShowTitle = true;

  @Input() cardCentered = false;
  @Input() size?: "large" | "small" | "landscape";

  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  @Input() scheduledTraining?: boolean = false;

  @Input() gradient?: boolean = false;
  @Input() footer?: boolean = false;

  @Input() valueFooter?: string = "";

  @Input() hideFreeContentTag?: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onItemClicked(item) {
    this.itemClick.emit(item);
  }
}
