import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getScheduledTraining = createAction(
  '[ScheduledTraining] Get ScheduledTraining',
);

export const getScheduledTrainingSuccess = createAction(
  '[ScheduledTraining] Get ScheduledTraining Success',
  props<{ payload: any }>(),
);

export const getScheduledTrainingError = createAction(
  '[ScheduledTraining] Get ScheduledTraining Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getScheduledTrainingExercise = createAction(
  '[ScheduledTraining] Get ScheduledTraining Exercise',
  props<{
    payload: {
      date: string;
    };
  }>(),
);

export const getScheduledTrainingExerciseSuccess = createAction(
  '[ScheduledTraining] Get ScheduledTraining Exercise Success',
  props<{ payload: any }>(),
);

export const getScheduledTrainingExerciseError = createAction(
  '[ScheduledTraining] Get ScheduledTraining Exercise Error',
  props<{ payload: HttpErrorResponse }>(),
);
