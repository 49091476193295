import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as CategoriesRecipesActions from '../actions/categoriesrecipes.actions';

import { CategoryService } from 'src/app/services/category.service';

@Injectable()
export class CategoriesRecipesEffects {
  getCategoryRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesRecipesActions.getCategoryRecipes),
      switchMap(({ payload: { type } }) => {
        return this.categoryService.getCategory(type).pipe(
          map((res: any) => {
            return CategoriesRecipesActions.getCategoryRecipesSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              CategoriesRecipesActions.getCategoryRecipesError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getCategoryRecipesItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesRecipesActions.getCategoryRecipesItem),
      switchMap(({ payload: { id } }) => {
        return this.categoryService.getCategoryItem(id).pipe(
          map((res: any) => {
            return CategoriesRecipesActions.getCategoryRecipesItemSuccess({
              payload: res.receitas,
            });
          }),
          catchError((error) =>
            of(
              CategoriesRecipesActions.getCategoryRecipesItemError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private categoryService: CategoryService,
    private store: Store,
  ) {}
}
