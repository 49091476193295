import { createReducer, on } from "@ngrx/store";
import * as FeedsActions from "../actions/feed.actions";

import {
  IBlockedUser,
  IFeed,
  IFeedData,
  IInteractionFeedPage,
  IMessagesFeed,
  IReactions,
  IReportMessage,
  IUpdateReactions,
} from "src/app/models/feed";

// interfaces
export interface IBlockedUsersState {
  blockedUsers: IBlockedUser[];
  loading: boolean;
}

export interface IFeedState {
  allFeedsItems: IFeed;
  feedItem: IFeed;
  loading: boolean;
}

export interface IInteractionsState {
  interactions: IInteractionFeedPage;
  loading: boolean;
}

export interface IReactionsState {
  reactions: IReactions[];
  loading: boolean;
}

export interface IMessagesState {
  messages: IMessagesFeed[];
  loading: boolean;
}

export interface ISetFeedState {
  loading: boolean;
}

export interface IinitialReportMessages {
  messages: IReportMessage[];
  loading: boolean;
}

// initial states

const initialStateBlockedUsers: IBlockedUsersState = {
  blockedUsers: [],
  loading: false,
};

const initialStateFeed: IFeedState = {
  allFeedsItems: null,
  feedItem: null,
  loading: false,
};

const initialStateInteraction: IInteractionsState = {
  interactions: null,
  loading: false,
};

const initialStateReaction: IReactionsState = {
  reactions: [],
  loading: false,
};

const initialStateMessages: IMessagesState = {
  messages: [],
  loading: false,
};

const initialStateSetFeed: ISetFeedState = {
  loading: false,
};

const initialReportMessages: IinitialReportMessages = {
  messages: [],
  loading: false,
};

function updateReactionFeed(update: IUpdateReactions, allFeedsItems: IFeed) {
  const aux = allFeedsItems.data.map((item) => {
    if (item?.id == update?.feed_id) {
      return {
        ...item,
        interaction_id: update?.id,
        reaction: {
          id: update?.reaction?.id,
          icon: update?.reaction?.icon,
          name: update?.reaction?.name,
        },
      };
    } else {
      return item;
    }
  });
  return { ...allFeedsItems, data: aux };
}

function deleteReactionFeed(feed_id: number, allFeedsItems: IFeed) {
  const aux = allFeedsItems.data.map((item) => {
    if (item?.id == feed_id) {
      return {
        ...item,
        interaction_id: null,
        reaction: null,
      };
    } else {
      return item;
    }
  });
  return { ...allFeedsItems, data: aux };
}

function deletePostFeed(feed_id: number, allFeedsItems: IFeed) {
  const aux = allFeedsItems.data.filter((item) => item.id != feed_id);
  return { ...allFeedsItems, data: aux };
}

export const feedsReducer = createReducer(
  initialStateFeed,
  on(FeedsActions.getFeeds, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getFeedsSuccess, (state, { payload: allFeedsItems }) => {
    return {
      ...state,
      allFeedsItems,
      loading: false,
    };
  }),
  on(FeedsActions.getFeedsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(FeedsActions.getMyFeed, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getMyFeedSuccess, (state, { payload: allFeedsItems }) => {
    return {
      ...state,
      allFeedsItems,
      loading: false,
    };
  }),
  on(FeedsActions.getMyFeedError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(FeedsActions.getFeedByID, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getFeedByIDSuccess, (state, { payload: feedItem }) => {
    return {
      ...state,
      feedItem,
      loading: false,
    };
  }),
  on(FeedsActions.getFeedByIDError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(FeedsActions.setReactions, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.setReactionsSuccess, (state, { payload: allFeedsItems }) => {
    return {
      ...state,
      allFeedsItems: updateReactionFeed(allFeedsItems, state.allFeedsItems),
      loading: false,
    };
  }),
  on(FeedsActions.setReactionsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(FeedsActions.updateReactions, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    FeedsActions.updateReactionsSuccess,
    (state, { payload: allFeedsItems }) => {
      return {
        ...state,
        allFeedsItems: updateReactionFeed(allFeedsItems, state.allFeedsItems),
        loading: false,
      };
    },
  ),
  on(FeedsActions.updateReactionsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(FeedsActions.deleteReactions, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.deleteReactionsSuccess, (state, { payload: feed_id }) => {
    return {
      ...state,
      allFeedsItems: deleteReactionFeed(feed_id, state.allFeedsItems),
      loading: false,
    };
  }),
  on(FeedsActions.deleteReactionsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(FeedsActions.setHidePost, (state, { payload: { postId } }) => {
    let auxAllFeedsItemsData = state.allFeedsItems.data.map((post) => {
      if (post?.id === postId) return { ...post, hideContent: true };
      return post;
    });

    return {
      ...state,
      allFeedsItems: {
        ...state.allFeedsItems,
        data: auxAllFeedsItemsData,
      },
    };
  }),
  on(FeedsActions.setShowPost, (state, { payload: { postId } }) => {
    let auxAllFeedsItemsData = state.allFeedsItems.data.map((post) => {
      if (post?.id === postId) return { ...post, hideContent: false };
      return post;
    });

    return {
      ...state,
      allFeedsItems: {
        ...state.allFeedsItems,
        data: auxAllFeedsItemsData,
      },
    };
  }),
);

export const interactionsReducer = createReducer(
  initialStateInteraction,
  on(FeedsActions.getInteractions, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    FeedsActions.getInteractionsSuccess,
    (state, { payload: interactions }) => {
      return {
        ...state,
        interactions,
        loading: false,
      };
    },
  ),
  on(FeedsActions.getInteractionsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);

export const reactionsReducer = createReducer(
  initialStateReaction,
  on(FeedsActions.getReactions, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getReactionsSuccess, (state, { payload: reactions }) => {
    return {
      ...state,
      reactions,
      loading: false,
    };
  }),
  on(FeedsActions.getReactionsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);

export const messagesReducer = createReducer(
  initialStateMessages,
  on(FeedsActions.getMessages, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getMessagesSuccess, (state, { payload: messages }) => {
    return {
      ...state,
      messages,
      loading: false,
    };
  }),
  on(FeedsActions.getMessagesError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);

export const setFeedReducer = createReducer(
  initialStateSetFeed,
  on(FeedsActions.setFeed, (state) => {
    return {
      loading: true,
    };
  }),
  on(FeedsActions.setFeedSuccess, (state, { payload: sucss }) => {
    return {
      loading: false,
    };
  }),
  on(FeedsActions.setFeedError, (state, { payload: err }) => {
    return {
      loading: false,
    };
  }),

  on(FeedsActions.updateFeed, (state) => {
    return {
      loading: true,
    };
  }),
  on(FeedsActions.updateFeedSuccess, (state, { payload: sucss }) => {
    return {
      loading: false,
    };
  }),
  on(FeedsActions.updateFeedError, (state, { payload: err }) => {
    return {
      loading: false,
    };
  }),
);

export const getReportMessagesReducer = createReducer(
  initialReportMessages,
  on(FeedsActions.getReportMessages, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(FeedsActions.getReportMessagesSuccess, (state, { payload: messages }) => {
    return {
      ...state,
      loading: false,
      messages,
    };
  }),
  on(FeedsActions.getReportMessagesError, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
);

export const getBlockedUsersReducer = createReducer(
  initialStateBlockedUsers,
  // get blocked users
  on(FeedsActions.getBlockedUsers, (state) => {
    return { ...state, loading: true };
  }),
  on(
    FeedsActions.getBlockedUsersSuccess,
    (state, { payload: blockedUsers }) => {
      return { ...state, blockedUsers, loading: false };
    },
  ),
  on(FeedsActions.getBlockedUsersSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(
    FeedsActions.setRemoveBlockedUserFromStore,
    (state, { payload: userId }) => {
      let auxBlockedUsers = [...state.blockedUsers];
      let idxUser = auxBlockedUsers?.findIndex((user) => user.id === userId);
      auxBlockedUsers.splice(idxUser, 1);
      return { ...state, blockedUsers: auxBlockedUsers };
    },
  ),
  on(
    FeedsActions.setAddBlockedUserInStore,
    (state, { payload: { userIdx, blockedUser } }) => {
      let auxBlockedUsers = [...state.blockedUsers];
      auxBlockedUsers.splice(userIdx, 0, blockedUser);
      return { ...state, blockedUsers: auxBlockedUsers };
    },
  ),
);
