import { createReducer, on } from "@ngrx/store";
import * as AppConfigsActions from "../actions/appConfigs.actions";

export interface IAppConfigs {
  termos_uso: string;
  numero_contato: string;
  informacao_calendario: string;
  guidelines_link: string;
}

export interface AppConfigsState {
  appConfigs: IAppConfigs;
}

const initialState: AppConfigsState = {
  appConfigs: null,
};

export const appConfigsReducer = createReducer(
  initialState,
  on(
    AppConfigsActions.getAppConfigsSuccess,
    (state, { payload: appConfigs }) => {
      return {
        ...state,
        appConfigs,
      };
    },
  ),
);
