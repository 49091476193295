import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-card-categories',
  templateUrl: './skeleton-card-categories.component.html',
  styleUrls: ['./skeleton-card-categories.component.scss'],
})
export class SkeletonCardCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
