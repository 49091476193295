import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setProfilePicture = createAction(
  '[ProfilePicture] Set ProfilePicture',
  props<{ payload: any }>()
);

export const setProfilePictureSuccess = createAction(
  '[ProfilePicture] Set ProfilePicture Success',
  props<{ payload: any }>()
);

export const setProfilePictureError = createAction(
  '[ProfilePicture] Set ProfilePicture Error',
  props<{ payload: HttpErrorResponse }>()
);
