import { createReducer, on } from '@ngrx/store';
import * as OrientationsActions from '../actions/orientations.actions';

export interface OrientationsState {
  nutritionItems: any[];
  nutritionItemsCategory: any[];
  popularItems: any[];
  currentNutritionItem: any;
  loading: boolean;
}

const initialState: OrientationsState = {
  nutritionItems: [],
  nutritionItemsCategory: [],
  popularItems: [],
  currentNutritionItem: null,
  loading: false,
};

export const orientationsReducer = createReducer(
  initialState,
  on(OrientationsActions.getNutritionItems, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    OrientationsActions.getNutritionItemsSuccess,
    (state, { payload: nutritionItems }) => {
      return {
        ...state,
        nutritionItems,
        loading: false,
      };
    },
  ),
  on(OrientationsActions.getNutritionItemsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(OrientationsActions.getNutritionItemsCategory, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    OrientationsActions.getNutritionItemsCategorySuccess,
    (state, { payload: nutritionItemsCategory }) => {
      return {
        ...state,
        nutritionItemsCategory,
        loading: false,
      };
    },
  ),
  on(
    OrientationsActions.getNutritionItemsCategoryError,
    (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
      };
    },
  ),

  on(OrientationsActions.getNutritionItem, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    OrientationsActions.getNutritionItemSuccess,
    (state, { payload: currentNutritionItem }) => {
      return {
        ...state,
        currentNutritionItem,
        loading: false,
      };
    },
  ),
  on(OrientationsActions.getNutritionItemError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(OrientationsActions.getPopularItems, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    OrientationsActions.getPopularItemsSuccess,
    (state, { payload: popularItems }) => {
      return {
        ...state,
        popularItems,
        loading: false,
      };
    },
  ),
  on(OrientationsActions.getPopularItemsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);
