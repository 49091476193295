import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';

import { MustMatch } from 'src/app/_helpers/must-match.validator';

import {
  cellPhoneValidator,
  getDDD,
  getPhone,
} from 'src/app/_helpers/phone-check.validator';

import { AnalyticsService } from 'src/app/services/analytics.service';

import * as StatesCitiesSelectors from 'src/app/store/selectors/state-city.selectors';
import * as StatesCitiesActions from 'src/app/store/actions/state-city.actions';

import { Observable } from 'rxjs';
import { CidadeIbge, EstadoIbge } from 'src/app/models';
import { Store } from '@ngrx/store';
import { AdhesisonContractModalPage } from '../_modals/adhesison-contract-modal/adhesison-contract-modal.page';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  userData: FormGroup;
  errorMessage: any;

  statesList$: Observable<EstadoIbge[]>;
  citiesList$: Observable<CidadeIbge[]>;

  termAccept = false;

  @Input() company: any;
  @Input() formCompany: any;
  @Input() externalData: any; // used for external vinculation (Synsuite | NEOTV)

  constructor(
    navParams: NavParams,
    public viewCtrl: ModalController,
    private fb: FormBuilder,
    private auth: AuthService,
    private userService: UserService,
    private alertController: AlertController,
    public toastController: ToastController,
    private router: Router,
    private loadingController: LoadingController,
    private analytics: AnalyticsService,
    private store: Store,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.analytics.trackScreen('register', 'RegisterScreen');

    this.initForm();
    this.getStatesList();
  }
  closeModal() {
    this.viewCtrl.dismiss();
  }

  async register() {
    const loading = await this.loadingController.create();
    await loading.present();

    this.errorMessage = null;

    const userData: any = {
      name: this.userData.value.name,
      email: this.userData.value.email,
      // data_nascimento: this.userData.value.date_birth,
      cpf: this.userData.value.cpf_cnpj,
      ddd: getDDD(this.userData.value.phone),
      telefone: getPhone(this.userData.value.phone),
      // uf: this.userData.value.uf,
      // cidade: this.userData.value.cidade
    };

    if (!this.externalData) {
      userData.senha = this.userData.value.password;
      userData.senha_confirmation = this.userData.value.password_confirmation;
    }

    // if is user with subscriberId
    if (this.externalData && this.company) {
      console.log('externalData && company = true');
      if (this.externalData.subscriberId) {
        userData.subscriberId = this.externalData.subscriberId;
      }
      if (this.externalData.login_data) {
        userData.interfocus_idassinante =
          this.externalData.login_data.idassinante;
        userData.interfocus_chavebase = this.externalData.login_data.chavebase;
      }
      if (this.company.tipos_vinculacao.includes("7az")) {
          userData._7az_username = this.externalData.user.username;
          userData._7az_password = this.externalData.user.password;
      }
      userData.empresa_id = this.company.id;
      console.log('userData: ', userData);
    }

    this.auth.register(userData).subscribe(
      async (res) => {
        if (!res.erro) {
          this.analytics.trackEvent('sign_up', {
            method: this.company ? 'partner' : 'email',
          });

          console.log(res);

          if (this.externalData) {
            this.loginByExternalKey(this.externalData, loading);
            return;
          }

          this.performLogin(loading);
        } else {
          this.errorMessage = '';
          Object.keys(res).forEach((key, index) => {
            if (key !== 'erro') {
              this.errorMessage += '- ' + res[key][0] + '<br>';
            }
          });
          await loading.dismiss();
        }
      },
      async (res) => {
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Cadastro falhou',
          message: res.error.error,

          buttons: ['OK'],
        });

        await alert.present();
      },
    );
  }

  initForm() {
    this.userData = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // date_birth: ['', [Validators.required]],
      cpf_cnpj: [''],
      phone: ['', [cellPhoneValidator, Validators.required]],
      // uf: ['', [Validators.required]],
      // cidade: ['', [Validators.required]],
    });

    if (!this.externalData) {
      const passwordValidators = [Validators.minLength(6), Validators.required];

      this.userData = this.fb.group({
        ...this.userData.controls,
        password: ['', passwordValidators],
        password_confirmation: ['', passwordValidators],
      });
    }

    // console.log('company: ', this.company);
    // console.log('companyProps: ', this.formCompany);
    if (this.company) {
      if (this.formCompany) {
        const value: any = [];
        value[this.formCompany._campoVinculacao] = this.formCompany.chave;
        if (this.formCompany._campoVinculacao === 'cpf') {
          value.cpf_cnpj = this.formCompany.chave;
        }
        console.log('[VALUE DE ENVIO DOS DADOS] ==> ', value);

        this.userData.patchValue(value);
        console.log(this.userData);
      }
    }
  }

  getStatesList() {
    this.store.dispatch(StatesCitiesActions.getStates());
    this.statesList$ = this.store.select(StatesCitiesSelectors.getStates);
  }

  getCitiesList() {
    const uf = this.userData.value.uf;

    this.store.dispatch(StatesCitiesActions.getCities({ payload: uf }));
    this.citiesList$ = this.store.select(StatesCitiesSelectors.getCities);
  }

  async performLogin(loading) {
    const loginData = {
      username: this.email.value,
      password: this.password.value,
    };
    this.auth.login(loginData).subscribe(
      async (response) => {
        if (this.company) {
          this.vinculateCompany();
        }
        await loading.dismiss();
        this.openHome();
      },
      async (error) => {
        console.error(error);
        await loading.dismiss();
      },
    );
  }

  loginByExternalKey(externalData, loading) {
    if (externalData.subscriberId) {
      this.performLoginSubscriber(externalData.subscriberId, loading);
      return;
    }
    if (externalData.login_data) {
      this.loginInterFocusData(externalData.login_data, loading);
      return;
    }
  }

  async performLoginSubscriber(subscriberId, loading) {
    this.auth.loginSubscriber(this.company.id, subscriberId).subscribe(
      async (res) => {
        await loading.dismiss();
        if (res.success) {
          this.openHome();
        }
      },
      async (err) => {
        await loading.dismiss();
      },
    );
  }

  /**
   * Obtain User Token from subscriberId
   */
  loginInterFocusData(loginData, loading) {
    this.auth.loginInterFocusData(this.company.id, loginData).subscribe(
      async (res) => {
        await loading.dismiss();
        if (res.success) {
          this.openHome();
        }
      },
      async (err) => {
        // this.presentAlert("Oops!", "Algo deu errado no seu login.");
        await loading.dismiss();
      },
    );
  }

  async openHome() {
    await this.auth.loadToken();
    await this.userService.loadUser();
    this.router.navigateByUrl('/personality', {
      replaceUrl: true,
    });
    // this.router.navigateByUrl('/home', {
    //   replaceUrl: true,
    // });
    this.closeModal();
  }

  /**
   * Metodo que irá realizar a ação ao concordar com o formulário
   * @author André Ramos
   */
  handleTerm(checked) {
    this.termAccept = checked;
  }

  // Contract Adhesion Modal
  async openContractModal() {
    const contractModal = await this.modalController.create({
      component: AdhesisonContractModalPage,
      cssClass: 'modal-full',
    });
    contractModal.onWillDismiss().then(async (res) => {
      this.termAccept = res.data.accepted;
    });
    return await contractModal.present();
  }

  vinculateCompany() {
    const data = {
      cpf: this.userData.value.cpf_cnpj,
      provedor_codigo: this.company.nome,
    };

    this.userService.vinculateCompany(data).subscribe(
      async (res) => {
        if (res.premium) {
          this.analytics.trackEvent('partner_vinculation', {
            partner_name: this.company.nome,
          });

          this.userService.loadUser();
          this.presentToast(
            'Cadastro concluído com sucesso, você agora é PREMIUM. Bom treino!',
          );
        } else {
          const alert = await this.alertController.create({
            header: 'Cadastro Concluído',
            message:
              "O seu cadastro foi concluído com sucesso, mas parece que algo deu errado na vinculação com a sua operadora. Tente novamente pelo menu 'Ajustes -> Vincular-se ao parceiro'",

            buttons: ['OK'],
          });

          await alert.present();
        }
      },
      async (err) => {
        const alert = await this.alertController.create({
          header: 'Cadastro Concluído',
          message:
            "O seu cadastro foi concluído com sucesso, mas parece que algo deu errado na vinculação com a sua operadora. Tente novamente pelo menu 'Ajustes -> Vincular-se ao parceiro'",

          buttons: ['OK'],
        });

        await alert.present();
      },
    );
  }

  // Easy access for form fields
  get name() {
    return this.userData.get('name');
  }
  get date_birth() {
    return this.userData.get('date_birth');
  }
  get cpf_cnpj() {
    return this.userData.get('cpf_cnpj');
  }
  get phone() {
    return this.userData.get('phone');
  }
  get email() {
    return this.userData.get('email');
  }
  get password() {
    return this.userData.get('password');
  }
  get password_confirmation() {
    return this.userData.get('password_confirmation');
  }
  get uf() {
    return this.userData.get('uf');
  }
  get cidade() {
    return this.userData.get('cidade');
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      color: 'dark',
    });
    toast.present();
  }
}
