import { Exercicio } from 'src/app/models/trainingPlanCustom';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

// models
import { Treino } from 'src/app/models/workoutSheet';

// actions
import * as FinishedWorkoutActions from 'src/app/store/actions/finishedWorkout.actions';
import * as WorkoutSheetsActions from 'src/app/store/actions/workoutSheets.actions';
import * as NewCustonWorkoutActions from 'src/app/store/actions/newCustomWorkout.actions';
import * as CustomPremiumActions from 'src/app/store/actions/customPremium.actions';

import { BttAlertActionComponent } from '../btt-alert-action/btt-alert-action.component';
import { WorkoutsOrder } from 'src/app/pages/home/training-plan/custom/utils';
@Component({
  selector: 'app-btt-training-sheet-content-card',
  templateUrl: './btt-training-sheet-content-card.component.html',
  styleUrls: ['./btt-training-sheet-content-card.component.scss'],
})
export class BttTrainingSheetContentCardComponent implements OnInit {
  @Input() sheetValue: any;
  @Input() currentWorkout?: Treino;
  @Output() getCompletedWorkoutsWeek: EventEmitter<any> = new EventEmitter();
  @Input() customSheet: boolean = false;
  @Input() isBlocked: boolean = false;

  selectedSliderColor: string;

  constructor(
    private store: Store,
    private router: Router,
    private loadingController: LoadingController,
    private modalController: ModalController,
  ) {}

  ngOnInit() {}

  async openTrainingPage(setTrainingSheet?: any) {
    const loading = await this.loadingController.create();

    if (this.isBlocked) {
      loading.dismiss();
      return this.handleBlockedContent();
    }
    if (await this.hasCurrentWorkout(setTrainingSheet))
      return loading.dismiss();

    const payload = {
      id: setTrainingSheet.id,
      percentage_completed: 0,
    };

    if (this.customSheet) {
      this.store.dispatch(
        NewCustonWorkoutActions.setFinishedCustomWorkout({ payload }),
      );
      this.store.dispatch(NewCustonWorkoutActions.getNewCustomWorkout());
    } else {
      this.store.dispatch(
        FinishedWorkoutActions.setFinishedWorkout({ payload }),
      );
      this.store.dispatch(WorkoutSheetsActions.getWorkoutSheets());
    }

    this.getCompletedWorkoutsWeek.emit();

    this.router
      .navigateByUrl('/home/training-plan/my-workout-bitt-training', {
        state: {
          trainingSheet: setTrainingSheet,
          customSheet: this.customSheet,
        },
      })
      .then(() => loading.dismiss());
  }

  async hasCurrentWorkout(setTrainingSheet: any) {
    if (this.currentWorkout && setTrainingSheet.id !== this.currentWorkout.id) {
      const modal = await this.modalController.create({
        component: BttAlertActionComponent,
        cssClass: 'modal-rate',
        componentProps: {
          title: `Você não finalizou a ficha ${this.currentWorkout.order}. Deseja continuar?`,
          subTitle: 'Seu progresso atual não será salvo.',
          buttonText: 'Continuar',
          localIcon: 'assets/custom-icon/btt-icon-warning.svg',
        },
      });
      await modal.present();

      const { role } = await modal.onWillDismiss();

      if (role === 'confirm') return false;

      return true;
    }

    return false;
  }

  handleBlockedContent() {
    this.openPremiumPage();
  }

  openPremiumPage() {
    this.store.dispatch(CustomPremiumActions.getCustomPremium());
  }

  formattedMuscleGroup(sheetValue) {
    if (this.customSheet)
      return `${sheetValue?.exercicios?.length} exercícios aplicados`;

    const formattedText = sheetValue.grupo_muscular?.map(
      (group) => ` ${group}`,
    );
    return formattedText;
  }

  userHasDrag(event: any) {
    if (event.detail.ratio > 0)
      this.selectedSliderColor = 'var(--ion-color-danger)';
    if (event.detail.ratio < 0)
      this.selectedSliderColor = 'var(--btt-color-auxiliary)';
  }
  userHasSelect(event: any) {
    if (event.detail.side === 'start') this.openEditWorkoutPage();
    if (event.detail.side === 'end') this.hasDeletedWorkout();
  }

  getCurrentOrderValue() {
    return WorkoutsOrder.find((a) => a.value === this.sheetValue.order)?.order;
  }

  openEditWorkoutPage() {
    this.router.navigateByUrl(
      'home/training-plan/custom/btt-my-custom-exercise-preview',
      {
        state: {
          currentExercises: this.sheetValue.exercicios,
          currentOrder: this.getCurrentOrderValue(),
          workoutId: this.sheetValue.id,
          setFormatExercise: true,
          fromShowWorkoutListPage: true,
        },
        replaceUrl: false,
      },
    );
  }

  async hasDeletedWorkout() {
    const modal = await this.modalController.create({
      component: BttAlertActionComponent,
      cssClass: 'modal-rate',
      componentProps: {
        title: `Deseja realmente excluir o treino ${this.sheetValue.order}?`,
        subTitle: 'A ação não poderá ser desfeita',
        buttonText: 'Continuar',
        localIcon: 'assets/custom-icon/btt-icon-warning.svg',
      },
    });
    await modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      const payload = this.sheetValue.id;
      this.store.dispatch(
        NewCustonWorkoutActions.setDeleteCustomWorkout({ payload }),
      );
      this.store.dispatch(NewCustonWorkoutActions.getNewCustomWorkout());
    }
  }
}
