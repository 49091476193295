import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-exercise-preview',
  templateUrl: './exercise-preview.component.html',
  styleUrls: ['./exercise-preview.component.scss'],
})
export class ExercisePreviewComponent implements OnInit {
  @Input() thumb: string;
  @Input() title: string;

  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  handleItemClick() {
    this.itemClick.emit();
  }

}
