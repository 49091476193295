import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setExerciseRating = createAction(
  '[ExerciseRating] Set ExerciseRating',
  props<{ payload: any }>(),
);

export const setExerciseRatingSuccess = createAction(
  '[ExerciseRating] Set ExerciseRating Success',
  props<{ payload: any }>(),
);

export const setExerciseRatingError = createAction(
  '[ExerciseRating] Set ExerciseRating Error',
  props<{ payload: HttpErrorResponse }>(),
);

// set user liked exercise
export const setLikedExercise = createAction(
  '[liked exercise] Set Liked Exercise',
  props<{ payload: any }>(),
);

export const setLikedExerciseSuccess = createAction(
  '[liked exercise] Set Liked Exercise Success',
  props<{ payload: any }>(),
);

export const setLikedExerciseError = createAction(
  '[liked exercise] Set Liked Exercise Error',
  props<{ payload: HttpErrorResponse }>(),
);
