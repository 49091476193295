import { Component, Input, OnInit } from "@angular/core";
import { IFeedData } from "src/app/models/feed";

// actions
import * as FeedsActions from "src/app/store/actions/feed.actions";
import { Store } from "@ngrx/store";
@Component({
  selector: "app-btt-hide-post-content",
  templateUrl: "./btt-hide-post-content.component.html",
  styleUrls: ["./btt-hide-post-content.component.scss"],
})
export class BttHidePostContentComponent implements OnInit {
  @Input() post: IFeedData;

  constructor(private store: Store) {}

  ngOnInit() {}

  // handle set show content
  handleSetShowContent() {
    let payload = {
      postId: this.post?.id,
    };
    this.store.dispatch(FeedsActions.setShowPost({ payload }));
  }
}
