import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as AppConfigsActions from '../actions/appConfigs.actions';

import { ConfigService } from 'src/app/services/config.service';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class AppConfigsEffects {
  getAppConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppConfigsActions.getAppConfigs),
      tap(() =>
        this.store.dispatch(
          LoadingActions.exibirLoading({
            payload: loadingTypesEnum.APP_CONFIG,
          }),
        ),
      ),
      switchMap(() => {
        return this.configService.getAppConfig().pipe(
          map((res: any) => {
            return AppConfigsActions.getAppConfigsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(AppConfigsActions.getAppConfigsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getAppConfigsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppConfigsActions.getAppConfigsSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.APP_CONFIG }),
      ),
    ),
  );

  getAppConfigsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppConfigsActions.getAppConfigsError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.APP_CONFIG }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private store: Store,
  ) {}
}
