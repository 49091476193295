import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'btt-content-creator-new',
  templateUrl: './btt-content-creator-new.component.html',
  styleUrls: ['./btt-content-creator-new.component.scss'],
})
export class BttContentCreatorNewComponent implements OnInit {
  @Input() img = 'assets/img/user-image.jpg';
  @Input() name = '(Não informado)';

  @Input() instructors;

  constructor() {}

  ngOnInit() {}
}
