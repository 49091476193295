import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const getPremiumStatus = createAction("[Premium] Get Premium Status");

export const getPremiumStatusSuccess = createAction(
  "[Premium] Get Premium Status Success",
  props<{ payload: any }>()
);

export const getPremiumStatusError = createAction(
  "[Premium] Get Premium Status Error",
  props<{ payload: HttpErrorResponse }>()
);
