import { createReducer, on } from '@ngrx/store';
import * as SearchResultActions from '../actions/searchResult.actions';

import { SearchResult } from 'src/app/models/searchResult';

export interface SearchResultState {
  searchResult: SearchResult[];
}

const initialState: SearchResultState = {
  searchResult: [],
};

export const searchResultReducer = createReducer(
  initialState,
  on(
    SearchResultActions.getSearchResultSuccess,
    (state, { payload: searchResult }) => {
      return {
        ...state,
        searchResult,
      };
    }
  )
);
