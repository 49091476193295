import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService, FORGOT_PASS_KEY } from 'src/app/services/auth.service';
// actions
import * as LoginActions from 'src/app/store/actions/login.actions';
// selectors
import * as LoginSelectors from 'src/app/store/selectors/login.selectors';

@Component({
  selector: 'app-successfully-reset-password',
  templateUrl: './successfully-reset-password.page.html',
  styleUrls: ['./successfully-reset-password.page.scss'],
})
export class SuccessfullyResetPasswordModal implements OnInit {
  user$: Observable<User>;
  constructor(
    private router: Router,
    private store: Store,
    private auth: AuthService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.getUser();
  }

  handleOpenLoginPage(user: User) {
    this.setLogout(user);
    this.router.navigateByUrl('login', { replaceUrl: true });
    this.modalCtrl.dismiss();
  }

  getUser() {
    this.user$ = this.store.select(LoginSelectors.getUser);
    Storage.remove({ key: FORGOT_PASS_KEY });
  }

  setLogout(user: User) {
    this.store.dispatch(
      LoginActions.logout({
        payload: {
          user,
        },
      }),
    );
  }
}
