import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const setPageRedirect = createAction(
  '[Page Redirect] Set Page Redirect',
  props<{ payload: any }>(),
);

// =====================================

export const getPageRedirect = createAction(
  '[Page Redirect] Get Page Redirect',
  props<{ payload: any }>(),
);
