import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getCompletedWorkoutsWeek = createAction(
  '[Completed Workouts Week] Get Completed Workouts Week',
);

export const getCompletedWorkoutsWeekSuccess = createAction(
  '[Completed Workouts Week] Get Completed Workouts Week Success',
  props<{ payload: any }>(),
);

export const getCompletedWorkoutsWeekError = createAction(
  '[Completed Workouts Week] Get Completed Workouts Week Error',
  props<{ payload: HttpErrorResponse }>(),
);
