import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as UpdateCustomExerciseActions from '../actions/updateCustomExercise.actions';

import { TrainingCustomService } from 'src/app/services/training-custom.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class UpdateCustomExerciseEffects {
  setUpdateCustomExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCustomExerciseActions.setUpdateCustomExercise),
      switchMap(({ payload }) => {
        return this.trainingCustomService.updateCustomExercise(payload).pipe(
          map((res: any) => {
            if (res?.message) this.presentToast('bottom', res.message);
            return UpdateCustomExerciseActions.setUpdateCustomExerciseSuccess();
          }),
          catchError((error) =>
            of(
              UpdateCustomExerciseActions.setUpdateCustomExerciseError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  // toast
  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: position,
      cssClass: 'toast__alert_content',
    });

    await toast.present();
  }

  constructor(
    private actions$: Actions,
    private trainingCustomService: TrainingCustomService,
    private toastController: ToastController,
  ) {}
}
