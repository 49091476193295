import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationService {
  constructor(
    private localNotifications: LocalNotifications,
    private http: HttpClient,
  ) {}

  async hasPermission() {
    await this.localNotifications
      .hasPermission()
      .then((res) => console.log('[hasPermission] =>', res));
    await this.localNotifications.requestPermission();
    console.log('[Requeste and has Permission]');
  }

  setNotification(
    id: number,
    weekday: number,
    hours: number,
    minutes: number,
    title: string,
    message: string,
    geral?: boolean,
  ) {
    this.localNotifications.schedule({
      id: geral ? Number(`${id}${weekday}${7}`) : Number(`${id}${weekday}`),
      title: title,
      text: message,
      trigger: {
        every: {
          weekday: weekday,
          hour: hours,
          minute: minutes,
        },
      },
      foreground: true,
    });
  }

  async clearAllNotifications() {
    this.localNotifications.clearAll();
    this.localNotifications.cancelAll();
  }

  clearNotification(id: number, weekday: number) {
    this.localNotifications.cancel(`${id}${weekday}`);
    this.localNotifications.clear(`${id}${weekday}`);
  }

  getAllNotification() {
    this.localNotifications
      .getAll()
      .then((res) => console.log('[getAll] =>', res));
  }

  getDailyReminders(): Observable<any> {
    const url: string = API_URL + `/daily-reminders`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
