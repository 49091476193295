const ExerciseFormattedMock = {
  id: 1305,
  video: "/videos/693698131",
  image:
    "/videos/693698131/pictures/1403736739-272ed2151c4376a1998d149dfb015f7f96621e775d742f37d3db88378f09fef1-d",
  title: "Treino com Janaina - 01/04/2022",
  text: "Lorem Ipsum é simplesmente um texto fictício da indústria de impressão e composição. Lorem Ipsum tem sido o texto fictício padrão da indústria desde 1500, quando um impressor desconhecido pegou um modelo de impressão e embaralhou-o para fazer um livro de amostra de tipos.",
  contentCreator: {
    name: "Isadora",
    image: "assets/img/main/orientacoes/receita.png",
  },
  contentRating: 5,
};
const ExerciseFormattedOutroMock = {
  id: 1399,
  video: "/videos/693698131",
  image:
    "/videos/693698131/pictures/1403736739-272ed2151c4376a1998d149dfb015f7f96621e775d742f37d3db88378f09fef1-d",
  title: "OUTRO TREINO",
  text: "OUTRO TEXTO",
  contentCreator: {
    name: "ANDRE",
    image: "assets/img/main/orientacoes/receita.png",
  },
  contentRating: 5,
  nextExercise: ExerciseFormattedMock,
  prevExercise: ExerciseFormattedMock,
};
const ExerciseMock = {
  id: 1305,
  treino_agendado_id: 1,
  exercicio_id: 1305,
  peso: 991,
  series: null,
  repeticoes: null,
  intervalo_descanso: null,
  status: 1,
  permitir_cronometro: 0,
  tempo_exercicio: null,
  intervalo_beep: 2,
  data_liberacao: "2022-04-01 05:30:00",
  nome_exibicao: "Com Janaina",
  is_premium: 1,
  vimeo_id: null,
  created_at: "2022-03-29 16:32:27",
  updated_at: "2022-03-29 16:32:47",
  modalidade_exercicio_id: 698,
  titulo: "Treino com Janaina - 01/04/2022",
  nome: "Com Janaina",
  descricao:
    "Lorem Ipsum é simplesmente um texto fictício da indústria de impressão e composição. Lorem Ipsum tem sido o texto fictício padrão da indústria desde 1500, quando um impressor desconhecido pegou um modelo de impressão e embaralhou-o para fazer um livro de amostra de tipos.",
  video_url: null,
  video_youtube_id: null,
  dificuldade: 3,
  permite_carga: 0,
  grupo_muscular_nome: "Aulas Diárias",
  vimeo: {
    id: 1351,
    model_id: 1305,
    model_type: "Exercicio",
    video: "/videos/693698131",
    picture:
      "/videos/693698131/pictures/1403736739-272ed2151c4376a1998d149dfb015f7f96621e775d742f37d3db88378f09fef1-d",
    created_at: "2022-03-29 16:26:49",
    updated_at: "2022-03-29 17:15:29",
  },
  data_liberacao_formatada: "01/04/2022 às 05:30",
  exercicio_liberado: true,
  is_current_day: false,
  exercicio: {
    id: 1305,
    titulo: "Treino com Janaina - 01/04/2022",
    descricao: "Treino membros superiores.",
    video_url: null,
    video_youtube_id: null,
    status: 1,
    dificuldade: 3,
    grupo_muscular_id: 47,
    permite_carga: 0,
    nao_listado: 1,
    vimeo_id: null,
    deleted_at: null,
    created_at: "2022-03-29 16:26:49",
    updated_at: "2022-03-29 16:31:42",
    vimeo: {
      id: 1351,
      model_id: 1305,
      model_type: "Exercicio",
      video: "/videos/693698131",
      picture:
        "/videos/693698131/pictures/1403736739-272ed2151c4376a1998d149dfb015f7f96621e775d742f37d3db88378f09fef1-d",
      created_at: "2022-03-29 16:26:49",
      updated_at: "2022-03-29 17:15:29",
    },
  },
};

export { ExerciseMock, ExerciseFormattedOutroMock, ExerciseFormattedMock };
