import { createReducer, on } from "@ngrx/store";
import * as RecommendedActions from "../actions/recommended.actions";

import { Recommended } from "src/app/models";

export interface RecommendedState {
  recommended: Recommended[];
}

const initialState: RecommendedState = {
  recommended: [],
};

export const recommendedReducer = createReducer(
  initialState,
  on(
    RecommendedActions.getRecommendedSuccess,
    (state, { payload: recommended }) => {
      return {
        ...state,
        recommended,
      };
    }
  )
);
