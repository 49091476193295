import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { Store } from '@ngrx/store';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { User } from '../models/user';

import * as LoginSelectors from 'src/app/store/selectors/login.selectors';

@Injectable({
  providedIn: 'root',
})
export class LocalDatabaseService {
  user$: Observable<User>;
  private storageReady = new BehaviorSubject(false);

  constructor(private storage: Storage, private store: Store) {}

  async init() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.storageReady.next(true);
  }

  getKeysDataBase() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => {
        return from(this.storage.keys()) || of([]);
      }),
    );
  }

  getLengthDataBase() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => {
        return from(this.storage.length()) || of([]);
      }),
    );
  }

  storageKeyForEach(storageKey: string) {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => {
        return (
          from(
            this.storage.forEach((key, value, index) => {
              if (storageKey === key) {
                return {
                  key,
                  value,
                  index,
                };
              }
            }),
          ) || of([])
        );
      }),
    );
  }

  async checkExistingKey(storageKey: string) {
    let returnValue: { existingKey: boolean; existingStick: boolean } = {
      existingKey: false,
      existingStick: false,
    };

    const keys = await this.storage.keys();

    if (keys.length > 0) {
      await this.storage.forEach((value, key, index) => {
        if (storageKey === key) {
          returnValue.existingKey = true;

          if (value.length > 0) {
            returnValue.existingStick = true;
          }
        } else {
          returnValue.existingKey = false;
        }
      });
    } else {
      returnValue.existingKey = false;
      returnValue.existingStick = false;
    }

    return returnValue;
  }

  getData(STORAGE_KEY: string) {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => {
        return from(this.storage.get(STORAGE_KEY)) || of([]);
      }),
    );
  }

  async addData(STORAGE_KEY: string, item: any) {
    const storedData = (await this.storage.get(STORAGE_KEY)) || [];
    storedData.push(item);
    return this.storage.set(STORAGE_KEY, storedData);
  }

  async removeItem(STORAGE_KEY: string, index: number) {
    const storedData = (await this.storage.get(STORAGE_KEY)) || [];
    storedData.splice(index, 1);
    return await this.storage.set(STORAGE_KEY, storedData);
  }

  async deleteData(STORAGE_KEY: string) {
    return await this.storage.remove(STORAGE_KEY);
  }

  async updateData(STORAGE_KEY: string, newValue: any) {
    return this.storage.set(STORAGE_KEY, newValue);
  }

  formatKeyDataBaseUser(STORAGE_KEY_BASE: string) {
    let storageDataUser: string;
    this.user$ = this.store.select(LoginSelectors.getUser);
    this.user$.subscribe((res) => {
      storageDataUser = `${STORAGE_KEY_BASE}_${res.id}`;
    });
    return storageDataUser;
  }
}
