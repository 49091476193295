import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getPersonalsList = createAction(
  '[Personals List] Get Personals List',
  props<{ payload: any }>(),
);

export const getPersonalsListSuccess = createAction(
  '[Personals List] Get Personals List Success',
  props<{ payload: any }>(),
);

export const getPersonalsListError = createAction(
  '[Personals List] Get Personals List Error',
  props<{ payload: HttpErrorResponse }>(),
);
