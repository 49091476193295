import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getObjectives = createAction('[Objectives] Get Objectives');

export const getObjectivesSuccess = createAction(
  '[Objectives] Get Objectives Success',
  props<{ payload: any }>()
);

export const getObjectivesError = createAction(
  '[Objectives] Get Objectives Error',
  props<{ payload: HttpErrorResponse }>()
);

// set objectives
export const setObjectives = createAction(
  '[Objectives] Set Objectives',
  props<{ payload: any }>()
);

export const setObjectivesSuccess = createAction(
  '[Objectives] Set Objectives Success',
  props<{ payload: any }>()
);

export const setObjectivesError = createAction(
  '[Objectives] Set Objectives Error',
  props<{ payload: HttpErrorResponse }>()
);
