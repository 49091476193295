import { createSelector } from "@ngrx/store";
import * as fromCore from "../reducers/index";

export const getStates = createSelector(
  fromCore.getStatesCitiesState,
  (state) => state.states
);
export const getCities = createSelector(
  fromCore.getStatesCitiesState,
  (state) => state.cities
);

export const getStatesWhereHasPartners = createSelector(
  fromCore.getStatesCitiesState,
  (state) => state.statesPartners
);
export const getCitiesWhereHasPartners = createSelector(
  fromCore.getStatesCitiesState,
  (state) => state.citiesPartners
);

export const getLoading = createSelector(
  fromCore.getStatesCitiesState,
  (state) => state.loading
);
