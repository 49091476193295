import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "btt-subtitle",
  templateUrl: "./subtitle.component.html",
  styleUrls: ["./subtitle.component.css"],
})
export class SubtitleComponent implements OnInit {
  @Input() text: string;
  @Input() buttonText: string = "Ver todos";

  @Input() showButton = true;

  @Output() onButtonClick: EventEmitter<CustomEvent> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  /** @ignore */
  public onHandleClick(event?: CustomEvent): void {
    this.onButtonClick.emit(event);
  }
}
