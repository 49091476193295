import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getExerciseById = createAction(
  '[Exercise By ID] Get Exercise By ID',
  props<{ payload: any }>(),
);

export const getExerciseByIdSuccess = createAction(
  '[Exercise By ID] Get Exercise By ID Success',
  props<{ payload: any }>(),
);

export const getExerciseByIdError = createAction(
  '[Exercise By ID] Get Exercise By ID Error',
  props<{ payload: HttpErrorResponse }>(),
);
