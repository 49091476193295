import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";


import { Router } from "@angular/router";

import {
  AlertController,
  IonRouterOutlet,
  MenuController,
  Platform,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AuthService } from "src/app/services/auth.service";


import { Observable } from "rxjs";
import { User } from "./models/user";
import { Store } from "@ngrx/store";

import * as LoginSelectors from "src/app/store/selectors/login.selectors";
import * as LoginActions from "src/app/store/actions/login.actions";

// test back button
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { LocalNotificationService } from "./services/local-notification.service";
import { LocalDatabaseService } from "./services/local-database.service";

interface IDailyReminders {
  id: number;
  message: string;
  time: string;
  title: string;
  weekdays: number[];
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  // pageName: string = "Notificações";
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  public selectedIndex = 0;
  public appPages = [
    {
      title: "Conteúdos salvos",
      url: "/home/favorite-content",
      icon: 'assets/svg/menu-icon-bookmark.svg'
    },
    {
      title: "Lembretes",
      url: "/home/sticky-notes",
      icon: 'assets/svg/menu-icon-clock.svg'
    },
    {
      title: "Aulas diárias",
      url: "/home/daily-workouts",
      icon: 'assets/svg/menu-icon-hour-repeat.svg'
    },
    {
      title: "Programas de treino",
      url: "/home/training-programs-home",
      icon: 'assets/svg/menu-icon-calendar.svg'
    },
    {
      title: "Treine na academia",
      url: "/home/training-plan",
      icon: 'assets/svg/menu-icon-alter.svg'
    },
    {
      title: "Nutrição e saúde",
      url: "/home/nutrition",
      icon: 'assets/svg/menu-icon-nutrition.svg'
    },
  ];

  user$: Observable<User>;
  selectedSegment: "menu" | "notifications" = "menu";

  // test back button
  voltarNativoListener: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AuthService,
    private store: Store,
    private menu: MenuController,
    public alertController: AlertController,
    private localDatabase: LocalDatabaseService,
    private localNotification: LocalNotificationService,
    private zone: NgZone,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.localDatabase.init();

    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = "bittrainers.com.br";

        const pathArray = event.url.split(domain);

        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });

    this.localNotification.hasPermission();
  }

  ngOnInit() {
    this.getUser();
    this.setStickyNotesUser();
  }

  setStickyNotesUser() {
    this.localNotification
      .getDailyReminders()
      .subscribe((res: IDailyReminders[]) => {
        this.localNotification.clearAllNotifications();

        res.map((sticky: IDailyReminders) => {
          sticky.weekdays.forEach((item) => {
            this.localNotification.setNotification(
              sticky.id,
              item,
              Number(sticky.time.split(":")[0]),
              Number(sticky.time.split(":")[1]),
              sticky.title,
              sticky.message,
              true
            );
          });
        });
      });
  }

  getUser() {
    this.auth.loadToken();
    this.store.dispatch(LoginActions.getAutenticatedUser());
    this.user$ = this.store.select(LoginSelectors.getUser);
  }

  logout(user: User) {
    this.store.dispatch(
      LoginActions.logout({
        payload: {
          user,
        },
      })
    );
  }

  closeMenu() {
    this.menu.close("btt-menu");
  }

  segmentChanged(ev) {
    this.selectedSegment = ev.detail.value;
  }
}
