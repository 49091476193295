import { createReducer, on } from '@ngrx/store';
import * as RecipeActions from '../actions/recipe.actions';

export interface RecipeState {
  recipeItems: any[];
  currentRecipeItem: any;

  loading: boolean;
}

const initialState: RecipeState = {
  recipeItems: [],
  currentRecipeItem: null,
  loading: false,
};

export const recipeReducer = createReducer(
  initialState,
  on(RecipeActions.getRecipeItems, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(RecipeActions.getRecipeItemsSuccess, (state, { payload: recipeItems }) => {
    return {
      ...state,
      recipeItems,
      loading: false,
    };
  }),
  on(RecipeActions.getRecipeItemsError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(RecipeActions.getRecipeItem, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    RecipeActions.getRecipeItemSuccess,
    (state, { payload: currentRecipeItem }) => {
      return {
        ...state,
        currentRecipeItem,
        loading: false,
      };
    },
  ),
  on(RecipeActions.getRecipeItemError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),
);
