import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as CompletedWorkoutsWeekActions from '../actions/completedWorkoutsWeek.actions';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { ICompletedWorkoutsDay } from 'src/app/models/completedWorkoutsDay';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class CompletedWorkoutsWeekEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompletedWorkoutsWeekActions.getCompletedWorkoutsWeek),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.COMPLETED_WORKOUT_WEEK,
        }),
      ),
      switchMap(() => {
        return this.userService.getCompletedWorkoutsWeek().pipe(
          map((res: any) => {
            const response: ICompletedWorkoutsDay[] = res;

            return CompletedWorkoutsWeekActions.getCompletedWorkoutsWeekSuccess(
              {
                payload: response,
              },
            );
          }),
          catchError((error) =>
            of(
              CompletedWorkoutsWeekActions.getCompletedWorkoutsWeekError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getCompletedWorkoutsWeekSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompletedWorkoutsWeekActions.getCompletedWorkoutsWeekSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.COMPLETED_WORKOUT_WEEK,
        }),
      ),
    ),
  );

  getCompletedWorkoutsWeekError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompletedWorkoutsWeekActions.getCompletedWorkoutsWeekError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.COMPLETED_WORKOUT_WEEK,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
  ) {}
}
