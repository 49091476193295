import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user';

export const userLogin = createAction(
  '[Login] User Login',
  props<{ payload: any }>(),
);

export const userLoginSuccess = createAction(
  '[Login] User Login Success',
  props<{ payload: any }>(),
);

export const userLoginError = createAction(
  '[Login] User Login Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getAutenticatedUser = createAction(
  '[Login] Get Autenticated User',
);

export const getAutenticatedUserSuccess = createAction(
  '[Login] Get Autenticated User Success',
  props<{ payload: User }>(),
);

export const getAutenticatedUserError = createAction(
  '[Login] Get Autenticated User Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const logout = createAction(
  '[Logout] Perform Logout',
  props<{ payload: { user: User | null } }>(),
);
