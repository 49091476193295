import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as PersonalsListActions from '../actions/personalsList.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// models
import { IPersonalsList } from 'src/app/models/personalsList';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class PersonalsListEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalsListActions.getPersonalsList),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.PERSONALSLIST,
        }),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.getPersonalsList(payload).pipe(
          map((res: any) => {
            let auxPersonalsList: IPersonalsList[] = res;
            return PersonalsListActions.getPersonalsListSuccess({
              payload: auxPersonalsList,
            });
          }),
          catchError((error) =>
            of(PersonalsListActions.getPersonalsListError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getPersonalsListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalsListActions.getPersonalsListSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PERSONALSLIST,
        }),
      ),
    ),
  );

  getPersonalsListError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalsListActions.getPersonalsListError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PERSONALSLIST,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
  ) {}
}
