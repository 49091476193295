import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as AnnotationActions from '../actions/annotation.actions';

import { UserService } from 'src/app/services/user.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class AnnotationEffects {
  setAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnotationActions.setAnnotation),
      switchMap(({ payload }) => {
        return this.userService.setAnnotation(payload).pipe(
          map((res: any) => {
            if (res?.message) this.presentToast('bottom', res.message);
            return AnnotationActions.setAnnotationSuccess();
          }),
          catchError((error) =>
            of(AnnotationActions.setAnnotationError({ payload: error })),
          ),
        );
      }),
    ),
  );

  // toast
  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: position,
      cssClass: 'toast__alert_content',
    });

    await toast.present();
  }

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private toastController: ToastController,
  ) {}
}
