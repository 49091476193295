import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject, of } from 'rxjs';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
  API_URL_V5,
  API_URL_V6,
} from 'src/environments/environment';

interface IDatavalueRating {
  idExercise: number;
  user_id: number;
  ratingValue: number;
}

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  public objetives: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {}

  /**
   * Get Scheduled trainings
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  getTrainingScheduled(): Observable<any> {
    const url: string = API_URL_V6 + '/treinos-agendados';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Scheduled trainings
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  getTrainingScheduledById(id): Observable<any> {
    const url: string = API_URL_V6 + '/treinos-agendados/' + id;
    // const url: string = API_URL_V3 + '/treinos-agendados/' + id;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
  getTrainingScheduledByDate(date: string): Observable<any> {
    const url: string =
      API_URL_V6 + '/treinos-agendados-exercicios?data=' + date; // 2023-02-28

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Objetives
   * @author André Ramos <oi@andreramos.dev>
   */
  getObjetives(): Observable<any> {
    const url: string = API_URL_V3 + '/objetivos';

    return this.http.get(url).pipe(
      tap((response) => {
        if (response && response.registros) {
          this.objetives.next(response.registros);
        }

        return response;
      }),
    );
  }

  //// set user objective
  setUserObjective(objectiveData): Observable<any> {
    const url: string = API_URL + `/user/set-objetivo`;

    return this.http.patch(url, objectiveData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get training by NivelSexoObjetives
   * @author André Ramos <oi@andreramos.dev>
   */
  getTrainingAggregate(
    levelId,
    gender,
    objetive,
    tipo_id,
    training_frequency,
    personal_id?,
  ): Observable<any> {
    const url: string =
      API_URL_V3 +
      `/treinos/${levelId}/${gender}/${objetive}?tipo_id=${tipo_id}&training_frequency=${training_frequency}&instrutor_id=${personal_id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getPersonalsList(payload): Observable<any> {
    const url: string =
      API_URL +
      `/instrutores/filter?nivel_id=${payload.nivel_id}&sexo=${payload.sexo}&objetivo_id=${payload.objetivo_id}&tipo_id=${payload.tipo_id}&frequencia=${payload.frequencia}`;
    return this.http.get(url, payload).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getTrainingTypes(): Observable<any> {
    const url: string = API_URL_V3 + `/treinos/tipos`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Exercises
   * @author André Ramos
   */
  getExercisesIndex(params = null): Observable<any> {
    const url: string = API_URL_V3 + `/exercicios/index`;

    return this.http.get(url, { params }).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  searchExercises(search): Observable<any> {
    const url: string = API_URL_V3 + `/exercicios/busca`;

    return this.http.post(url, search).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Mark exercise as finished
   */
  setExerciseFinished(exerciseData): Observable<any> {
    const url: string = API_URL_V2 + `/user-exercicio-concluido`;

    return this.http.post(url, exerciseData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // INTERESTS ROUTES

  //// get list interests
  getListInterests(): Observable<any> {
    const url: string = API_URL + `/categorias-conteudos`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  //// set user interests
  setUserInterests(interestsData): Observable<any> {
    const url: string = API_URL + `/user/set-categorias`;

    return this.http.post(url, interestsData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // Evaluate the exercise
  setExerciseRating({ idExercise, user_id, ratingValue }: IDatavalueRating) {
    const url: string =
      API_URL + `/visualizeds/exercicio/${idExercise}/set-nota`;
    const postData = {
      rating: ratingValue,
      user_id: user_id,
    };

    return this.http.patch(url, postData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setExerciseView({
    exerciseId,
    origin,
    percentage_completed,
    origin_id,
  }): Observable<any> {
    let auxData = {
      percentage_completed: percentage_completed,
      origin: origin,
      origin_id: origin_id,
    };

    const url: string = API_URL + `/exercicios/${exerciseId}/set-view`;

    return this.http.post(url, auxData).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getDailyWorkoutsViewed(params = null): Observable<any> {
    const url: string = API_URL + `/calendar`;

    return this.http.get(url, { params }).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // user set finished exercise
  setFinishedExercise(payload): Observable<any> {
    const url: string = API_URL + `/user/set-exercicio-finalizado`;
    return this.http.post(url, payload).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // user set finished workout
  setFinishedWorkout({
    id,
    percentage_completed,
    finish_all_exercises,
  }: any): Observable<any> {
    const url: string = API_URL + `/user/set-treino-concluido/${id}`;
    return this.http
      .post(url, { percentage_completed, finish_all_exercises })
      .pipe(
        tap((response) => {
          return response;
        }),
      );
  }

  getExercicesByWorkout(params: number): Observable<any> {
    const url: string = API_URL + `/user/list-exercicios-treino/${params}`;
    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // user set liked exercise
  setLikedExercise({ exerciseId, origin, originId }: any): Observable<any> {
    const data = {
      origin: origin,
      origin_id: originId,
    };
    const url: string =
      API_URL + `/visualizeds/exercicio/${exerciseId}/set-like`;
    return this.http.patch(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
