import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, filter, take } from "rxjs/operators";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
} from "src/environments/environment";
import { IPostFeed, IUpdateFeed } from "../models/feed";

@Injectable({
  providedIn: "root",
})
export class FeedService {
  constructor(private http: HttpClient) {}

  getFeeds(query: string): Observable<any> {
    const url: string = API_URL + `/feeds/all${query}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
  getMyFeed(query: string): Observable<any> {
    const url: string = API_URL + `/feeds${query}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getFeedByID(id: number): Observable<any> {
    const url: string = API_URL + `/feed/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getInteractions(query: string): Observable<any> {
    const url: string = API_URL + `/feed-interactions${query}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getReactions(): Observable<any> {
    const url: string = API_URL + `/feed-reactions`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getFeedMessages(): Observable<any> {
    const url: string = API_URL + `/feed-messages`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setFeed(data: IPostFeed): Observable<any> {
    const url: string = API_URL + `/feeds`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setReactions(feed_id: number, feed_reaction_id: number): Observable<any> {
    const url: string = API_URL + `/feed-interactions`;

    return this.http.post(url, { feed_id, feed_reaction_id }).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  updateFeed(id: number, data: IUpdateFeed): Observable<any> {
    const url: string = API_URL + `/feeds/${id}`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  updateReactions(
    feed_interaction_id: number,
    feed_reaction_id: number,
  ): Observable<any> {
    const url: string = API_URL + `/feed-interactions/${feed_interaction_id}`;

    return this.http.post(url, { feed_reaction_id }).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  deleteReactions(
    feed_interaction_id: number,
    // data: {
    //   feed_reaction_id: number;
    // },
  ): Observable<any> {
    const url: string = API_URL + `/feed-interactions/${feed_interaction_id}`;

    return this.http.delete(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  deleteFeedByID(id: number): Observable<any> {
    const url: string = API_URL + `/feeds/${id}`;

    return this.http.delete(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setViewInteractionFeed(id: number): Observable<any> {
    const url: string = API_URL + `/feed-interactions/set-view/${id}`;

    return this.http.post(url, null).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // get report messages
  getReportMessages() {
    const url: string = API_URL + "/feed-report-messages";

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // set feed report
  setFeedReport({ feed_id, message, additional_message }): Observable<any> {
    const url: string = API_URL + "/feed/report";

    const data: object = {
      feed_id,
      message,
      additional_message,
    };

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // set block user
  setBlockUser({ feed_id }): Observable<any> {
    const url: string = API_URL + `/feed/${feed_id}/block-user`;

    return this.http.patch(url, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // get blocked users
  getBlockedUsers(): Observable<any> {
    const url: string = API_URL + `/feed/report/blocked-users`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  // set unblock user
  setUnblockUser({ user_id }): Observable<any> {
    const url: string = API_URL + `/feed/unblock-user/${user_id}`;

    return this.http.patch(url, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
