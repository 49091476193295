import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "btt-header-image",
  templateUrl: "./btt-header-image.component.html",
  styleUrls: ["./btt-header-image.component.scss"],
})
export class BttHeaderImageComponent implements OnInit {
  @Input() img = "";
  constructor() {}

  ngOnInit(): void {}
}
