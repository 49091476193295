import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as StatesCitiesActions from '../actions/state-city.actions';

import { StatesService } from 'src/app/services/states.service';

@Injectable()
export class StatesCitiesEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatesCitiesActions.getStates),
      switchMap(() => {
        return this.statesService.getEstadosIbge().pipe(
          map((states: any) => {
            return StatesCitiesActions.getStatesSuccess({
              payload: states,
            });
          }),
          catchError((error) =>
            of(StatesCitiesActions.getStatesError({ payload: error }))
          )
        );
      })
    )
  );

  getCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatesCitiesActions.getCities),
      switchMap(({ payload: uf }) => {
        return this.statesService.getCidadesIbge(uf).pipe(
          map((cities: any) => {
            return StatesCitiesActions.getCitiesSuccess({
              payload: cities,
            });
          }),
          catchError((error) =>
            of(StatesCitiesActions.getCitiesError({ payload: error }))
          )
        );
      })
    )
  );

  // Partners Related
  getStatesWhereHasPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatesCitiesActions.getStatesWhereHasPartners),
      switchMap(() => {
        return this.statesService.getItems().pipe(
          map((res: any) => {
            return StatesCitiesActions.getStatesWhereHasPartnersSuccess({
              payload: res.registros,
            });
          }),
          catchError((error) =>
            of(
              StatesCitiesActions.getStatesWhereHasPartnersError({
                payload: error,
              })
            )
          )
        );
      })
    )
  );

  getCitiesWhereHasPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatesCitiesActions.getCitiesWhereHasPartners),
      switchMap(({ payload: { stateId, partnerType } }) => {
        return this.statesService
          .getCitiesWhereHasPartners(stateId, partnerType)
          .pipe(
            map((res: any) => {
              return StatesCitiesActions.getCitiesWhereHasPartnersSuccess({
                payload: res.registros,
              });
            }),
            catchError((error) =>
              of(
                StatesCitiesActions.getCitiesWhereHasPartnersError({
                  payload: error,
                })
              )
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private statesService: StatesService,
    private store: Store
  ) {}
}
