import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "phone",
})
export class PhonePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): string {
        let finalText = value;
        const converted = finalText.replace(/\-/g, "");

        if (converted.length === 11) {
            const ddd = converted.slice(0, 2);
            const initialPart = converted.slice(2, 7);
            const finalPart = converted.slice(7, 11);
            finalText = `(${ddd}) ${initialPart}-${finalPart}`;
        } else if (converted.length === 10) {
            const ddd = converted.slice(0, 2);
            const initialPart = converted.slice(2, 6);
            const finalPart = converted.slice(6, 10);
            finalText = `(${ddd}) ${initialPart}-${finalPart}`;
        }
        return finalText;
    }
}
