import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as InterestsActions from '../actions/interests.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Interest } from 'src/app/models/interest';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { AlertController } from '@ionic/angular';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class InterestsEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.getInterests),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.INTERESTS }),
      ),
      switchMap(() => {
        return this.trainingService.getListInterests().pipe(
          map((res: any) => {
            const interests: Interest[] = res.registros.map((interest) => {
              return {
                id: interest.id,
                nome: interest.nome,
              };
            });

            return InterestsActions.getInterestsSuccess({
              payload: interests,
            });
          }),
          catchError((error) =>
            of(InterestsActions.setInterestsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getInterestsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.getInterestsSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.INTERESTS }),
      ),
    ),
  );

  getInterestsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.getInterestsError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.INTERESTS }),
      ),
    ),
  );

  // setInterests

  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.setInterests),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.INTERESTS }),
      ),
      switchMap(({ payload: payload }) => {
        return this.trainingService.setUserInterests({ ...payload }).pipe(
          map((res: any) => {
            return InterestsActions.setInterestsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(InterestsActions.getInterestsError({ payload: error })),
          ),
        );
      }),
    ),
  );
  setInterestsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.setInterestsSuccess),
      map(() => {
        this.router.navigate(['/home'], { replaceUrl: true });
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.INTERESTS,
        });
      }),
    ),
  );

  setInterestsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterestsActions.setInterestsError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.INTERESTS }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
    private alertController: AlertController,
  ) {}
}
