import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import {
  AuthService,
  FORGOT_PASS_KEY,
  TOKEN_KEY,
} from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { LocalNotificationService } from 'src/app/services/local-notification.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthFacebookService } from 'src/app/services/auth-facebook.service';

import * as LoginActions from '../actions/login.actions';
import * as LoadingActions from '../actions/loading.actions';
import * as PremiumActions from '../actions/premium.actions';
import { getPremiumStatus } from '../actions/premium.actions';

// selectors
import * as FirstAccessSelectors from 'src/app/store/selectors/firstaccess.selector';
import * as PageRedirectSelectors from 'src/app/store/selectors/pageRedirect.selectors';
import { setPageRedirect } from 'src/app/store/actions/pageRedirect.actions';

import { Premium } from 'src/app/models/premium';
import { User } from 'src/app/models/user';
import { IValueStorage } from 'src/app/models/sticky-notes';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginEffects {
  // login effects
  userLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.userLogin),
      tap(() => {
        this.store.dispatch(
          LoadingActions.exibirLoading({ payload: loadingTypesEnum.LOGIN }),
        );
      }),
      switchMap(({ payload: credentials }) => {
        return this.authService.login(credentials).pipe(
          map((authData: any) => {
            return LoginActions.userLoginSuccess({
              payload: authData,
            });
          }),
          catchError((error) => {
            console.log('[Login error]=> ', error);
            // this.showAlertModal(error.error.message);
            this.selectTypeError(error);
            return of(LoginActions.userLoginError({ payload: error }));
          }),
        );
      }),
    ),
  );

  userLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.userLoginSuccess),
      tap(() =>
        this.store.dispatch(
          LoadingActions.ocultarLoading({ payload: loadingTypesEnum.LOGIN }),
        ),
      ),
      map(() => {
        Storage.remove({ key: FORGOT_PASS_KEY });
        return LoginActions.getAutenticatedUser();
      }),
    ),
  );

  userLoginError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.userLoginError),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.LOGIN,
        });
      }),
    ),
  );

  getAutenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.getAutenticatedUser),
      tap(() => {
        this.store.dispatch(
          LoadingActions.exibirLoading({ payload: loadingTypesEnum.LOGIN }),
        );
      }),
      switchMap(() =>
        this.userService.getUserByToken().pipe(
          map((res: any) => {
            // this.router.navigate(['/home'], { replaceUrl: true });

            const user: User = {
              altura: res.altura,
              apple_authorization_token: res.apple_authorization_token,
              apple_identity_token: res.apple_identity_token,
              apple_user: res.apple_user,
              bairro: res.bairro,
              cep: res.cep,
              cidade: res.cidade,
              cidade_id: res.cidade_id,
              cpf: res.cpf,
              data_nascimento: res.data_nascimento,
              data_nascimento_formatada: res.data_nascimento_formatada,
              ddd: res.ddd,
              descricao: res.descricao,
              email: res.email,
              empresa: res.empresa,
              empresa_id: res.empresa_id,
              empresa_img: res.empresa_img,
              facebook: res.facebook,
              facebook_id: res.facebook_id,
              google_id: res.google_id,
              foto: res?.foto || res?.social_photo,
              social_photo: res.social_photo,
              id: res.id,
              idade: res.idade,
              isAnon: res.isAnon,
              logradouro: res.logradouro,
              name: res.name,
              numero: res.numero,
              peso: res.peso,
              player_id: res.player_id,
              sexo: res.sexo,
              subscriberId: res.subscriberId,
              telefone: res.telefone,
              termos_uso: res.termos_uso,
              uf: res.uf,
              whatsapp: res.whatsapp,
              nickname: res.nickname,
              fitness_level: res.fitness_level,
              objetivo_id: res.objetivo_id,
              image_usage_accepted: res.image_usage_accepted,
            };

            this.personalityGuardCheck(user);

            this.platform.ready().then(() => {
              this.NotificationsService.initOneSignal(res);
            });

            this.setStickyNotesUser(user.id);

            return LoginActions.getAutenticatedUserSuccess({
              payload: user,
            });
          }),
          catchError((error) =>
            of(LoginActions.getAutenticatedUserError({ payload: error })),
          ),
        ),
      ),
    ),
  );

  getAutenticatedUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.getAutenticatedUserSuccess),
      tap(() => {
        this.store.dispatch(
          LoadingActions.ocultarLoading({ payload: loadingTypesEnum.LOGIN }),
        );
      }),
      map((response) => {
        if (environment.production) {
          this.analytics.identifyMixpanelUser(response.payload.id.toString());
        }

        this.store
          .select(PageRedirectSelectors.getPageRedirect)
          .subscribe(async (pageRedirect) => {
            const hasForgotPassword = await Storage.get({
              key: FORGOT_PASS_KEY,
            });

            if (hasForgotPassword?.value) {
              return this.router.navigateByUrl('/reset-password', {
                replaceUrl: true,
              });

              // Check for page redirection
            } else if (pageRedirect && pageRedirect !== 'home') {
              await this.router.navigateByUrl(pageRedirect, {
                replaceUrl: true,
              });
              // clear page redirect
              this.store.dispatch(setPageRedirect({ payload: null }));
            }
          });

        return getPremiumStatus();
      }),
    ),
  );

  getAutenticatedUserError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.getAutenticatedUserError),
      tap(() => {
        this.store.dispatch(
          LoadingActions.ocultarLoading({ payload: loadingTypesEnum.LOGIN }),
        );
      }),
      map(() => {
        return LoginActions.logout({
          payload: {
            user: null,
          },
        });
      }),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$?.pipe(
        ofType(LoginActions.logout),
        map(async ({ payload: { user } }) => {
          if (this.platform.is('mobile')) {
            this.removeStickyNotesUser(user?.id);
          }

          if (user?.facebook_id) {
            this.Facebook.logoutFacebook();
          }

          if (user?.google_id) {
            await GoogleAuth?.signOut().catch((err) => console.log(err));
            console.log('[LOGOUT GOOGLE]');
          }

          this.clearPremiumStatus();
          this.clearStorage();
          this.analytics.trackEvent('logout');

          const hasForgotPassword = await Storage.get({ key: FORGOT_PASS_KEY });

          if (hasForgotPassword?.value)
            return this.router.navigateByUrl('/verification', {
              replaceUrl: true,
            });

          this.router.navigate(['/'], { replaceUrl: true });
          // ajustar depois
        }),
      ),
    {
      dispatch: false,
    },
  );

  // aux functions

  clearPremiumStatus() {
    const auxPremium: Premium = null;
    this.store.dispatch(
      PremiumActions.getPremiumStatusSuccess({
        payload: auxPremium,
      }),
    );
  }

  // personality guard function
  personalityGuardCheck(userData: User) {
    // if it comes from the reset password flow, go back
    if (this.checkForgotPasswordStorage()) return;

    // create firstAccess auxiliar variable
    let isFirstAccess = true;
    // check if it is the first acces
    this.store
      .select(FirstAccessSelectors.getFirstAccess)
      .subscribe((response) => {
        isFirstAccess = response;
      });

    // Check if there is missing data
    if (
      (!userData.nickname ||
        !userData.altura ||
        !userData.peso ||
        !userData.sexo ||
        !userData.fitness_level) &&
      (userData?.empresa ? userData?.empresa?.integra_neotv === 0 : true) &&
      isFirstAccess
    ) {
      this.router.navigateByUrl('/personality', {
        replaceUrl: true,
      });
      return true;
      // Check if the user objective is missing
    } else if (!userData.objetivo_id && isFirstAccess) {
      this.router.navigateByUrl('/personality/form-objective', {
        replaceUrl: true,
      });
      return true;
      // Check if there user categorias_conteudos is missing
    } else if (userData.categorias_conteudos?.length === 0 && isFirstAccess) {
      this.router.navigateByUrl('/personality/form-interests', {
        replaceUrl: true,
      });
      return true;
    }
  }

  async checkForgotPasswordStorage() {
    const hasForgotPassword = await Storage.get({
      key: FORGOT_PASS_KEY,
    });
    // if it comes from the reset password flow, go back
    if (hasForgotPassword.value) return true;

    return false;
  }

  // select type error
  selectTypeError(res: any) {
    // message default
    let message = 'O servidor não está respondendo';
    if (res.status === 401)
      message = 'Credenciais Inválidas. Verifique os dados e tente novamente';
    return this.showAlertModal(message);
  }

  // show modal
  async showAlertModal(message: string) {
    const alert = await this.alertController.create({
      header: 'Erro ao entrar',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  setStickyNotesUser(id: number) {
    this.localDatabaseService
      .getData(`${'stickNotes'}_${id}`)
      .subscribe((res: IValueStorage[]) => {
        res?.map((sticky, index: number) => {
          const title = 'Vamos treinar!';
          const message = 'Está na hora do seu treino! 👟⏳';

          if (sticky.status) {
            sticky.weekDays.forEach(
              (item) =>
                item.checked == true &&
                this.localNotificationService.setNotification(
                  index,
                  item.indexValue,
                  new Date(sticky.hours).getUTCHours(),
                  new Date(sticky.hours).getUTCMinutes(),
                  title,
                  message,
                ),
            );
          }
        });
      });
  }

  removeStickyNotesUser(id: number) {
    this.localDatabaseService
      .getData(`${'stickNotes'}_${id}`)
      .subscribe((res: IValueStorage[]) => {
        res?.map((sticky, index: number) => {
          if (sticky.status) {
            sticky.weekDays.forEach(
              (item) =>
                item.checked == true &&
                this.localNotificationService.clearNotification(
                  index,
                  item.indexValue,
                ),
            );
          }
        });
      });
  }

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private NotificationsService: NotificationsService,
    private localNotificationService: LocalNotificationService,
    private platform: Platform,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private localDatabaseService: LocalDatabaseService,
    private analytics: AnalyticsService,
    private Facebook: AuthFacebookService,
  ) {}

  clearStorage() {
    Storage.remove({ key: TOKEN_KEY });
    Storage.remove({ key: 'user-training' }); // Remoção do storage de treinos dos usuários
  }
}
