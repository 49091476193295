import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { AnalyticsService } from 'src/app/services/analytics.service';

// actions
import * as AppConfigsActions from 'src/app/store/actions/appConfigs.actions';
// reducers
import * as AppConfigSelectors from 'src/app/store/selectors/appConfigs.selectors';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-adhesison-contract-modal',
  templateUrl: './adhesison-contract-modal.page.html',
  styleUrls: ['./adhesison-contract-modal.page.scss'],
})
export class AdhesisonContractModalPage implements OnInit {
  terms$: Observable<any>;

  constructor(
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.analytics.trackScreen('adhesion_contract', 'TermosUsoScreen');
    this.getContract();
  }

  async getContract() {
    this.store.dispatch(AppConfigsActions.getAppConfigs());
    this.terms$ = this.store.select(AppConfigSelectors.getAppConfigs);
  }

  dismiss(accepted) {
    this.analytics.trackEvent('terms_readed', {
      accepted,
    });

    this.modalCtrl.dismiss({
      accepted,
    });
  }
}
