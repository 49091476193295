import { createReducer, on } from '@ngrx/store';
import * as LoadingActions from '../actions/loading.actions';

export interface LoadingState {
  status: string[];
}

const initialState: LoadingState = {
  status: [],
};

export const loadingReducer = createReducer(
  initialState,
  on(LoadingActions.exibirLoading, (state, { payload: newLoading }) => {
    const auxStatus = [...state.status];
    if (!state.status.includes(newLoading)) auxStatus.push(newLoading);

    return {
      status: auxStatus,
    };
  }),
  on(LoadingActions.ocultarLoading, (state, { payload: newLoading }) => {
    const auxState = [...state?.status];
    const idx = auxState?.indexOf(newLoading);
    if (idx >= 0) auxState.splice(idx, 1);

    return {
      status: auxState,
    };
  }),
);
