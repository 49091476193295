import { createReducer, on } from '@ngrx/store';
import * as RecommendedAccordionActions from '../actions/recommendedAccordion.actions';

import { RecommendedAccordion } from 'src/app/models/recommendedAccordion';

export interface RecommendedAccordionState {
  recommendedAccordion: RecommendedAccordion[];
}

const initialState: RecommendedAccordionState = {
  recommendedAccordion: [],
};

export const recommendedAccordionReducer = createReducer(
  initialState,
  on(
    RecommendedAccordionActions.getRecommendedAccordionSuccess,
    (state, { payload: recommendedAccordion }) => {
      return {
        ...state,
        recommendedAccordion,
      };
    }
  )
);
