import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// actions
import * as FavoriteContentActions from 'src/app/store/actions/favoriteContent.actions';
// premium
import * as PremiumSelectors from 'src/app/store/selectors/premium.selectors';
import { PremiumService } from 'src/app/services/premium.service';

@Component({
  selector: 'app-btt-new-save-content',
  templateUrl: './btt-new-save-content.component.html',
  styleUrls: ['./btt-new-save-content.component.scss'],
})
export class BttNewSaveContentComponent implements OnInit {
  @Input() isFavorite: boolean = false;
  @Input() type:
    | 'exercicio'
    | 'receita'
    | 'desafio'
    | 'modalidade'
    | 'subcategoria_modalidade'
    | 'treino_agendado';
  @Input() id: number;
  premiumStatus$: Observable<any>;

  constructor(
    private store: Store,
    public premiumService: PremiumService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.setupListeners();
  }

  handleChangeSaved() {
    let dataValues = {
      tipo: this.type,
      id: this.id,
    };
    this.isFavorite = !this.isFavorite;
    this.store.dispatch(
      FavoriteContentActions.setFavoriteContent({ payload: dataValues }),
    );
  }

  // data premium user
  setupListeners() {
    this.premiumStatus$ = this.store.select(PremiumSelectors.getPremiumStatus);

    this.premiumService.IsPremium.subscribe(
      () => {
        this.ref.detectChanges();
      },
      () => {},
    );
    this.premiumService.hasPurchased.subscribe(
      () => {
        this.ref.detectChanges();
      },
      () => {},
    );
  }
}
