import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

// export const saveUserOneSignalToken = createAction(
//   '[Notifications] Save User OneSignal Token',
//   props<{
//     payload: {
//       data: any;
//     };
//   }>(),
// );

// export const saveUserOneSignalTokenSuccess = createAction(
//   '[Notifications] Save User OneSignal Token Success',
//   props<{ payload: any }>(),
// );

// export const saveUserOneSignalTokenError = createAction(
//   '[Notifications] Save User OneSignal Token Error',
//   props<{ payload: HttpErrorResponse }>(),
// );

export const getUserNotifications = createAction(
  '[Notifications] Get User Notifications',
  props<{
    payload: any;
  }>(),
);

export const getUserNotificationsSuccess = createAction(
  '[Notifications] Get User Notifications Success',
  props<{ payload: any }>(),
);

export const getUserNotificationsError = createAction(
  '[Notifications] Get User Notifications Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteUserNotifications = createAction(
  '[Notifications] Delete User Notifications',
  props<{
    payload: any;
  }>(),
);

export const deleteUserNotificationsSuccess = createAction(
  '[Notifications] Delete User Notifications Success',
  props<{ payload: any }>(),
);

export const deleteUserNotificationsError = createAction(
  '[Notifications] Delete User Notifications Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const readAllUserNotifications = createAction(
  '[Notifications] Read All User Notifications',
  props<{
    payload: {
      data: any;
    };
  }>(),
);

export const readAllUserNotificationsSuccess = createAction(
  '[Notifications] Read All User Notifications Success',
  props<{ payload: any }>(),
);

export const readAllUserNotificationsError = createAction(
  '[Notifications] Read All User Notifications Error',
  props<{ payload: HttpErrorResponse }>(),
);
