import { createReducer, on } from '@ngrx/store';
import * as ModalitiesActions from '../actions/modalities.actions';

export interface ModalitiesState {
  listModalities: any[];
  showModality: any;
  showSubmodality: any;
  currentModality: any;
  submodality: any;
  modalityImage: string;

  loading: boolean;
}

const initialState: ModalitiesState = {
  listModalities: null,
  showModality: null,
  showSubmodality: null,
  currentModality: null,
  submodality: null,
  modalityImage: null,

  loading: false,
};

export const modalitiesReducer = createReducer(
  initialState,
  on(ModalitiesActions.getModalityItem, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    ModalitiesActions.getModalityItemSuccess,
    (state, { payload: currentModality }) => {
      return {
        ...state,
        currentModality,
        loading: false,
      };
    },
  ),
  on(ModalitiesActions.getModalityItemError, (state, { payload: err }) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(ModalitiesActions.setSubmodality, (state, { payload: submodality }) => {
    return {
      ...state,
      submodality,
    };
  }),
  on(
    ModalitiesActions.setModalityImage,
    (state, { payload: modalityImage }) => {
      return {
        ...state,
        modalityImage,
      };
    },
  ),
  // =============================
  on(
    ModalitiesActions.getListModalitiesSuccess,
    (state, { payload: listModalities }) => {
      return {
        ...state,
        listModalities,
      };
    },
  ),
  // =============================
  on(
    ModalitiesActions.getShowModalitySuccess,
    (state, { payload: showModality }) => {
      return {
        ...state,
        showModality,
      };
    },
  ),
  // =============================
  on(
    ModalitiesActions.getShowSubmodalitySuccess,
    (state, { payload: showSubmodality }) => {
      return {
        ...state,
        showSubmodality,
      };
    },
  ),
);
