const INTERNAL_URL_PREFIX = [
  "https://web.bittrainers.com.br/",
  "bittrainers://",
];

export function checkIsInternalLink(url: string): string | null {
  for (const prefix of INTERNAL_URL_PREFIX) {
    if (url.startsWith(prefix)) {
      const route = url.substring(prefix.length);
      return route;
    }
  }

  return null;
}
