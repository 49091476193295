import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { YoutubeVideoPlayer } from "@ionic-native/youtube-video-player/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgxMaskModule, IConfig } from "ngx-mask";

// Custom Components
import { ComponentsModule } from "src/app/components/components.module";

// Custom Pipes
// import { PipesModule } from "./pipes/pipes.module";

// Cordova HTTP (ignore CORS)
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

// Interceptor for Bearer Token in Requests
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";

// IAP - In App Purchases
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2/ngx";

// social sharing api
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";

// Pagination
import { NgxPaginationModule } from "ngx-pagination";

// in app browser
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

// Rating
import { AppRate } from "@ionic-native/app-rate/ngx";

import { Market } from "@ionic-native/market/ngx";

import { SwiperModule } from "swiper/angular";

import {
  LaunchNavigator,
  LaunchNavigatorOptions,
} from "@awesome-cordova-plugins/launch-navigator/ngx";

export let options: Partial<IConfig> | (() => Partial<IConfig>) = {
  validation: false,
};

import {
  RoundProgressModule,
  ROUND_PROGRESS_DEFAULTS,
} from "angular-svg-round-progressbar";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { coreReducers } from "./store/reducers";
import { coreEffects } from "./store/effects";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";

import { ReactiveComponentModule } from "@ngrx/component";

import { Instagram } from "@awesome-cordova-plugins/instagram/ngx";

// beep sound
// import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx'; //legacy

//calendar
import { CalendarModule } from "ion2-calendar";
import { NgCalendarModule } from "ionic2-calendar";

// Local Notifications
import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications/ngx";

import { IonicStorageModule } from "@ionic/storage-angular";

import { Drivers } from "@ionic/storage";

import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

import { IonicGestureConfig } from "../utils/IonicGestureConfig";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: "md",
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(options),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    StoreModule.forRoot(coreReducers),
    EffectsModule.forRoot(coreEffects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    ReactiveComponentModule,
    CalendarModule,
    IonicStorageModule.forRoot({
      name: "mydatabase",
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    YoutubeVideoPlayer,
    ComponentsModule,
    InAppPurchase2,
    SocialSharing,
    HTTP,
    RoundProgressModule,
    SwiperModule,
    {
      provide: ROUND_PROGRESS_DEFAULTS,
      useValue: {
        color: "#ffcd00",
        radius: 125,
        animation: "easeOutCubic",
        animationDelay: null,
        duration: 500,
        stroke: 15,
        background: "#000",
        responsive: false,
        clockwise: true,
        semicircle: false,
        rounded: false,
      },
    },
    NgxPaginationModule,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppRate,
    Market,
    LaunchNavigator,
    Instagram,
    NgCalendarModule,
    LocalNotifications,
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    Mixpanel
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
