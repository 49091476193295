import { Browser } from '@capacitor/browser';

export async function openSupport(wppNumber: string = '+55 33 9823-1860') {
  // remove all non-numeric characters
  let auxWppNumber = wppNumber.replace(/[^0-9]/g, '');
  // open web browser
  const browser = await Browser.open({
    url: `https://api.whatsapp.com/send?phone=${auxWppNumber}`,
  });
}
