import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const getBanners = createAction("[Banners] Get Banners");

export const getBannersSuccess = createAction(
  "[Banners] Get Banners Success",
  props<{ payload: any }>()
);

export const getBannersError = createAction(
  "[Banners] Get Banners Error",
  props<{ payload: HttpErrorResponse }>()
);
