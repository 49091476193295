import { createReducer, on } from '@ngrx/store';
import * as ForgotPasswordActions from '../actions/forgotPassword.actions';

export interface CodeToRedefinePasswordState {
  codeToRedefinePassword: {
    message: string;
    time_to_waiting: number;
    remaining_waiting_time: number;
  };
}

const initialState: CodeToRedefinePasswordState = {
  codeToRedefinePassword: {
    message: null,
    time_to_waiting: null,
    remaining_waiting_time: null,
  },
};

export const codeToRedefinePasswordReducer = createReducer(
  initialState,
  on(
    ForgotPasswordActions.setCodeToRedefinePasswordSuccess,
    (state, { payload: codeToRedefinePassword }) => {
      return {
        ...state,
        codeToRedefinePassword,
      };
    },
  ),
);
