import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getRecommendedAccordion = createAction(
  '[RecommendedAccordion] Get RecommendedAccordion'
);

export const getRecommendedAccordionSuccess = createAction(
  '[RecommendedAccordion] Get RecommendedAccordion Success',
  props<{ payload: any }>()
);

export const getRecommendedAccordionError = createAction(
  '[RecommendedAccordion] Get RecommendedAccordion Error',
  props<{ payload: HttpErrorResponse }>()
);
