import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getInterests = createAction('[Interests] Get Interests');

export const getInterestsSuccess = createAction(
  '[Interests] Get Interests Success',
  props<{ payload: any }>()
);

export const getInterestsError = createAction(
  '[Interests] Get Interests Error',
  props<{ payload: HttpErrorResponse }>()
);

// submit interests
export const setInterests = createAction(
  '[Interests] Set Interests',
  props<{ payload: any }>()
);
export const setInterestsSuccess = createAction(
  '[Interests] Set Interests Success',
  props<{ payload: any }>()
);
export const setInterestsError = createAction(
  '[Interests] Set Interests Error',
  props<{ payload: HttpErrorResponse }>()
);
