import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { BehaviorSubject, Observable, timer } from "rxjs";

import { API_URL } from "src/environments/environment";

import OneSignal from "onesignal-cordova-plugin";

import { PremiumService } from "./premium.service";
import { User } from "../models/user";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";

import { checkIsInternalLink } from "../_helpers/check-link";

// const INTERNAL_URL_PREFIX = [
//   "https://web.bittrainers.com.br/",
//   "bittrainers://",
// ];

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  notifications: BehaviorSubject<object> = new BehaviorSubject<object>(
    undefined
  );
  hasUnreaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private premiumService: PremiumService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  initOneSignal(user: any) {
    if (!user.id) return;

    OneSignal.setAppId("59c0229f-2122-4679-8539-1ac83cba0d97");
    OneSignal.setInAppMessageClickHandler((action) => {
      if (action.clickName) {
        this.openNotificationUrl(action.clickName);
      }
    });

    OneSignal.setNotificationOpenedHandler((jsonData) => {
      const launchURL = jsonData?.notification?.launchURL;
      if (launchURL) {
        this.openNotificationUrl(launchURL);
      }
    });

    OneSignal.setNotificationWillShowInForegroundHandler((data) => {
      this.getUserNotifications().subscribe(
        (res) => {},
        (err) => {}
      );
    });

    this.setValuesOneSignal(user);
  }

  setValuesOneSignal(user: User) {
    OneSignal.setExternalUserId(`${user?.id}`);

    user.email && OneSignal.setEmail(user.email);

    let valueTelephone =
      user?.telefone?.length == 9
        ? `${user?.ddd}${user?.telefone}`
        : user?.telefone?.length == 11
        ? user?.telefone
        : null;

    valueTelephone && OneSignal.setSMSNumber(`+55${valueTelephone}`);

    OneSignal.sendTag(
      "user_provider_id",
      `${user?.empresa ? user?.empresa?.id : "without provider id"}`
    );

    OneSignal.sendTag("gender", `${user.sexo ?? "without gender"}`);

    OneSignal.sendTag(
      "user_provider",
      `${user?.empresa ? user?.empresa?.tag_onesignal : "without provider"}`
    );

    this.premiumService.getPremiumStatus().subscribe((res) => {
      OneSignal.sendTag(
        "subscription_type",
        `${res.premium ? "premium" : "free"}`
      );
    });

    this.updateUserOneSignalToken().then((res) => {
      this.getUserNotifications().subscribe(
        (response) => {
          console.log(this.notifications.value);
        },
        (err) => {}
      );
    });
  }

  /**
   * Get One Signal IDs and send to server
   * @author André Ramos <oi@andreramos.dev>
   * @returns Promise
   */
  updateUserOneSignalToken() {
    return new Promise((resolve, reject) => {
      OneSignal.getDeviceState((stateChanges) => {
        if (
          !stateChanges.hasNotificationPermission ||
          stateChanges.pushDisabled
        ) {
          OneSignal.addTrigger("prompt_permission", "true");
        }

        if (stateChanges?.pushToken && stateChanges?.userId) {
          this.saveUserOneSignalToken({
            pushToken: stateChanges.pushToken,
            userId: stateChanges.userId,
          }).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
        }
      });
    });
  }

  /**
   * Save the user onesignal token in API
   * @author André Ramos <oi@andreramos.dev>
   * @param data user data
   * @returns Promise
   */
  saveUserOneSignalToken(data: {
    pushToken: string;
    userId: string;
  }): Observable<any> {
    const url: string = API_URL + `/user/update-onesignal-token`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  /**
   * Get User Notifications From API
   * @author André Ramos <oi@andreramos.dev>
   */
  getUserNotifications(): Observable<any> {
    const url: string = API_URL + `/notifications`;

    return this.http.get(url).pipe(
      tap((response) => {
        this.notifications.next(response.registros);
        this.hasUnreaded.next(response.hasUnreaded);
        // console.log('NOTIFICATIONS==>', response);
        return response;
      })
    );
  }
  /**
   * DELETE User Notifications From API
   * @author André Ramos <oi@andreramos.dev>
   */
  deleteUserNotifications(): Observable<any> {
    const url: string = API_URL + `/notifications`;

    return this.http.delete(url).pipe(
      tap((response) => {
        if (response.success) {
          this.notifications.next([]);
        }
        return response;
      })
    );
  }
  /**
   * Mark all Notifications readed in API
   * @author André Ramos <oi@andreramos.dev>
   */
  readAllUserNotifications(data = {}): Observable<any> {
    const url: string = API_URL + `/notifications/mark-readed-all`;

    return this.http.post(url, data).pipe(
      tap((response) => {
        if (response.registros) {
          this.notifications.next(response.registros);
        }
        return response;
      })
    );
  }

  private openNotificationUrl(launchURL: string) {
    if (launchURL) {
      const internal = checkIsInternalLink(launchURL);
      if (internal) {
        timer(300).subscribe(() => {
          this.ngZone.run(() => {
            this.router.navigateByUrl(internal);
          });
        });
      } else {
        Browser.open({ url: launchURL });
      }
    }
  }

  // private checkIsInternalLink(url: string): string | null {
  //   for (const prefix of INTERNAL_URL_PREFIX) {
  //     if (url.startsWith(prefix)) {
  //       const route = url.substring(prefix.length);
  //       return route;
  //     }
  //   }

  //   return null;
  // }
}
