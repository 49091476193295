import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

import {
  SERVER_URL,
  API_URL,
  API_URL_V2,
  environment,
} from 'src/environments/environment';

import { LoadingController } from '@ionic/angular';

import { PremiumService } from './premium.service';

import { UserService } from './user.service';

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { Store } from '@ngrx/store';

import * as LoginActions from 'src/app/store/actions/login.actions';

const options: SignInWithAppleOptions = {
  clientId: 'com.bittrainers',
  redirectURI: 'com.bittrainers',
  scopes: 'email name',
  // state: '12345',
  // nonce: 'nonce',
};

interface ILogin {
  username: any;
  password: any;
  stayLogged?: boolean | undefined;
}

export const TOKEN_KEY = 'access_token';
export const FORGOT_PASS_KEY = 'forgot-password';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null,
  );
  isAuthenticatedStatus = false;

  facebookRegister = false;
  googleRegister = false;

  token = '';

  constructor(
    private http: HttpClient,
    private loadingController: LoadingController,
    private premiumService: PremiumService,
    private userService: UserService,
    private store: Store,
  ) {
    // this.loadToken();
  }
  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });

    if (token && token.value) {
      this.token = token.value;
      this.store.dispatch(LoginActions.getAutenticatedUser());
    }
  }

  // getAuthenticatedUser() {
  //   const url: string = API_URL + '/user/authenticated';

  //   return this.http.get(url).pipe(
  //     tap((response) => {
  //       return response;
  //     }),
  //   );
  // }

  /**
   * Login and get Access Token
   * @param credentials Usuário e senha de acesso
   */

  login(credentials: ILogin): Observable<any> {
    if (typeof credentials?.stayLogged !== 'boolean')
      credentials.stayLogged = true;
    const request = {
      grant_type: 'password',
      username: credentials.username,
      password: credentials.password,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
    };

    return this.http.post(SERVER_URL + '/oauth/token', request).pipe(
      // map((data: any) => data.token),
      switchMap((token) => {
        return from(
          Storage.set({
            key: TOKEN_KEY,
            value: JSON.stringify(token),
          }),
        );
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
      }),
    );
  }

  register(userData): Observable<any> {
    return this.http.post(API_URL + '/user-cadastro', userData);
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.isAuthenticatedStatus = false;

    this.userService.user.next(null);

    this.premiumService.vinculatedCompany.next(null);

    return Storage.remove({ key: TOKEN_KEY });
  }

  async signInApple(callback = (value) => {}) {
    try {
      const { response } = await SignInWithApple.authorize(options);
      const userData = {
        name: response.givenName + ' ' + response.familyName,
        email: response.email,
        apple_authorization_token: response.authorizationCode,
        apple_identity_token: response.identityToken,
        apple_user: response.user,
      };

      const loading = await this.loadingController.create();
      await loading.present();

      this.loginApple(userData).subscribe(
        async (res) => {
          await loading.dismiss();
          callback(res);
        },
        async (err) => {
          await loading.dismiss();
        },
      );
    } catch (err) {
      console.log(err);
    }
  }

  loginFacebook(data): Observable<any> {
    return this.http.post(API_URL + '/user-facebook-cadastro', data).pipe(
      map((res: any) => {
        this.facebookRegister = res.facebook_cadastro;
        Storage.set({
          key: TOKEN_KEY,
          value: JSON.stringify(res.token),
        });
        return res;
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
        return _;
      }),
    );
  }

  loginGoogle(data): Observable<any> {
    return this.http.post(API_URL + '/user-google-cadastro', data).pipe(
      map((res: any) => {
        this.facebookRegister = res.google_cadastro;

        Storage.set({
          key: TOKEN_KEY,
          value: JSON.stringify(res.token),
        });
        return res;
      }),
      tap((res) => {
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
        return res;
      }),
    );
  }

  loginApple(data): Observable<any> {
    return this.http.post(API_URL + '/user-apple-cadastro', data).pipe(
      map((res: any) => {
        this.facebookRegister = res.apple_cadastro;

        Storage.set({
          key: TOKEN_KEY,
          value: JSON.stringify(res.token),
        });
        return res;
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
        return _;
      }),
    );
  }

  /**
   * Get Access token by subscriberId
   * @author André Ramos <oi@andreramos.dev>
   * @param companyId integer
   * @param subscriberId string
   */
  loginSubscriber(companyId, subscriberId): Observable<any> {
    const subscriberData = {
      subscriber_id: subscriberId,
      empresa_id: companyId,
    };
    const url: string = API_URL + `/login-subscriber`;

    return this.http.post(url, subscriberData).pipe(
      map((token) => {
        console.log('LOGIN SUBSCRIBER token: ', token);
        Storage.set({
          key: TOKEN_KEY,
          value: JSON.stringify(token),
        });
        return token;
      }),
      tap((response) => {
        console.log('LOGIN SUBSCRIBER response: ', response);
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
        return response;
      }),
    );
  }

  /**
   * Get Access token by loginData
   * @author André Ramos <oi@andreramos.dev>
   * @param companyId integer
   * @param loginData string
   */
  loginInterFocusData(companyId, loginData): Observable<any> {
    const subscriberData = {
      idassinante: loginData.idassinante,
      chavebase: loginData.chavebase,
      empresa_id: companyId,
    };
    const url: string = API_URL + `/login-interfocus`;

    return this.http.post(url, subscriberData).pipe(
      map((token) => {
        console.log('LOGIN InterFocusData token: ', token);
        Storage.set({
          key: TOKEN_KEY,
          value: JSON.stringify(token),
        });
        return token;
      }),
      tap((response) => {
        console.log('LOGIN InterFocusData response: ', response);
        this.isAuthenticated.next(true);
        this.isAuthenticatedStatus = true;
        return response;
      }),
    );
  }

  public facebookRegisterBoolean(): boolean {
    return this.facebookRegister;
  }

  setForgotPassword(data): Observable<any> {
    const url: string = API_URL_V2 + '/forgot-password';
    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  setCodeToRedefinePassword(data): Observable<any> {
    const url: string = API_URL_V2 + '/forgot-password/code';
    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
