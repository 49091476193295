import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as TrainingProgramsActions from '../actions/trainingprograms.actions';

import { TrainingProgramsService } from 'src/app/services/training-programs.service';
import { Training } from 'src/app/models/training';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class TrainingProgramsEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingProgramsActions.getTrainingPrograms),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.TRAINING_PROGRAMS,
        }),
      ),
      switchMap(() => {
        return this.trainingProgramsService.getTrainingPrograms().pipe(
          map((res: any) => {
            var auxRes = res;

            return TrainingProgramsActions.getTrainingProgramsSuccess({
              payload: auxRes,
            });
          }),
          catchError((error) =>
            of(
              TrainingProgramsActions.getTrainingProgramsError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getTrainingProgramsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingProgramsActions.getTrainingProgramsSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_PROGRAMS,
        }),
      ),
    ),
  );

  getTrainingProgramsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingProgramsActions.getTrainingProgramsError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.TRAINING_PROGRAMS,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingProgramsService: TrainingProgramsService,
    private store: Store,
    private router: Router,
  ) {}
}
