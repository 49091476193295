import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import SwiperCore, { Autoplay, Pagination } from 'swiper';

import { IonicSlides } from '@ionic/angular';
import { Banner } from 'src/app/models/banner';

SwiperCore.use([Autoplay, Pagination, IonicSlides]);
@Component({
  selector: 'btt-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() items: Banner[];
  @Input() isWeb: boolean;

  @Input() options = {
    loop: true,
    speed: 1000,
    pager: true,
    autoplay: {
      delay: 4000,
    },
  };

  @Output() onClick: EventEmitter<CustomEvent> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  items_web = (value) => {
    return value.filter((item) => item?.imagem_web);
  };

  /** Método que valida a entrada das variáveis **onClick** e **onClickDisabled**,
   * quando a propriedade **isLoading** for _true_, o botão não apresentará ação de click,
   * caso **disabled** for _true_ e a função **onClickDisable** for informada, ao clicar
   * será executado o método da propriedade **onClickDisable**.
   *
   * @ignore
   */
  public onHandleClick(event?: CustomEvent): void {
    this.onClick.emit(event);
  }
}
