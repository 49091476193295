import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  environment,
  API_URL_V2,
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getCategory(type): Observable<any> {
    const url: string = API_URL + `/categorias-${type}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
  getCategoryRevenues(): Observable<any> {
    const url: string = API_URL + `/categorias-receitas`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
  getCategoryTips(): Observable<any> {
    const url: string = API_URL + `/categorias-dicas`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getCategoryItem(params): Observable<any> {
    const url: string = API_URL + `/categorias-dicas-receitas/${params}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
