import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

// actions
import * as StateCityActions from "src/app/store/actions/state-city.actions";
// selectors
import {
  getStates,
  getCities,
} from "src/app/store/selectors/state-city.selectors";

@Component({
  selector: "app-btt-alert-permission",
  templateUrl: "./btt-alert-permission.component.html",
  styleUrls: ["./btt-alert-permission.component.scss"],
})
export class BttAlertPermissionComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Input() localIcon: string;
  @Input() htmlComponent: any;

  @Input() colorIcon?: string = "white";
  @Input() changeFlow?: boolean = false;

  termAccept: boolean = false;
  allowSharing: boolean = false;
  userProgressStep: 1 | 2 = 1;

  listStates$: Observable<any>;
  listCities$: Observable<any>;
  cities: any;

  uf: string;
  cidade: string;
  citySearch: string = '';

  @ViewChild("progressBar") progressBar: ElementRef;

  constructor(private modalController: ModalController, private store: Store) {}

  ngOnInit() {
    this.getStates();
  }

  handleClickClose() {
    this.modalController.dismiss(null, "cancel");
  }

  handleClickConfirm() {
    const data = {
      termAccept: this.termAccept,
      allowSharing: this.allowSharing,
      uf: this.uf,
      cidade: this.cidade,
    };
    return this.modalController.dismiss(data, "confirm");
  }

  async handleTerm(event?: CustomEvent) {
    if (this.termAccept) {
      this.setProgressBar(1);
    } else {
      this.setProgressBar(0);
    }
    if (event) return await (this.termAccept = event.detail.checked);
    this.termAccept = !this.termAccept;
  }

  handleSetAllowSharing(event?: CustomEvent) {
    if (event) return (this.allowSharing = event.detail.checked);

    this.allowSharing = !this.allowSharing;
  }

  handleUsetSetNextStep() {
    if (this.userProgressStep === 1) {
      this.userProgressStep = 2;
      this.setProgressBar(2);
    }
  }

  setProgressBar(step: number) {
    switch (step) {
      case 0:
        this.progressBar.nativeElement.style.width = "0%";
        break;
      case 1:
        this.progressBar.nativeElement.style.width = "25%";
        break;
      case 2:
        this.progressBar.nativeElement.style.width = "50%";
        break;
      case 3:
        this.progressBar.nativeElement.style.width = "75%";
        break;
      case 4:
        this.progressBar.nativeElement.style.width = "100%";
        break;
      default:
        return;
    }
  }

  getStates() {
    this.store.dispatch(StateCityActions.getStates());
    this.listStates$ = this.store.select(getStates);
  }
  getCities(selectedState) {
    this.uf = selectedState.target.value;

    this.store.dispatch(
      StateCityActions.getCities({ payload: selectedState.target.value }),
    );
    this.listCities$ = this.store.select(getCities);
    this.listCities$.subscribe((res) => (this.cities = res));
    this.citySearch = '';
  }

  handleChangeCity(selectedCity) {
    this.cidade = selectedCity;
    this.setProgressBar(3);
  }

  searchCity(event) {
    event.preventDefault();
    this.citySearch = event.target.value;
  }

  getSelectedListResponseWithFilter() {
    if (this.citySearch == '') {
      return this.cities;
    }

    let auxSelectedListResponse = this.cities?.filter(
      (dado) =>
        dado?.nome?.toLowerCase().indexOf(this.citySearch.toLowerCase()) > -1,
    );

    return auxSelectedListResponse;
  }
}
