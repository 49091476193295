import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { from } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { Injectable } from "@angular/core";

import { StorageService } from "src/app/services/storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    token: any;
    constructor(
        private router: Router,
        public toastController: ToastController,
        private storage: StorageService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return from(this.storage.getItem("access_token")).pipe(
            mergeMap((token) => {
                if (token) {
                    // clone and modify the request
                    request = request.clone({
                        setHeaders: {
                            Authorization: `${token.token_type} ${token.access_token}`,
                        },
                    });
                }

                return next.handle(request);
            })
        );
    }
}
