import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-item-training-modality",
    templateUrl: "./item-training-modality.component.html",
    styleUrls: ["./item-training-modality.component.scss"],
})
export class ItemTrainingModalityComponent implements OnInit {
    @Input() t;
    @Input() userPremium;
    @Input() modalityPremium;

    constructor() {}

    ngOnInit() {}
}
