import { createReducer, on } from '@ngrx/store';
import * as TrainingTypesActions from '../actions/trainingTypes.actions';

import { TrainingTypes } from 'src/app/models/trainingTypes';

export interface TrainingTypesState {
  trainingTypes: TrainingTypes[];
}

const initialState: TrainingTypesState = {
  trainingTypes: [],
};

export const trainingTypesReducer = createReducer(
  initialState,
  on(
    TrainingTypesActions.getTrainingTypesSuccess,
    (state, { payload: trainingTypes }) => {
      return {
        ...state,
        trainingTypes,
      };
    }
  )
);
