import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import {
  SERVER_URL,
  API_URL,
  API_URL_V3,
  API_URL_V4,
  API_URL_V5,
  environment,
  API_URL_V6,
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrainingProgramsService {
  constructor(private http: HttpClient) {}

  /**
   * Get training programs (Desafios)
   * @return json
   * @author André Ramos <oi@andreramos.dev>
   */
  getTrainingPrograms(): Observable<any> {
    const url: string = API_URL_V6 + '/desafios/lists';

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  /**
   * Get Details of TrainingProgram / Desafio
   * @author André Ramos <oi@andreramos.dev>
   * @param id ID of Desafio
   * @returns Observable with json
   */
  getDetail(id: number): Observable<any> {
    const url = `${API_URL_V5}/desafios/details/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getExerciseById(id: number): Observable<any> {
    const url = `${API_URL}/exercicio/${id}`;

    return this.http.get(url).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
