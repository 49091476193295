import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as CustomPremiumActions from '../actions/customPremium.actions';

import { PremiumService } from 'src/app/services/premium.service';
import { CustomPremium } from 'src/app/models/custompremium';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { ModalController } from '@ionic/angular';

// modal
import { CustomPremiumModalPage } from 'src/app/pages/_modals/custom-premium-modal/custom-premium-modal.page';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';
// selector
import * as PremiumSelectors from 'src/app/store/selectors/premium.selectors';

// service

@Injectable()
export class CustomPremiumEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomPremiumActions.getCustomPremium),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.CUSTOM_PREMIUM,
        }),
      ),
      switchMap(() => {
        return this.premiumService.getCustomPremium().pipe(
          map((res: any) => {
            let auxResponse: CustomPremium[] = res?.registros.filter(
              (auxCustomPremium) => auxCustomPremium.status === 1,
            );
            this.setBehavior(auxResponse);
            return CustomPremiumActions.getCustomPremiumSuccess({
              payload: auxResponse,
            });
          }),
          catchError((error) =>
            of(CustomPremiumActions.getCustomPremiumError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getCustomPremiumSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomPremiumActions.getCustomPremiumSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CUSTOM_PREMIUM,
        }),
      ),
    ),
  );

  getCustomPremiumError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomPremiumActions.getCustomPremiumError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.CUSTOM_PREMIUM,
        }),
      ),
    ),
  );

  async setBehavior(auxResponse) {
    let auxIsPremium;
    this.store
      .select(PremiumSelectors.getPremiumStatus)
      .subscribe((premiumStatus) => {
        console.log(`[status premium]=> `, premiumStatus);
        auxIsPremium = premiumStatus.premium;
      });

    if (auxResponse.length === 0 || auxIsPremium) {
      this.router.navigateByUrl('/premium', {
        state: { useNavControlBack: true },
      });
    } else {
      this.openModal();
    }
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: CustomPremiumModalPage,
      // cssClass: 'modal-rate',
      componentProps: {},
    });
    await modal.present();
  }

  constructor(
    private actions$: Actions,
    private premiumService: PremiumService,
    private store: Store,
    private router: Router,
    private modalController: ModalController,
  ) {}
}
