import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, flatMap, map, switchMap, take, tap } from "rxjs/operators";
import * as BannersActions from "../actions/banners.actions";

import { UserService } from "src/app/services/user.service";
import { Banner } from "src/app/models/banner";
import { Router } from "@angular/router";

import * as LoadingActions from "../actions/loading.actions";

// enum
import { loadingTypesEnum } from "src/app/enums/loadingTypes.enum";

@Injectable()
export class BannersEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBanners),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.BANNERS }),
      ),
      switchMap(() => {
        return this.userService.getBanners().pipe(
          map((res: any) => {
            const banners: Banner[] = res;

            return BannersActions.getBannersSuccess({
              payload: banners,
            });
          }),
          catchError((error) =>
            of(BannersActions.getBannersError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getBannersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBannersSuccess),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.BANNERS }),
      ),
    ),
  );

  getBannersError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBannersError),
      map(() =>
        LoadingActions.ocultarLoading({ payload: loadingTypesEnum.BANNERS }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
  ) {}
}
