// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client_id: 2,
  client_secret: "ZtQZgxysBRhygcSrHhQ70FPbghXOqibQkeVNm5bm",
  // client_secret: "WUwJzW6ELMTiPu6S5X0keEyEKorM1gXvPbNDWrQ9", //Heli
  // client_secret: '57Bq6RYizkLEGr8t3cyz09SICSvJ51cJv3Ozh0XM', //Joaquim
  ignoredFeedCompaniesIDs: [64],
  mixpanelToken: 'ea9146569ad534e45a49a1deb66e0894'
};

export const SERVER_URL = "https://api.bittrainers.com.br";
// export const SERVER_URL = 'https://testes.bittrainers.com.br'; //API de testes
// export const SERVER_URL = 'http://192.168.0.19:8181'; //Heli
// export const SERVER_URL = 'http://localhost:32769'; //André

export const API_URL = `${SERVER_URL}/api`;

export const API_URL_V2 = `${SERVER_URL}/api/v2`;

export const API_URL_V3 = `${SERVER_URL}/api/v3`;

export const API_URL_V4 = `${SERVER_URL}/api/v4`;

export const API_URL_V5 = `${SERVER_URL}/api/v5`;

export const API_URL_V6 = `${SERVER_URL}/api/v6`;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
