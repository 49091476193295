import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as SearchResultActions from '../actions/searchResult.actions';

import { SearchService } from 'src/app/services/search.service';
import { Banner } from 'src/app/models/banner';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';
import { SearchResult } from 'src/app/models/searchResult';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class SearchResultEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultActions.getSearchResult),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.SEARCH_RESULT,
        }),
      ),
      switchMap(({ payload: searchValue }) => {
        return this.searchService.getSearchResult(searchValue).pipe(
          map((res: any) => {
            let auxResponse: SearchResult = res.registros;

            return SearchResultActions.getSearchResultSuccess({
              payload: auxResponse,
            });
          }),
          catchError((error) =>
            of(SearchResultActions.getSearchResultError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getSearchResultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultActions.getSearchResultSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SEARCH_RESULT,
        }),
      ),
    ),
  );

  getSearchResultError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultActions.getSearchResultError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.SEARCH_RESULT,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private store: Store,
    private router: Router,
  ) {}
}
