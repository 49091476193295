import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getTrainingPrograms = createAction(
  '[TrainingPrograms] Get TrainingPrograms'
);

export const getTrainingProgramsSuccess = createAction(
  '[TrainingPrograms] Get TrainingPrograms Success',
  props<{ payload: any }>()
);

export const getTrainingProgramsError = createAction(
  '[TrainingPrograms] Get TrainingPrograms Error',
  props<{ payload: HttpErrorResponse }>()
);
