import { createSelector } from '@ngrx/store';
import * as fromCore from '../reducers/index';

export const getUserNotifications = createSelector(
  fromCore.getNotificationsState,
  (state) => {
    state.notifications;
  },
);

export const readAllUserNotifications = createSelector(
  fromCore.getNotificationsState,
  (state) => {
    state.notifications;
    state.hasUnreaded;
  },
);

export const getLoading = createSelector(
  fromCore.getNotificationsState,
  (state) => state.loading,
);
