import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btt-like-content',
  templateUrl: './btt-like-content.component.html',
  styleUrls: ['./btt-like-content.component.scss'],
})
export class BttLikeContentComponent implements OnInit {
  @Input() isLiked: boolean = false;
  @Input() isDisliked: boolean = false;

  @Output() handleSetLike: EventEmitter<any> = new EventEmitter();
  @Output() handleSetDislike: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  // xor logic where both data are mutually exclusive
  handleClickSetLike() {
    this.handleSetLike.emit();
    this.isLiked = !this.isLiked;
    this.isDisliked = false;
  }
  handleClickSetDislike() {
    this.handleSetDislike.emit();
    this.isDisliked = !this.isDisliked;
    this.isLiked = false;
  }
}
