import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';

@Injectable({
  providedIn: 'root',
})
export class AutoUpdate implements CanLoad {
  constructor(private router: Router) {}

  async canLoad(): Promise<boolean> {
    let enableOpenPage: boolean = false;

    await AppUpdate.getAppUpdateInfo()
      .then((appUpdateInfo) => {
        // Check for an update for the App in the store
        if (
          appUpdateInfo.updateAvailability ===
          AppUpdateAvailability.UPDATE_AVAILABLE 
        ) {
          // Open the auto update page passing the information from appUpdateInfo
          this.router.navigateByUrl('/auto-update', {
            state: {
              appUpdateInfo: appUpdateInfo,
            },
            replaceUrl: true,
          });
        } else {
          enableOpenPage = true;
        }
      })
      .catch(() => (enableOpenPage = true));

    return enableOpenPage;
  }
}
