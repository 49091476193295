import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "vimeoThumb",
})
export class VimeoThumbPipe implements PipeTransform {
    transform(pictureUrl: string): string {
        if (pictureUrl) {
            const strReplace = "/pictures/";

            const indexPictureId = pictureUrl.indexOf(strReplace);

            const pictureId = pictureUrl.slice(
                indexPictureId + strReplace.length
            );
            return `https://i.vimeocdn.com/video/${pictureId}_640x360.jpg?r=pad`;
        }
    }
}
