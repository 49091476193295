import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as MyFavoriteContentActions from '../actions/myFavoriteContent.actions';

import { UserService } from 'src/app/services/user.service';
import { MyFavoriteContent } from 'src/app/models/myFavoriteContent';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class MyFavoriteContentEffects {
  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyFavoriteContentActions.getMyFavoriteContent),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.MY_FAVORITE_CONTENT,
        }),
      ),
      switchMap(() => {
        return this.userService.getMyFavoriteContent().pipe(
          map((res: any) => {
            const auxMyFavoriteContent: MyFavoriteContent = res.registros;

            return MyFavoriteContentActions.getMyFavoriteContentSuccess({
              payload: auxMyFavoriteContent,
            });
          }),
          catchError((error) =>
            of(
              MyFavoriteContentActions.getMyFavoriteContentError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  geMyFavoriteContentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyFavoriteContentActions.getMyFavoriteContentSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MY_FAVORITE_CONTENT,
        }),
      ),
    ),
  );

  getMyFavoriteContentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyFavoriteContentActions.getMyFavoriteContentError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.MY_FAVORITE_CONTENT,
        }),
      ),
    ),
  );

  constructor(private actions$: Actions, private userService: UserService) {}
}
