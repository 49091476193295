import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';

@Component({
  selector: "app-btt-footer-web",
  templateUrl: "./btt-footer-web.component.html",
  styleUrls: ["./btt-footer-web.component.scss"]
})
export class BttFooterWebComponent implements OnInit {
  isWeb: boolean;

  constructor(private platform: Platform) {
    platform.ready().then(() => {
      if (!platform.is("mobile")) {
        this.isWeb = true;
      } else {
        this.isWeb = false;
      }
    });
  }

  ngOnInit() {
    this.checkIfWeb();
    window.addEventListener("resize", () => this.checkIfWeb());
  }

  checkIfWeb(): void {
    this.isWeb = this.platform.is("desktop");
    if (!this.isWeb) {
      this.isWeb = window.innerWidth >= 769;
    }
  }

  async openInstagram() {
    const browser = await Browser.open({
      url: `https://instagram.com/sejabitt`
    });
  }
  async openFacebook() {
    const browser = await Browser.open({
      url: `https://www.facebook.com/sejabitt`
    });
  }
}
