import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { IntroGuard } from "./guards/intro.guard";
import { AutoLoginGuard } from "./guards/auto-login.guard";
import { WebAccess } from "./guards/webAccess.guard";
import { AutoUpdate } from "./guards/autoUpdate.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/landing",
    // canLoad: [AutoLoginGuard],
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canLoad: [WebAccess, AutoUpdate, IntroGuard, AutoLoginGuard],
  },
  {
    path: "intro",
    loadChildren: () =>
      import("./pages/intro/intro.module").then((m) => m.IntroPageModule),
    canLoad: [WebAccess],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
    canLoad: [IntroGuard, AuthGuard, AutoUpdate],
  },
  {
    path: "home/:id",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
    canLoad: [IntroGuard, AuthGuard, AutoUpdate],
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: "adhesison-contract-modal",
    loadChildren: () =>
      import(
        "./pages/_modals/adhesison-contract-modal/adhesison-contract-modal.module"
      ).then((m) => m.AdhesisonContractModalPageModule),
  },
  {
    path: "main",
    loadChildren: () =>
      import("./pages/home/main/main.module").then((m) => m.MainPageModule),
    canLoad: [],
  },
  {
    path: "landing-web",
    loadChildren: () =>
      import("./pages/landing-web/landing-web.module").then(
        (m) => m.LandingWebPageModule
      ),
    canLoad: [IntroGuard, AutoLoginGuard],
  },
  {
    path: "landing",
    loadChildren: () =>
      import("./pages/landing/landing.module").then((m) => m.LandingPageModule),
    canLoad: [WebAccess, IntroGuard, AutoUpdate, AutoLoginGuard],
  },
  {
    path: "login-company",
    loadChildren: () =>
      import("./pages/login-company/login-company.module").then(
        (m) => m.LoginCompanyPageModule
      ),
  },
  {
    path: "login-company-alert",
    loadChildren: () =>
      import(
        "./pages/_alert/login-company-alert/login-company-alert.module"
      ).then((m) => m.LoginCompanyAlertPageModule),
  },
  {
    path: "social-login",
    loadChildren: () =>
      import("./pages/_modals/social-login/social-login.module").then(
        (m) => m.SocialLoginPageModule
      ),
  },
  {
    path: "exercise-modal",
    loadChildren: () =>
      import("./pages/_modals/exercise-modal/exercise-modal.module").then(
        (m) => m.ExerciseModalPageModule
      ),
  },
  {
    path: "premium",
    loadChildren: () =>
      import("./pages/premium/premium.module").then((m) => m.PremiumPageModule),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./pages/notifications/notifications.module").then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: "social-sharing",
    loadChildren: () =>
      import("./pages/social-sharing/social-sharing.module").then(
        (m) => m.SocialSharingPageModule
      ),
  },
  {
    path: "video-modal",
    loadChildren: () =>
      import("./pages/_modals/video-modal/video-modal.module").then(
        (m) => m.VideoModalPageModule
      ),
  },
  {
    path: "history",
    loadChildren: () =>
      import("./pages/history/history.module").then((m) => m.HistoryPageModule),
  },
  {
    path: "user-profile",
    loadChildren: () =>
      import("./pages/user-profile/user-profile.module").then(
        (m) => m.UserProfilePageModule
      ),
  },
  {
    path: "personality",
    loadChildren: () =>
      import("./pages/personality/personality.module").then(
        (m) => m.PersonalityPageModule
      ),
  },

  {
    path: "training-programs-workouts",
    loadChildren: () =>
      import(
        "./pages/training-programs/training-programs-workouts/training-programs-workouts.module"
      ).then((m) => m.TrainingProgramsWorkoutsPageModule),
  },
  {
    path: "training-programs-home",
    loadChildren: () =>
      import(
        "./pages/training-programs/training-programs-home/training-programs-home.module"
      ).then((m) => m.TrainingProgramsHomePageModule),
  },
  {
    path: "training-programs",
    loadChildren: () =>
      import("./pages/training-programs/training-programs.module").then(
        (m) => m.TrainingProgramsPageModule
      ),
  },
  {
    path: "search-result-modal",
    loadChildren: () =>
      import(
        "./pages/_modals/search-result-modal/search-result-modal.module"
      ).then((m) => m.SearchResultModalPageModule),
  },
  {
    path: "change-password-modal",
    loadChildren: () =>
      import(
        "./pages/_modals/change-password-modal/change-password-modal.module"
      ).then((m) => m.ChangePasswordModalPageModule),
  },

  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "custom-premium-modal",
    loadChildren: () =>
      import(
        "./pages/_modals/custom-premium-modal/custom-premium-modal.module"
      ).then((m) => m.CustomPremiumModalPageModule),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("./pages/_modals/calendar/calendar.module").then(
        (m) => m.CalendarPageModule
      ),
  },
  {
    path: "auto-update",
    loadChildren: () =>
      import("./pages/auto-update/auto-update.module").then(
        (m) => m.AutoUpdatePageModule
      ),
  },
  {
    path: "interactions-feed",
    loadChildren: () =>
      import("./pages/_modals/interactions-feed/interactions-feed.module").then(
        (m) => m.InteractionsFeedPageModule
      ),
  },
  {
    path: "feed-sharing",
    loadChildren: () =>
      import("./pages/feed-sharing/feed-sharing.module").then(
        (m) => m.FeedSharingPageModule
      ),
  },
  {
    path: "verification",
    loadChildren: () =>
      import("./pages/verification/verification.module").then(
        (m) => m.VerificationPageModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
    canLoad: [AutoLoginGuard],
  },
  {
    path: "successfully-reset-password",
    loadChildren: () =>
      import(
        "./pages/_modals/successfully-reset-password/successfully-reset-password.module"
      ).then((m) => m.SuccessfullyResetPasswordModalModule),
  },
  {
    path: "selfie",
    loadChildren: () =>
      import("./pages/selfie/selfie.module").then((m) => m.SelfiePageModule),
  },
  {
    path: "report",
    loadChildren: () =>
      import("./pages/_modals/report/report.module").then(
        (m) => m.ReportPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
