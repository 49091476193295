import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-card-preview-responsive',
  templateUrl: './card-preview-responsive.component.html',
  styleUrls: ['./card-preview-responsive.component.scss'],
})
export class CardPreviewResponsiveComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;

  @Output() onClick: EventEmitter<CustomEvent> = new EventEmitter();

  @Input() isWeb: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  handleClick(event) {
    this.onClick.emit(event);
  }
}
