import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as OrientationsActions from '../actions/orientations.actions';

import { NutritionService } from 'src/app/services/nutrition.service';
import {
  IDataOrientations,
  IPaginateOrientations,
} from 'src/app/shared/models/orientations.model';

@Injectable()
export class OrientationsEffects {
  getNutritionItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsActions.getNutritionItems),
      switchMap(({ payload: { type, search, per_page, page } }) => {
        let params = search ? type + `?titulo=${search}` : type;
        params = per_page
          ? params +
            `${search ? '&' : '?'}per_page=${per_page}&page=${page ?? 1}`
          : params;
        return this.nutritionService.getNutritionItems(params).pipe(
          map((res: IPaginateOrientations) => {
            let auxResFinal = { ...res, data: res.data.sort(this.orderByDate) };
            return OrientationsActions.getNutritionItemsSuccess({
              payload: auxResFinal,
            });
          }),
          catchError((error) =>
            of(OrientationsActions.getNutritionItemsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  orderByDate(a, b) {
    let auxA = a.release_date
      ? new Date(a.release_date)
      : new Date(a.created_at);
    let auxB = b.release_date
      ? new Date(b.release_date)
      : new Date(b.created_at);

    // @ts-ignore
    return auxB - auxA;
  }

  getNutritionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsActions.getNutritionItem),
      switchMap(({ payload: { id } }) => {
        return this.nutritionService.getItem(id).pipe(
          map((res: IDataOrientations) => {
            return OrientationsActions.getNutritionItemSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(OrientationsActions.getNutritionItemsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getPopularItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsActions.getPopularItems),
      switchMap(({ payload: { type, popular, per_page, page } }) => {
        let params = popular ? type + `?popular=${popular}` : type;
        params = per_page
          ? params + `${popular && '&'}per_page=${per_page}&page=${page ?? 1}`
          : params;
        return this.nutritionService.getNutritionItems(params).pipe(
          map((res: IPaginateOrientations) => {
            let auxResFinal = res;
            return OrientationsActions.getPopularItemsSuccess({
              payload: auxResFinal,
            });
          }),
          catchError((error) =>
            of(OrientationsActions.getPopularItemsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getNutritionItemsCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsActions.getNutritionItemsCategory),
      switchMap(({ payload: { type, search, per_page, page } }) => {
        let params = search ? type + `?titulo=${search}` : type;
        params = per_page
          ? params +
            `${search ? '&' : '?'}per_page=${per_page}&page=${page ?? 1}`
          : params;
        return this.nutritionService.getNutritionItems(params).pipe(
          map((res: IPaginateOrientations) => {
            let auxResFinal = { ...res, data: res.data.sort(this.orderByDate) };
            return OrientationsActions.getNutritionItemsCategorySuccess({
              payload: auxResFinal,
            });
          }),
          catchError((error) =>
            of(
              OrientationsActions.getNutritionItemsCategoryError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private nutritionService: NutritionService,
    private store: Store,
  ) {}
}
