import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as LoginActions from 'src/app/store/actions/login.actions';
// analytics
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: "app-btt-header-user-options",
  templateUrl: "./btt-header-user-options.component.html",
  styleUrls: ["./btt-header-user-options.component.scss"]
})
export class BttHeaderUserOptionsComponent implements OnInit {
  bttLogout = "assets/custom-icon/btt-logout.svg";

  constructor(
    private router: Router,
    private store: Store,
    private analytics: AnalyticsService,
    private popoverController: PopoverController
  ) {}

  ngOnInit() {}

  async setOpenProfile() {
    this.router.navigateByUrl("/home/profile-web", { replaceUrl: true });
    await this.popoverController.dismiss();
  }

  async openTrainingPrograms() {
    this.router.navigateByUrl("/home/training-programs-home", {
      replaceUrl: true
    });
    await this.popoverController.dismiss();
  }

  async openNutritionHealth() {
    this.router.navigateByUrl("/home/orientations", {
      replaceUrl: true
    });
    await this.popoverController.dismiss();
  }

  async openGymTraining() {
    this.router.navigateByUrl("/home/training-plan", {
      replaceUrl: true
    });
    await this.popoverController.dismiss();
  }

  async openExercises() {
    this.router.navigateByUrl("/home/exercises-search", {
      replaceUrl: true
    });
    await this.popoverController.dismiss();
  }

  // logout
  async logout() {
    this.store.dispatch(
      LoginActions.logout({
        payload: {
          user: null
        }
      })
    );
    await this.popoverController.dismiss();
  }
}
