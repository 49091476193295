import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as PersonalityActions from '../actions/personality.actions';

import { UserService } from 'src/app/services/user.service';
import { Personality } from 'src/app/models/personality';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class PersonalityEffects {
  // setOPersonality
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalityActions.setPersonality),
      tap(() =>
        LoadingActions.exibirLoading({ payload: loadingTypesEnum.PERSONALITY }),
      ),
      switchMap(({ payload }) => {
        return this.userService.setUserPersonality(payload).pipe(
          map((res: any) => {
            return PersonalityActions.setPersonalitySuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(PersonalityActions.setPersonalityError({ payload: error })),
          ),
        );
      }),
    ),
  );

  setObjectivesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalityActions.setPersonalitySuccess),
      map(() => {
        this.router.navigate(['/personality/form-objective'], {
          replaceUrl: true,
        });

        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PERSONALITY,
        });
      }),
    ),
  );

  setObjectivesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalityActions.setPersonalityError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.PERSONALITY,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private router: Router,
  ) {}
}
