import { createReducer, on } from '@ngrx/store';
import * as CustomPremiumActions from '../actions/customPremium.actions';

import { CustomPremium } from 'src/app/models/custompremium';

export interface CustomPremiumState {
  customPremium: CustomPremium[];
}

const initialState: CustomPremiumState = {
  customPremium: [],
};

export const customPremiumReducer = createReducer(
  initialState,
  on(
    CustomPremiumActions.getCustomPremiumSuccess,
    (state, { payload: customPremium }) => {
      return {
        ...state,
        customPremium,
      };
    },
  ),
);
