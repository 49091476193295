import { createReducer, on } from '@ngrx/store';
import * as FirstAccessActions from '../actions/firstaccess.actions';
import * as LoginActions from '../actions/login.actions';

import { Banner } from 'src/app/models/banner';

export interface FirstAccessState {
  firstAccess: boolean;
}

const initialState: FirstAccessState = {
  firstAccess: true,
};

export const firstAccessReducer = createReducer(
  initialState,
  on(FirstAccessActions.setFirstAccess, (state, { payload: firstAccess }) => {
    return {
      ...state,
      firstAccess,
    };
  }),
  on(LoginActions.logout, (state, {}) => {
    return { ...state, firstAccess: true };
  }),
);
