import { Component, OnInit } from '@angular/core';

import { LoadingController, AlertController } from '@ionic/angular';

import { NotificationsService } from 'src/app/services/notifications.service';

import { AnalyticsService } from 'src/app/services/analytics.service';

import * as NotificationsSelectors from 'src/app/store/selectors/notifications.selectors';
import * as NotificationsActions from 'src/app/store/actions/notifications.actions';
import { Store } from '@ngrx/store';

import { Browser } from '@capacitor/browser';
import { Observable } from 'rxjs';
@Component({
  selector: 'btt-menu-notifications',
  templateUrl: './btt-menu-notifications.component.html',
  styleUrls: ['./btt-menu-notifications.component.scss'],
})
export class BttMenuNotificationsComponent implements OnInit {
  notifications$: Observable<any>;
  loading$: Observable<boolean>;

  constructor(
    public notificationsService: NotificationsService,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.analytics.trackScreen('notifications', 'NotificaçõesScreen');
    // this.notificationsService.getUserNotifications();
    this.setupNotifications();
  }

  setupNotifications() {
    this.notifications$ = this.store.select(
      NotificationsSelectors.getUserNotifications,
    );
    this.loading$ = this.store.select(NotificationsSelectors.getLoading);

    this.store.dispatch(
      NotificationsActions.getUserNotifications({
        payload: null,
      }),
    );

    // this.print(this.notifications$);
  }

  async clearNotifications() {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.notificationsService.deleteUserNotifications().subscribe(
      async (res) => {
        await loading.dismiss();
        this.analytics.trackEvent('notifications_clear');
      },
      async (err) => {
        await loading.dismiss();
      },
    );
  }

  async markAllReaded() {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    this.notificationsService.readAllUserNotifications().subscribe(
      async (res) => {
        this.analytics.trackEvent('notifications_read_all');
        this.notificationsService.getUserNotifications().subscribe(
          async (response) => {
            await loading.dismiss();
            const alert = await this.alertCtrl.create({
              header: 'Sucesso!',
              subHeader: 'Suas notificações foram lidas!',
              buttons: ['OK'],
            });
            alert.present();
          },
          async (err) => {
            await loading.dismiss();
          },
        );
      },
      async (err) => {
        await loading.dismiss();
      },
    );
  }

  async openUrl(url) {
    await Browser.open({ url });
  }

  print(value: any) {
    // console.log('NOTIFICATIONS==>', value);
    alert(`NOTIFICATIONS==> ${value}`);
  }
}
