import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btt-checkbox',
  templateUrl: './btt-checkbox.component.html',
  styleUrls: ['./btt-checkbox.component.scss']
})
export class BttCheckboxComponent implements OnInit {
  @Input() label;
  @Input() name;
  @Input() checked;
  @Input() disabled;
  @Input() value;

  @Input() secondaryButtonText?;

	/** Prop para ser chamada no clique de algum checkbox retonando a listagem atualizada.
	 *
	 * @required
	 */
  @Output() onChange: EventEmitter<any> = new EventEmitter();


  @Output() onClickSecondary: EventEmitter<CustomEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

	/** @ignore */
	public onCheckBoxChange(event: CustomEvent): void {
		this.onChange.emit(event.detail.checked);
	}

  /** Método que valida a entrada das variáveis **onClick** e **onClickDisabled**,
   * quando a propriedade **isLoading** for _true_, o botão não apresentará ação de click,
   * caso **disabled** for _true_ e a função **onClickDisable** for informada, ao clicar
   * será executado o método da propriedade **onClickDisable**.
   *
   * @ignore
   */
  public onHandleClick(event?: CustomEvent): void {
    this.onClickSecondary.emit(event);
  }

}
