import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, flatMap, map, switchMap, take, tap } from "rxjs/operators";
import * as FeedsActions from "../actions/feed.actions";
import * as LoadingActions from "../actions/loading.actions";

import { FeedService } from "src/app/services/feed.service";
import {
  IFeed,
  IFeedData,
  IInteractionFeed,
  IInteractionFeedPage,
  IMessagesFeed,
  IReactions,
} from "src/app/models/feed";
import { loadingTypesEnum } from "src/app/enums/loadingTypes.enum";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";

@Injectable()
export class FeedsEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private feedService: FeedService,
    private alertController: AlertController,
  ) {}

  formatDate(date: string) {
    const dateFormat: string = new Date(date).toLocaleDateString("pt-br", {
      year: "numeric",
      month: "long" || "short" || "numeric",
      // weekday: 'long' || 'short',
      day: "numeric",
    });
    return dateFormat;
  }

  getFeeds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getFeeds),
      switchMap(
        ({ payload: { page, per_page, empresa_id, verificado, auxData } }) => {
          const params =
            `?page=${page}&per_page=${per_page}` +
            (empresa_id ? `&empresa_id=${empresa_id}` : "") +
            (verificado ? `&verificado=${verificado}` : "");

          return this.feedService.getFeeds(params).pipe(
            map((res: IFeed) => {
              let aux: IFeedData[];
              let formatAux: IFeedData[] = res.data.map((item) => {
                return {
                  ...item,
                  formattedData: this.formatDate(item.published_at),
                };
              });

              if (auxData) {
                aux = auxData.concat(formatAux);

                return FeedsActions.getFeedsSuccess({
                  payload: { ...res, data: aux },
                });
              }

              return FeedsActions.getFeedsSuccess({
                payload: { ...res, data: formatAux },
              });
            }),
            catchError((error) =>
              of(FeedsActions.getFeedsError({ payload: error })),
            ),
          );
        },
      ),
    ),
  );

  getMyFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getMyFeed),
      switchMap(
        ({ payload: { page, per_page, empresa_id, verificado, auxData } }) => {
          const params =
            `?page=${page}&per_page=${per_page}` +
            (empresa_id ? `&empresa_id=${empresa_id}` : "") +
            (verificado ? `&verificado=${verificado}` : "");

          return this.feedService.getMyFeed(params).pipe(
            map((res: IFeed) => {
              let aux: IFeedData[];
              let formatAux: IFeedData[] = res.data.map((item) => {
                return {
                  ...item,
                  formattedData: this.formatDate(item.published_at),
                };
              });

              if (auxData) {
                aux = auxData.concat(formatAux);

                return FeedsActions.getMyFeedSuccess({
                  payload: { ...res, data: aux },
                });
              }

              return FeedsActions.getMyFeedSuccess({
                payload: { ...res, data: formatAux },
              });
            }),
            catchError((error) =>
              of(FeedsActions.getMyFeedError({ payload: error })),
            ),
          );
        },
      ),
    ),
  );

  getFeedByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getFeedByID),
      switchMap(({ payload: { id } }) => {
        return this.feedService.getFeedByID(id).pipe(
          map((res: IFeed) => {
            return FeedsActions.getFeedByIDSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(FeedsActions.getFeedByIDError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getReactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getReactions),
      switchMap(() => {
        return this.feedService.getReactions().pipe(
          map((res: IReactions[]) => {
            return FeedsActions.getReactionsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(FeedsActions.getReactionsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getInteractions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getInteractions),
      switchMap(({ payload: { page, per_page, auxData } }) => {
        const params = `?page=${page}&per_page=${per_page}`;
        return this.feedService.getInteractions(params).pipe(
          map((res: IInteractionFeedPage) => {
            let aux: IInteractionFeed[];
            if (auxData) {
              aux = auxData.concat(res.data);

              return FeedsActions.getFeedsSuccess({
                payload: { ...res, data: aux },
              });
            }

            return FeedsActions.getInteractionsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(FeedsActions.getInteractionsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getMessages),
      switchMap(() => {
        return this.feedService.getFeedMessages().pipe(
          map((res: IMessagesFeed[]) => {
            return FeedsActions.getMessagesSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(FeedsActions.getMessagesError({ payload: error })),
          ),
        );
      }),
    ),
  );

  setFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setFeed),
      switchMap(({ payload: { data } }) => {
        return this.feedService.setFeed(data).pipe(
          map((res: any) => {
            if (res?.error == true) {
              console.log("[error]=> ", res);
              this.showAlertModal("Erro ao publicar.", res.message);
              return FeedsActions.setFeedError({ payload: res });
            } else {
              return FeedsActions.setFeedSuccess({
                payload: res,
              });
            }
          }),
          catchError((error) => {
            console.log("[error]=> ", error);
            this.showAlertModal("Erro ao publicar.", error.message);
            return of(FeedsActions.setFeedError({ payload: error }));
          }),
        );
      }),
    ),
  );

  setReactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setReactions),
      switchMap(({ payload: { feed_id, feed_reaction_id } }) => {
        return this.feedService.setReactions(feed_id, feed_reaction_id).pipe(
          map((res: any) => {
            return FeedsActions.setReactionsSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(FeedsActions.setReactionsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  updateFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.updateFeed),
      switchMap(({ payload: { id, data } }) => {
        return this.feedService.updateFeed(id, data).pipe(
          map((res: any) => {
            if (res?.error == true) {
              console.log("[error]=> ", res);
              this.showAlertModal("Erro ao publicar.", res.message);
              return FeedsActions.updateFeedError({ payload: res });
            } else {
              this.openFeed();
              return FeedsActions.updateFeedSuccess({
                payload: res,
              });
            }
          }),
          catchError((error) => {
            console.log("[error]=> ", error);
            this.showAlertModal("Erro ao publicar.", error.message);
            return of(FeedsActions.setFeedError({ payload: error }));
          }),
        );
      }),
    ),
  );

  updateReactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.updateReactions),
      switchMap(({ payload: { feed_interaction_id, feed_reaction_id } }) => {
        return this.feedService
          .updateReactions(feed_interaction_id, feed_reaction_id)
          .pipe(
            map((res: any) => {
              return FeedsActions.updateReactionsSuccess({
                payload: res,
              });
            }),
            catchError((error) =>
              of(FeedsActions.updateReactionsError({ payload: error })),
            ),
          );
      }),
    ),
  );

  deleteReactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.deleteReactions),
      switchMap(({ payload: { feed_id, feed_interaction_id } }) => {
        return this.feedService.deleteReactions(feed_interaction_id).pipe(
          map((res: any) => {
            return FeedsActions.deleteReactionsSuccess({
              payload: feed_id,
            });
          }),
          catchError((error) =>
            of(FeedsActions.deleteReactionsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  deleteFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.deleteFeed),
      switchMap(({ payload: { feed_id } }) => {
        return this.feedService.deleteFeedByID(feed_id).pipe(
          map((res: any) => {
            return FeedsActions.deleteFeedSuccess({
              payload: feed_id,
            });
          }),
          catchError((error) => {
            console.log("[error]=> ", error);
            this.showAlertModal("Erro ao deletar.", error.message);
            return of(FeedsActions.deleteFeedError({ payload: error }));
          }),
        );
      }),
    ),
  );

  setViewInteractionFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setViewInteractionFeed),
      switchMap(({ payload: { feed_interaction_id } }) => {
        return this.feedService
          .setViewInteractionFeed(feed_interaction_id)
          .pipe(
            map((res: any) => {
              return FeedsActions.setViewInteractionFeedSuccess({
                payload: feed_interaction_id,
              });
            }),
            catchError((error) => {
              return of(
                FeedsActions.setViewInteractionFeedError({ payload: error }),
              );
            }),
          );
      }),
    ),
  );

  getReportMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getReportMessages),
      switchMap(() => {
        return this.feedService.getReportMessages().pipe(
          map((res: any) => {
            return FeedsActions.getReportMessagesSuccess({
              payload: res,
            });
          }),
          catchError((error) => {
            return of(FeedsActions.getReportMessagesError({ payload: error }));
          }),
        );
      }),
    ),
  );

  setFeedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setFeedReport),
      switchMap(({ payload }) => {
        return this.feedService.setFeedReport(payload).pipe(
          map((res: any) => {
            return FeedsActions.setFeedReportSuccess();
          }),
          catchError((error) => {
            return of(FeedsActions.setFeedReportError({ payload: error }));
          }),
        );
      }),
    ),
  );

  setBlockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setBlockUser),
      switchMap(({ payload }) => {
        return this.feedService.setBlockUser(payload).pipe(
          map((res: any) => {
            return FeedsActions.setBlockUserSuccess();
          }),
          catchError((error) => {
            return of(FeedsActions.setBlockUserError({ payload: error }));
          }),
        );
      }),
    ),
  );

  // get blocked users
  getBlockedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.getBlockedUsers),
      switchMap(() => {
        return this.feedService.getBlockedUsers().pipe(
          map((res: any) => {
            return FeedsActions.getBlockedUsersSuccess({ payload: res });
          }),
          catchError((error) => {
            return of(FeedsActions.getBlockedUsersError({ payload: error }));
          }),
        );
      }),
    ),
  );

  // set unblock user
  setUnblockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedsActions.setUnblockUser),
      switchMap(({ payload }) => {
        return this.feedService.setUnblockUser(payload).pipe(
          map((res: any) => {
            return FeedsActions.setUnblockUserSuccess();
          }),
          catchError((error) => {
            return of(FeedsActions.setUnblockUserError({ payload: error }));
          }),
        );
      }),
    ),
  );

  // show modal
  async showAlertModal(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  openFeed() {
    this.router.navigate(["/home/feed"], {
      state: {
        reloading: true,
      },
      replaceUrl: true,
    });
  }
}
