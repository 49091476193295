import { createReducer, on } from '@ngrx/store';
import * as ProfilePictureActions from '../actions/profilePicture.actions';

// import { Banner } from 'src/app/models/banner';

export interface ProfilePictureState {
  profilePicture: any;
}

const initialState: ProfilePictureState = {
  profilePicture: null,
};

export const profilePictureReducer = createReducer(
  initialState,
  on(
    ProfilePictureActions.setProfilePictureSuccess,
    (state, { payload: profilePicture }) => {
      return {
        ...state,
        profilePicture,
      };
    }
  )
);
