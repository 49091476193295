import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getRecipeItems = createAction(
  '[Orientations] Get Recipe Items',
  props<{
    payload: {
      type: 'receitas' | 'dicas';
    };
  }>(),
);

export const getRecipeItemsSuccess = createAction(
  '[Orientations] Get Recipe Items Success',
  props<{ payload: any }>(),
);

export const getRecipeItemsError = createAction(
  '[Orientations] Get Recipe Items Error',
  props<{ payload: HttpErrorResponse }>(),
);

export const getRecipeItem = createAction(
  '[Orientations] Get Recipe Item',
  props<{
    payload: {
      id: number;
    };
  }>(),
);

export const getRecipeItemSuccess = createAction(
  '[Orientations] Get Recipe Item Success',
  props<{ payload: any }>(),
);

export const getRecipeItemError = createAction(
  '[Orientations] Get Recipe Item Error',
  props<{ payload: HttpErrorResponse }>(),
);
