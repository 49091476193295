import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';

import * as ExerciseViewActions from '../actions/exerciseView.actions';

import { TrainingService } from 'src/app/services/training.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class ExerciseViewEffects {
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseViewActions.setExerciseView),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.EXERCISE_VIEW,
        }),
      ),
      switchMap(({ payload }) => {
        return this.trainingService.setExerciseView(payload).pipe(
          map((res: any) => {
            return ExerciseViewActions.setExerciseViewSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(ExerciseViewActions.setExerciseViewError({ payload: error })),
          ),
        );
      }),
    ),
  );

  setProfilePictureSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseViewActions.setExerciseViewSuccess),
      map(() => {
        return LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_VIEW,
        });
      }),
    ),
  );

  setProfilePictureError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExerciseViewActions.setExerciseViewError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.EXERCISE_VIEW,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private trainingService: TrainingService,
    private store: Store,
    private router: Router,
    private loadingController: LoadingController,
  ) {}
}
