enum loadingTypesEnum {
  ANNOTATION = 'annotation',
  APP_CONFIG = 'app_config',
  BANNERS = 'banners',
  CATEGORIES = 'categories',
  CATEGORIES_RECIPES = 'categories_recipes',
  CHANGE_PASSWORD = 'change_password',
  CODE_TO_REDEFINE_PASSWORD = 'code_to_redefine_password',
  COMPLETED_WORKOUT_WEEK = 'completed_workout_work',
  CONTINUE_TRAINING = 'continue_training',
  CUSTOM_PREMIUM = 'custom_premium',
  DAILY_WORKOUT_VIEWED = 'daily_workout_viewed',
  EXERCISE_RATING = 'exercise_rating',
  EXERCISES_BY_WORKOUT = 'exercises_by_workout',
  EXERCISE_VIEW = 'exercise_view',
  FAVORITE_CONTENT = 'favorite_content',
  FINISHED_WORKOUT = 'finished_workout',
  FORGOT_PASSWORD = 'forgot_password',
  GET_EXERCISE_BY_ID = 'get_exercise_by_id',
  HOME = 'home',
  INTERESTS = 'interests',
  LOGIN = 'login',
  MODALITIES = 'modalities',
  MY_FAVORITE_CONTENT = 'my_favorite_content',
  NEW_CUSTOM_WORKOUT = 'new_custom_workout',
  NEW_CUSTOM_WORKOUT_COMPLETED = 'new_custom_workout_completed',
  NOTIFICATIONS = 'notifications',
  OBJECTIVES = 'objectives',
  ORIENTATIONS = 'orientations',
  ORIENTATIONS_RATING = 'orientations_rating',
  PARTNERS = 'partners',
  PERSONALITY = 'personality',
  PERSONALSLIST = 'personals_list',
  PREMIUM = 'premium',
  PROFILE_PICTURE = 'profile_picture',
  RECIPE = 'recipe',
  RECOMMENDED = 'recommended',
  RECOMMENDED_ACCORDION = 'recommended_accordion',
  SCHEDULED_TRAINING = 'scheduled_training',
  SEARCH_CATEGORIES = 'search_categories',
  SEARCH_RESULT = 'search_result',
  STATE_CITY = 'state_city',
  TRAINING_PLAN_CUSTOM = 'training_plan_custom',
  TRAINING_PROGRAMS = 'training_programs',
  TRAINING_TYPES = 'training_types',
  UPDATE_CUSTOM_EXERCISE = 'update_custom_exercise',
  WORKOUT_SHEETS = 'workout_sheets',
  FEED = 'set_feed',
}

export { loadingTypesEnum };
