import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import * as OrientationsRatingActions from '../actions/orientationsRating.actions';

import { NutritionService } from 'src/app/services/nutrition.service';
import { Router } from '@angular/router';

import * as LoadingActions from '../actions/loading.actions';

// enum
import { loadingTypesEnum } from 'src/app/enums/loadingTypes.enum';

@Injectable()
export class OrientationsRatingEffects {
  // setOPersonality
  setStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsRatingActions.setOrientationsRating),
      tap(() =>
        LoadingActions.exibirLoading({
          payload: loadingTypesEnum.ORIENTATIONS_RATING,
        }),
      ),
      switchMap(({ payload }) => {
        return this.nutritionService.setNutritionRating(payload).pipe(
          map((res: any) => {
            return OrientationsRatingActions.setOrientationsRatingSuccess({
              payload: res,
            });
          }),
          catchError((error) =>
            of(
              OrientationsRatingActions.setOrientationsRatingError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setOrientationsRatingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsRatingActions.setOrientationsRatingSuccess),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.ORIENTATIONS_RATING,
        }),
      ),
    ),
  );

  setOrientationsRatingError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsRatingActions.setOrientationsRatingError),
      map(() =>
        LoadingActions.ocultarLoading({
          payload: loadingTypesEnum.ORIENTATIONS_RATING,
        }),
      ),
    ),
  );

  setOrientationLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsRatingActions.setOrientationLike),
      switchMap(({ payload }) => {
        return this.nutritionService.setNutritionLike(payload).pipe(
          map((res: any) => {
            return OrientationsRatingActions.setOrientationLikeSuccess();
          }),
          catchError((error) =>
            of(
              OrientationsRatingActions.setOrientationLikeError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  setOrientationDislike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrientationsRatingActions.setOrientationDislike),
      switchMap(({ payload }) => {
        return this.nutritionService.setNutritionDislike(payload).pipe(
          map((res: any) => {
            return OrientationsRatingActions.setOrientationDislikeSuccess();
          }),
          catchError((error) =>
            of(
              OrientationsRatingActions.setOrientationDislikeError({
                payload: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private nutritionService: NutritionService,
    private store: Store,
    private router: Router,
  ) {}
}
